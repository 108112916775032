import * as React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { green } from '@mui/material/colors';

export default function SelectPackagingUnit({ data, obj_fn, product, index, tipo }) {
  const handleChangeUnit = (event, id_item) => {
    if (/compra|venda/.test(tipo)) {
      // compra - venda
      obj_fn.setData((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    } else if (/medicamento|nutricao|producao/.test(tipo)) {
      // medicamentos - nutrição - produção
      obj_fn.setData((prevState) => ({
        ...prevState,
        lotes: prevState.lotes.map((lote) => {
          if (lote.id === id_item) {
            let aux_lote_prod = { ...lote.product };
            aux_lote_prod.packaging_unit = event.target.value;

            return { ...lote, product: aux_lote_prod };
          }
          return lote;
        }),
      }));
    } else if (/plantio|colheita/.test(tipo)) {
      // plnatio - colheita
      obj_fn.setData((prevState) => ({
        ...prevState,
        areas: prevState.areas.map((area_prev) => {
          if (area_prev.id === id_item) {
            if (area_prev.products) {
              // colheita - multiplos produtos
              let aux_area_prod = [...area_prev.products];
              for (var i = 0, tam = aux_area_prod.length; i < tam; i++) {
                if (aux_area_prod[i].id === product.id) {
                  aux_area_prod[i].packaging_unit = event.target.value;
                  i = tam;
                  return { ...area_prev, products: aux_area_prod };
                }
              }
            } else {
              // plnatio
              let aux_area_prod = { ...area_prev.product };
              aux_area_prod.packaging_unit = event.target.value;

              return { ...area_prev, product: aux_area_prod };
            }
          }
          return area_prev;
        }),
      }));
    } else if (/recursos/.test(tipo)) {
      // recursos
      obj_fn.setData((prevState) => {
        const updatedProducts = [...(prevState.resources?.products || [])];
        var selectedProduct = updatedProducts[id_item];
        selectedProduct.product.packaging_unit = event.target.value;
        selectedProduct.packaging_unit = event.target.value;

        return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
      });
    }

    if (obj_fn.setUpdated) {
      obj_fn.setUpdated(1);
      obj_fn.setData((prevState) => ({ ...prevState, edit_prod: true }));
    }
  };

  return (
    <>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="packaging_unit" color="success">
          Embalagem ou Medida
        </InputLabel>
        <Select
          labelId="packaging_unit"
          id="selectPackagingUnit"
          label="Embalagem ou Medida"
          name="packaging_unit"
          value={(/compra|venda/.test(tipo) ? data.packaging_unit : product?.packaging_unit) || 'unidade'}
          onChange={(e) => handleChangeUnit(e, index || 0)}
          disabled={!product?.volume_pkg}
          color="success"
          MenuProps={{
            sx: {
              '&& .Mui-selected': {
                backgroundColor: green[50],
              },
              '&& .MuiMenuItem-root': {
                '&:hover': {
                  backgroundColor: green[100],
                },
              },
            },
          }}
        >
          <MenuItem key={'medida'} value={'medida'}>
            Medida ({product?.unit_name})
          </MenuItem>
          <MenuItem key={'unidade'} value={'unidade'}>
            Unidade/Embalagem ({product?.packaging_name})
          </MenuItem>
        </Select>
        <FormHelperText>{/* Escolhida no cadastro do produto <br /> */}O produto será utilizado como embalagem completa ou pela sua medida específica?</FormHelperText>
      </FormControl>
    </>
  );
}
