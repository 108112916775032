import { useState } from "react";
import { Box, Grid, Typography, Stack, Divider } from "@mui/material";
import Typewriter from "../../../../../components/Typewriter/Typewriter";
import InputIcon from "@mui/icons-material/Input";
import { green } from "@mui/material/colors";
import ThreeDotsSpinner from "../../../../../components/Loader/ThreeDotsSpinner";
import DialogRegisterArea from "../../../../areasMaps/components/DialogRegisterArea";
import FormInputStep3AreasMapsAreasMapsRegister from "./FormInputStep3AreasMapsAreasMapsRegister";
import FormInputFooter from "./FormInputFooter";

export default function FormInputStep3AreasMaps({ data, obj_fn }) {
  const [showText, setShowText] = useState(false);
  const [addArea, setAddArea] = useState(0);

  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 500);

  // para validar o button continuar
  const isError = () => {
    // console.log(data.areas);
    if (data.areas.length >= 1) {
      return false;
    }
    return true;
  };

  // ativar o botao retornar
  const returnError = () => {
    return false;
  };

  const handleAssign = (area) => {
    setTimeout(() => {
      const newArea = obj_fn.usestore.reposAreas.getAreaMapStore('id', area.id)[0];
      // let aux_areas = data.areas.concat(newArea);
      obj_fn.setData((prevState) => ({
        ...prevState,
        areas: [newArea],
        nameArea: newArea.name
      }));

      setTimeout(() => {
        setAddArea(newArea.id);
      }, 300);
    }, 200);
  };

  return (
    <>
      <Grid container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <InputIcon />
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                Entradas dos animais
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item sx={{ mt: 1, height: 120 }}>
            <img style={{ maxHeight: 120 }} src="/dist/image/lotedeanimaisnaarea.png" alt="Lotes - Área" class="img-fluid" />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 0 }}>
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Seleção de área</Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mt: 2 }} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={7}>
          <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
            É possivel desenhar novas áreas clicando no botão {obj_fn.ismobile ? 'a baixo' : 'ao lado'}
          </Typography>
          <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
            A nova área será automaticamente vinculada à criação atual!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DialogRegisterArea setData={handleAssign} assign={data.project}/>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1, mb: 1, width: '90%', borderColor: green[100] }}/>

      <Grid item xs={12} alignItems="center" sx={{ mt: 2, mb: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} alignItems="center">
            <Typography fontWeight={700} gutterBottom>
              Selecione a área para o lote
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ height: 20 }}>
            {showText ? (
              <>
                <div className="d-lg-none">
                  <Typography gutterBottom style={{ color: green[800] }}>
                    <Typewriter tam={20} minHeight={0} text="É hora de informar onde o seu lote se encontra." />
                  </Typography>
                </div>

                <div className="d-none d-lg-block">
                  <Typography gutterBottom style={{ color: green[800] }}>
                    <Typewriter tam={0} minHeight={0} text="É hora de informar onde o seu lote se encontra." />
                  </Typography>
                </div>
              </>
            ) : (
              <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                {" "}
                <ThreeDotsSpinner />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={12} alignItems="center">
            <FormInputStep3AreasMapsAreasMapsRegister data={data} obj_fn={obj_fn} newArea={addArea} />
          </Grid>
        </Grid>
      </Grid>

      <FormInputFooter
        data={data}
        setData={obj_fn.setData}
        handleNext={obj_fn.handleNext}
        handleBack={obj_fn.handleBack}
        activeStep={obj_fn.activeStep}
        steps={obj_fn.steps}
        isError={isError}
        handleClose={obj_fn.handleClose}
        returnError={returnError}
      />
    </>
  );
}
