import { useEffect, useRef, useState } from "react";
import { Box, Card, CardActionArea, CardActions, CardMedia, Grid, InputAdornment, ListItemText, Stack, TextField, Typography } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import InputIcon from "@mui/icons-material/Input";
import FirstStepFormInputRadioButton from "../FormActivities/Input/FormInputStep1NumAnimalRadioButton";
import FormInputStep4PeriodBasicDatePickerLivestock from "../FormActivities/Input/FormInputStep4PeriodBasicDatePickerLivestock";
import EditFirstStepFormInputRadioButtonProgress from "./EditFirstStepFormInputRadioButtonProgress";
import AreaSelectDialog from "../../../livestock/components/addLivestock/AreaSelectDialog";
import ChipsArrayAreaMaps from "../../../livestock/components/addLivestock/ChipsArrayAreaMaps";
import FormEditInputFooter from "./FormEditInputFooter";

// Converte o valor numérico para uma string formatada
const formatValue = (num) => {
  // Verifica se num é um número; caso contrário, usa 0
  const number = isNaN(num) || num === '' ? 0 : Number(num)
  let str = number.toFixed(0)
  str = str.replace('.', ',') // Substitui ponto por vírgula
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, '.') // Adiciona separadores de milhar
}

export default function FormEditActivities({ data, setData, reposActivitiesInputs, handleClose, onSubmit }) {
  const [isLoading, setIsLoading] = useState(false);
  // validar o nome do lote
  const [validName, setValidName] = useState([true, ""]);
  const [numberAnimalsError, setNumberAnimalsError] = useState(false);
  // validar o lote no fomr
  //const [validateNameBatch, setValidateNameBatch] = useState(false);
  // console.log("FormEditActivities");
  // console.log(data);
  // para validar o input
  // para validar o input

  const validateActivityBatchName = (value) => {
    // console.log("validateActivityBatchName");
    // console.log(value);
    var nameBatch = value.trim();
    if (!nameBatch || nameBatch.length < 5) {
      setValidName([false, "Por favor, insira um nome válido para seu projeto!"]);
    } else {
      var list_activity = reposActivitiesInputs.getActivityInputStore("nameBatch", nameBatch);
      // console.log(list_activity);
      if (list_activity.length > 0) {
        var compare_activity = list_activity.filter((activity) => {
          if (activity.id != data.id) {
            return activity;
          }
        });
        if (compare_activity.length > 0) {
          setValidName([false, "Já existe outro Projeto com esse nome!"]);
        }
      } else {
        setValidName([true, ""]);
      }
    }
  };
  const returnValidName = (num) => {
    if (num > 0) {
      if (!validName[1]) {
        return <></>;
      } else {
        return (
          <p id="NameLivestockError" className="form-text text-danger is-invalid">
            {validName[1]}
          </p>
        );
      }
    } else {
      return validName[num];
    }
  };

  // atualizar os dados da aŕea no objeto data após mudança da area
  if (data?.areas?.length > 0) {
    // console.log("XXXXXXXXXXXXXXxxxxxx");
    data.areamap_use = data.areas[0].usedArea ? data.areas[0].usedArea : data.areas[0].area;
    if (data.areas[0].id != data.id_areamap) {
      // console.log("atualiza");
      data.id_areamap = data.areas[0].id;
      data.nameArea = data.areas[0].name;
    }
  }

  // função para receber os valores digitados do formulário
  const handleChangeInput = (event, blur) => {
    // console.log("handleChangeInput");
    var value = event.target.value;
    if (event.target.name === 'numberAnimals') {
      value = event.target.value.replace(/\D/g, '');
      value = parseFloat(value || '0');
      if (value > 999999999) {
        value = 999999999;
      }
    } else if (blur) {
      value = value.trim();
      validateActivityBatchName(value);
    }
    setData(prevState => ({ ...prevState, [event.target.name]: value }));
  };

  //iniico para validar o nome do lote
  useEffect(() => {
    // console.log("RENDER - validateActivityBatchName");
    validateActivityBatchName(data.nameBatch);
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    // if (data.nameBatch) {
    //   console.log("reposActivitiesInputs");
    //   console.log(reposActivitiesInputs.listActivityInput);
    //   for (const activity of reposActivitiesInputs.listActivityInput) {
    //     console.log(activity.nameBatch, data.nameBatch);
    //     if (activity.nameBatch === data.nameBatch) {
    //       console.log(activity.nameBatch, data.nameBatch);
    //       setValidateNameBatch(true);
    //       return true;
    //     } else {
    //       setValidateNameBatch(false);
    //     }
    //   }
    // }
    if (!data.nameBatch || data.nameBatch.trim() === "") {
      return true;
    }
    if (data.areas.length === 0) {
      return true;
    }
    if (!data.numberAnimals) {
      return true;
    }

    return false;
  };

  const returnError = () => {
    return true;
  };

  const inputRef = useRef(null);
  const handleFocus = (event) => {
    // Adiciona um pequeno atraso para garantir que a seleção ocorra após o campo receber foco
    setTimeout(() => {
      if (inputRef.current) {
        const length = event.target.value.length;
        event.target.setSelectionRange(length, length); // Posiciona o cursor no final do texto
      }
    }, 10);
  }

  return (
    <>
      <Box>
        <Grid container spacing={1} sx={{}}>
          <InputIcon />
          <Box sx={{ ml: 1, display: "flex" }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>
                Entradas dos animais
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Box sx={{ ml: 0, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Fase da Criação:</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3}>
          <Grid container spacing={2} sx={{ m: 2 }}>
            <Grid item xs={12} sm={12}>
              <FirstStepFormInputRadioButton data={data} setData={setData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ ml: 0, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Número de Animais:</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1, mb: 1 }} >
          <Grid item xs={12} sm={5} >
            <TextField
              inputRef={inputRef}
              id="numberAnimals"
              label="Número de Animais"
              name="numberAnimals"
              // type="text"
              inputMode="numeric"
              color="success"
              value={formatValue(data?.numberAnimals)}
              // onChange={handleChangeInput}
              onFocus={handleFocus}
              fullWidth
              sx={{ width: "100%",
                "& .MuiInputBase-input": {
                  textAlign: 'right',
                },
              }}
              variant="standard"
              helperText={<>Quantidade de animais entrando na propriedade<br />Campo obrigatório</>}
              InputProps={{
                inputProps: {
                  mask: Number,
                  maptoradix: ['.'],
                  thousandsseparator: '.',
                  scale: 1,
                  padfractionalzeros: 'true',
                  normalizezeros: 'true',
                  signed: "false",
                  min: 0,
                  max: 999999999,
                  onChange: handleChangeInput,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Box sx={{ ml: 0, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Nome do Lote:</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
          <TextField
            color="success"
            label="Nome do Lote"
            id="nameBatch"
            name="nameBatch"
            size="small"
            value={data.nameBatch}
            //{`Lote${capitalize(data.stage)}23/01`}
            //onChange={handleChangeInput}
            onChange={handleChangeInput}
            onBlur={(e) => handleChangeInput(e, true)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TitleIcon sx={{ color: "action.active" }} />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { color: "#808080" } }}
            variant="standard"
            error={!returnValidName(0)}
            helperText={returnValidName(1)}
          />
        </Grid>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Box sx={{ ml: 0, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área atual da Criação: {data.nameArea}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} alignItems="center" sx={{ my: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} alignItems="center">
              <Grid item xs={12} md={12}>
                <ListItemText primary="Trocar localização:" />
                {/* <ConfirmListAreasMapsLivestockImagens data={data} /> */}
              </Grid>
              <AreaSelectDialog data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading} />
              <Grid item xs={12} sm={12} sx={{ my: 1, display: "flex", alignItems: "flex-end" }}>
                <ChipsArrayAreaMaps data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading} />
              </Grid>
            </Grid>
            <Grid item xs={6} md={6} alignItems="center">
              <Grid item xs={12} md={12}>
                <ListItemText primary="Localização salva dos animais:" />
                {/* <ConfirmListAreasMapsLivestockImagens data={data} /> */}
              </Grid>

              <Grid container spacing={3} sx={{ my: 1 }}>
                {data.areas.map((area) => (
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia component="img" image={area.url || area.imageDecode} alt={'Área: ' + area.name} title={'Área: ' + area.name} />
                    <CardActionArea>
                      <CardActions>
                        <p>{area.label || area.name}: </p>
                        <p>
                          Área utilizada: {area.areamap_use ? area.areas_used[0].usedArea : area.area} ({area.measure})
                        </p>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Box sx={{ ml: 0, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Prazo para Realização da Atividade:</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
          <FormInputStep4PeriodBasicDatePickerLivestock data={data} setData={setData} />
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Box sx={{ ml: 0, display: "flex" }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Situação:</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
          <EditFirstStepFormInputRadioButtonProgress data={data} setData={setData} />
        </Grid>
        <FormEditInputFooter data={data} setData={setData} isError={isError} handleClose={handleClose} returnError={returnError} onSubmit={onSubmit} />
        {/* < FormInputFooter data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} isError={isError} handleClose={handleClose} returnError={returnError} /> */}
      </Box>
    </>
  );
}
