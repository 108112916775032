import { Box, Button } from '@mui/material';

export default function ImageTypeLivestock({ data }) {
  // validado pelo data.typenameLivestock
  // console.log("3-init-ImageTypeLivestock")
  // console.log(data.typenameLivestock)

  // useEffect(() => {

  // }, [data.typenameLivestock])

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
      <Button disabled variant="" color="success" style={{ minHeight: '15vh', minWidth: '25vh' }}>
        <div className="nav-link">
          <div className="mb-0" style={{ color: '#000' }}>
            Tipo de criação:
          </div>
          {data.typenameLivestock === 'Pecuária de Corte' && (
            <>
              <div className="justify-content-center ">
                <i>
                  <img src="/dist/img_fp/icon-button/icon-pecuaria.png" width="80" name="Pecuária de Corte"></img>
                </i>
              </div>
              <div className=".text-success mt-3" style={{ color: '#2e7d32' }}>
                Pecuária de Corte
              </div>
            </>
          )}
          {data.typenameLivestock === 'Pecuária de Leite' && (
            <>
              <div className="justify-content-center ">
                <i>
                  <img src="/dist/img_fp/icon-button/icon-pecuariadeleite.png" width="80" name="Pecuária de Leite"></img>
                </i>
              </div>
              <div className=".text-success mt-3" style={{ color: '#2e7d32' }}>
                Pecuária de Leite
              </div>
            </>
          )}
          {data.typenameLivestock === 'Suinicultura' && (
            <>
              <div className="justify-content-center ">
                <i>
                  <img src="/dist/img_fp/icon-button/icon-suinos.png" width="80" name="Suinicultura"></img>
                </i>
              </div>
              <div className=".text-success mt-3" style={{ color: '#2e7d32' }}>
                Suinicultura
              </div>
            </>
          )}
          {data.typenameLivestock === 'Avicultura' && (
            <>
              <div className="justify-content-center ">
                <i>
                  <img src="/dist/img_fp/icon-button/icon-galinha.png" width="80" name="Avicultura"></img>
                </i>
              </div>
              <div className=".text-success mt-3" style={{ color: '#2e7d32' }}>
                Avicultura
              </div>
            </>
          )}
          {data.typenameLivestock === 'Psicultura' && (
            <>
              <div className="justify-content-center ">
                <i>
                  <img src="/dist/img_fp/icon-button/icon-peixe.png" width="80" name="Psicultura"></img>
                </i>
              </div>
              <div className=".text-success mt-3" style={{ color: '#2e7d32' }}>
                Psicultura
              </div>
            </>
          )}
          {data.typenameLivestock === 'Outros' && (
            <>
              <div className="justify-content-center ">
                <i>
                  <img src="/dist/img_fp/icon-button/icon-lupa-mais.png" width="80" name="Outros"></img>
                </i>
              </div>
              <div className=".text-success mt-3" style={{ color: '#2e7d32' }}>
                Outros
              </div>
            </>
          )}
        </div>
      </Button>
    </Box>
  );
}
