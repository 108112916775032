import React, { useEffect, useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/system';
import { H6, Span } from '../../../components/Typography';
import GradingIcon from '@mui/icons-material/Grading';
import SendIcon from '@mui/icons-material/Send';
import api from '../../../services/api';
import CardMsnEdit from './forms/CardMsnEdit';
import { toast } from 'react-toastify';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { blue, green, grey } from '@mui/material/colors';
import useUserReposStore from '../../../store/useUserReposStore';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';
import EditNoteIcon from '@mui/icons-material/EditNote';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialogEdit({ id, post, setIsLoading }) {
  console.log('FullScreenDialogEdit');
  const usestore = useStore();
  const { aux_add, setAuxAdd } = useContext(Context);
  const [LoadingButton, setLoadingButton] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
    setLoadingButton(false);
  }
  function handleClose() {
    setOpen(false);
  }

  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [message, setMessage] = useState({});

  const display_demo = JSON.parse(localStorage.getItem('display'));

  useEffect(() => {
    loadPostsId();
    setIsLoadingEdit(true);
  }, [isLoadingEdit]);

  const loadPostsId = async () => {
    console.log('loadPostsId');
    //carregar repositorio dos users
    const optionsEquipe = usestore.reposUser.listUser;

    if (id) {
      //const response = await api.post(`/messenger/posts/${id}`);
      //console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx ")
      //console.log(response.data._value);
      //const info = response.data._value
      //console.log(response.data._value[0]);
      const info = usestore.reposMessenger.getMessengerStore('id', id)[0];
      for (const iterator of optionsEquipe) {
        if (iterator.id === info.id_requester) {
          info.name = iterator.name;
          const name = info.name;
          //console.log(name)
          const charName = name[0].toUpperCase();
          //console.log(charName)
          info.charName = charName;
        }
      }
      setMessage(info);
    }
  };

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const onSubmit = async (e) => {
    //setIsLoading(true)
    e.preventDefault();
    setLoadingButton(true);
    console.log('entrou no submit');

    setFormError({});
    let errors = {};
    let formOk = true;

    if (formOk) {
      const header = {
        header: {
          'Content-Type': 'application/json',
        },
      };
      const dateData = JSON.parse(localStorage.getItem('date'));
      message.date = dateData;
      const imageData = JSON.parse(localStorage.getItem('image'));
      message.key_image = imageData;
      const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
      message.id_receiver = userCurrent.id;
      const propertyData = JSON.parse(localStorage.getItem('property') || '1');
      message.id_property = propertyData;
      const statusMessenger = JSON.parse(localStorage.getItem('statusMessenger'));
      message.status = statusMessenger;
      const marker = JSON.parse(localStorage.getItem('marker'));
      // let decoded = await JSON.parse(base64_decode(res.data.coordinates));
      // let encoded = base64_encode(JSON.stringify(path));
      let encoded = base64_encode(JSON.stringify(marker));
      message.coordinates = encoded;
      console.log('messagexxxxxxxxxxxxxxx');
      console.log(message);
      // fazer
      const res = await api.put('/messenger', message, header);
      //const res = true
      console.log(res);
      if (res) {
        //console.log("entrou");
        localStorage.removeItem('image');
        localStorage.removeItem('date');
        //localStorage.removeItem("property");
        localStorage.removeItem('statusMessenger');
        localStorage.removeItem('marker');
        //setIsLoading(false)

        usestore.reposMessenger.fetch().then((res) => {
          setAuxAdd(aux_add + 1);
          setIsLoading(true);
          setTimeout(() => {
            handleClose();
          }, 1000);
        });
      }
    } else {
      // escreve mensagem de erro para cliente
      toast.error('Por favor, preencha os campos obrigatórios');
      setFormError(errors);
      //return false;
    }
  };

  return (
    <Box>
      {post.status === '0' ? (
        <Button variant="contained" size="small" onClick={handleClickOpen}>
          <EditNoteIcon /> Editar
        </Button>
      ) : post.status === '1' ? (
        <Button variant="contained" size="small" color="success" onClick={handleClickOpen}>
          <EditNoteIcon /> Editar
        </Button>
      ) : (
        <Button variant="contained" size="small" style={{ backgroundColor: grey[500] }} onClick={handleClickOpen}>
          <EditNoteIcon /> Editar
        </Button>
      )}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }} color="success">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }} className="m-0">
              Sistema de Solicitações{' '}
            </H6>
            {display_demo ? (
              <div></div>
            ) : (
              <Button color="inherit" onClick={onSubmit}>
                Enviar
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <section className="content ">
          <div className="container-fluid " style={{ maxWidth: 900 }}>
            <CardMsnEdit message={message} setMessage={setMessage} post={post} setIsLoading={setIsLoading} />
          </div>
        </section>

        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {display_demo ? (
                <div></div>
              ) : (
                <Button sx={{ width: 100, position: 'relative' }} color="success" variant="contained" type="submit" onClick={onSubmit}>
                  {LoadingButton ? (
                    <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
                  ) : (
                    <>
                      {' '}
                      <SendIcon />
                      <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Enviar</Span>{' '}
                    </>
                  )}
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </Box>
  );
}
