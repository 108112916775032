import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import RegisterPerson from './components/addPerson/RegisterPerson';
import CardPersons from './components/outros/CardPersons';
import { Groups } from '@mui/icons-material';
import ContactPageIcon from '@mui/icons-material/ContactPage';

export default function PersonContent() {
  return (
    <Box className="content-wrapper" sx={{ backgroundColor: 'white' }}>
      <Box className="content-header" sx={{ marginTop: 0 }}>
        <Box className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ height: 40, width: 40 }}>
                  <ContactPageIcon sx={{ ml: 1, height: 40, width: 40 }} color="success" />
                </Box>
                <Stack spacing={0.5} sx={{ ml: 2, mt: 0.5 }}>
                  <Typography fontWeight="500" variant="h6">
                    Cadastros
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={9} md={6}>
                  <RegisterPerson />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="content">
        <Box className="container-fluid">
          <Box className="card">
            <CardPersons />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
