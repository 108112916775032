import React, { useState } from 'react';
import { Modal, Box, CardMedia } from '@mui/material';

const ImageModalZoom = ({ imageUrl, altText = 'Image', triggerElement }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
        {triggerElement || <CardMedia component="img" height="140" image={imageUrl} alt={altText} />}
      </div>
      <Modal
        open={open}
        onClose={handleClose} // Fechar ao clicar fora
        closeAfterTransition
        aria-labelledby="image-modal"
        aria-describedby="image-modal-description"
      >
        <Box
          onClick={handleClose} // Garante fechamento ao clicar na área externa
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            bgcolor: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()} // Impede o clique na imagem de fechar o modal
            sx={{ maxWidth: '90%', maxHeight: '90%' }}
          >
            <img src={imageUrl} alt={altText} style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '4px' }} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ImageModalZoom;
