import React, { useState } from 'react';
//import { ThemeContext } from "./components/context-api/ThemeContext";
import Router from './routes/Router';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './components/Context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
//import { BrowserRouter } from "react-router-dom";

function App() {
  console.log('App');
  //const theme = useContext(ThemeContext);
  //const darkMode = theme.state.darkMode;
  console.log('App - Entrou no APP');
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById('spinner');
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = 'none';
      setLoading(false);
    }, 2000);
  }

  const authenticated_store = localStorage.getItem('authenticated');
  if (!authenticated_store || authenticated_store == 'false') {
    localStorage.clear();
    localStorage.setItem('authenticated', JSON.stringify(false));
  }

  return (
    !loading && (
      <div className="wrapper">
        <AuthProvider>
          <Router />
        </AuthProvider>
        <ToastContainer />
      </div>
    )
  );
}

export default App;
