import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { blue, green } from '@mui/material/colors';
import { Context } from '../../Context/AuthContext';
import Loading from '../../Loader/Loading';
import useStore from '../../../store/useStore';
import LoadActivityListLivestock from '../../LoadActivityList/LoadActivityListLivestock';
import LoadActivityListHarvest from '../../LoadActivityList/LoadActivityListHarvest';
import CardChatKanban from '../CardChatKanban';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ZoomIn, ZoomOut } from '@mui/icons-material';

export default function BoardTypes({ type }) {
  const { aux_add } = useContext(Context);
  const { idFarm, id } = useParams();

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredCollune, setColoumns] = useState([]);

  const loadActivityListLivestock = LoadActivityListLivestock();
  const loadActivityListHarvest = LoadActivityListHarvest();

  const usestore = useStore();

  const handleRows = () => {
    let filters = {
      table_active: 'realized',
      status: 'edit',
    };

    let rows_atv;
    if (type == 'harvest') {
      const currentHarvest = usestore.reposHarvest.getHarvestStore('id', id)[0];
      rows_atv = loadActivityListHarvest.loadList(currentHarvest, filters);
    } else {
      const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];
      rows_atv = loadActivityListLivestock.loadList(currentLivestok, filters);
    }

    setRows(rows_atv);
    let coloumns_aux = {};
    let coloumns = [];

    rows_atv.map((activityItem) => {
      if (!coloumns_aux[activityItem.activity]) {
        coloumns_aux[activityItem.activity] = activityItem.activity;
        coloumns.push({
          id: coloumns.length + 1,
          title: activityItem.activity,
        });
      }
    });

    setTimeout(() => {
      setIsLoadingTable(false);
      setColoumns(coloumns);
    }, 500);
  };

  useEffect(() => {
    console.log('refresh BoardTypes');
    handleRows();
  }, [aux_add]);

  const [zoomLevel, setZoomLevel] = useState(1);

  // Recupera o valor do zoom do localStorage ao carregar o componente
  useEffect(() => {
    const storedZoom = localStorage.getItem('zoomLevel');
    if (storedZoom) {
      setZoomLevel(parseFloat(storedZoom));
    }
  }, []);

  // Salva o valor do zoom no localStorage sempre que ele for alterado
  useEffect(() => {
    localStorage.setItem('zoomLevel', zoomLevel);
  }, [zoomLevel]);

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 2)); // Limita o zoom máximo a 2x
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Limita o zoom mínimo a 0.5x
  };

  // area do board
  return (
    <>
      <Box sx={{ ml: -3 }}>
        <div className="content-wrapper kanban p-0 m-0" style={{ backgroundColor: '#fff', font: '14px', color: '#333' }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col"></div>
              </div>
            </div>
          </section>
          {isLoadingTable ? (
            <>
              {' '}
              <Loading />{' '}
            </>
          ) : filteredCollune.length == 0 ? (
            <>
              <div className="d-flex justify-content-center p-5">
                <p>Nenhuma atividade de campo a ser monitorada</p>
              </div>
            </>
          ) : (
            <>
              <section className="content pb-3 ">
                <Box sx={{ display: 'flex' }}>
                  {/* <Stack spacing={0.5} sx={{ ml: 1, mt: 0 }}>
                    <Typography variant="subtitle2" color="textSecondary" fontWeight="500">
                      Dica: utilize as setas do teclado ou o scroll do mouse para visualizar todas informações
                    </Typography>
                  </Stack> */}
                  <Box sx={{ ml: 1, mb: 1 }}>
                    <Button onClick={handleZoomIn} variant="contained" color="inherit" startIcon={<ZoomIn />}>
                      Ampliar
                    </Button>
                    <Button onClick={handleZoomOut} variant="contained" color="success" startIcon={<ZoomOut />} sx={{ ml: 1 }}>
                      Reduzir
                    </Button>
                  </Box>
                </Box>
                <div
                  className="container-fluid h-100"
                  style={{
                    transform: `scale(${zoomLevel})`,
                    transformOrigin: 'top left',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                >
                  {filteredCollune.map((activity) => (
                    <div className={`card card-row `}>
                      <div className="card-header">
                        <h3 className="card-title">
                          <CheckBoxOutlineBlankIcon sx={{ mr: 0.5, mt: -0.4, color: green[700] }} /> {activity.title}
                        </h3>
                      </div>
                      <div className="card-body">
                        {rows
                          ?.filter((filteredPost) => filteredPost.activity === activity.title)
                          .map((post) => (
                            <CardChatKanban post={post} type={'tipos'} isLoading={isLoadingTable} />
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </>
          )}
        </div>
      </Box>
    </>
  );
}
