import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

// create the store
const useActivityWeightReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityWeight: [],
        listActivityWeightAnimals: [],
        listActivityWeightLotes: [],
        listActivityWeightLogs: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('activityWeight/list', item, header);
          const data = await response.data;

          let animal_weight = [];
          let lote_weight = [];
          let logs_weight = [];
          await set({
            listActivityWeight: data._value.map((activityWeight) => {
              animal_weight = animal_weight.concat(activityWeight.weight_animals);
              lote_weight = lote_weight.concat(activityWeight.weight_lotes);
              logs_weight = logs_weight.concat(activityWeight.logs);
              return {
                id: activityWeight.id,
                id_property: activityWeight.id_property,
                id_user: activityWeight.id_user,
                name_user: activityWeight.name_user,
                id_livestock: activityWeight.id_livestock,
                name: activityWeight.name,
                name_nf: activityWeight.name.toLowerCase(),
                total_weights: Number(activityWeight.num_animals) + Number(activityWeight.num_females) + Number(activityWeight.num_males),
                num_animals: Number(activityWeight.num_animals),
                num_females: Number(activityWeight.num_females),
                num_males: Number(activityWeight.num_males),
                weight: activityWeight.weight,
                unit: activityWeight.unit,
                date_start: dayjs(activityWeight.date_start).format('YYYY-MM-DD HH:mm:ss'),
                date_start_f: dayjs(activityWeight.date_start).format('DD/MM/YYYY'),
                date_prev_end: dayjs(activityWeight.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
                date_prev_end_f: dayjs(activityWeight.date_prev_end).format('DD/MM/YYYY'),
                date_end: activityWeight.date_end ? dayjs(activityWeight.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
                date_end_f: activityWeight.date_end ? dayjs(activityWeight.date_end).format('DD/MM/YYYY') : '',
                isDeleted: activityWeight.isDeleted,
                progress: activityWeight.progress,
                planning: activityWeight.planning,
                logs: activityWeight.logs,
                lote: typeof activityWeight.lote == 'string' ? JSON.parse(activityWeight.lote) : activityWeight.lote,
                weight_lotes: activityWeight.weight_lotes,
                weight_animals: activityWeight.weight_animals,
                tipo_repos: activityWeight.name,
                activity: activityWeight.name,
                type: 'activity',
                name_activity: activityWeight.name,
              };
            }),
          });

          set({
            listActivityWeightAnimals: animal_weight,
            listActivityWeightLotes: lote_weight,
            listActivityWeightLogs: logs_weight,
          });

          const lista = JSON.parse(localStorage.getItem('listActvs'));
          // remove pesagens que não sejam provenientes da atividade
          const lista_i = get().listActivityWeight.filter((activityWeight) => {
            return /^PESAGEM.*/.test(activityWeight.name_activity);
          });

          let array_aux = lista.filter((activity) => {
            if (!/PESAGEM.*/.test(activity.activity)) {
              return activity;
            }
          });
          const res = array_aux.concat(lista_i);
          localStorage.setItem('listActvs', JSON.stringify(res));

          return data;
        },
        // pega a(s) livestock(s) da listActivityWeight de acordo com o [key e val] informados
        getActivityWeightStore: (key, val) => {
          const list = get().listActivityWeight.filter((activityWeight) => {
            return activityWeight[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // // Adiciona uma nova livestock à listActivityWeight
        addActivityWeightStore: (livestock) => {
          set((state) => ({
            listActivityWeight: [...state.listActivityWeight, livestock],
          }));
        },
        // Gera uma nova listActivityWeight sem a activity informada
        delWeightStore: (id) => {
          set({
            listActivityWeight: get().listActivityWeight.filter((activity) => {
              return activity.id != id;
            }),
          });
        },
        // atualiza listActivityWeight de acordo com id, key e val informados
        updateActivityWeightStore: (id, key, val) => {
          set({
            listActivityWeight: get().listActivityWeight.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val;
                } else {
                  activity[key] = val;
                }
              }
              return activity;
            }),
          });
          return JSON.parse(JSON.stringify(get().listActivityWeight));
        },
        getAnimalWeightStore: (key, val) => {
          const list = get().listActivityWeightAnimals.filter((animal) => {
            return animal[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        //Ex: [['progress', 'CONCLUÍDA'], ['id', 1]] -> todos as pesagem concluídas do animal 1
        getCompostAnimalWeightStore: (keys) => {
          const list = get().listActivityWeightAnimals.filter((animal) => {
            let count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (animal[keys[i][0]] == keys[i][1]) {
                count++;
              }
            }
            return count == keys.length;
          });
          return JSON.parse(JSON.stringify(list));
        },
        getLoteWeightStore: (key, val) => {
          const list = get().listActivityWeightLotes.filter((lote) => {
            return lote[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        //Ex: [['progress', 'CONCLUÍDA'], ['id', 1]] -> todos as pesagem concluídas do lote 1
        getCompostLoteWeightStore: (keys) => {
          const list = get().listActivityWeightLotes.filter((lote) => {
            let count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (lote[keys[i][0]] == keys[i][1]) {
                count++;
              }
            }
            return count == keys.length;
          });
          return JSON.parse(JSON.stringify(list));
        },
        getLogsWeightStore: (key, val) => {
          const list = get().listActivityWeightLogs.filter((logs) => {
            return logs[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
      }),
      { name: 'reposActivityWeight' }
    )
  )
);

export default useActivityWeightReposStore;
