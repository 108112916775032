import * as React from 'react';
import { TextField, Box, Grid, Typography, InputAdornment, Stack, MenuItem, IconButton } from '@mui/material';
// import { FormControlLabel, Checkbox } from '@mui/material'
import { AccountBalance, Payment } from '@mui/icons-material';
import useStore from '../../../../store/useStore';
import SingleDateField from '../../../../components/Fields/SingleDateField';
import { green } from '@mui/material/colors';

const formPayments = [
  {
    value: 'dinheiro',
    label: 'Dinheiro',
  },
  {
    value: 'boleto',
    label: 'Boleto',
  },
  {
    value: 'cartao',
    label: 'Cartão',
  },
  {
    value: 'pix',
    label: 'Pix',
  },
];

const installment = [
  {
    value: 'a_vista',
    label: 'À vista / Pago',
  },
  ...Array.from({ length: 12 }, (_, index) => ({
    value: (index + 1).toString(),
    label: `${index + 1}x`,
  })),
  {
    value: '36',
    label: '36x',
  },
  {
    value: '48',
    label: '48x',
  },
];

// CONDIÇÕES DO PAGAMENTO
export default function FormPaymentCondition({ data, handleChange, obj_date, obj_fn }) {
  // console.log('FormPaymentCondition')
  const usestore = useStore();
  const bankList = usestore.reposBank.listBank;

  let title_txt = /.*(receber|receita).*/.test(data?.operationType?.toLowerCase()) ? 'Condições de recebimento' : 'Condições de pagamento';

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número
    if (data.installmentInterval !== value) {
      handleChange({ target: { name: e.target.name, value } });
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Payment sx={{ mr: 1 }} />
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>{title_txt}</Typography>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container spacing={3}>
          <Grid item xs={11} sm={4} sx={{ mx: 2 }}>
            <TextField
              required
              select
              fullWidth
              InputLabelProps={{ style: { color: green[800] } }}
              id="formPayment"
              name="formPayment"
              label={"Forma de " + (/.*(receber|receita).*/.test(data?.operationType?.toLowerCase()) ? "Recebimento" : "Pagamento")}
              value={data.formPayment}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <Payment />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="success"
            >
              {formPayments.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={11} sm={4} sx={{ mx: 2 }}>
            <TextField
              required
              select
              fullWidth
              InputLabelProps={{ style: { color: green[800] } }}
              id="bankAccount"
              name="bankAccount"
              label="Conta de Pagamento"
              value={data.bankAccount}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <AccountBalance />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="success"
              helperText="Defina a conta bancária"
            >
              {bankList.map((option) => (
                <MenuItem key={'bank_' + option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3} sx={obj_fn.ismobile ? { ml: 2 } : { mx: 2 } }>
            <TextField
              select
              fullWidth
              InputLabelProps={{ style: { color: green[800] } }}
              id="installment"
              name="installment"
              label="Parcelamento"
              value={data.installment}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              variant="outlined"
              color="success"
              helperText="Quantidade de parcelas"
              SelectProps={{
                MenuProps: {
                  sx: { height: "225px" },
                },
              }}
            >
              {installment.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={5} sm={2} sx={!obj_fn.ismobile ? { mx: 2 } : {} }>
            <TextField
              fullWidth
              id="installmentInterval"
              name="installmentInterval"
              autoComplete="installmentInterval"
              value={data.installmentInterval}
              label="Intervalo"
              variant="outlined"
              color="success"
              type="text"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                inputProps: {
                  maxLength: 3, // Limite de 3 caracteres
                },
              }}
              onChange={handleInputChange}
              onBlur={(e) => handleChange(e, true)}
              helperText="Dias entre as parcelas"
              disabled={data.installment === 'a_vista'}
            />
          </Grid>
          {obj_date && (
            <Grid item xs={11} sm={4} sx={{ mt: -3, mx: 2 }}>
              <SingleDateField data={data} obj_date={obj_date} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* <Grid item xs={5} sm={1} sx={{ mt: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.payed}
              color="success"
              onChange={(e) => handleChange(e, 'checkbox')}
              inputProps={{ 'aria-label': 'checkbox with default color' }}
            />
          }
          label={<Typography>Pago</Typography>}
          labelPlacement="end"
        />
      </Grid> */}
    </Grid>
  );
}
