import * as React from 'react'
import { AppBar, Button, Grid, Toolbar } from '@mui/material'
import { expandAndFade } from '../Animations/animationKeyFrames'

export default function FooterModal({ data, obj_fn, isError, returnError }) {
  return (
    <>
      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Grid item xs={4} sm={4} sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
              Cancelar
            </Button>
          </Grid>
          {data && (<>
            <Grid item xs={4} sm={4} sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button onClick={obj_fn.handleBack} color="success" disabled={returnError()}>
                Retornar
              </Button>
            </Grid>
            <Grid item xs={4} sm={4} sx={{ flexGrow: 1, textAlign: 'center' }}>
            {/* O botão Confirmar e Salvar, leva para tela successo */}
              <Button 
                color="success" 
                variant="contained" 
                type="submit" 
                onClick={!isError() ? obj_fn.handleNext : () => null} disabled={isError()}
                sx={{ my: 2, animation: `${expandAndFade} 2s linear infinite`, }}
              >
                {obj_fn.activeStep === obj_fn.steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
              </Button>
            </Grid>
          </>)}
        </Toolbar>
      </AppBar>
    </>
  )
}
