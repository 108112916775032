import React, { useState, useContext, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import BackgroundAuth from '../../../assets/png/planodefundof.png';
import LogoNameWhite from '../../../assets/png/logo-name-white.png';
import { Context } from '../../../components/Context/AuthContext';
import { toast } from 'react-toastify';
import hexSha1 from 'hex-sha1';
import api from '../../../services/api';
import './Login.css';
import { apiGetUserByEmail } from '../../../queries/apiGetUserByEmail';
import { CircularProgress } from '@mui/material';
import usePropertyReposStore from '../../../store/usePropertyReposStore';
import LoadingBdLogin from './LoadingBdLogin';
//import LoadingButton from "@mui/lab/LoadingButton";

export default function LoginContent() {
  const { handleLogout, handleLogin, setIsLoading, isLoading } = useContext(Context);
  const navigate = useNavigate();
  // ------ inicicia os repos das Images ------
  const fetchProperty = usePropertyReposStore((state) => state);

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [logado, setLoginOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDemo, setLoadingDemo] = useState(false);
  const [loadingBd, setLoadingBd] = useState(false);

  const loginSucess = (data) => {
    console.log('logado com sucesso');

    const token = data.token;
    localStorage.setItem('token', token);
    api.defaults.headers.common['token'] = token;
    const permission = data.permission;
    localStorage.setItem('permission', permission);
    const userData = data.userData;
    localStorage.setItem('userData', JSON.stringify(userData));

    const date = new Date();
    const hourToExpire = 172;
    date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000);
    localStorage.setItem('expiresTime', date);

    localStorage.setItem('navMenuActive', 'selectProperty');
    localStorage.setItem('ButtonActive', 'Central');

    apiGetUserByEmail(userData.email).then((response) => {
      console.log(response);
      if (!response) {
        setTimeout(() => {
          var user_aux = localStorage.getItem('userCurrent');
          user_aux = user_aux ? JSON.parse(localStorage.getItem('userCurrent')) : '1';
          fetchProperty.fetch(user_aux).then((res) => {
            setIsLoading(400);
            setLoginOk(true);
          });
        }, 1000);
      } else {
        fetchProperty.fetch(response.id).then((res) => {
          setIsLoading(400);
          setLoginOk(true);
        });
      }
    });
  };

  useEffect(() => {
    if (logado) {
      handleLogin();
    }
  }, [logado]);

  useEffect(() => {
    console.log(isLoading);
    if (logado && !isLoading) {
      navigate('/');
    } else if (isLoading === 'start') {
      const start = localStorage.getItem('Property');
      if (start) {
        // ainda está logado, mas fechou/recarregou o navegador
        setIsLoading(0);
        handleLogin();
      } else {
        // não está mais logado
        handleLogout();
      }
    }
  }, [isLoading]);

  const onSubmit = async (e, type) => {
    console.log('entrou - onSubmit');
    const header = { header: { 'Content-Type': 'application/json' } };
    let res = '';

    // criar uma tela para esperar o carregamento do create table
    setLoadingBd(true);

    try {
      if (type) {
        setLoadingDemo(true);
        res = await api.post('/users/authD', {}, header);
      } else {
        if (!email) {
          toast.error('O campo e-mail deve ser preenchido!');
          document.getElementById('login_email').focus();
          return false;
        } else if (!senha) {
          toast.error('O campo senha deve ser preenchido!');
          document.getElementById('login_password').focus();
          return false;
        }
        setLoading(true);
        res = await api.post('/users/auth', { email, senha }, header);
      }

      localStorage.clear();
      localStorage.setItem('authenticated', JSON.stringify(false));
      if (res.data.isSuccess) {
        toast.success('Logado com sucesso!');
        loginSucess(res.data._value);
        setTimeout(() => {
          setLoadingBd(false);
        }, 2000);
      } else {
        toast.error(res.data);
        setLoading(false);
        setLoadingDemo(false);
        setLoadingBd(false);
        return false;
      }
    } catch (error) {
      if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
        // Exibir uma mensagem de erro ou algo indicando que o servidor está offline
        toast.error('Opss... Desculpa!! O servidor está offline. Tente novamente mais tarde ou entre em contato com a CPTSoftwares!', {
          position: 'top-center', // Coloca o toast no topo
          autoClose: 5000, // Tempo para o toast desaparecer
          hideProgressBar: true, // Remove a barra de progresso
          closeOnClick: false, // Não permite fechar ao clicar
          draggable: false, // Não permite arrastar
          pauseOnHover: false, // Não pausa quando o mouse passa por cima
          style: {
            width: '100%', // Garante que o toast ocupe toda a largura da tela
            textAlign: 'center', // Alinha o texto ao centro
            padding: '10px 0', // Aumenta a altura do toast para dar mais espaço
            fontSize: '18px', // Aumenta o tamanho da fonte
          },
        });
      } else {
        // Caso ocorra outro erro (não relacionado à rede)
        // Caso ocorra outro erro (não relacionado à rede)
        toast.error('Erro ao tentar fazer login. Tente novamente mais tarde!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: false,
          style: {
            width: '100%',
            textAlign: 'center',
            padding: '10px 0',
            fontSize: '18px',
          },
        });
      }
      setLoading(false);
      setLoadingDemo(false);
      setLoadingBd(false);
    }
  };
  //loadingBd
  return (
    <>
      {loadingBd ? (
        <LoadingBdLogin />
      ) : (
        <div
          style={{
            backgroundImage: `url(${BackgroundAuth})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className=" login-page "
        >
          <div className="login">
            {/* /.login-logo */}
            <div className="login-logo " style={{ width: '340px', height: '200px' }}>
              {/* <img style={{ height: 70 }} src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="CPT" className="brand-image center" />
                        <h5 className="font-weight-normal mt-3 text-white"  >CPT Fazenda Premium</h5> */}
              <img className="m-3" src={LogoNameWhite} style={{ width: '290px', height: '90px' }} alt="CPT" />
              <h3 className=" text-white mt-3 ">Seja bem-vindo!</h3>
            </div>
            <div>
              <div className="card-body ">
                <p className="login-box-msg text-white">Acesse a sua conta</p>
                {/* temos um state: false e  de formulario null */}
                <form action="../../index3.html" method="post">
                  <div className="input-group mb-3">
                    <input id="login_email" type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Digite seu e-mail" />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    {/* <input type="password" onChange={e => setSenha(window.hex_sha1(e.target.value))} className="form-control" placeholder="Digite sua senha" /> */}
                    <input id="login_password" type="password" onChange={(e) => setSenha(hexSha1(e.target.value))} className="form-control" placeholder="Digite sua senha" />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <div className=" text-center mt-2 mb-1">
                    <NavLink to="#">
                      <button type="submit" onClick={onSubmit} className="btn btn-success  btn-block">
                        {loading ? <CircularProgress sx={{ mb: -0.6 }} size="1.3rem" style={{ color: '#fff' }} /> : 'Entrar'}
                      </button>
                    </NavLink>
                  </div>
                </form>
                <button type="submit" onClick={(e) => onSubmit(e, true)} className="btn btn-success  btn-block">
                  {loadingDemo ? <CircularProgress sx={{ mb: -0.6 }} size="1.3rem" style={{ color: '#fff' }} /> : 'Entrar - Versão demostrativa'}
                </button>
                <p className="mb-1 mt-2">
                  <Link to="/forgotpassword">
                    <span className="text-white ">Esqueceu a sua senha?</span>
                  </Link>
                </p>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.login-box */}
        </div>
      )}
    </>
  );
}
