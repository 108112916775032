import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { deDE } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField, Grid, Typography, Stack, useMediaQuery } from '@mui/material';

/* Exemple:
  let obj_date = {
    label: 'Data inicial do saldo',
    field_data: 'balance_date',
    text_helper: 'Defina a data em que o saldo da conta foi iniciado.',
    max: new Date(),
    handleChange: handleChangeDate,
    maxWidth: '250px',
    error_date: false,
    disabled: false,
  }
*/
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);
const weekdays = { '2ª': 'seg', '3ª': 'ter', '4ª': 'qua', '5ª': 'qui', '6ª': 'sex', Sa: 'sáb', Do: 'dom' };

export default function SingleDateField({ data, obj_date }) {
  const [value, setValue] = useState(dayjs(data[obj_date.field_data]));
  // Gerencia abertura do calendário
  const [open, setOpen] = useState(false);
  // Detecta telas menores que X: 768px ou Y: 650px
  const isMobile = useMediaQuery('(max-width: 768px) or (max-height: 650px)');
  const calendarRef = useRef(null);

  const isErrorDate = () => {
    let returne = false;
    if (!value.$D) {
      returne = true;
    } else if (obj_date.min && dayjs(value).isBefore(dayjs(obj_date.min), 'day')) {
      returne = true;
    } else if (obj_date.max && dayjs(value).isAfter(dayjs(obj_date.max), 'day')) {
      returne = true;
    }
    return returne;
  };

  const error_date = isErrorDate();
  const color = error_date ? 'error' : 'success';

  useEffect(() => {
    if (!dayjs(value).isSame(data[obj_date.field_data])) {
      setValue(data[obj_date.field_data]);
    }
  }, [data[obj_date.field_data]]);

  const handleClickOutside = (event) => {
    let parent_node = event.target.parentNode;
    let aux_tar_class = (parent_node).getAttribute('class');
    // valida se o elemento "clicado" foi fora do calendar
    let test_calendar = (
      /.*MuiPickersCalendar.*/.test(aux_tar_class) ||
      /.*MuiDayPicker.*/.test(aux_tar_class) || 
      /.*MuiPickersArrowSwitcher.*/.test(aux_tar_class) ||
      (parent_node).getElementsByClassName('MuiPickersCalendarHeader-root') || 
      (parent_node).getElementsByClassName('MuiPickersFadeTransitionGroup-root')
    );
    if (calendarRef.current && !calendarRef.current.contains(event.target) && !test_calendar) {
      setOpen(false); // Fecha o calendário ao clicar fora
    }
  };

  useEffect(() => {
    if (!isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (!isMobile) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [isMobile]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const DatePickerComponent = isMobile ? MobileDatePicker : DesktopDatePicker;

  return (
    <Grid container style={{ maxWidth: obj_date.maxWidth || '100%' }}>
      <Grid item xs={12} md={12} sx={{ my: 3, height: 125 }}>
        <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
          <Stack>
            <DatePickerComponent
              id={"id_" + obj_date.field_data}
              label={obj_date.label || 'Data'}
              value={value}
              open={isMobile ? undefined : open} // Define abertura manual para desktop
              onOpen={handleOpen}
              onClose={handleClose}
              onChange={(newValue) => {
                setValue(newValue);
                obj_date.handleChange(newValue, obj_date.id);
              }}
              adapterLocale="pt"
              inputFormat="DD/MM/YYYY"
              dayOfWeekFormatter={(day) => `${weekdays[day]}`}
              minDate={obj_date.min || null}
              maxDate={obj_date.max || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color={color}
                  error={!value.$D || error_date}
                  helperText={
                    !value.$D ||
                    (error_date && (
                      <p id="data_field_valid" className="form-text text-danger is-invalid">
                        Por favor, insira uma data válida.
                      </p>
                    ))
                  }
                />
              )}
              disabled={obj_date.disabled}
              ref={calendarRef}
            />
          </Stack>
          <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: color }}>
            {!value.$D || error_date ? 'Por favor, insira uma data válida.' : obj_date.text_helper}
          </Typography>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
