import React, { useState, useEffect, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Box, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { DeleteForever, TaskAlt } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Context } from '../../../../components/Context/AuthContext';
import useStore from '../../../../store/useStore';
import EditBank from './editBank/EditBank';
import DeleteBank from './editBank/DeleteBank';
import RestoreBank from './editBank/RestoreBank';

const columns = [
  { id: 'id', label: 'ID', minWidth: 50 },
  { id: 'accountNickname', label: 'Apelido da conta', minWidth: 150 },
  //{ id: 'receipt', label: 'Recebimento', minWidth: 100 },
  { id: 'bank', label: 'Banco', minWidth: 150 },
  {
    id: 'TypeAccount',
    label: 'Tipo de conta',
    minWidth: 170,
    //align: 'right',
    //format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'BillingStatus',
  //   label: 'Status da cobrança',
  //   minWidth: 100,
  //   //align: 'right',
  //   //format: (value) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'conciliation',
  //   label: 'conciliação',
  //   minWidth: 120,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: 'actions',
    label: 'Ações', // Nomeie a nova coluna como 'Ações'
    minWidth: 100,
    align: 'center',
  },
];

// Função para criar dados com valores iniciais para "Ações" (ícone)
function createDataRow(id, accountNickname, bank_icon, bank_title, TypeAccount, balanceOpening, conciliation, fullData) {
  return { id, accountNickname, bank_icon, bank_title, TypeAccount, balanceOpening, conciliation, fullData };
}

export default function TableBank({ type }) {
  // ---- controle dos intens ativos ----
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [rows, setRowsBank] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ---- controle dos intens na lixeira ----
  const [page_d, setPageD] = useState(0);
  const [rowsPerPage_d, setRowsPerPageD] = useState(10);
  const [isLoadingTable_d, setIsLoadingTableD] = useState(true);
  const [rows_d, setRowsBankD] = useState([]);

  const handleChangePageD = (event, newPage) => {
    setPageD(newPage);
  };
  const handleChangeRowsPerPageD = (event) => {
    setRowsPerPageD(+event.target.value);
    setPageD(0);
  };

  const { aux_add } = useContext(Context);
  // instancia os repos
  const usestore = useStore();
  const reposBank = usestore.reposBank;
  // const property_id = (localStorage.getItem("Property") || 1);

  useEffect(() => {
    console.log('refresh TablesBanks');
    setTimeout(() => {
      handleRows();
    }, 200);
  }, [aux_add]);

  const handleRows = () => {
    let rows_b = [];
    var data;
    const listBanks = JSON.parse(JSON.stringify(reposBank.listBank));

    var listBanks_row = listBanks.filter((item) => !item.isDeleted);
    for (const item of listBanks_row) {
      //console.log(item);
      if (!item.icon || !item.icon.$$typeof) {
        item.icon = usestore.reposBank.getBankIcon('id', item.id, 'icon');
      }
      data = createDataRow(item.id, item.name, item.icon, item.name_bank_title, item.type_title, item.balance_opening, '', item);

      rows_b.push(data);
    }
    rows_b.sort((a, b) => b.id - a.id);
    setRowsBank(rows_b);
    setIsLoadingTable(false);

    rows_b = [];
    listBanks_row = listBanks.filter((item) => item.isDeleted > 0);
    for (const item of listBanks_row) {
      //console.log(item);
      if (!item.icon || !item.icon.$$typeof) {
        item.icon = usestore.reposBank.getBankIcon('id', item.id, 'icon');
      }
      data = createDataRow(item.id, item.name, item.icon, item.name_bank_title, item.type_title, item.balance_opening, '', item);

      rows_b.push(data);
    }
    rows_b.sort((a, b) => b.id - a.id);

    setRowsBankD(rows_b);
    setIsLoadingTableD(false);
  };

  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        <Box sx={{ ml: 1, mt: 2, display: 'flex', flexDirection: 'column' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>
              <TaskAlt sx={{ color: green[600] }} /> Ativas{' '}
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead key={'th_'}>
              <TableRow key={'tr_'}>
                {columns.map((column) => (
                  <TableCell
                    key={'tc_' + column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <>
                    {isLoadingTable ? (
                      <>
                        <TableRow key={'void'}>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          {/* <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell> */}
                          {/* <TableCell align="right" height={67}><Skeleton variant="rectangular" height={34} /></TableCell> */}
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow hover role="checkbox" tabIndex={-1} key={'tr_l_' + row.id} style={{ backgroundColor: row.fullData.isDeleted > 0 ? 'auto' : 'white' }}>
                        <TableCell align="left"> {row.id} </TableCell>
                        <TableCell align="left"> {row.accountNickname} </TableCell>
                        <TableCell align="left">
                          {' '}
                          {row.bank_icon} {row.bank_title}{' '}
                        </TableCell>
                        <TableCell align="left"> {row.TypeAccount} </TableCell>
                        {/* <TableCell align="left"> {row.balanceOpening} </TableCell> */}
                        {/* <TableCell align="left"> {row.conciliation} </TableCell> */}
                        <TableCell align="right" name="EditBank" width={'115px'}>
                          <Box key={'bx_' + row.id} sx={{ p: 0, display: 'flex' }}>
                            <EditBank row_data={row.fullData} usestore={usestore} />
                            <DeleteBank row_data={row.fullData} usestore={usestore} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Linhas por página'}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Grid container sx={{ mt: 2 }}>
        <Box sx={{ ml: 1, mt: 2, display: 'flex', flexDirection: 'column' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>
              <DeleteForever sx={{ color: red[600] }} /> Lixeira{' '}
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead key={'th_d_'}>
              <TableRow key={'tr_d_'}>
                {columns.map((column) => (
                  <TableCell
                    key={'tc_d_' + column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows_d.slice(page_d * rowsPerPage_d, page_d * rowsPerPage_d + rowsPerPage_d).map((row) => {
                return (
                  <>
                    {isLoadingTable_d === true ? (
                      <>
                        <TableRow key={'void_d'}>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                          {/* <TableCell align="right" height={67}><Skeleton variant="rectangular" height={34} /></TableCell> */}
                          <TableCell align="right" height={67}>
                            <Skeleton variant="rectangular" height={34} />
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow hover role="checkbox" tabIndex={-1} key={'tr_ld_' + row.id} style={{ backgroundColor: row.fullData.isDeleted > 0 ? 'auto' : 'white' }}>
                        <TableCell align="left"> {row.id} </TableCell>
                        <TableCell align="left"> {row.accountNickname} </TableCell>
                        <TableCell align="left"> {row.bank} </TableCell>
                        <TableCell align="left"> {row.TypeAccount} </TableCell>
                        <TableCell align="left"> {row.balanceOpening} </TableCell>
                        {/* <TableCell align="left"> {row.conciliation} </TableCell> */}
                        <TableCell align="right" width={'112px'}>
                          <Box key={'bx_d_' + row.id} sx={{ p: 0, display: 'flex' }}>
                            <RestoreBank row_data={row.fullData} usestore={usestore} />
                            <DeleteBank row_data={row.fullData} usestore={usestore} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Linhas por página'}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows_d.length}
          rowsPerPage={rowsPerPage_d}
          page={page_d}
          onPageChange={handleChangePageD}
          onRowsPerPageChange={handleChangeRowsPerPageD}
        />
      </Paper>
    </>
  );
}
