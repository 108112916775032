import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardHeader, Chip, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import useStore from '../../store/useStore';
import api from '../../services/api';
import { Context } from '../../components/Context/AuthContext';
import { formatValue } from '../../utils/functions';
import TableBuyContent from './TableBuyContent';
import TableBuyDelete from './TableBuyDelete';
import { ExpandMore } from '@mui/icons-material';
import Typewriter from '../../components/Typewriter/Typewriter';
import { green } from '@mui/material/colors';

const columns = [
  {
    id: 'id',
    label: 'Num',
    minWidth: 50,
  },
  {
    id: 'date',
    label: 'Data do pedido',
    minWidth: 100,
  },
  {
    id: 'person',
    label: 'Fornecedor',
    minWidth: 150,
    align: 'left',
  },
  {
    id: 'amount',
    label: 'Valor (R$)',
    minWidth: 120,
    align: 'right',
  },
  {
    id: 'situation',
    label: 'Estoque',
    minWidth: 120,
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Ações',
    minWidth: 100,
    align: 'center',
  },
];

// Função para criar dados com valores iniciais para "Ações" (ícone)
function createDataRow(id, date, person, amount, situation, payed, inventory, item) {
  return { id, date, person, amount, situation, payed, inventory, item };
}

const formatRows = (list) => {
  var array = [];
  for (var i = 0, tam = list.length; i < tam; i++) {
    array.push(
      createDataRow(
        list[i].id,
        dayjs(list[i].date_entry).format('DD/MM/YYYY HH:mm'),
        list[i].person,
        formatValue(list[i].value),
        list[i].status,
        list[i].id_trans_pay ? true : false,
        list[i].id_trans_inv ? true : false,
        list[i]
      )
    );
  }
  return array;
};

export default function TableBuyOrder({ type }) {
  const usestore = useStore();
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;
  const { aux_add, setAuxAdd } = useContext(Context);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [rows, setRows] = useState([]);

  useEffect(() => {
    let setrows = formatRows(usestore.reposBuys.getBuyStore('status', type));
    setRows(setrows);
  }, [aux_add]);

  const handleStockClick = async (row) => {
    row.id_user = Number(userCurrent.id);
    row.id_property = Number(property_id);
    row.date_entry = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');

    const header = { header: { 'Content-Type': 'application/json' } };

    await api
      .post('/buy/inventory', row, header) // buy ou sales
      .then((response) => {
        //console.log(response.data);
        if (response.data.isSuccess) {
          usestore.reposProducts.fetch();
          usestore.reposLivestock.fetch();
          usestore.reposFinances.fetch();
          usestore.reposFinances.fetchT({ id_property: property_id });

          toast.success('Estoque da compra atualizado com sucesso!');
          usestore.reposBuys.fetch({ id_property: property_id }).then((res) => {
            setAuxAdd(aux_add + 1);
          });
        }
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        toast.error('Erro ao atualizar compra, tente novamente mais tarde!');
      });
  };

  const [openDel, setOpenDel] = useState(false);
  const [obj_fn, setObjFun] = useState({
    usestore: usestore,
    aux_add: aux_add,
    setAuxAdd: setAuxAdd,
    userCurrent: userCurrent,
    isOpen: 0,
  });
  const handleOpenDel = (open, item) => {
    setObjFun((prevState) => ({
      ...prevState,
      isOpen: item.id,
      row: item,
    }));
    setOpenDel(open);
  };
  const handleCloseDel = () => {
    setOpenDel(false);
    setObjFun((prevState) => ({
      ...prevState,
      isOpen: 0,
      row: null,
    }));
  };

  console.log(rows);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ width: '100%' }}>
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                        <Typography fontWeight="500" variant="subtitle1" style={{ color: '#808080' }}>
                          <strong>Menos tarefas manuais para você ganhar tempo</strong>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <span>Após realizar o pedido de compra, o sistema automaticamente atualiza o financeiro, e você poderá monitorar e dar baixa no estoque quando a mercadoria chegar.</span>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <strong>A mercadoria não chegou?</strong>
                          <span> É fácil fazer o estorno. Basta clicar em 'X', e o sistema atualizará automaticamente o financeiro e o estoque.</span>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex' }}>
                      <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                         <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <strong>
                            O pedido de compra é a formalização da autorização da compra por parte da sua empresa em relação ao fornecedor escolhido.
                          </strong>
                        </Typography> 
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <strong>Registre um pedido de compra: </strong>
                          <span> realize o pedido e rastre-o até à entrega final de forma simples.</span>
                        </Typography> 
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <span>Após realizar o pedido de compra, o sistema automaticamente atualiza o financeiro, e você poderá monitorar e dar baixa no estoque quando a mercadoria chegar.</span>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <strong>A mercadoria não chegou?</strong>
                          <span> É fácil fazer o estorno. Basta clicar em 'X', e o sistema atualizará automaticamente o financeiro e o estoque.</span>
                        </Typography>
                        <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                          <strong>Controle a sua compra: </strong>
                          <span>Visualize informações como: Detalhes do {type === 'expense' ? 'fornecedor' : 'cliente'}, Condições comerciais e Financeiras acordadas.</span>
                          <br />
                          <span>Além disso, o pedido de compra também o auxilia na gestão de estoque, permitindo a atualização do inventário conforme os pedidos são concluídos.</span>
                        </Typography> 
                      </Stack>
                    </Box>
                  </Grid> */}

                  {rows.length == 0 ? (
                    <Grid item xs={12} md={12}>
                      {type !== 'Finalizada' ? (
                        <Typography style={{ color: green[800] }}>
                          <Typewriter tam={60} minHeight={0} text={`No momento, não há pedidos de compra em aberto para recebimento.`} />
                        </Typography>
                      ) : (
                        <Typography style={{ color: green[800] }}>
                          <Typewriter tam={60} minHeight={0} text={`No momento, não há pedidos de recebidos.`} />
                        </Typography>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={12}>
                      <Box>
                        <Typography style={{ color: green[800] }}>
                          <div className="d-lg-none">
                            {type !== 'Finalizada' ? (
                              <Typewriter
                                tam={110}
                                minHeight={0}
                                text={`Pedidos de compra pendentes aguardando confirmação de entrega. Informe o recebimento clicando em "Adicionar ao Estoque" para concluir o processo. `}
                              />
                            ) : (
                              <Typewriter tam={110} minHeight={0} text={`Pedidos de compra que encontram-se finalizados. Os itens do pedido já encontram no estoque da propriedade.`} />
                            )}
                          </div>
                          <div className="d-none d-lg-block">
                            {type !== 'Finalizada' ? (
                              <Typewriter
                                tam={60}
                                minHeight={0}
                                text={`Pedidos de compra pendentes aguardando confirmação de entrega. Informe o recebimento clicando em "Adicionar ao Estoque" para concluir o processo. `}
                              />
                            ) : (
                              <Typewriter tam={60} minHeight={0} text={`Pedidos de compra que encontram-se finalizados. Os itens do pedido já encontram no estoque da propriedade.`} />
                            )}
                          </div>
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} md={12}>
                    <Box display="flex" justifyContent="flex-start">
                      <Typography fontWeight="500" sx={{ mt: 0 }} variant="subtitle1">
                        {type !== 'Finalizada'
                          ? 'Pedidos de compra que encontram-se em aberto. Informe o recebimento do pedido clicando em "Adicionar ao estoque" para finaliza-lo.'
                          : 'Pedidos de compra que encontram-se finalizados. Os itens do pedido foram recebidos'}
                      </Typography>
                    </Box>
                  </Grid> */}
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
      </Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="Lista de compras em aberto">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="left">{row.person}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="center" title="Estoque">
                    <Chip
                      label={!row.inventory ? 'Adicionar ao Estoque' : 'Estoque adicionado'}
                      color={!row.inventory ? 'info' : 'success'}
                      onClick={() => handleStockClick(row)}
                      style={{
                        marginRight: '10px',
                        borderRadius: '2', // cantos quadrados
                      }}
                      disabled={row.inventory}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ p: 0, display: 'flex' }}>
                      <TableBuyContent row={row.item} usestore={usestore} show_btn={true} />
                      <TableBuyDelete row={row.item} open={openDel} setOpen={handleOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn} show_btn={true} />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={'Linhas por página'}
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
