export default function areaProgress(area) {
  // console.log(harvest);
  const initialLabel = '';
  const initialAreas = 0;
  const fields = ['label', 'areas'];

  console.log(area);

  if (!area || area.length === 0) {
    return {
      progress: 0,
      status: {
        label: '?',
        areas: '?',
      },
    };
  }

  const current = area;
  let progress = 0;
  const status = {};

  if (current.length > 0) {
    if (current.length == 1) progress = 50;
    if (current.length > 2) progress = 100;
    status.areas = 'Ok';
  } else {
    status.areas = '?';
  }

  return { progress, status };
}
