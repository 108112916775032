import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { AppBar, Container, Divider, Grid, IconButton, Paper, Slide, Toolbar, Typography } from '@mui/material';
import HandymanIcon from '@mui/icons-material/Handyman';
import MultiStepFormMaintenance from './components/form/MultiStepFormMaintenance';
import CloseIcon from '@mui/icons-material/Close';
import { expandAndFade } from '../../components/Animations/animationKeyFrames';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterNewMaintenance() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //console.log("handleClickOpen");
    // console.log(open);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" sx={{ animation: `${expandAndFade} 2s linear infinite`, marginRight: 2 }} startIcon={<HandymanIcon />} color="success" onClick={handleClickOpen}>
        Nova manutenção
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> */}
        <AppBar sx={{ position: 'relative' }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div">
                  Manutenção
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <Box sx={{ width: '100%' }} id="focus_top_modal">
          <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
            {/* <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}> */}
            <MultiStepFormMaintenance handleClose={handleClose} />
            {/* </Paper> */}
          </Container>
        </Box>
      </Dialog>
    </>
  );
}
