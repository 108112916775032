import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from "../../services/api";
import dayjs from "dayjs";
import "dayjs/locale/pt";


function returnAreas(activity) {
  let areas = JSON.parse(activity.areas)
  areas.map((area) => {
    area.products = JSON.parse(area.products || '[]');
    return area;
  });
  return areas;
}

// create the store
const useActivityHarvestingReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityHarvesting: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem("Property") || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("activityHarvesting/list", item, header);
          const data = await response.data;

          await set({
            listActivityHarvesting: data._value.map((activityHarvesting) => {
              return {
                id: activityHarvesting.id,
                id_property: activityHarvesting.id_property,
                id_user: activityHarvesting.id_user,
                name_user: activityHarvesting.name_user,
                id_harvest: activityHarvesting.id_harvest,
                areas: returnAreas(activityHarvesting),
                /* produto utilizado por área
                  areas.map((area) => {
                    area.id_area
                    area.products -> []
                    area.products.map((product, index) => {
                      area.products[i]
                      area.products[i].quant_colhida
                      area.products[i].location_selected
                    })
                  })
                */
                date_start: dayjs(activityHarvesting.date_start).format("YYYY-MM-DD HH:mm:ss"),
                date_start_f: dayjs(activityHarvesting.date_start).format("DD/MM/YYYY"),
                date_prev_end: dayjs(activityHarvesting.date_prev_end).format("YYYY-MM-DD HH:mm:ss"),
                date_prev_end_f: dayjs(activityHarvesting.date_prev_end).format("DD/MM/YYYY"),
                date_end: activityHarvesting.date_end ? dayjs(activityHarvesting.date_end).format("YYYY-MM-DD HH:mm:ss") : "",
                date_end_f: activityHarvesting.date_end ? dayjs(activityHarvesting.date_end).format("DD/MM/YYYY") : "",
                description: activityHarvesting.description,
                isDeleted: activityHarvesting.isDeleted,
                info_areas: JSON.parse(activityHarvesting.info),
                id_transaction: activityHarvesting.id_transaction,
                info_transaction: activityHarvesting.info_transaction ? JSON.parse(activityHarvesting.info_transaction) : '',
                record: activityHarvesting.record ? JSON.parse(activityHarvesting.record) : '',

                type: "activity",
                activity: activityHarvesting.name,
                name_activity: activityHarvesting.name,
                progress: activityHarvesting.progress || "CONCLUÍDA",
                planning: activityHarvesting.planning || 'realized',
                tipo_repos: "COLHEITA",
              }
            })
          });

          const lista = JSON.parse(localStorage.getItem("listActvsH"));
          const lista_i = get().listActivityHarvesting;

          let array_aux = lista.filter((activity) => {
            return !/COLHEITA.*/.test(activity.activity);
          });
          const res = array_aux.concat(lista_i);
          localStorage.setItem("listActvsH", JSON.stringify(res));

          return data;
        },
        // pega o(s) colheita(s) da listActivityHarvesting de acordo com o [key e val] informados
        getActivityHarvestingStore: (key, val) => {
          const list = get().listActivityHarvesting.filter((activityHarvesting) => {
            return activityHarvesting[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // // Adiciona um nov colheita à listActivityHarvesting
        addActivityHarvestingStore: (colheita) => {
          set((state) => ({
            listActivityHarvesting: [...state.listActivityHarvesting, colheita],
          }));
        },
        // Gera uma nova listActivityHarvesting sem a activity informada
        delHarvestingStore: (id) => {
          set({
            listActivityHarvesting: get().listActivityHarvesting.filter((activity) => {
              if (activity.id != id) {
                return activity;
              }
            }),
          });
        },
        // atualiza listActivityHarvesting de acordo com id, key e val informados
        updateActivityHarvestingStore: (id, key, val) => {
          set({
            listActivityHarvesting: get().listActivityHarvesting.map((activity) => {
              if (activity.id === id) {
                if (key == "all") {
                  // atualiza todos os dados
                  activity = val;
                } else {
                  activity[key] = val;
                }
              }
              return activity;
            }),
          });
          return JSON.parse(JSON.stringify(get().listActivityHarvesting));
        },
      }),
      { name: "reposActivityHarvesting" }
    )
  )
);

export default useActivityHarvestingReposStore;
