import React, { useState } from 'react';
import { ButtonBase, Grid, Typography, Box, Stack, Modal, Paper, Input, List, ListItem, ListItemText } from '@mui/material';
import { Comment, Payments, PointOfSale } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import { green } from '@mui/material/colors';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WidgetsIcon from '@mui/icons-material/Widgets';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '160px',
    borderRadius: '8px',
    transition: 'border-color 0.3s',
  },
  selectedButton: {
    border: '2px solid red',
  },
}));

export default function FormEntryStep1({ data, obj_fn }) {
  const classes = useStyles();
  const [selectedOperation, setSelectedOperation] = useState('');
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);
  const [showText, setShowText] = useState(false);
  const [showContent, setShowContent] = useState(false);

  setTimeout(() => {
    setShowText(true);
    setTimeout(() => {
      setIsLoadingSkeleton(false);
    }, 400);
  }, 500);

  const handleSelectOperation = (operation) => {
    console.log('operation:', operation);
    setSelectedOperation(operation);
    setShowContent(true);
  };

  const handleChoice = (hasStockMovement) => {
    const operationType = hasStockMovement ? (selectedOperation === 'Despesa' ? 'Compra' : 'Venda') : selectedOperation === 'Despesa' ? 'Registrar Despesa' : 'Registrar Receita';

    obj_fn.setData((prevState) => ({
      ...prevState,
      operationType,
      operationName: selectedOperation,
      category: operationType === 'Compra' || operationType === 'Venda' ? 'Estoque' : 'Financeiro',
    }));

    obj_fn.handleNext();
    setShowContent(false);
  };

  const renderButton = (title, name, icon) => {
    console.log('Button name:', name);
    console.log('Selected Operation:', selectedOperation);

    return (
      <Grid item xs={6} md={6} key={name}>
        <ButtonBase
          component={Paper}
          elevation={2}
          onClick={() => handleSelectOperation(name)}
          className={classes.button}
          sx={{
            border: selectedOperation === name ? '1px solid black' : '2px solid transparent', // Verificação condicional
            borderRadius: '8px',
            transition: 'border-color 0.3s', // Animação suave
          }}
        >
          <Stack direction="column" alignItems="center">
            <Box height={45}>
              {React.cloneElement(icon, {
                style: { fontSize: 50, color: name === 'Despesa' ? 'red' : 'green' },
              })}
            </Box>
            <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
              {title}
            </Typography>
          </Stack>
        </ButtonBase>
      </Grid>
    );
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Lançamento: {data?.operationType}</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} sx={{ mb: 2 }}>
          <Typography style={{ color: green[800] }}>
            {!showText ? <ThreeDotsSpinner /> : <Typewriter text="Por favor, escolha o tipo de lançamento que deseja registrar:" variant={'subtitle1'} color={green[800]} />}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {renderButton('Despesas (Gastos ou Compras)', 'Despesa', <Payments />)}
        {renderButton('Receitas (Entradas Financeiras ou Vendas)', 'Receita', <PointOfSale />)}
      </Grid>
      {showContent && (
        <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 1, textAlign: 'center', mt: 0 }}>
          <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} sx={{ mb: 0 }}>
            <Typography style={{ color: green[800] }}>
              {!showText ? <ThreeDotsSpinner /> : <Typewriter text="Este lançamento envolve o estoque da propriedade?" variant={'subtitle1'} color={green[800]} />}
            </Typography>
          </Grid>
          {/* <Typography variant="h6" gutterBottom>
          
            Gasto não altera estoque ou animais, enquanto compra envolve entrada no estoque ou aquisição de animais.
          </Typography> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
            <Grid container spacing={3} sx={{ mb: 0 }}>
              <Grid item xs={12} md={6} alignItems="center" sx={{ mb: 0 }}>
                <ButtonBase
                  onClick={() => handleChoice(false)}
                  component={Paper}
                  elevation={2}
                  className={`${classes.button} ${selectedOperation === 'Despesa' ? classes.selectedButton : ''}`}
                  sx={{
                    p: 3, // Aumenta o espaço interno
                    borderRadius: 1,
                    flexDirection: 'column',
                    width: '100%', // Usa toda a largura disponível
                    minHeight: 200, // Altura mínima para o botão
                    display: 'flex', // Garante que o conteúdo centralize
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${selectedOperation === 'Despesa' ? 'red' : 'green'}`, // Condicional para a borda
                    transition: 'box-shadow 0.3s', // Animação suave para a sombra
                    '&:hover': {
                      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Efeito de sombra no hover
                      transform: 'scale(1.05)', // Eleva o botão
                      border: `2px solid ${selectedOperation === 'Despesa' ? 'red' : 'green'}`, // Condicional para a borda
                    },
                  }}
                >
                  {/* <Typography>Sem Movimentação</Typography> */}
                  <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                    {selectedOperation === 'Despesa' ? (
                      <strong style={{ color: 'red' }}>Somente Gastos (sem impacto no estoque) </strong>
                    ) : (
                      <strong style={{ color: 'green' }}>Somente Entradas Financeiras (sem impacto no estoque)</strong>
                    )}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                    Apenas registra no financeiro, sem impactar o estoque ou animais.
                  </Typography> */}
                  <List sx={{ mb: 2, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem
                      // key={value}
                      disableGutters
                      secondaryAction={<AttachMoneyIcon />}
                    >
                      <ListItemText primary={`Registra no financeiro`} />
                    </ListItem>
                    <ListItem
                      // key={value}
                      disableGutters
                      secondaryAction={<WidgetsIcon />}
                    >
                      <ListItemText primary={`Não impacta o estoque ou animais`} />
                    </ListItem>
                  </List>
                </ButtonBase>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" sx={{ mb: 2 }}>
                <ButtonBase
                  onClick={() => handleChoice(true)}
                  component={Paper}
                  elevation={2}
                  className={classes.button}
                  sx={{
                    p: 2, // Aumenta o espaço interno
                    borderRadius: 1,
                    flexDirection: 'column',
                    width: '100%', // Usa toda a largura disponível
                    minHeight: 200, // Altura mínima para o botão
                    display: 'flex', // Garante que o conteúdo centralize
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${selectedOperation === 'Despesa' ? 'red' : 'green'}`, // Condicional para a borda
                    transition: 'box-shadow 0.3s', // Animação suave para a sombra
                    '&:hover': {
                      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Efeito de sombra no hover
                      transform: 'scale(1.05)', // Eleva o botão
                      border: `2px solid ${selectedOperation === 'Despesa' ? 'red' : 'green'}`, // Condicional para a borda
                    },
                  }}
                >
                  {/* <Typography>Com Movimentação</Typography> */}
                  <Typography variant="body2" sx={{ mt: 0, color: 'text.secondary', textAlign: 'center' }}>
                    {selectedOperation === 'Despesa' ? (
                      <strong style={{ color: 'red' }}>Compra (inclui movimentação de estoque)</strong>
                    ) : (
                      <strong style={{ color: 'green' }}>Venda (inclui movimentação de estoque)</strong>
                    )}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                    Registra no financeiro e envolve a entrada no estoque ou entrada de animais.
                  </Typography> */}
                  <List sx={{ mb: 0, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem
                      // key={value}
                      disableGutters
                      secondaryAction={<AttachMoneyIcon />}
                    >
                      <ListItemText primary={`Registra no financeiro`} />
                    </ListItem>
                    <ListItem
                      // key={value}
                      disableGutters
                      secondaryAction={<WidgetsIcon />}
                    >
                      <ListItemText primary={`Envolve a saída no estoque ou saída de animais`} />
                    </ListItem>
                  </List>
                </ButtonBase>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
}
