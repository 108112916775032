import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardHeader, Container, Divider, FormHelperText, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Span } from '../../../components/Typography';
import ModalChangePassword from './ModalChangePassword';
import { Context } from '../../../components/Context/AuthContext';
import api from '../../../services/api';
import useStore from '../../../store/useStore';
import hexSha1 from 'hex-sha1';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function CardChangePassword() {
  const { aux_add, setAuxAdd } = useContext(Context);
  const usestore = useStore();
  const [open, setOpen] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  // const name = userCurrent.name || 'Usuário';
  // const email = userCurrent.email || 'email@exemplo.com';
  // const charName = name[0].toUpperCase(); // Primeiro caractere do nome

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      email: userCurrent.email,
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().min(6).required('Insira a sua senha atual'),
      password: Yup.string().min(6).required('Defina uma nova senha com no mínimo 6 caracteres'),
      confirmPassword: Yup.string()
        .min(6)
        .oneOf([Yup.ref('password')], 'As senhas não coincidem. Tente novamente'),
    }),
    onSubmit: async (data) => {
      // console.log('onSubmit');
      // console.log(data);
      const header = { header: { 'Content-Type': 'application/json' } };

      var data_aux = JSON.parse(JSON.stringify(data));
      data_aux.oldPassword = hexSha1(data_aux.oldPassword);
      data_aux.password = hexSha1(data_aux.password);
      data_aux.confirmPassword = hexSha1(data_aux.confirmPassword);

      await api
        .post('/users/changePass', data_aux, header)
        .then((response) => {
          if (!response.data.isSuccess) {
            return toast.error('Erro ao atualizar a Senha!');
          }
          toast.success(`Senha atualizada com sucesso!`);

          usestore.reposUser.fetch().then((res) => {
            setAuxAdd(aux_add + 1);
            setTimeout(() => {
              setOpen(true);
            }, 200);
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao atualizar a Senha!');
        });
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <>
      <Card sx={{ m: 2 }}>
        <CardHeader sx={{}} title={userCurrent.name || 'Cliente'} subheader={userCurrent.email || 'email@exemplo.com'}></CardHeader>
        <Divider />
      </Card>

      <Container component="main" maxWidth="sm" sx={{ mb: 3 }}>
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ mt: 0 }}>
              <Typography variant="subtitle1">Você pode atualizar sua senha para melhorar a segurança ou definir uma nova senha abaixo, caso tenha esquecido a anterior.</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0 }}>
              <Typography variant="subtitle1">
                <strong>Atualizando a senha:</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="oldPassword"
                label="Sua senha atual"
                variant="outlined"
                size="small"
                type="oldPassword"
                fullWidth
                error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
                onChange={formik.handleChange}
                value={formik.values.oldPassword}
                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="password"
                label="Sua nova senha"
                variant="outlined"
                size="small"
                type={showPassword ? 'text' : 'password'} // Toggling between 'text' and 'password'
                fullWidth
                error={Boolean(formik.touched.password && formik.errors.password)}
                onChange={formik.handleChange}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="confirmPassword"
                label="Confirmar a nova senha"
                variant="outlined"
                size="small"
                type={showPassword ? 'text' : 'password'} // Toggling between 'text' and 'password'
                fullWidth
                error={Boolean(formik.touched.password && formik.errors.password)}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Certifique-se de ter pelo menos 6 caracteres, incluindo um número e uma letra.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            {/* <Button onClick={formik.handleSubmit} variant="contained" disabled={isSubmitting} color="success">
              <Span sx={{ textTransform: 'capitalize' }}>{formik.isSubmitting ? 'Atualizado' : 'Atualizar'}</Span>
            </Button> */}
            <Button onClick={formik.handleSubmit} variant="contained" color="success">
              <Span sx={{ textTransform: 'capitalize' }}>Atualizar</Span>
            </Button>
          </Grid>
        </Paper>

        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>Definindo uma nova senha:</strong>
          </Typography>
          <NavLink to="/forgotpassword">
            <Button variant="contained" color="primary">
              <span className="text-black">Esqueci a minha senha.</span>
            </Button>
          </NavLink>
        </Grid>
      </Container>
      <Card sx={{}}>
        <Container component="main" maxWidth="sm" sx={{}}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ textAlign: 'center' }}>
              <Grid container xs={12} md={12} item alignItems="center" justifyContent="center">
                <img src="/dist/image/assinatura.png" name=""></img>
              </Grid>
              <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                Proteja suas informações.
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Troque a sua senha imediatamente sempre que desconfiar que ela foi descoberta ou que o computador no qual você a utilizou pode ter sido invadido ou infectado.
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Card>
      <ModalChangePassword open={open} setOpen={setOpen} />
      {/* {formik.isSubmitting && <ModalChangePassword start={true} />} */}
    </>
  );
}
