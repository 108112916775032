import React, { useState } from 'react';
import moment from 'moment';
import { Autocomplete, TextField, Button, Box, Grid, Typography, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Scale, ExpandMore } from '@mui/icons-material';
import FormIndividualWeightFooter from './FormIndividualWeightFooter';
import ModalIndividualWeightStep1 from './ModalIndividualWeightStep1';
import { calculaIdade } from '../../../../../components/Calculations/CalculaIdade';

export default function FormIndividualWeightStep1({ data, obj_fn }) {
  //1-texto
  // const [showTextFase, setShowTextFase] = useState(false);
  // setTimeout(() => {
  //   setShowTextFase(true);
  // }, 500);

  // Valida o button continuar
  const isError = () => {
    return !data.num_animals;
  };
  // Valida o button retornar
  const returnError = () => {
    return true;
  };
  function formatData(Inputdata) {
    if (!Inputdata) {
      return '';
    }
    return moment(Inputdata).format('DD/MM/YYYY');
  }

  const default_weight = {
    id: 0,
    identifier: '',
    brinco: '',
    sisbov: '',
    name: '',
    date_birth: '',
    date_birth_f: '',
    idade: '',
    ageMonths: '',
    lote: '',
    fase_manejo: '',
    raca: '',
    sexo: '',
    categoria: '',
    id_father: '',
    father: '',
    id_mother: '',
    mother: '',
    weight_list: [],
    last_weight: 0,
    last_weight_d: '',
    last_weight_d_h: '',
    new_weight: 0,
    new_gmd: '',
  };
  const [dados_animal, setDadosAnimal] = useState(default_weight);

  const handleChangeW = (event) => {
    let value = parseFloat(event.target.value || '0');
    obj_fn.setData((prevState) => ({ ...prevState, new_weight: value }));

    if (!value) {
      dados_animal.new_gmd = '';
    } else if (dados_animal.weight_list.length > 0) {
      let new_gmd = 0;
      new_gmd = (value - parseFloat(dados_animal.last_weight)) / (dados_animal.last_weight_d_h || 1);
      dados_animal.new_gmd = new_gmd.toFixed(2);
    }
  };

  const handleChange = (value, tipo) => {
    // console.log("handleChange");
    let selected_animal = obj_fn.livestockRepos.animais.filter((animal) => {
      return animal.id === value.id;
    });
    let animal = JSON.parse(JSON.stringify(selected_animal[0]));
    let aux_p = [];
    let aux_p_label = '';
    animal.idade = calculaIdade(animal.date_birth, 'completa');
    animal.ageMonths = calculaIdade(animal.date_birth, 'mes');
    animal.date_birth_f = formatData(animal.date_birth);

    if (animal.id_father) {
      aux_p_label = '';
      aux_p = obj_fn.usestore.reposLivestock.getAnimalsStore('id', animal.id_father);
      if (aux_p.length > 0) {
        aux_p_label += aux_p[0].identifier;
        aux_p_label += aux_p[0].brinco !== '' ? (aux_p_label.length > 0 ? '|' : '') + aux_p[0].brinco : '';
        aux_p_label += aux_p[0].sisbov !== '' ? (aux_p_label.length > 0 ? '|' : '') + aux_p[0].sisbov : '';
        //aux_p_label += aux_p[0].name !== '' ? (aux_p_label.length > 0 ? ' - ' : '') + aux_p[0].name : '';
        animal.father = aux_p_label;
      }
    }
    if (animal.id_mother) {
      aux_p_label = '';
      aux_p = obj_fn.usestore.reposLivestock.getAnimalsStore('id', animal.id_mother);
      if (aux_p.length > 0) {
        aux_p_label += aux_p[0].identifier;
        aux_p_label += aux_p[0].brinco !== '' ? (aux_p_label.length > 0 ? '|' : '') + aux_p[0].brinco : '';
        aux_p_label += aux_p[0].sisbov !== '' ? (aux_p_label.length > 0 ? '|' : '') + aux_p[0].sisbov : '';
        //aux_p_label += aux_p[0].name !== '' ? (aux_p_label.length > 0 ? ' - ' : '') + aux_p[0].name : '';
        animal.mother = aux_p_label;
      }
    }

    animal.weight_list = JSON.parse(JSON.stringify(obj_fn.usestore.reposActivityWeight.getAnimalWeightStore('id', animal.id)));
    animal.last_weight = animal.weight_list.length > 0 ? animal.weight_list[0].weight : 0;
    animal.last_weight_d = animal.weight_list.length > 0 ? formatData(animal.weight_list[0].date) : '';
    animal.last_weight_d_h = animal.weight_list.length > 0 ? parseFloat(calculaIdade(animal.weight_list[0].date, 'dia')) : '';

    obj_fn.setData((prevState) => ({ ...prevState, table_selected: animal }));
    obj_fn.setData((prevState) => ({ ...prevState, [tipo]: { id: animal.id, label: animal[tipo] } }));

    // reseta demais identificadores
    if (tipo !== 'identifier') {
      obj_fn.setData((prevState) => ({ ...prevState, identifier: { id: 0, label: '' } }));
    }
    if (tipo !== 'brinco') {
      obj_fn.setData((prevState) => ({ ...prevState, brinco: { id: 0, label: '' } }));
    }
    if (tipo !== 'sisbov') {
      obj_fn.setData((prevState) => ({ ...prevState, sisbov: { id: 0, label: '' } }));
    }
    if (tipo !== 'name') {
      obj_fn.setData((prevState) => ({ ...prevState, name: { id: 0, label: '' } }));
    }

    setDadosAnimal(animal);
  };

  const handleAddWeight = () => {
    let aux_weight_total = parseFloat(data.weight_total) + parseFloat(data.new_weight);
    let aux_weight_medio = aux_weight_total / (data.num_animals + 1);

    obj_fn.setData((prevState) => ({ ...prevState, weight_medio: aux_weight_medio.toFixed(2) }));
    obj_fn.setData((prevState) => ({ ...prevState, weight_total: aux_weight_total }));

    obj_fn.setData((prevState) => ({ ...prevState, num_animals: data.num_animals + 1 }));
    if (dados_animal.sexo === 'Macho') {
      obj_fn.setData((prevState) => ({ ...prevState, num_males: data.num_males + 1 }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, num_females: data.num_females + 1 }));
    }
    dados_animal.new_weight = data.new_weight;

    const aux_add_confirm = JSON.parse(JSON.stringify(data.table_confirm));
    aux_add_confirm.push(dados_animal);

    const aux_lote = JSON.parse(JSON.stringify(data.lote));
    aux_lote[dados_animal.id_lote] = { id: dados_animal.id_lote, label: dados_animal.lote };

    obj_fn.setData((prevState) => ({ ...prevState, lote: aux_lote }));
    obj_fn.setData((prevState) => ({ ...prevState, table_confirm: aux_add_confirm }));

    resetInfos();
  };
  // função para resetar as informações inserida na página
  const resetInfos = () => {
    obj_fn.setData((prevState) => ({ ...prevState, identifier: { id: 0, label: '' } }));
    obj_fn.setData((prevState) => ({ ...prevState, brinco: { id: 0, label: '' } }));
    obj_fn.setData((prevState) => ({ ...prevState, sisbov: { id: 0, label: '' } }));
    obj_fn.setData((prevState) => ({ ...prevState, name: { id: 0, label: '' } }));
    obj_fn.setData((prevState) => ({ ...prevState, new_weight: 0 }));

    setDadosAnimal(default_weight);
  };
  // const para validar o button add pesagem
  const isErrorAdd = () => {
    return !data.new_weight || !dados_animal.id;
  };
  const img_pesagem = '/dist/image/pesagem_bovino.png';

  return (
    <>
      <Grid container>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12} md={12} sx={{}}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5} sx={{ mt: -0.5 }}>
                  <Typography variant="h6" fontWeight={500}>
                    <Scale color="success" sx={{ mr: 1, width: '32px', height: '32px' }} /> Pesagem individual do animal
                  </Typography>
                </Stack>
              </Box>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle2" align="center" gutterBottom>
                  Clique em identificador para selecionar o animal e depois entre com a pesagem atual.
                </Typography>
              </Grid>
            </Grid>

            {/* Primeiro bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {/* Bloco esquerda */}
              <Grid item xs={12} md={4} sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                      <strong>Identificador Automatizado</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <i>
                        {' '}
                        <img src="/dist/image/pesagem_dispositivo.png" width="140" alt="Dispositivo de Pesagem de Animais" />{' '}
                      </i>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button disabled variant="contained">
                        Conectar Dispositivo
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {/* Bloco direita */}
              <Grid item xs={12} md={8} sx={{ mt: 0, p: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                      <strong>Identificadores físicos *</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mb: 1.5 }}>
                    <Autocomplete
                      disableClearable
                      freeSolo
                      size="small"
                      filterSelectedOptions
                      value={data.identifier}
                      getOptionLabel={(option) => (option && option.label ? option.label : '')}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={data.options_identifier}
                      onChange={(event, newValue) => {
                        handleChange(newValue, 'identifier');
                      }}
                      id="selectIdentifier"
                      name="selectIdentifier"
                      ListboxProps={{ style: { maxHeight: 200 } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Identificador"
                          color="success"
                          placeholder={dados_animal.identifier ? dados_animal.identifier : 'Identificador'}
                          focused
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <strong> </strong>,
                          }}
                        />
                      )}
                      noOptionsText="Sem resultados"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ mb: 1.5 }}>
                    <Autocomplete
                      disableClearable
                      freeSolo
                      size="small"
                      filterSelectedOptions
                      value={data.brinco}
                      getOptionLabel={(option) => (option && option.label ? option.label : '')}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={data.options_brinco}
                      onChange={(event, newValue) => {
                        handleChange(newValue, 'brinco');
                      }}
                      id="selectBrinco"
                      name="selectBrinco"
                      ListboxProps={{ style: { maxHeight: 200 } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Brinco"
                          color="success"
                          placeholder={dados_animal.brinco ? dados_animal.brinco : 'Brinco'}
                          focused
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <strong> </strong>,
                          }}
                        />
                      )}
                      noOptionsText="Sem resultados"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ mb: 1.5 }}>
                    <Autocomplete
                      disableClearable
                      freeSolo
                      size="small"
                      filterSelectedOptions
                      value={data.sisbov}
                      getOptionLabel={(option) => (option && option.label ? option.label : '')}
                      isOptionEqualToValue={(option, value) => (!value ? '' : option.id === value.id)}
                      options={data.options_sisbov}
                      onChange={(event, newValue) => {
                        handleChange(newValue, 'sisbov');
                      }}
                      id="selectSisbov"
                      name="selectSisbov"
                      ListboxProps={{ style: { maxHeight: 200 } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Sisbov"
                          color="success"
                          placeholder={dados_animal.sisbov ? dados_animal.sisbov : 'Sisbov'}
                          focused
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <strong> </strong>,
                          }}
                        />
                      )}
                      noOptionsText="Sem resultados"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ mb: 1.5 }}>
                    <Autocomplete
                      disableClearable
                      freeSolo
                      size="small"
                      filterSelectedOptions
                      value={data.name}
                      getOptionLabel={(option) => (option && option.label ? option.label : '')}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={data.options_name}
                      onChange={(event, newValue) => {
                        handleChange(newValue, 'name');
                      }}
                      id="selectName"
                      name="selectName"
                      ListboxProps={{ style: { maxHeight: 200 } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Nome"
                          color="success"
                          placeholder={dados_animal.name ? dados_animal.name : 'Nome'}
                          focused
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <strong> </strong>,
                          }}
                        />
                      )}
                      noOptionsText="Sem resultados"
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button color="success" sx={{ width: '300px' }} variant="outlined">Carregar dados</Button>
                </Grid> */}
                </Grid>
              </Grid>
            </Grid>

            {/* Segundo bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography> Dados do Animal</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: '-15px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Grid container spacing={0} sx={{ mb: 0 }}>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={2} sx={{ mt: 0, mb: 1 }}>
                            <Grid item xs={12} md={4}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                size="small"
                                //sx={{ width: 150 }}
                                id="date_birth"
                                name="date_birth"
                                label="Data de Nascimento"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.date_birth_f}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                //sx={{ width: 150 }}
                                size="small"
                                id="ageMonths"
                                name="ageMonths"
                                label="Idade em Meses"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.ageMonths}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                size="small"
                                //sx={{ width: 150 }}
                                id="lote"
                                name="lote"
                                label="Lote"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.lote}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                            <Grid item xs={12} md={4}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                size="small"
                                //sx={{ width: 150 }}
                                id="race"
                                name="race"
                                label="Raça"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.raca}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                //sx={{ width: 150 }}
                                size="small"
                                id="sexo"
                                name="sexo"
                                label="Sexo"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.sexo}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                //sx={{ width: 150 }}
                                size="small"
                                id="fase_name"
                                name="fase_name"
                                label="Fase Manejo"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.fase_name}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                size="small"
                                //sx={{ width: 150 }}
                                id="father"
                                name="father"
                                label="Pai"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.father}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                InputLabelProps={{
                                  //style: { color: "#2e7d32" },
                                  shrink: true,
                                }}
                                disabled
                                //required
                                //sx={{ width: 150 }}
                                size="small"
                                id="mother"
                                name="mother"
                                label="Mãe"
                                fullWidth
                                autoComplete="shipping property-line1"
                                variant="outlined"
                                color="success"
                                value={dados_animal.mother}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* terceiro bloco */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {/* Bloco esquerda */}
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Pesagem Anterior</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      {' '}
                      <i>
                        {' '}
                        <img src={img_pesagem} width="100" alt="Pesagem de Animais" />{' '}
                      </i>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ pt: 2, pl: 4, pr: 4, background: '#e0e0e0' }}>
                      <Typography variant="h3" gutterBottom sx={{ color: '#878787' }}>
                        <strong>{dados_animal.last_weight}</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <strong>Data: </strong> {dados_animal.last_weight_d}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <strong>Dias até hoje: </strong> {dados_animal.last_weight_d_h}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Bloco direita */}
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Pesagem Atual</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      {' '}
                      <i>
                        {' '}
                        <img src={img_pesagem} width="100" alt="Pesagem de Animais" />{' '}
                      </i>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ pt: 2, pl: 4, pr: 4 }}>
                      <TextField
                        //label="Peso médio dos animais"
                        name="weight"
                        id="weight"
                        type="number"
                        color="success"
                        variant="standard"
                        fullWidth
                        disabled={!dados_animal.id}
                        sx={{
                          m: 1,
                          width: '100%',
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                          },
                        }}
                        InputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          maxLength: 12,
                          min: 0,
                          autoFocus: true,
                          style: {
                            color: '#1F8505',
                            fontSize: '3rem',
                            marginBottom: '0.35em',
                            fontWeight: 400,
                            lineHeight: 1.167,
                            autoFocus: true,
                          },
                        }}
                        value={data.new_weight}
                        onChange={handleChangeW}
                        onBlur={(e) => handleChangeW(e, true)}
                      />
                    </Box>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <strong>GMD:</strong> {dados_animal.new_gmd}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} md={12} sx={{ mt: -1, mb: -1, display: 'flex', justifyContent: 'flex-start' }}>
                    <FormControlLabel control={<Checkbox defaultChecked color="success" />} label="Peso Manual" />
                  </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* terceiro bloco */}
            <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
              <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <ModalIndividualWeightStep1 data={data} obj_fn={obj_fn} />
              </Grid>
              <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      <strong>Animais Pesados:</strong> {data.num_animals}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      <strong>Peso Médio:</strong> {data.weight_medio}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button color="success" variant="contained" type="submit" onClick={!isErrorAdd() ? handleAddWeight : () => null} disabled={isErrorAdd()}>
                  Adicionar pesagem
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FormIndividualWeightFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
