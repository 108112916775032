import * as React from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardContent, CardHeader, Chip, Divider, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import IEProgressBars from './IEProgressBars';
import { green } from '@mui/material/colors';
import CircularStaticIE from './CircularStaticIE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from 'react';
import { useState } from 'react';
import IconsLivestockActivities from '../../../components/Icons/IconsLivestockActivities';
import SpaIcon from '@mui/icons-material/Spa';
import MapIcon from '@mui/icons-material/Map';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LightTooltip from '../../../components/Tooltip/LightTooltip';
import propertyProgress from './propertyProgress';
import propertySettingsProgress from './propertySettingsProgress';
import livestockProgress from './livestockProgress';
import harvestProgress from './harvestProgress';

export default function CardIR({ newData, property, livestock, harvest }) {
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const user = userCurrent;
  const name = user.name;
  const charName = name[0].toUpperCase();

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }

  return (
    <Card>
      <CardContent>
        <Grid>
          <Grid xs={12}>{/* <IEProgressBars bar={bar} /> */}</Grid>
          <Grid xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Passos iniciais</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <SettingsSuggestIcon widthStyles={'30px'} />
                    <LightTooltip
                      title={`Nome da propriedade(${propertySettingsProgress(property).status.label}) \n Localização no Mapa(${
                        propertySettingsProgress(property).status.address
                      }) \n Área total da propriedade(${propertySettingsProgress(property).status.area})`}
                    >
                      <strong className="ml-1 ">Configurações</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[0]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails>

              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <MapsHomeWorkIcon widthStyles={'30px'} />
                    <LightTooltip
                      title={`Nome da propriedade(${propertyProgress(property).status.label}) \n Localização no Mapa(${propertySettingsProgress(property).status.address}) Área total da propriedade(${
                        propertyProgress(property).status.area
                      }) \n  Telefone(${propertyProgress(property).status.tel}) \n  Imagem(${propertyProgress(property).status.image})`}
                    >
                      <strong className="ml-1 ">Propriedade</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[1]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails>

              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <IconsLivestockActivities widthStyles={'30px'} />
                    <LightTooltip
                      title={`Nome do projeto(${livestockProgress(livestock).status.label}) \n Quantidade de áreas(${livestockProgress(livestock).status.areas}) 
                      Animais(${livestockProgress(livestock).status.animais}) \n `}
                    >
                      <strong className="ml-1 ">Criações</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[2]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails>

              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <SpaIcon style={{ fontSize: 30 }} />
                    <LightTooltip title={`Nome do projeto(${harvestProgress(harvest).status.label}) \n Quantidade de áreas(${harvestProgress(harvest).status.areas})`}>
                      <strong className="ml-1 ">Safras</strong>
                    </LightTooltip>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[3]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <AccordionDetails>
                <Grid container direction="row" alignItems="center">
                  <Grid xs={2}>
                    <MapIcon style={{ fontSize: 30 }} /> <strong className="ml-1 ">Áreas</strong>
                  </Grid>
                  <Grid xs={10}>
                    <IEProgressBars percentage={newData[4]?.porcentagem} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
