import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { Close, Delete, DeleteForever } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';
import Typewriter from '../../../../components/Typewriter/Typewriter';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="Fechar"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TrashActivitiesHarvests({ row, usestore }) {
  const [open, setOpen] = useState(false);
  const { aux_add, setAuxAdd } = useContext(Context);
  const [loadingButton, setLoadingButton] = useState(false);
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleCloseCancel = () => {
    setOpen(false);
  }

  // atualiza a tabela
  const handleTrash = async (e) => {
    e.preventDefault();
    setLoadingButton(true);

    const header = { header: { 'Content-Type': 'application/json' } };
    const status = 'trash';
    let url = '';
    let reposActivity = {};

    if (row.activity === 'PLANTIO') {
      url = `/activityPlanting/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityPlanting;
    } else if (row.activity === 'COLHEITA') {
      url = `/activityHarvesting/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityHarvesting;
    } else if (row.activity === 'MONITORING') {
      url = `/activityMonitoring/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityMonitoring;
    } else if (row.activity === 'PREPARAÇÃO DO SOLO') {
      url = `/activitySoilPreparation/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivitySoilPreparation;
    } else if (row.activity === 'APLICAÇÃO') {
      url = `/activityApplications/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityApplications;
    } else if (/.*VINCULA.*/.test(row.activity)) {
      url = `/activityAssignArea/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityAssignArea;
    }

    // const res = await api
    await api.delete(url, header)
      .then((response) => {
        // console.log(response.data)
        if (response.data.isSuccess) {
          // atualiza o repos
          toast.success(`Atividade movida para a lixeira com sucesso!`);
          setLoadingButton(false);
          reposActivity.fetch().then((res) => {
            setAuxAdd(aux_add + 1);
            setTimeout(() => {
              setOpen(false);
            }, 200)
          })
        }
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        toast.error(`Falha ao mover atividade para a lixeira, tente novamente mais tarde!`);
        setLoadingButton(false);
      })
  }

  return (
    <>
      <div>
        <IconButton aria-label="Lixeira" onClick={handleClickOpen} title="Lixeira">
          <DeleteForever sx={{ color: red[500] }} />
        </IconButton>
        <BootstrapDialog onClose={handleCloseCancel} aria-labelledby="Lixeira" open={open}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseCancel}>
            <Delete /> Lixeira
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid sx={{ width: 250, p: 1, m: 1 }}>
              <Box sx={{}}>
                <Typography style={{ color: '#2e7d32' }}>
                  <Typewriter tam={80} minHeight={0} text="A atividade será movida para lixeira." />
                </Typography>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseCancel} style={{ color: '#8a8a8a' }} aria-label="Cancelar" title="Cancelar">
              Cancelar
            </Button>
            <Button autoFocus onClick={handleTrash} variant="outlined" color="success" sx={{ width: 200, height: 50 }} aria-label="Mover" title="Mover">
              {loadingButton ? <CircularProgress sx={{ mb: -0.6 }} size="1.3rem" style={{ color: '#fff' }} /> : 'Mover'}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  )
}
