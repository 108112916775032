import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Container, Paper } from '@mui/material'
import SuccessDialog from '../../../../../components/Dialog/SuccessDialog'
import { Context } from '../../../../../components/Context/AuthContext'
import useStore from '../../../../../store/useStore'
import api from '../../../../../services/api'
import dayjs from 'dayjs'
import FormProgressSelect from '../FormProgress/FormProgressSelect'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import HeaderModalNew from '../../../../../components/Header/HeaderModalNew'
import FormApplicationsStep1 from './FormApplicationsStep1'
import FormApplicationsStep2 from './FormApplicationsStep2'
import { PendingActions } from '@mui/icons-material'

export default function MultiStepFormApplications({ handleClose, type }) {
  // console.log('MultiStepFormApplications')
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const harvestRepos = usestore.reposHarvest.harvestRepos;

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_harvest: harvestRepos.id,
    operationType: 'Aplicacao',

    areas: [],
    /* produto utilizado
      areas.map((area) => (
        area.product
        area.product.location
        area.product.location_selected.quanty
        area.product.location_selected.quant_utilizada
        area.product.location_selected.value
    */

    description: '',
    error_date: false,

    isDeleted: 0,
    progress_radio: 1,
    planning: 'realized',
    progress: 'CONCLUÍDA',
    date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    date_end: null,
  });

  const [activeStep, setActiveStep] = useState(0);

  const [track, setTrack] = useState((new Date().getTime()));
  const handleNext = (submit) => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      if (submit !== 'submit' && activeStep === steps.length - 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleScroll();
      }
    }
  }
  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      handleScroll();
    }
  }

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth", });
    }, 300)
  }

  const validate = () => {
    let valid = true;

    if (!data.areas.length) {
      valid = false;
    } else {
      data.areas?.map((area) => {
        var qtd_prod = area.product?.location_selected?.quanty || 0;
        if (area.product?.packaging_unit !== 'unidade') {
          qtd_prod = ((qtd_prod || 0) * (area.product?.volume_pkg || 1));
        }

        if (!area.product?.location_selected?.quant_utilizada) {
          valid = false;
        } else if (qtd_prod < area.product?.location_selected?.quant_utilizada) {
          valid = false;
        }
        return area;
      })
    }
    return valid;
  }

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormProgressSelect', 'FormApplicationsStep1', 'FormApplicationsStep2']
  const steps_text = [
    'Informe como se encontra o progresso da aplicação',
    'Preencha as informações necessárias para a realização da aplicação',
    'Pronto! Agora basta conferir as informações da aplicação e clicar em confirmar!',
  ];

  const onSubmit = async () => {
    let formOk = validate() || false;
    data.id_monitoring = data.selectedId;

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api.post('/activityApplications/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Aplicação registrado com sucesso!');
            usestore.reposProducts.fetch({ id_farm: property_id });
            usestore.reposProducts.fetchLocationStock({ id_property: property_id });
            usestore.reposPatrimony.fetch({ id_property: property_id });
            usestore.reposFinances.fetch({ id_property: property_id });
            usestore.reposFinances.fetchT({ id_property: property_id });
            usestore.reposCostProduction.fetch();

            usestore.reposActivityApplications.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar o Aplicação, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  }

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      isMobile: isMobile.any(),
    };

    switch (step) {
      case 0:
        // progresso do plantio
        return <FormProgressSelect data={data} obj_fn={obj_fn} options={options} />;
      case 1:
        // identificadores do plantio
        return <FormApplicationsStep1 data={data} obj_fn={obj_fn} />;
      case 2:
        // confirmação dos dados
        return <FormApplicationsStep2 data={data} obj_fn={obj_fn} />;
      case 3:
        const data_success = {
          distination: 'activitiesHarvest/' + property_id + '/' + harvestRepos.id,
          name: 'Atividade de Aplicação de defensivos',
          text_success: 'Acesse a lista de suas atividades de campo para monitorar os manejos agrícolas.',
          distination_title: 'Atividades de campo',
          icon: <PendingActions color="success" style={{ fontSize: 100 }} sx={{ mr: 1, width: '50px', height: '50px' }} />,
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  }

  const options = {
    title: 'Aplicação de defensivos',
    txt_msn: steps_text[activeStep],
    icon: <LocationSearchingIcon widthStyles={'55'} />,
  };

  const optionsIcon = {
    title: 'Aplicação de defensivos',
    txt_msn: steps_text[activeStep],
    icon: <LocationSearchingIcon style={{ fontSize: 24 }} />,
  };

  const optionsImage = {
    title: 'Progresso',
    txt_msn: steps_text[activeStep],
    icon: <LocationSearchingIcon style={{ fontSize: 100 }} />,
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {activeStep !== 3 && <HeaderModalNew optionsIcon={optionsIcon} optionsImage={optionsImage} />}
        {getStepContent(activeStep)}
      </Box>
    </>
  );
}
