import React, { useEffect, useState } from 'react';
import { Span } from '../../../../../components/Typography';
import { createTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { deDE } from '@mui/x-date-pickers/locales';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AppBar, Box, Button, Grid, Stack, TextField, Toolbar, Typography } from '@mui/material';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { expandAndFade } from '../../../../../components/Animations/animationKeyFrames';

const theme = createTheme(
  { palette: { primary: { main: '#1976d2' } } },
  deDE // use 'de' locale for UI texts (start, next month, ...)
);
export default function FirstStepDate({ data, setData, handleNext, activeStep, steps, handleClose }) {
  // console.log("FirstStep -> datas");
  const [loadingText, setLoadingText] = useState(true);

  const dateNow = new Date();
  //post.date = format(date, "MMMM do, yyyy H:mma");
  // const dateNow = format(date, "dd-MM-yyyy");
  const [value, setValue] = React.useState(dayjs(dateNow));

  const aYearFromNow = dateNow.setFullYear(dateNow.getFullYear() + 1);
  // console.log(aYearFromNow)

  const [valueEnd, setValueEnd] = React.useState(dayjs(aYearFromNow));

  // valor para inicializar o data
  useEffect(() => {
    const anoInitvalue = dateNow.getFullYear() - 1;
    const anoFimvalue = anoInitvalue + 1;
    // console.log(anoInitvalue, anoFimvalue)
    setData((prevState) => ({ ...prevState, name: data.typenameHarvest + ' ' + anoInitvalue + '/' + anoFimvalue }));

    setData((prevState) => ({ ...prevState, date_start: dayjs(value).format('YYYY-MM-DD HH:mm:ss') }));
    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format('YYYY-MM-DD HH:mm:ss') }));
    // console.log(data);
  }, []);

  // se os valores de dateStar e dateEnd forem adicionados
  useEffect(() => {
    const newValueStart = dayjs(value).year();
    const newValueEnd = dayjs(valueEnd).year();
    // console.log(newValueEnd)
    setData((prevState) => ({ ...prevState, date_start: newValueStart }));
    setData((prevState) => ({ ...prevState, dateEnd: newValueEnd }));
    setData((prevState) => ({ ...prevState, name: data.typenameHarvest + ' ' + newValueStart + '/' + newValueEnd }));

    setData((prevState) => ({ ...prevState, date_start: dayjs(value).format('YYYY-MM-DD HH:mm:ss') }));
    setData((prevState) => ({ ...prevState, date_prev_end: dayjs(valueEnd).format('YYYY-MM-DD HH:mm:ss') }));
    // console.log(data);
  }, [value, valueEnd]);

  // const isFutureDate = (selectedDate) => {
  //   console.log("isFutureDate")
  //   // console.log(selectedDate)
  //   return selectedDate.isAfter(dayjs());
  // };
  const weekdays = { '2ª': 'seg', '3ª': 'ter', '4ª': 'qua', '5ª': 'qui', '6ª': 'sex', Sa: 'sáb', Do: 'dom' };

  return (
    <>
      <Grid container spacing={2} sx={{ my: 1 }}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid container spacing={2} sx={{ height: 65 }}>
              <Grid item xs={1} md={1}>
                <CalendarMonthIcon sx={{ color: 'action.active', mr: 0.5 }} />
              </Grid>
              <Grid item xs={11} md={11}>
                <Typography gutterBottom style={{ color: '#2e7d32' }}>
                  {loadingText ? (
                    <Typewriter text="Clique no ícone do calendário ou insira diretamente a data de início e de término desejada para o projeto." />
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle1">Defina uma data de início e de término para o projeto.</Typography>
                    </Box>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{ my: 2, height: 125 }}>
              <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                <div className="d-lg-none">
                  <Stack spacing={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MobileDatePicker
                          label="Data de Início*"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                            const newValueEnd = dayjs(newValue).add(1, 'year');
                            // console.log("------")
                            const anoInit = dayjs(newValue).year();
                            const anoFim = dayjs(newValueEnd).year();
                            setData((prevState) => ({ ...prevState, name: data.typenameHarvest + ' ' + anoInit + '/' + anoFim }));
                            setValueEnd(newValueEnd);
                          }}
                          adapterLocale="pt"
                          inputFormat="DD/MM/YYYY"
                          dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                          renderInput={(params) => <TextField {...params} color="success" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MobileDatePicker
                          label="Data de Término*"
                          value={valueEnd}
                          onChange={(newValue) => {
                            setValueEnd(newValue);
                          }}
                          adapterLocale="pt"
                          inputFormat="DD/MM/YYYY"
                          dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                          renderInput={(params) => <TextField {...params} color="success" />}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </div>
                <div className="d-none d-lg-block">
                  <Stack spacing={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <DesktopDatePicker
                          label="Data de Início*"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                            const newValueEnd = dayjs(newValue).add(1, 'year');
                            // console.log("------")
                            const anoInit = dayjs(newValue).year();
                            const anoFim = dayjs(newValueEnd).year();
                            setData((prevState) => ({ ...prevState, name: data.typenameHarvest + ' ' + anoInit + '/' + anoFim }));
                            setValueEnd(newValueEnd);
                          }}
                          adapterLocale="pt"
                          inputFormat="DD/MM/YYYY"
                          dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              color="success"
                              error={!value}
                              helperText={
                                !value && (
                                  <p id="NameHarvest" className="form-text text-danger is-invalid">
                                    Por favor, insira uma data válida.
                                  </p>
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DesktopDatePicker
                          label="Data de Término*"
                          value={valueEnd}
                          onChange={(newValue) => {
                            setValueEnd(newValue);
                          }}
                          color="success"
                          adapterLocale="pt"
                          inputFormat="DD/MM/YYYY"
                          dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              color="success"
                              error={!valueEnd}
                              helperText={
                                !valueEnd && (
                                  <p id="NameHarvest" className="form-text text-danger is-invalid">
                                    Por favor, insira uma data válida.
                                  </p>
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </div>
                <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 2, color: '#808080' }}>
                  Por padrão, o sistema considera 1 ano como a data de início e término da atividade.
                  <br />
                  Caso preferir, você poderá alterar as 2 datas.
                  <br />
                  Caso o projeto tenha mais de uma safra, sugiro colocar em término a data da última safra.
                </Typography>
              </LocalizationProvider>
              <Grid item sm={12} xs={12} sx={{ mt: 2 }}>
                <Typography paragraph variant="subtitle1" style={{ color: '#2e7d32' }} fontWeight={500}>
                  Clique abaixo em continuar...
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
      <Button sx={{ my: 2 }} variant="contained" color="success" onClick={data.date_start ? handleNext : () => null} disabled={!data.date_start}>
        {activeStep === steps.length - 1 ? "Confirmar e Salvar" : "Continuar"}
      </Button>
    </Box> */}
      <AppBar color="success" elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button variant="text" color="inherit" type="submit" onClick={handleClose}>
              <Span>Cancelar</Span>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button
              sx={{
                my: 2,
                animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
              }}
              variant="text"
              color="inherit"
              onClick={data.date_start ? handleNext : () => null}
              disabled={!data.date_start}
            >
              {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
