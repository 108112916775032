import React, { useState } from 'react';
import { Box, Card, CardMedia, CardHeader, Grid, ListItemIcon, Typography, Checkbox, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormBatchHeader from './FormBatchHeader';
import FormBatchFooter from './FormBatchFooter';
import { LocationOn } from '@mui/icons-material';

const useStyles = makeStyles({
  selected: {
    border: '2px solid green',
    overflow: 'auto',
  },
});

export default function FormBatchStep2({ data, obj_fn }) {
  const classes = useStyles();
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  // valida o button continuar
  const isError = () => {
    if (!data.area || !data.area.id) {
      return true;
    }
    return false;
  };
  // valida o botao retornar
  const returnError = () => {
    return false;
  };

  //const selectedIds = {};
  //data.area?.forEach((area) => (selectedIds[area.id] = area.id));
  //// Marca áreas selecionadas
  //const [selectedId, setSelectedId] = useState(selectedIds);

  const handleChange = (e, area) => {
    console.log('handleChangeArea');

    if (e.target.checked) {
      obj_fn.setData((prevState) => ({ ...prevState, area: area }));
    } else {
      obj_fn.setData((prevState) => ({ ...prevState, area: {} }));
    }
  };
  /*const handleChange2 = (e, area) => {
    console.log("handleChangeArea");
    //selectedIds[area.id] = !selectedIds[area.id];
    //setSelectedId({ ...selectedIds });

    if (!selectedIds[area.id]) {
      let aux_select = {};
      aux_select[area.id] = true;
      setSelectedId(aux_select);

      obj_fn.setData((prevState) => ({ ...prevState, area: area }));
    } else {
      setSelectedId({});
      obj_fn.setData((prevState) => ({ ...prevState, area: {} }));
    }
  };*/

  const text_title = 'Informações essenciais do lote';
  const txt_msn = 'Informe a localização do seu lote';

  return (
    <>
      <FormBatchHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} />
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography color={'#2e7d32'} fontWeight={700}>
              Lista de áreas criadas:
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Se desejar atribuir o lote a uma nova área, será necessário ir em "NOVA ATIVIDADE DE CAMPO" e "Vincular Áreas à criação". </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        {data.areas_used.map((item) => (
          <>
            <Grid key={'g_' + item.id} xs={12} md={4} sx={{ mb: 2 }}>
              <Card key={'c_' + item.id} sx={{ maxHeight: 500, width: 270, overflow: 'auto' }} className={data.area?.id == item.id ? classes.selected : null}>
                <ListItemIcon key={'li_' + item.id}>
                  <Checkbox key={'cb_' + item.id} sx={{ height: '150px' }} checked={data.area?.id == item.id ? true : false} onChange={(e) => handleChange(e, item)} color="success" />
                  <div key={'dv1_' + item.id} style={{ position: 'relative' }}>
                    <CardMedia key={'cm_' + item.id} component="img" height="150" image={item.imageDecode} alt="Paella dish" />
                    <Box
                      key={'b_' + item.id}
                      sx={{
                        position: 'absolute',
                        top: '95px',
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.45)',
                        color: 'white',
                        padding: '27px',
                      }}
                    >
                      <div key={'dv2_' + item.id} style={{ position: 'absolute', color: 'white', bottom: 3, left: '5%' }}>
                        <Typography variant="h6">{item.name}</Typography>
                        <Typography variant="body2">
                          {item.usedArea} {item.measure || 'ha'}
                        </Typography>
                      </div>
                    </Box>
                    <CardHeader
                      key={'ch_' + item.id}
                      sx={{ maxHeight: '35px' }}
                      subheader={
                        <Box key={'b_' + item.id} sx={{}}>
                          <Typography variant="body2" color="text.secondary">
                            <LocationOn color="success" /> Total animais: {item.num_animais || '-'} ca
                          </Typography>
                        </Box>
                      }
                    />
                  </div>
                </ListItemIcon>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>

      <FormBatchFooter data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
