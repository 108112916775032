import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ImportReportComponentPdfMake from './ImportReportComponentPdfMake';

const iconMapping = {
  ListAltIcon: <ListAltIcon sx={{ fontSize: 40 }} />,
  WidgetsIcon: <WidgetsIcon sx={{ fontSize: 40 }} />,
};

export default function CardReports({ type, doc, title, text, icon }) {
  const iconData = iconMapping[icon] || null;

  return (
    <Card sx={{ maxWidth: 345 }}>
      {title && (
        <>
          <CardHeader sx={{ height: 100 }} action={iconData} title={<span dangerouslySetInnerHTML={{ __html: title }}></span>} />
          <CardContent sx={{ height: 100 }}>
            <Typography variant="body2" color="text.secondary">
              <span dangerouslySetInnerHTML={{ __html: text }}></span>
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <ImportReportComponentPdfMake type={type} doc={doc} />
          </CardActions>
        </>
      )}
    </Card>
  );
}
