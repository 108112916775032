import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../Context/AuthContext';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import KeyIcon from '@mui/icons-material/Key';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { Settings } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';

export default function AccountMenu() {
  const display_demo = JSON.parse(localStorage.getItem('display'));
  const navigate = useNavigate();
  const { handleLogout } = useContext(Context);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      handleLeft();
    }, 10);
  };
  const handleLeft = () => {
    window.jQuery('#account_menu_user').find('.MuiPaper-root').css({ left: '', right: '1%' });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handlePan = () => {
    navigate('/signature');
  };

  const handleProperty = () => {
    navigate('/property');
  };

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  //console.log('userCurrent')
  //console.log(userCurrent)
  const user = userCurrent;
  //console.log(user)

  const name = user?.name;
  const charName = name ? name[0].toUpperCase() : '';
  //console.log(charName);

  const title_acount = 'Conta da Fazenda\n' + name + '\n' + userCurrent?.email;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{title_acount}</div>}>
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
            <Avatar sx={{ width: 32, height: 32, bgcolor: '#28a745' }}>{charName}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account_menu_user"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfile}>
          <Avatar /> Meus Dados
        </MenuItem>
        <MenuItem onClick={() => navigate('/quickGuide')}>
          <ListItemIcon>
            <LocalLibraryIcon />
          </ListItemIcon>
          Guia Rápido
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleProperty}>
          <ListItemIcon>
            <HomeWorkIcon />
          </ListItemIcon>
          Propriedades
        </MenuItem>
        {display_demo ? (
          <div key="demo"></div>
        ) : (
          [
            <MenuItem onClick={() => navigate('/settingsuser')}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              Configurações
            </MenuItem>,
            userCurrent?.permissions !== 'owner' ? <></> :
              <MenuItem onClick={handlePan}>
                <ListItemIcon>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                Assinatura
              </MenuItem>,
            <MenuItem onClick={() => navigate('/image')}>
              <ListItemIcon>
                <InsertPhotoIcon />
              </ListItemIcon>
              Imagens
            </MenuItem>,
            <MenuItem onClick={() => navigate('/documentManagement')}>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              Documentos
            </MenuItem>,
            <MenuItem onClick={() => navigate('/changepassword')}>
              <ListItemIcon>
                <KeyIcon />
              </ListItemIcon>
              Mudar senha
            </MenuItem>,
          ]
        )}

        <MenuItem onClick={() => navigate('/indexExperience')}>
          <ListItemIcon>
            <CoPresentIcon />
          </ListItemIcon>
          Ind. Experiência
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair do app
        </MenuItem>
      </Menu>
    </>
  );
}
