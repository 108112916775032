import React, { useState } from 'react';
import { AppBar, Box, Button, Card, CardMedia, Grid, ListItemIcon, Toolbar, Typography } from '@mui/material';
import FormAssignAreaHeader from './FormAssignAreaHeader';


export default function FormDesassignAreaStep2({ data, obj_fn }) {
  //1-texto
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const handleSubmit = () => {
    // console.log('onSubmit');
    obj_fn.onSubmitRmv();
  };

  let tipo_item = data.tipo_item === 'livestock' ? 'criação' : 'safra';
  const title = 'Desvincular Áreas da ' + tipo_item;
  const text_title = 'Confirmação dos dados';
  const txt_msn = 'Pronto! Agora basta conferir os dados e clicar em confirmar!';

  return (
    <>
      <FormAssignAreaHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} imagem={'/dist/image/entrada.png'} title={title} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Typography fontWeight="500" variant="h6">
            Áreas a serem desvinculadas da {tipo_item}
          </Typography>
        </Grid>
        {data.new_area.map((item) => (
          <>
            <Grid key={'g_' + item.id} xs={12} md={4} sx={{ mb: 2 }}>
              <Card key={'c_' + item.id} sx={{ maxHeight: 500, width: 270, overflow: 'auto' }}>
                <ListItemIcon key={'li_' + item.id}>
                  <div style={{ position: 'relative' }}>
                    <CardMedia key={'cm_' + item.id} component="img" image={item.url || item.imageDecode} alt="Imagem da área" />
                    <Box
                      key={'b_' + item.id}
                      sx={{
                        position: 'absolute',
                        top: '95px',
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.45)',
                        color: 'white',
                        padding: '27px',
                      }}
                    >
                      <div key={'dv_' + item.id} style={{ position: 'absolute', color: 'white', bottom: 3, left: '5%' }}>
                        <Typography variant="h6">{item.name}</Typography>
                        <Typography variant="body2">
                          {item.area} {item.measure || 'ha'}
                        </Typography>
                      </div>
                    </Box>
                  </div>
                </ListItemIcon>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>

      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
              Cancelar
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={obj_fn.handleBack} color="success" sx={{ marginRight: 2 }}>
              Retornar
            </Button>
            {/* O botão Confirmar e Salvar, leva para tela successo */}
            <Button color="success" variant="contained" type="submit" onClick={handleSubmit}>
              Confirmar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
