import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import IconsLivestockActivities from '../../../components/Icons/IconsLivestockActivities';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../../components/Context/AuthContext';
import { expandAndFade, expandAndFadeLine } from '../../../components/Animations/animationKeyFrames';
import LightTooltip from '../../../components/Tooltip/LightTooltip';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

//sort list
const sortList = (list) => {
  let userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const stgs_lv = userCurrent.settings.livestock;
  if (!stgs_lv.first) {
    return list;
  }
  const sortedData = [
    ...list.filter((item) => item.id == stgs_lv.first),
    ...list.filter((item) => item.id == stgs_lv.second),
    ...list.filter((item) => item.id == stgs_lv.third),
    ...list.filter((item) => item.id != stgs_lv.first && item.id != stgs_lv.second && item.id != stgs_lv.third),
  ];
  return sortedData;
};

export default function CardCreations({ data_cards, changeRepos }) {
  const navigate = useNavigate();

  const criacoes_nf = Object.assign(
    [],
    data_cards.criacoes.filter((criacao) => criacao.situacao != 'finalizada')
  );
  const sortlist = sortList(criacoes_nf);
  const duas_criacoes = sortlist.splice(0, 2);

  const { menuContext, setMenuContext } = React.useContext(Context);

  const handleChange = (e) => {
    var criation = JSON.parse(window.jQuery(e.currentTarget).attr('value'));
    localStorage.setItem('creationHarvestRepos', JSON.stringify(criation));
    localStorage.setItem('ButtonActive', 'Painel');
    localStorage.setItem('navMenuActive', 'selectCreationHarvest');

    changeRepos(criation);
    setMenuContext(new Date().getTime());
  };
  function getAreaUsed(areas) {
    var usedArea = 0;
    for (var i = 0, tam = areas.length; i < tam; i++) {
      usedArea += parseFloat(areas[i].usedArea);
    }
    return usedArea.toFixed(2);
  }

  console.log(duas_criacoes);

  return (
    <Card>
      <CardContent>
        {data_cards.criacoes.length > 0 ? (
          <Grid container spacing={2} sx={{ mb: 1.1 }}>
            {duas_criacoes.map((criacao) => (
              <Grid item xs={12} sm={12} key={criacao.id}>
                <NavLink
                  to={'/controlpanelcreations/' + criacao.id_property + '/' + criacao.id}
                  className="text-success"
                  onClick={(e) => {
                    handleChange(e);
                  }}
                  value={JSON.stringify({ label: criacao.name, id: criacao.id, tipo_repos: 'Criações', id_property: criacao.id_property, tipe: criacao.id_tipe, activity: criacao.tipo })}
                >
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item sx={{ width: 50 }}>
                      <IconsLivestockActivities activity={'ENTRADA'} type_activity={criacao.tipo} widthStyles={'42px'} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LightTooltip title="Acesse o projeto pecuário e realize a gestão das atividades de campo.">
                        <Typography sx={{ animation: `${expandAndFadeLine} 2s linear infinite` }} variant="body2" component="div">
                          {criacao.name}
                        </Typography>
                      </LightTooltip>
                      <Typography variant="caption" color="text.secondary" component="div">
                        {criacao.tipo}
                      </Typography>
                    </Grid>
                    <div className="d-lg-none">
                      <Grid item xs={12} md={12} sx={{ mt: 1, ml: 1 }}>
                        <Typography variant="caption" color="text.secondary" component="div">
                          Animais:&nbsp;{criacao.total_animais}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" component="div">
                          Áreas:&nbsp;{criacao.areas.length}&nbsp;-&nbsp;({getAreaUsed(criacao.areas)}ha)
                        </Typography>
                      </Grid>
                    </div>

                    <div className="d-none d-lg-block">
                      <Grid item xs={12} md={12}>
                        <Typography variant="caption" color="text.secondary" component="div">
                          Animais:&nbsp;{criacao.total_animais}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" component="div">
                          Áreas:&nbsp;{criacao.areas.length}&nbsp;-&nbsp;({getAreaUsed(criacao.areas)}&nbsp;ha)
                        </Typography>
                      </Grid>
                    </div>
                  </Grid>
                </NavLink>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box height="140px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px dashed gray" borderRadius="4px" padding="16px">
            <IconButton sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" aria-label="upload picture" component="label" onClick={() => navigate('/livestock')}>
              <Grid item sx={{ width: 50 }}>
                <LightTooltip title="Crie um projeto pecuário e realize a gestão das atividades de campo.">
                  <IconsLivestockActivities activity={'Geral'} widthStyles={'42px'} />
                </LightTooltip>
              </Grid>
            </IconButton>
            <span>Clique aqui para registrar a sua criação</span>
          </Box>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton sx={{ ml: 'auto' }}>
          <NavLink to="/livestock" className="nav-link">
            <Typography variant="body2" color="text.secondary" component="div">
              Ver todas as criações ...
            </Typography>
          </NavLink>
        </IconButton>
      </CardActions>
    </Card>
  );
}
