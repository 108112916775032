import React, { useContext } from 'react';
import { IconButton } from '@mui/material';
import { Loop } from '@mui/icons-material';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';

export default function RestoreActivitiesHarvests({ row, usestore }) {
  // atualiza a tabela
  const { aux_add, setAuxAdd } = useContext(Context);
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const handleClick = async () => {
    const header = { header: { 'Content-Type': 'application/json' } };
    const status = 'edit';
    let url = '';
    let reposActivity = {};

    if (row.activity === 'PLANTIO') {
      url = `/activityPlanting/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityPlanting;
    } else if (row.activity === 'COLHEITA') {
      url = `/activityHarvesting/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityHarvesting;
    } else if (row.activity === 'MONITORING') {
      url = `/activityMonitoring/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityMonitoring;
    } else if (row.activity === 'PREPARAÇÃO DO SOLO') {
      url = `/activitySoilPreparation/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivitySoilPreparation;
    } else if (row.activity === 'APLICAÇÃO') {
      url = `/activityApplications/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityApplications;
    } else if (/.*VINCULA.*/.test(row.activity)) {
      url = `/activityAssignArea/delete/${row.id}/${status}?param1=${property_id}&param2=${userCurrent.id}`;
      reposActivity = usestore.reposActivityAssignArea;
    }

    await api.delete(url, header)
      .then((response) => {
        // console.log(response.data)
        if (response.data.isSuccess) {
          toast.success(`Atividade restaurada com sucesso!`);
          // atualiza o repos
          reposActivity.fetch().then((res) => {
            setAuxAdd(aux_add + 1);
          })
        }
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        toast.error(`Falha ao restaurar atividade, tente novamente mais tarde!`);
      })
  }

  return (
    <>
      <IconButton color="info" aria-label="Restaurar" onClick={handleClick} title="Restaurar">
        <Loop />
      </IconButton>
    </>
  )
}
