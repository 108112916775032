import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Menu, MenuItem, Select, Slide } from '@mui/material';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';
import api from '../../../services/api';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import MoveStock from './MovestockProducts/MoveStock';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import QuantityStock from './MovestockProducts/QuantityStock';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import usePatrimonyReposStore from '../../../store/usePatrimonyReposStore';
import { red } from '@mui/material/colors';
import LocationStock from './MovestockProducts/LocationStock';
import StockCost from './MovestockProducts/StockCost';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import StockEntryOut from './MovestockProducts/StockEntryOut';
import { getIconComponentCategoryProducts } from '../../products/getIconComponentCategoryProducts';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#14db22' : '#4fb028',
  },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const option = [{ label: 'Entrada' }, { label: 'Saída' }, { label: 'Balanço' }];
const options = ['Entrada', 'Saída', 'Balanço'];

function Row({ data, handleDelete, setSelectedProduct }) {
  const [open, setOpen] = React.useState(false);
  const usestore = useStore();
  //console.log('Rowxxxx ')
  //console.log(data)

  //const [packaging, setPackaging] = useState(usestore.reposProducts.listPackaging)

  const pack = usestore.reposProducts.listPackaging.filter((item) => item.id == data.id_packaging)[0];

  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const [editStockProduct, setEditStockProduct] = React.useState([]);

  console.log(data);

  const dataA = {
    typeMov: '',
    id_user: userCurrent.id,
    id_property: Number(property_id),
    id_product: data.id,
    name: data.name,
    id_account: data.id_account,
    value_cost: data?.stock_cost,
    value_sale: '',
    quanty: data?.stock_quanty,
    quanty_min: data.stock_quanty_min,
    quanty_max: data.stock_quanty_max,
    id_patrimony: Number(data.id_patrimony),
    stock_quanty_array: data.stock_quanty_array || 0,
    id_product_location_array: data.id_product_location_array,
    transferQuanty: 0,
    unit_sigla: data.unit_sigla,
    volume_pkg: data.volume_pkg,
    packaging_name: data.packaging_name,
    // precisra inseir o max, na tabela property_produt
    // precisa inseri o useProductsReposStore
    // depois mudar aqui
    description: '',
  };

  const [dataStock, setDataStock] = useState(dataA);

  // useEffect(() => {
  //   setDataStock(dataA)
  // }, [data])

  //console.log('dataStock ')
  //console.log(dataStock)

  // useEffect(() => {
  //   setDataStock((prevData) => ({
  //     ...prevData,
  //     ['value_cost']: 0,
  //   }))
  // }, [])

  //console.log('Row')
  //console.log(dataStock)

  const { aux_add, setAuxAdd } = useContext(Context);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // dialogo moveStock

  // abrir o Modal movometar estoque
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  // clicar no menu e acionar o comando handleEditDialogOpen para mover o estoque
  const handleEditMoveStock = async (data) => {
    setEditStockProduct(data);
    handleEditDialogOpen();
    //console.log('DATA TO EDIT:')
    //console.table(data)
  };

  // modal entrada de estoque

  const [isLoading, setIsloading] = useState(false);
  const [editStockEntryOutDialogOpen, setEditStockEntryOutDialogOpen] = React.useState(false);
  const [editQuantityStockDialogOpen, setEditQuantityStockDialogOpen] = React.useState(false);

  // adicionar ou remover do estoque

  // o meu chama esta função passando data e typeMov = in ou out
  const handleEditStockEntryOut = async (data, typeMov) => {
    setEditStockProduct(data);
    setDataStock((prevData) => ({
      ...prevData,
      typeMov: typeMov,
    }));
    setIsloading(true);
    // chama o modal
    handleEditStockEntryOutDialogOpen();
    //console.log('DATA TO EDIT:')
    //console.table(data)
  };

  // abrir o modal
  const handleEditStockEntryOutDialogOpen = () => {
    setEditStockEntryOutDialogOpen(true);
  };

  // fechar o modal
  const handleEditStockEntryOutDialogClose = () => {
    setEditStockEntryOutDialogOpen(false);
  };

  // Qantity
  const handleEditQuantityStockDialogOpen = () => {
    setEditQuantityStockDialogOpen(true);
  };

  const handleEditQuantityStockDialogClose = () => {
    setEditQuantityStockDialogOpen(false);
  };

  const handleEQuantityStockProduct = async (data) => {
    setEditStockProduct(data);
    handleEditQuantityStockDialogOpen();
    //console.log('DATA TO EDIT:')
    //console.table(data)
    usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
      setAuxAdd(aux_add + 1);
      setTimeout(() => {}, 200);
    });
  };

  // location
  const [editLocationDialogOpen, setEditLocationDialogOpen] = React.useState(false);

  const handleEditLocationDialogOpen = () => {
    setEditLocationDialogOpen(true);
  };

  const handleEditLocationDialogClose = () => {
    setEditLocationDialogOpen(false);
  };

  const handleEditLocationProduct = async (data) => {
    setEditStockProduct(data);
    handleEditLocationDialogOpen();
    //console.log('DATA TO EDIT:')
    //console.table(data)
  };

  // Value stock_cost
  const [editStockCostDialogOpen, setEditStockCostDialogOpen] = React.useState(false);

  const handleEditStockCostDialogOpen = () => {
    setEditStockCostDialogOpen(true);
  };

  const handleEditStockCostDialogClose = () => {
    setEditStockCostDialogOpen(false);
  };

  const handleEditStockCostProduct = async (data) => {
    setEditStockProduct(data);
    handleEditStockCostDialogOpen();
    //console.log('DATA TO EDIT:')
    //console.table(data)
    // usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {
    //   setTimeout(() => {
    //     setAuxAdd(aux_add + 1)
    //   }, 100)
    // })
  };

  // salvar

  const stockCostFormatted = data.stock_cost?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  // console.log(stockCostFormatted)

  const reposPatrimony = usePatrimonyReposStore((state) => state);
  //console.log(data.id_patrimony)
  //console.log(reposPatrimony.patrimonyLocation)

  //console.log(data)
  //console.log(data?.id_patrimony)
  // Verificar se há vírgula na string

  const [dataStockList, setDataStockList] = useState([]);

  const [listStockLocation, setListStockLocation] = useState([]);

  let location_patrimony = null;
  let location_patrimony_array = [];

  const filterLovation = () => {
    if (data.id_product_location_array) {
      // console.log('entrou1')
      //id_product_location_array é o id da tabela product_location
      // extraio o array de forem 2 ou mais localizações ou 1 localização
      let array = data.id_product_location_array.split(',');
      array = array.map((numStr) => parseInt(numStr));
      // array dos ids product_location
      // console.log(array)
      // preciso agora do conteúdo da lista de patrimonyLocation com ids product_location
      let ListDataLocation = reposPatrimony.patrimonyLocation.filter((item) => array.includes(item.id));
      // tenho a localização com as quantidades
      setDataStockList(ListDataLocation);
      let filteredArray = [];
      // preciso obter os patrimonios filtrados
      for (const [index, item_array] of ListDataLocation.entries()) {
        // console.log(item_array)
        const item = reposPatrimony.listPatrimony.find((item) => item.id === item_array.id_patrimony);
        filteredArray.push(item);
      }
      //let filteredArray = reposPatrimonyFilter.filter((item) => array.includes(item.id))
      //console.log(filteredArray)
      setListStockLocation(filteredArray);
    }
  };

  useEffect(() => {
    filterLovation();
  }, []);

  useEffect(() => {
    //console.log('atualizou')
    //usestore.reposProducts.fetch({ id_property: property_id }).then((res) => {})
    // usestore.reposPatrimony.fetch({ id_property: property_id }).then((res) => {})
    filterLovation();
  }, [aux_add, data]);

  //console.log(dataStockList)
  //console.log(listStockLocation)
  // if (data?.id_patrimony && typeof data.id_patrimony === 'string' && data.id_patrimony.includes(',')) {
  //   // Se houver, dividir a string em um array com base na vírgula
  //   let array = data?.id_patrimony?.split(',')
  //   let arrayQuanty = data?.stock_quanty_array?.split(',')
  //   // Converter cada elemento do array em um número inteiro
  //   array = array.map((numStr) => parseInt(numStr))
  //   arrayQuanty = arrayQuanty.map((numStr) => parseInt(numStr))
  //   //console.log(array)
  //   //console.log(arrayQuanty)

  //   // Verificar se os arrays têm o mesmo comprimento
  //   let newArray = []
  //   if (array.length === arrayQuanty.length) {
  //     // Criar um array de objetos combinando os valores dos dois arrays
  //     newArray = array.map((value, index) => ({
  //       id_patrimony_array: value,
  //       id_quanty_array: arrayQuanty[index],
  //     }))
  //   } else {
  //     console.error('Os arrays têm comprimentos diferentes')
  //   }
  //   //console.log(newArray)

  //   for (const item_array of newArray) {
  //     const location = reposPatrimony.patrimonyLocation.filter((item) => item.id == item_array.id_patrimony_array)[0]
  //     //console.log(location)
  //     //console.log(location.name)
  //     const location_patrimony_quanty = newArray.find((item) => item.id_patrimony_array === item_array.id_patrimony_array)
  //     //console.log(location_patrimony_quanty)
  //     location_patrimony_array.push({ name: location.name, id: location.id, quanty: location_patrimony_quanty.id_quanty_array })
  //   }
  // } else {
  //   // Se não houver vírgula, apenas converter a string em um número inteiro
  //   let id = parseInt(data?.id_patrimony)
  //   location_patrimony = reposPatrimony.patrimonyLocation.filter((item) => item.id == id)[0]
  //   //console.log(location_patrimony)

  //   // location_patrimony_array.push({ name: location_patrimony.name, id: location_patrimony.id, quanty: parseInt(data.id_quanty_array) })
  // }

  //console.log('location_patrimony')
  //console.log(location_patrimony)
  //console.log('location_patrimony_array')
  //console.log(location_patrimony_array)

  const color = data.stock_quanty < data.stock_quanty_min ? red[500] : undefined;
  //console.log(color)

  //console.log(data.stock_cost)
  //console.log('data ')
  //console.log(data)

  // moveStock

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography fontWeight="300" variant="subtitle1" style={{ color: '#2e7d32' }}>
            <>
              <strong> {data?.name}</strong>
            </>
          </Typography>
        </TableCell>
        <TableCell align="center">
          {data.stock_quanty_max !== 0 ? (
            <LightTooltip title={`Mínimo: ${data.stock_quanty_min}, Máximo: ${data.stock_quanty_max}, Quant.: ${data.stock_quanty}`}>
              {/* <BorderLinearProgress variant="determinate" value={data.stockPercentage} aria-label="stock-progress" sx={{ '& .MuiLinearProgress-bar': { backgroundColor: color } }} /> */}
              <Box position="relative" display="inline-flex" width="100%">
                <BorderLinearProgress
                  variant="determinate"
                  value={data.stockPercentage}
                  aria-label="stock-progress"
                  sx={{
                    width: '100%',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: color,
                    },
                  }}
                />
                <Box position="absolute" top="0%" left="75%" transform="translate(-50%, -50%)">
                  <Typography variant="caption" component="div" color="textSecondary">
                    {`${Math.round(data.stockPercentage)}%`}
                  </Typography>
                </Box>
              </Box>
            </LightTooltip>
          ) : (
            <IconButton
              onClick={() => {
                handleEQuantityStockProduct(data);
              }}
            >
              <LightTooltip title={`Adicione o estoque máximo`}>
                <HelpCenterIcon />
              </LightTooltip>
            </IconButton>
          )}
          {/* <BorderLinearProgress variant="determinate" aria-label="stock-progress" value={data.stockPercentage} min={data.estoque_min} max={data.estoque_max} /> */}
        </TableCell>
        <TableCell align="right">
          {data.location.length == 0 ? (
            <IconButton
              onClick={() => {
                handleEditLocationProduct(data);
              }}
            >
              <LightTooltip title={`Defina o local de armazenamento do produto`}>
                <HelpCenterIcon />
              </LightTooltip>
            </IconButton>
          ) : dataStockList.length > 0 ? (
            dataStockList.map((item, index) => (
              <p key={index} style={{ fontSize: '12px', marginBottom: '0px' }}>
                {item.name}({item.quantity})
              </p>
            ))
          ) : (
            <p style={{ fontSize: '12px', marginBottom: '0px' }}>
              {location_patrimony?.name}({parseInt(data?.stock_quanty_array)})
            </p>
          )}
        </TableCell>
        <TableCell align="right">
          {data.stock_cost == 0 ? (
            <IconButton
              onClick={() => {
                handleEditStockCostProduct(data);
              }}
            >
              <LightTooltip title={`Adicione o vaor unitário do produto`}>
                <HelpCenterIcon />
              </LightTooltip>
            </IconButton>
          ) : (
            stockCostFormatted
          )}
        </TableCell>
        <TableCell align="right">
          {data.stock_quanty} {data.packaging_name !== 'Unidade' ? data.packaging_name : data.unit_sigla}
          {data.packaging_name !== 'Unidade' && (
            <LightTooltip
              title={data.packaging_name !== 'Unidade' ? `Volume unitário: ${data.volume_pkg}${data.unit_sigla}, Volume Total: ${data.stock_quanty * data.volume_pkg}${data.unit_sigla}` : 'sem volume'}
            >
              <QuestionAnswerIcon sx={{ ml: 1 }} style={{ fontSize: 20 }} />
            </LightTooltip>
          )}
        </TableCell>
        {/* <TableCell align="right">{data.qnt_disponivel}</TableCell> */}
        {/* <IconButton aria-label="expand row" size="small" onClick={() => handleDelete(data.id)}> */}
        <TableCell key={data.id} align="right">
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {/* Itens do Menu */}
            {data?.stock_quanty > 1 && data?.location.length > 1 && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleEditMoveStock(data);
                }}
              >
                <MoveUpIcon sx={{ mr: 1 }} /> Movimentar
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleMenuClose();
                handleEditStockEntryOut(data, 'in');
              }}
            >
              <AddIcon sx={{ mr: 1 }} /> Adicionar quantidade
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                handleEditStockEntryOut(data, 'out');
              }}
            >
              <RemoveIcon sx={{ mr: 1 }} /> Remover quantidade
            </MenuItem>
            {data?.stock_quanty != 0 && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleEQuantityStockProduct(data);
                }}
              >
                <QueryStatsIcon sx={{ mr: 1 }} /> Quant. min e max
              </MenuItem>
            )}
            {/* {data?.stock_quanty != 0 && (
              <MenuItem onClick={handleMenuClose}>
                <SellIcon sx={{ mr: 1 }} /> Vender
              </MenuItem>
            )}
            {data?.stock_quanty != 0 && (
              <MenuItem onClick={handleMenuClose}>
                <HistoryIcon sx={{ mr: 1 }} /> Ver histórico
              </MenuItem>
            )} */}
          </Menu>
        </TableCell>
        {editDialogOpen && (
          <MoveStock
            //origem={origem}
            //setOrigem={setOrigem}
            data={data}
            dataStock={dataStock}
            setDataStock={setDataStock}
            open={editDialogOpen}
            handleClose={handleEditDialogClose}
          />
        )}
        {editStockEntryOutDialogOpen && (
          <StockEntryOut
            location_patrimony_array={location_patrimony_array}
            dataStockList={dataStockList}
            dataStock={dataStock}
            setDataStock={setDataStock}
            open={editStockEntryOutDialogOpen}
            handleClose={handleEditStockEntryOutDialogClose}
          />
        )}
        {editQuantityStockDialogOpen && <QuantityStock dataStock={dataStock} setDataStock={setDataStock} open={editQuantityStockDialogOpen} handleClose={handleEditQuantityStockDialogClose} />}
        {editLocationDialogOpen && <LocationStock dataStock={dataStock} setDataStock={setDataStock} open={editLocationDialogOpen} handleClose={handleEditLocationDialogClose} />}
        {editStockCostDialogOpen && <StockCost dataStock={dataStock} setDataStock={setDataStock} open={editStockCostDialogOpen} handleClose={handleEditStockCostDialogClose} />}

        {/* <DeleteModalProduct id={data.id} />
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setSelectedProduct(data)
              handleClickOpen()
            }}
          >
            <BorderColorIcon />
          </IconButton> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Informações
              </Typography>

              <Typography variant="p" sx={{ fontWeight: 'bold' }} gutterBottom component="div">
                Estoque
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Quantidade disponível</TableCell>
                    <TableCell>Custo médio (R$)</TableCell>
                    <TableCell align="right">Estoque mínimo</TableCell>
                    <TableCell align="right">Estoque máximo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {data.stock_quanty} {pack.name}(s)
                    </TableCell>
                    <TableCell>{stockCostFormatted}</TableCell>
                    <TableCell align="right">{data.stock_quanty_min}</TableCell>
                    <TableCell align="right">{data.stock_quanty_max}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Typography variant="p" sx={{ fontWeight: 'bold', mt: 3 }} gutterBottom component="div">
                Pesos e Dimensões
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Altura (cm)</TableCell>
                    <TableCell>Largura (cm)</TableCell>
                    <TableCell align="right">Profundidade (cm)</TableCell>
                    <TableCell align="right">Volume</TableCell>
                    <TableCell align="right">Peso Líquido</TableCell>
                    <TableCell align="right">Peso Bruto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {data.altura}
                    </TableCell>
                    <TableCell>{data.largura}</TableCell>
                    <TableCell align="right">{data.profundidade}</TableCell>
                    <TableCell align="right">{data.volume}</TableCell>
                    <TableCell align="right">{data.peso_liquido}</TableCell>
                    <TableCell align="right">{data.peso_bruto}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {data.description !== '' && (
                <Typography variant="p" sx={{ fontWeight: 'bold', mt: 3 }} gutterBottom component="div">
                  Descrição: {data.description}
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function InventoryListTable({ productList, setProductList }) {
  const [filter, setFilter] = useState(''); // Estado para o filtro selecionado

  const [isProductDeleted, setIsProductDeleted] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState([]);

  const handleClickOpen = (data) => {
    //console.log(data)
    // setEditStockProduct(data)
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedProduct([]);
  };

  const handleDelete = async (id) => {
    const userConfirm = window.confirm('Tem certeza de que deseja excluir este produto?');
    const status = 1;
    if (userConfirm) {
      try {
        const header = { header: { 'Content-Type': 'application/json' } };
        await api.delete(`products/list/${id}/${status}`, header);
        toast.success('Produto inativado com sucesso!');
        setIsProductDeleted(true);
      } catch (error) {
        console.error('Erro ao deletar produto:', error);
        toast.error('Erro ao deletar produto.');
      }
    }
  };

  const [groupedData, setGroupedData] = useState({});
  // Função para agrupar os dados por categoria
  const groupByCategory = (data) => {
    let aux_group = data.reduce((acc, item) => {
      let filtro = !filter ? true : filter == 'sem_estoque' ? item.stock_quanty == 0 : item.stock_quanty > 0;

      if (item.isDeleted === 0 && filtro) {
        const categoryId = item.id_category;
        if (!acc[categoryId]) {
          acc[categoryId] = {
            categoryName: item.category_name, // Armazenar o nome da categoria
            categoryIcon: item.category_icon, // Armazenar o icone da categoria
            products: [], // Inicializar a lista de produtos para esta categoria
          };
        }
        acc[categoryId].products.push(item);
      }
      return acc;
    }, {});
    setGroupedData(aux_group);
    return aux_group;
  };

  //let groupedData = null
  // useEffect(() => {
  //   console.log('setGroupedData(groupedData)')
  // }, [groupedData])

  useEffect(() => {
    groupByCategory(productList);
  }, [filter, productList]);

  // const [searchQuery, setSearchQuery] = useState('') // Estado para a pesquisa

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // const handleSearchChange = (event) => {
  //   setSearchQuery(event.target.value)
  // }

  // Implementação de lógica para imprimir a tabela
  // const handlePrint = () => {
  //   // Implemente a lógica de impressão aqui
  // }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: -1, mb: 2, p: 1 }}>
        <Select
          sx={{ width: '30%', maxWidth: '30%' }}
          value={filter}
          variant="standard"
          onChange={handleFilterChange}
          displayEmpty
          color="success"
          size="small"
          inputProps={{ 'aria-label': 'Filtros' }}
        >
          <MenuItem value="">Filtros</MenuItem>
          <MenuItem value="estoque">Em estoque</MenuItem>
          <MenuItem value="sem_estoque">Sem Estoque</MenuItem>
          {/* Adicione mais opções de filtros aqui */}
        </Select>
        {/* Ícone de Imprimir */}
        {/* <IconButton onClick={handlePrint} title='Imprimir'>
          <LocalPrintshopIcon />
        </IconButton> */}
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: 600 }} id="table_group_products">
        <Table stickyHeader aria-label="Tabla de produtos">
          {/* <TableContainer component={Paper}>
        <Table aria-label="collapsible table"> */}
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <b>Nome do produto</b>
              </TableCell>
              <TableCell align="right">
                <b>Estoque(%)</b>
              </TableCell>
              <TableCell align="right">
                <b>Local</b>
              </TableCell>
              <TableCell align="right">
                <b>Valor uni (R$)</b>
              </TableCell>
              <TableCell align="right">
                <b>Quantidade</b>
              </TableCell>
              <TableCell align="right">
                <b>Ações</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([categoryId, { categoryName, categoryIcon, products }]) => (
              <React.Fragment key={categoryId}>
                {/* Linha de cabeçalho da categoria (opcional) */}
                <TableRow>
                  <TableCell colSpan="7" style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', color: 'gray' }}>
                    {/* <WidgetsIcon sx={{ fontSize: 16, mr: 1, color: 'gray' }} />  */}
                    {getIconComponentCategoryProducts(categoryIcon)}
                    {categoryName}
                    {/* Substitua por uma busca do nome da categoria se disponível */}
                  </TableCell>
                </TableRow>
                {/* Itens da categoria */}
                {products.map((data) => (
                  <Row key={data.id} data={data} handleDelete={handleDelete} handleClickOpen={handleClickOpen} setSelectedProduct={setSelectedProduct} />
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {/* <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
          <Box id="focus_top_modal">
            <AppBar elevation={1} sx={{ position: 'relative' }} style={{ background: '#fff' }}>
              <Toolbar>
                <Typography sx={{ color: '#000', flexGrow: 1, textAlign: 'center' }} variant="h6" component="div">
                  Atualizar Produto <b>{selectedProduct.name}</b>
                </Typography>
                <Typography sx={{ flexGrow: 1, textAlign: 'start' }} variant="h6" component="div"></Typography>
                <IconButton edge="center" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon sx={{ color: '#000' }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="lg" sx={{}}>
              <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}></Paper>
            </Container>
          </Box>
        </Dialog> */}
      </TableContainer>
    </>
  );
}
