import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Card, CardContent, CardMedia, Grid, ListItemIcon, Radio, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from '../../../../../components/Loader/Loading';
import { formatValue } from '../../../../../utils/functions';
import { green } from '@mui/material/colors';

const useStyles = makeStyles({
  selected: {
    border: '2px solid green',
    boxSizing: 'border-box',
    margin: '-2px',
  },
});

export default function FormInputStep3AreasMapsAreasMapsRegister({ data, obj_fn, newArea }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [areas, setAreas] = useState([]);
  const [areasProp, setAreasProp] = useState([]);

  //Id da área selecionada
  const [selectedId, setSelectedId] = useState(data?.areas.length > 0 ? data.areas[0].id : 0);

  //função para lidar com a alteração de seleção de área
  const handleChange = (event, area) => {
    // console.log('handleChange');
    //define o selectedId para a área selecionada
    setSelectedId(area.id);

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: [area],
      nameArea: area.name
    }));
  };

  //A função setMapAreas é definida como assíncrona para carregar as áreas de mapa e definir o estado areas com areaMapList e o estado loading como false.
  const setMapAreas = async () => {
    const listAreasLivestock = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
      ['id', data.id_livestock],
      ['tipo', 'livestock'],
    ]).sort((a, b) => b.id - a.id);

    const allAreas_aux = obj_fn.usestore.reposAreas.getAreaMapStore('id_property', data.id_property);
    const allAreas = allAreas_aux.filter((area) => {
      var is_in = false;
      for (const areaL of listAreasLivestock) {
        if (areaL.id === area.id) {
          is_in = true;
          break;
        }
      }
      area.assign = true;
      return !is_in;
    }).sort((a, b) => b.id - a.id);

    setAreas(listAreasLivestock);
    setAreasProp(allAreas);
    setLoading(false);
  };

  //Inicia as áreas com a função setMapAreas e para o loading
  useEffect(() => {
    if (loading) {
      setMapAreas();
    }
  }, []);
  //Chama setMapAreas quando newArea é alterado.
  useEffect(() => {
    if (!loading) {
      setMapAreas();
      setSelectedId(data?.areas[0]?.id || newArea || 0);
    }
  }, [newArea]);

  /**
   * Cria um estado para armazenar o status de cada imagem 
   * Inicializa todas as imagens como "carregando"
  */
  const [loadingStates, setLoadingStates] = useState(
    obj_fn.usestore.reposAreas.listAreasMap.reduce((imgArea, area) => {
      imgArea[area.id] = true;
      return imgArea;
    }, {})
  );
  // Define o estado da imagem específica como "carregada"
  const handleImageLoad = (id) => {
    setLoadingStates((prev) => ({
      ...prev,
      [id]: false,
    }));
  };

  /**
  * Função que carrega o card de áreas de acordo com a lista informada
  */
  const getCardAreas = (list) => {
    return (<>
      {list?.map((area) => (
        <Grid item xs={12} md={6} key={area.id} container alignItems="center" justifyContent="center">
          <Card sx={{ display: 'flex', boxSizing: 'border-box', width: '100%' }} className={selectedId === area.id ? classes.selected : null}>
            <ListItemIcon>
              <Radio
                name={"radioArea_" + area.id}
                color="success"
                checked={selectedId === area.id}
                onChange={(e) => handleChange(e, area)}
              />
              {loadingStates[area.id] ? (
                <Skeleton variant="rectangular" width={100} height={100} />
              ) : (
                <CardMedia
                  component="img"
                  sx={{ width: 100 }}
                  image={area.url || area.imageDecode}
                  alt={'Área: ' + area.name}
                  title={'Área: ' + area.name}
                />
              )}
              <CardMedia
                //Imagem oculta para gerenciar o carregamento
                component="img"
                sx={{ display: "none" }} // Esconde o CardMedia auxiliar
                image={area.url || area.imageDecode}
                alt={'Área: ' + area.name}
                title={'Área: ' + area.name}
                onLoad={() => handleImageLoad(area.id)} // Atualiza estado da imagem ao carregar
                onError={() => handleImageLoad(area.id)} // Trata erro de carregamento
              />
              <CardContent sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Área:
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {area.name}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    <i className="fa fa-map text-success">
                      {' '} {formatValue(area.usedArea || area.area)} ({area.measure})
                    </i>
                  </Typography>
                </Box>
              </CardContent>
            </ListItemIcon>
          </Card>
        </Grid>
      ))}
    </>)
  };

  return (
    <div className="row">
      <div className="col">
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typography fontWeight={700} gutterBottom>
                  Áreas vinculadas à criação:
                </Typography>
              </Grid>
              {getCardAreas(areas)}
            </Grid>

            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              <Grid item xs={12} md={12} alignItems="center">
                <Typography fontWeight={700} gutterBottom>
                  Áreas da propriedade:
                </Typography>
                <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
                  Ao utilizar uma destas áreas, ela será automaticamente vinculada à criação atual!
                </Typography>
              </Grid>
              {getCardAreas(areasProp)}
            </Grid>
          </>
        )}
      </div>
    </div>
  );
}
