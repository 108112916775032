import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Paper, Typography } from '@mui/material';
import { AppBar, Divider, Drawer, Toolbar, Box, Fab } from '@mui/material';
import DrawArea from '../../../../areasMaps/components/DrawArea';
import CloseIcon from '@mui/icons-material/Close';
import FormSaveArea from '../../../../areasMaps/components/FormSaveArea';
import html2canvas from 'html2canvas';
import MapView from './mapView/MapView';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function FormTransferStep2MoveBatchDialogTransferArea({ data, setData, setImage, setLoading }) {
  console.log('FormTransferStep2MoveBatchDialogTransferArea');
  console.log(data);
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState([]);

  const [LoadingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    setLoadingButton(false);
  }, []);

  const captureImage = () => {
    console.log('captureImage');
    var element = document.getElementById('map');
    console.log(element);
    html2canvas(element, {
      backgroundColor: null,
      useCORS: true,
    }).then(function (canvas) {
      var base64image = canvas.toDataURL('image/jpeg');
      //setDrawM(false);
      setImage(base64image);
      data.areaTranferId.image = base64image;
      // setDrawF(true);
    });
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  //const [drawM, setDrawM] = useState(true);
  //const [drawF, setDrawF] = useState(false);
  //const [textButon, setTextButton] = useState('Próximo');

  // controla o estado de desenho ou formSave
  const handleSelection = (e) => {
    setLoading(true);
    console.log('save');
    console.log(data);
    setLoadingButton(true);
    setTimeout(() => {
      captureImage();
    }, 500);

    console.log(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setPath([]);
    setOpen(false);
    data.areaTranferId = { id_area: '', name: '', image: '' };

    // setDrawM(true);
    // setDrawF(false);
    setImage('');
    // setTextButton('Próximo');
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log("entrou no submit");
  // }

  return (
    <div className="row" style={{ textAlign: 'center' }}>
      <div className="col">
        <Button size="small" variant="contained" color="success" onClick={handleClickOpen}>
          Transferir o Lote
          <SwapHorizIcon sx={{ ml: 1 }} />
        </Button>
        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'lg'}>
          <section className="content " style={{ overflowY: 'hidden', paddingBottom: 80 }}>
            <div className="container-fluid ">
              <DialogTitle style={{ cursor: 'move', display: 'flex', alignItems: 'center' }} id="draggable-dialog-title">
                Transferência de lote:
                <div className="d-none d-lg-block">
                  <Typography variant="subtitle2" sx={{ mt: 1, ml: 2 }} fontWeight={500}>
                    Clique na área que deseja trasnferir o lote
                  </Typography>
                </div>
              </DialogTitle>
              <Fab sx={{ position: 'fixed', top: 5, right: 10, backgroundColor: '#fff' }} color="neutral" variant="extended" size="small" onClick={handleClose} aria-label="Close">
                <CloseIcon sx={{ mr: 0 }} />
              </Fab>
              <Divider />
              <DialogContent style={{ padding: '2px' }}>
                {!open ? <></> : <MapView data={data} setData={setData} container={handleClose} setImage={setImage} />}
                {/*!drawM || !open ? <></> : <DrawArea path={path} setPath={setPath}/> */}
                {/*drawF ? <FormSaveArea handleClose={handleClose} path={path} image_area={image_area} setAddArea={setAddArea} /> : <></>*/}
              </DialogContent>
              <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
                <Toolbar>
                  <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                    <Fab sx={{ backgroundColor: '#fff' }} color="neutral" variant="extended" size="small" onClick={handleClose} aria-label="Close">
                      <CloseIcon sx={{ mr: 1 }} /> Cancelar
                    </Fab>
                  </Box>
                  <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                    <Button sx={{ width: 120, ml: 1, ml: 'auto' }} color="success" variant="contained" type="submit" onClick={(e) => handleSelection(e)}>
                      {LoadingButton ? (
                        <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
                      ) : (
                        <>
                          <SaveAltIcon sx={{ mr: 1 }} />
                          Salvar
                        </>
                      )}
                    </Button>
                  </Box>
                </Toolbar>
              </AppBar>
            </div>
          </section>
        </Dialog>
      </div>
    </div>
  );
}
