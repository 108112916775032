import React, { useEffect, useState } from 'react';
import { Avatar, Box, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import api from '../../../services/api';
// import { toast } from 'react-toastify';
import { Context } from '../../../components/Context/AuthContext';
import usePatrimonyReposStore from '../../../store/usePatrimonyReposStore';
import { getIconComponent } from './getIconComponent';

export default function ListPatrimony({ setPatrimonyData }) {
  const [patrimonyList, setPatrimonyList] = useState([]);
  // Estado para controlar a visibilidade dos grupos
  const [visibleGroups, setVisibleGroups] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const { aux_add } = React.useContext(Context);
  const reposPatrimony = usePatrimonyReposStore((state) => state);

  //aux_add atualiza o estado
  useEffect(() => {
    setPatrimonyList(reposPatrimony?.listPatrimony);
  }, [aux_add, reposPatrimony.listPatrimony]);

  useEffect(() => {
    console.log('Updated visibleGroups state:', visibleGroups);
  }, [visibleGroups]);

  // Função para atualizar o estado searchTerm baseado na entrada do usuário
  // Função para atualizar o estado searchTerm baseado na entrada do usuário
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    console.log(newSearchTerm);
    setSearchTerm(newSearchTerm);

    // Ao digitar, abrir todos os grupos
    setVisibleGroups((prevState) => {
      const updatedState = {};

      // Preenchendo o estado com todos os grupos definidos como 'true'
      filteredAndGroupedPatrimonies.forEach(([groupName]) => {
        updatedState[groupName] = true;
      });

      return updatedState;
    });
  };

  // Função para alternar a visibilidade do grupo
  // Função para alternar a visibilidade do grupo
  const toggleGroupVisibility = (groupName) => {
    setVisibleGroups((prevState) => {
      const updatedState = {
        ...prevState,
        [groupName]: !prevState[groupName],
      };
      return updatedState;
    });
  };

  // const handleDeletePatrimony = async (id) => {
  //   const userConfirm = window.confirm('Tem certeza que deseja excluir este patrimônio?');
  //   if (userConfirm) {
  //     try {
  //       await api.delete(`/patrimony/list/${id}`);
  //       toast.success('Patrimônio deletado com sucesso!');
  //       setAuxAdd(!aux_add); // Atualize o estado para refletir a mudança na lista de patrimônios
  //     } catch (error) {
  //       console.error('Erro ao deletar patrimônio:', error);
  //       toast.error('Erro ao deletar patrimônio.');
  //     }
  //   }
  // };

  // atualiza o card que irá aparecer
  const updateNamePatrimony = (data) => {
    setPatrimonyData(data); // Atualize o estado com os dados do patrimônio selecionado
  };

  const filteredAndGroupedPatrimonies = Object.entries(
    patrimonyList.reduce((acc, patrimony) => {
      if (patrimony.name.toLowerCase().includes(searchTerm)) {
        const groupName = patrimony.group;
        if (!acc[groupName]) acc[groupName] = [];
        acc[groupName].push(patrimony);
      }
      return acc;
    }, {})
  );

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <TextField
        sx={{ width: '100%', maxWidth: '100%' }}
        id="filled-search"
        label="Pesquisar Patrimônio"
        type="search"
        variant="standard"
        color="success"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <List
        sx={{
          mt: 2,
          mr: 2,
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 450,
          '& ul': { padding: 0 },
        }}
      >
        {filteredAndGroupedPatrimonies.map(([groupName, items]) => (
          <li key={`section-${groupName}`}>
            <ul>
              <ListSubheader
                key={groupName}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2" component="div" sx={{ mt: 2 }}>
                  <strong>{groupName}</strong>
                </Typography>
                <IconButton onClick={() => toggleGroupVisibility(groupName)}>{visibleGroups[groupName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              </ListSubheader>
              {visibleGroups[groupName] &&
                items.map((item) => (
                  <ListItem key={item.id} sx={{ ml: -2 }}>
                    <ListItemButton onClick={() => updateNamePatrimony(item)}>
                      <ListItemAvatar>
                        {item.image ? <Avatar aria-label="Imagem do patrimônio" src={`data:image/jpeg;base64,${item.image}`}></Avatar> : getIconComponent(item.patrimony_group_icon)}
                      </ListItemAvatar>
                      <ListItemText primary={<Typography>{capitalizeFirstLetter(item.name)}</Typography>} secondary={item.type} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </ul>
          </li>
        ))}
        {filteredAndGroupedPatrimonies.length === 0 && (
          <Box sx={{ ml: 2, textAlign: 'left' }}>
            <Typography variant="body2" component="div" sx={{ mt: 2 }}>
              <strong>Patrimônio inexistente.</strong>
            </Typography>
            <Typography variant="body2" component="div" sx={{ mt: 2 }} style={{ color: '#2e7d32' }}>
              Clique em "NOVO PATRIMÔNIO" e cadastre-o.
            </Typography>
          </Box>
        )}
      </List>
    </>
  );
}
