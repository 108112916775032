import React, { useState, useEffect, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/system';
import { H6, Span } from '../../../components/Typography';
import SendIcon from '@mui/icons-material/Send';
import CardMsn from './forms/CardMsn';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { encode as base64_encode } from 'base-64';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';
import SuccessMsn from './SuccessMessenger/SuccessMsn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ButtonFullScreenDialog({ isLoading, setIsLoading, openModal, setOpenModal }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const usestore = useStore();
  const propertyRepos = usestore.reposProperty.propertyRepos;
  const reposMessenger = usestore.reposMessenger;
  const { aux_add, setAuxAdd } = useContext(Context);

  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [LoadingButton, setLoadingButton] = useState(false);

  function handleClickOpen() {
    setOpen(true);
    setLoadingButton(false);
  }

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
  };

  useEffect(() => {
    setLoadingButton(false);
    if (openModal === false) {
      setOpen(false);
    } else if (openModal === true) {
      setOpen(true);
      setIsLoadingSuccess(false);
    }
    //setOpen(true)
  }, [openModal]);

  const display_demo = JSON.parse(localStorage.getItem('display'));

  // para user current
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const message_default = {
    title: '',
    description: '',
    date: new Date(),
    id_requester: userCurrent.id,
    id_receiver: '',
    id_property: propertyRepos.id,
    id_livestock: '',
    id_harvest: '',
    key_image: '',
    sendMsn: '',
    area: '',
    coordinates: '',
    zoom: '15',
    status: '0',
    isDeleted: '0',
  };
  const [message, setMessage] = useState(message_default);
  //console.log(message)

  useEffect(() => {
    setMessage(message_default);
  }, []);

  //['Resolver'->0, 'Resolvido'->1, 'Não Resolvido'->2, 'Parado'->3];

  // errors.chave define true (property.chave não validada) ou false
  const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  const onSubmit = async (e, send) => {
    e.preventDefault();
    console.log('entrou no submit');
    //setIsLoading(true)

    setFormError({});
    let errors = {};
    let formOk = true;

    message.title = message.title.trim();
    if (message.title < 5) {
      errors.title = true;
      formOk = false;
    }

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const imageData = JSON.parse(localStorage.getItem('image'));
      message.key_image = imageData;
      const dateData = JSON.parse(localStorage.getItem('date'));
      message.date = dateData;
      const userCurrent2 = JSON.parse(localStorage.getItem('userCurrent'));
      message.id_requester = userCurrent2.id;
      const propertyData = JSON.parse(localStorage.getItem('Property') || '1');
      message.id_property = propertyData;
      const statusMessenger = JSON.parse(localStorage.getItem('statusMessenger'));
      message.status = statusMessenger;
      const marker = JSON.parse(localStorage.getItem('marker'));
      // let decoded = await JSON.parse(base64_decode(res.data.coordinates));
      // let encoded = base64_encode(JSON.stringify(path));
      let encoded = marker ? base64_encode(JSON.stringify(marker)) : '';
      message.coordinates = encoded;
      console.log('xxxxxxxxxxxxxxmessagexxxxxxxxxxxxxxxxxxx');
      console.log(message);
      //const res = await api.post("/messenger", message, header);
      //const res = true
      //console.log(res);
      //const res = false
      //if (res) {
      const response = await api
        .post('/messenger', message, header)
        .then((res) => {
          if (!res.data.isSuccess) {
            toast.error(`Opss.. Ocorreu algum erro ao registrar a entrada de animais,por favor, tente novamente.`);
            console.log('erro');
            return false;
          }
          if (send) {
            const currentPageLink = window.location.href;
            const number = message.sendMsn.phone;
            const newMessageWhatsapp = `*_FazendaPremium_*\n\n*Assunto*: ${message.title}\n*Descrição*: ${message.description} \n\nAcesse: ${currentPageLink}`;

            // Verificar se está em um dispositivo móvel
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

            let url = '';

            if (isMobile) {
              // Abrir o WhatsApp no aplicativo móvel
              // url = `whatsapp://send?phone=${number}&text=${encodeURIComponent(newMessageWhatsapp)}`;
              //url = `https://wa.me/${number}?text=${encodeURIComponent(newMessageWhatsapp)}&type=phone_number&app_absent=0`;
              url = `https://api.whatsapp.com/send/?phone=${number}&text=${encodeURIComponent(newMessageWhatsapp)}&type=phone_number&app_absent=0`;
              //https://api.whatsapp.com/send/?phone=5531987683264&text=Assunto%3Ateste07%0ADescri%C3%A7%C3%A3o%3Ateste07&type=phone_number&app_absent=0
              //https://web.whatsapp.com/send/?phone=5531987683264&text=Assunto%3Ateste08%0ADescri%C3%A7%C3%A3o%3Ades&type=phone_number&app_absent=0
            } else {
              // Abrir o WhatsApp Web em uma nova guia
              url = `https://web.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(newMessageWhatsapp)}`;
            }

            // Appending the phone number to the URL
            //let url = `https://web.whatsapp.com/send?phone=${number}`;

            // Appending the message to the URL by encoding it
            //url += `&text=${encodeURI(newMessageWhatsapp)}&app_absent=0`;

            // Abrir a URL em uma nova guia
            window.open(url, '_blank');
          }
          //console.log("entrou");
          setLoadingButton(true);
          setIsLoadingSuccess(true);
          localStorage.removeItem('image');
          localStorage.removeItem('date');
          localStorage.removeItem('property');
          localStorage.removeItem('statusMessenger');
          localStorage.removeItem('marker');
          //setIsLoading(false)
          reposMessenger.fetch().then((res) => {
            setAuxAdd(aux_add + 1);
            setMessage(message_default);
            setTimeout(() => {
              handleClose();
            }, 2000);
          });
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error(`Erro ao enviar solicitação, tente novamente mais tarde!`);
        });
    } else {
      // escreve mensagem de erro para cliente
      toast.error(`Por favor, preencha o campo obrigatório do Título da solicitação!`);
      setFormError(errors);
      console.log(formError);
    }
  };
  //console.log(message.sendMsn)
  //console.log(message);
  return (
    <Box>
      {/* <Button variant="contained" endIcon={<SendIcon />} sx={{ width: 150 }} size="medium" color="success" onClick={handleClickOpen}>
        Solicitação
      </Button> */}

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        {isLoadingSuccess && <SuccessMsn />}
        <AppBar sx={{ position: 'relative' }} color="success">
          <Toolbar>
            <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }} className="m-0">
              Sistema de Solicitações{' '}
            </H6>
            {display_demo ? (
              <div></div>
            ) : (
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <section className="content ">
          <div className="container-fluid " style={{ maxWidth: 900 }}>
            <CardMsn message={message} setMessage={setMessage} propertyRepos={propertyRepos} />
          </div>
        </section>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button>
            </Box>
            {!message.sendMsn == '' && (
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                {display_demo ? (
                  <div></div>
                ) : (
                  <Button sx={{ width: 100, position: 'relative' }} color="success" variant="contained" type="submit" onClick={(e) => onSubmit(e, true)}>
                    {LoadingButton ? (
                      <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
                    ) : (
                      <>
                        {' '}
                        <WhatsAppIcon />
                      </>
                    )}
                  </Button>
                )}
              </Box>
            )}
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {display_demo ? (
                <div></div>
              ) : (
                <Button sx={{ width: 100, position: 'relative' }} color="success" variant="contained" type="submit" onClick={onSubmit} disabled={message.title === ''}>
                  {LoadingButton ? (
                    <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
                  ) : (
                    <>
                      <SendIcon />
                      <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Enviar</Span>
                    </>
                  )}
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </Box>
  );
}
