import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import api from '../services/api';
import dayjs from 'dayjs';

// create the store
const useBillsReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listBills: [],
        listBillsPay: [],
        listBillsReceive: [],
        billsRepos: { total_pay: 0, total_receive: 0, total: 0 },
        fetch: async (item) => {
          var id_property = (localStorage.getItem("Property") || 1);
          
          if (!item) {
            item = { 'id_property': id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          item.allBills = true;

          const header = { header: { "Content-Type": "application/json" } };
          const response = await api.post("finances/bills", (item), header);
          
          const data = await response.data;
          const data_map = await (data.isSuccess ? data._value : []);

          let aux_bill;
          let bills_repos = { total_pay: 0, total_receive: 0, total: 0 };
          let list_bills_pay = [];
          let list_bills_receive = [];
          await set({
            listBills:
              data_map.map((bill) => {
                aux_bill = {
                  id: bill.id,
                  id_property: bill.id_property,
                  type_bill: bill.type_bill,
                  id_account: bill.id_account,
                  id_bank: bill.id_bank,
                  type: bill.type,
                  formPayment: bill.type,
                  value: bill.value,
                  date_entry: dayjs(bill.date_entry).format('YYYY-MM-DD HH:mm:ss'),
                  date_entry_f: dayjs(bill.date_entry).format('DD/MM/YYYY'),
                  date_expire: dayjs(bill.date_expire).format('YYYY-MM-DD HH:mm:ss'),
                  date_expire_f: dayjs(bill.date_expire).format('DD/MM/YYYY'),
                  portion: bill.portion,
                  portion_text: bill.portion_text,
                  status: bill.status,
                  id_transaction: bill.id_transaction,
                  date_payment: bill.date_payment ? dayjs(bill.date_payment).format('YYYY-MM-DD HH:mm:ss') : '',
                  date_payment_f: bill.date_payment_f ? dayjs(bill.date_payment_f).format('DD/MM/YYYY') : '',
                  id_trans_pay: bill.id_trans_pay,
                  description: bill.description || '',
                  info: bill.info ? JSON.parse(bill.info) : {},
                  record: bill.record ? JSON.parse(bill.record) : {},
                  info_pay: bill.info_pay ? JSON.parse(bill.info_pay) : {},
                  record_pay: bill.record_pay ? JSON.parse(bill.record_pay) : {},
                }

                if (bill.type_bill === 'pay') {
                  list_bills_pay.push(aux_bill);
                  if (bill.status === 'aberta') {
                    bills_repos.total_pay += bill.value;
                    bills_repos.total -= bill.value;
                  }
                } else {
                  list_bills_receive.push(aux_bill);
                  if (bill.status === 'aberta') {
                    bills_repos.total_receive += bill.value;
                    bills_repos.total += bill.value;
                  }
                }

                return aux_bill;
            })
          });
          set({
            billsRepos: (bills_repos),
            listBillsPay: (list_bills_pay),
            listBillsReceive: (list_bills_receive)
          });

          return data;
        },
        changeBillRepos: (BillsRepos) => {
          set((state) => ({ BillsRepos }))
        },
        // pega a(s) Bill(s) da listBills de acordo com o [key e val] informados
        getBillStore: (key, val) => {
          const list = get().listBills.filter((Bill) => {
            return Bill[key] === val;
          });
          return JSON.parse(JSON.stringify(list));

        },
        // keys é uma matriz, onde 0 é a chave para comparação e 1 é o valor de comparação
        //Ex1: [['id', 1], ['status', 'aberta']] -> Contas a pagar / receber em aberto
        //Ex2: [['status', 'aberta'], ['type_bill', 'pay']] -> Contas a pagar em aberto
        getBillMultiple: (keys) => {
          const list = get().listBills.filter((bill) => {
            var count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (bill[keys[i][0]] == keys[i][1]) {
                count++;
              }
            }
            return keys.length === count;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // Adiciona uma nova Bill à listBills
        addBillStore: (Bill) => {
          set(state => ({
            listBills: [...state.listBills, Bill]
          }))
        },
        // Gera uma nova listBills sem a Bill informada
        delBillStore: (id) => {
          set({
            listBills:
              get().listBills.filter((Bill) => {
                return Bill.id !== id;
              })
          })
        },
        // atualiza listBills de acordo com id, key e val informados
        updateBillStore: (id, key, val) => {
          set({
            listBills:
              get().listBills.map((Bill) => {
                if (Bill.id === id) {
                  if (key === 'all') { // atualiza todos os dados
                    Bill = val;
                  } else {
                    Bill[key] = val;
                  }
                }
                return Bill;
              })
          })
        },
        // pega a(s) Bill(s) da listBillsReceive de acordo com o [key e val] informados
        getBillsReceive: (key, val) => {
          const list = get().listBillsReceive.filter((Bill) => {
            return Bill[key] === val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        getBillsReceiveMultiple: (keys) => {
          const list = get().listBillsReceive.filter((bill) => {
            var count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (bill[keys[i][0]] == keys[i][1]) {
                count++;
              }
            }
            return keys.length === count;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // pega a(s) Bill(s) da listBillsPay de acordo com o [key e val] informados
        getBillsPay: (key, val) => {
          const list = get().listBillsPay.filter((Bill) => {
            return Bill[key] === val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        getBillsPayMultiple: (keys) => {
          const list = get().listBillsPay.filter((bill) => {
            var count = 0;
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (bill[keys[i][0]] == keys[i][1]) {
                count++;
              }
            }
            return keys.length === count;
          });
          return JSON.parse(JSON.stringify(list));
        },
      }),
      { name: "reposBills" }
    )
  )
);

export default useBillsReposStore;