import React, { useState } from 'react';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';
import FullScreenDialogEdit from '../DialogMessenger/FullScreenDialogEdit';
import MenuCard from './MenuCard';
import Badge from '@mui/material/Badge';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NotesIcon from '@mui/icons-material/Notes';
import EditNoteIcon from '@mui/icons-material/EditNote';
export default function CardChat({ post, setIsLoading }) {
  console.log('CardChat');

  const [isLoadingFarm, setIsLoadingFarm] = useState(false);

  const status = { 0: 'Resolver', 1: 'Resolvido', 2: 'Parado' };
  const statuscolor = { 0: 'primary', 1: 'success', 2: 'default' };

  return (
    <>
      {isLoadingFarm ? (
        <Card sx={{ mt: 1 }}>
          <CardHeader
            sx={{ m: -2, ml: -1 }}
            avatar={
              <>
                <Skeleton animation="wave" variant="circular" width={40} height={40} />
              </>
            }
            action={null}
            title={<Skeleton animation="wave" height={10} width="5%" style={{ marginBottom: 6 }} />}
            subheader={<Skeleton animation="wave" height={10} width="10%" />}
          />
          <CardContent sx={{ m: -3, ml: 0 }}>
            <React.Fragment></React.Fragment>
          </CardContent>
          <CardActions sx={{ mb: -2, mr: 0.5 }}>
            <IconButton aria-label="add to favorites">
              <Skeleton animation="wave" height={70} width={70} />
            </IconButton>

            <Box sx={{ ml: 'auto', mr: 5 }}></Box>
            <Skeleton animation="wave" height={35} width="6%" />
            <Skeleton animation="wave" height={10} width="8%" />
          </CardActions>
        </Card>
      ) : (
        <Card sx={{ mt: 1 }}>
          {/* <Card sx={{ maxWidth: 345 }}></Card> */}
          <CardHeader
            sx={{ m: -2, ml: -1 }}
            avatar={
              <>
                {post.status === '0' ? (
                  <Avatar sx={{ backgroundColor: blue[700] }} aria-label="recipe">
                    {post.charName}
                  </Avatar>
                ) : post.status === '1' ? (
                  <Avatar sx={{ backgroundColor: green[800] }} aria-label="recipe">
                    {post.charName}
                  </Avatar>
                ) : (
                  <Avatar sx={{ backgroundColor: grey[500] }} aria-label="recipe">
                    {post.charName}
                  </Avatar>
                )}
              </>
            }
            action={
              <IconButton aria-label="settings">
                <MenuCard id={post.id} setIsLoading={setIsLoading} />
              </IconButton>
            }
            title={
              <Typography variant="button">
                <strong>{post.title}</strong>
              </Typography>
            }
            subheader={
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                {post.name_property}
              </Typography>
            }
          />
          <CardContent sx={{ m: -2, ml: -1 }}>
            <Grid container direction="row" alignItems="flex-start" gap={1}>
              <Grid item sx={{ mr: 2.5 }}>
                {post.description && (
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <NotesIcon sx={{ ml: 1 }} /> {post.description}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {post.name_receiver && (
              <>
                <Grid container direction="row" alignItems="flex-end" gap={1}>
                  <Grid item sx={{ ml: 'auto', mr: 2.5 }}>
                    <Typography sx={{ fontSize: 14 }} color="text.success" gutterBottom>
                      <HowToRegIcon sx={{ fontSize: 22 }} color="success" /> {post.name_receiver}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>
          <CardActions>
            <IconButton aria-label="Editar" title="Editar">
              <FullScreenDialogEdit id={post.id} post={post} setIsLoading={setIsLoading} />
            </IconButton>
            {post.status && statuscolor && <Badge sx={{ ml: 'auto', mr: 5 }} badgeContent={status[post.status]} color={statuscolor[post.status]} />}
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {/*isLoadingDate && date*/}
              {post.date_f}
            </Typography>
          </CardActions>
        </Card>
      )}
    </>
  );
}
