import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { green } from '@mui/material/colors';
import dayjs from 'dayjs';
import FormMainConfirm from '../FormMain/FormMainConfirm';

const operationType = {
  "Compra": "Compra",
  "Despesa": "Conta a pagar",
  "Receita": "Conta a receber",
  "Venda": "Venda",
  "": ""
}

function getAport(array) {
  let apport_id = [];

  array.map((project) => {
    apport_id.push(project.id);
    return project;
  });
  return apport_id;
}

function getInstallment(array, obj_fn) {
  let installments = [];

  array.map((installment) => {
    const bankaccount = obj_fn.usestore.reposBank.getBankStore('id', installment.id_bank)[0];

    installments.push({
      dueDate: installment.date_entry,
      dueDate_f: dayjs(installment.date_entry).format("YYYY-MM-DD HH:mm:ss"),
      formPayment: installment.type,
      bankAccount: bankaccount.id,
      valueTotal: parseFloat(installment.value),
      portion: installment.portion,
      description: installment.description ? installment.description : ("Parcela " + installment.portion + "/" + array.length),
      payed: (!installment.date_payment ? false : true),
      error_date: false,
    });
    return installment;
  });

  return installments;
}

export default function ModalVisualizeBill({ bill, open, handleClose, obj_fn }) {
  const [data, setData] = useState({
    id_user: '',
    id_property: '',

    //step1
    operationType: '',
    operationName: '',

    //step2
    //  Informações do lançamento
    date_release: '',
    date_release_f: '',
    date_release_fc: '',
    supplier: '',
    client: '',
    person: '',
    value: '',
    descriptionEntry: '',
    //  Categoria da conta
    category: '',
    subcategory: '',
    //  Vincular com e descrição
    projects: '',
    projects_apport: '',
    description: '',

    //step3
    //  Condições pagamento
    dueDate: '',
    dueDate_f: '',
    dueDate_fc: '',
    installment: '',
    installmentInterval: '',
    formPayment: '',
    bankAccount: '',
    payed: '',
    //  Previsão de pagamento
    fee: '',
    penalty: '',
    discount: '',

    value_end: '',
    //  Parcelas do pagamento
    arr_installment: '',

    error_date: false,
    loaded: false,
  });
  
  useEffect(() => {
    if (open && bill.id === obj_fn.isOpen) {
    // if (open) {
      const transaction = obj_fn.usestore.reposFinances.getTransactionStore('id', bill.id_transaction)[0];
      const allBillT = obj_fn.usestore.reposBill.getBillStore('id_transaction', bill.id_transaction);
      allBillT.sort((a, b) => {
        return dayjs(a.date_expire).isBefore(b.date_expire);
      });
      const bankaccount = obj_fn.usestore.reposBank.getBankStore('id', bill.id_bank)[0];
      const person = obj_fn.usestore.reposPerson.getPersonStore('id', bill.info.id_person)[0];

      const account = obj_fn.usestore.reposAccount.getAccountStore('id', transaction.info.id_account)[0];

      setData({
        id_user: transaction.id_user,
        id_property: transaction.id_property,

        //step1
        operationType: operationType[transaction.type],
        operationName: transaction.type,

        //step2
        //  Informações do lançamento
        date_release: dayjs(transaction.date_entry),
        date_release_f: dayjs(transaction.date_entry).format("YYYY-MM-DD HH:mm:ss"),
        date_release_fc: dayjs(transaction.date_entry).format("DD/MM/YYYY HH:mm"),
        supplier: bill.type_bill === 'pay' ? bill.info.id_person : null,
        client: bill.type_bill !== 'pay' ? bill.info.id_person : null,
        person: person,
        value: (parseFloat(transaction.value) + (parseFloat(transaction.info.fee || '0')) + (parseFloat(transaction.info.penalty || '0')) - (parseFloat(transaction.info.discount || '0'))),
        descriptionEntry: transaction.info.descriptionEntry || "",
        //  Categoria da conta
        category: account ? (/subcategory/.test(account.info) ? account.mother : account.id) : '',
        subcategory: account ? (/subcategory/.test(account.info) ? account.id : null) : '',
        //  Vincular com e descrição
        projects: transaction.record.aport ? getAport(transaction.info.aport) : [],
        projects_apport: transaction.record.aport ? transaction.info.aport : [],
        description: transaction.info.description || '',

        //step3
        //  Condições pagamento
        dueDate: dayjs(allBillT[0].date_expire),
        dueDate_f: dayjs(allBillT[0].date_expire).format("YYYY-MM-DD HH:mm:ss"),
        dueDate_fc: dayjs(allBillT[0].date_expire).format("DD/MM/YYYY HH:mm"),
        installment: allBillT.length,
        installmentInterval: dayjs(transaction.date_entry).diff(dayjs(allBillT[0].date_expire).format("YYYY-MM-DD HH:mm:ss")),
        formPayment: allBillT[0].type,
        bankAccount: bankaccount.id,
        payed: false,
        //  Previsão de pagamento
        fee: parseFloat(transaction.info.fee || '0'),
        penalty: parseFloat(transaction.info.penalty || '0'),
        discount: parseFloat(transaction.info.discount || '0'),

        value_end: transaction.value,
        //  Parcelas do pagamento
        arr_installment: getInstallment(allBillT, obj_fn),

        error_date: false,
        loaded: true,
      });
    }
  }, [open]);

  return (<>
    {!data.loaded ? <></> :
      <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={handleClose} >
        <DialogTitle sx={{ color: green[700] }}>
          Visualização do Lançamento
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: '100%',
            }}
          > 
            <FormMainConfirm data={data} obj_fn={obj_fn} visualize={true} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mt: '-25px', mr: '10px' }}>
          <Button onClick={handleClose} color="success" variant="outlined" sx={{mr: '35px'}}>Fechar</Button>
        </DialogActions>
      </Dialog>
    }
  </>);
}