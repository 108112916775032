import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextAddProjectStepper from '../../../livestock/components/addLivestock/tutorial/TextAddProjectStepper';
import FlagIcon from '@mui/icons-material/Flag';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import TutorialaddActivitiesAccordion from '../../../activities/components/tutorial/TutorialaddActivitiesAccordion';
import MessengerList from './MessengerList';

export default function TutorialMessenger({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  return (
    <div>
      <IconButton variant="outlined" color="success" onClick={(e) => handleClickOpen(e)}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccountTreeIcon sx={{ mr: 1 }} /> Registros das solicitações
          </div>
          <Typography variant="subtitle2" color="textSecondary" sx={{ ml: 4 }}>
            Gestão das informações
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Toda a equipe poderá registrar solicitações, tarefas, anotações e observações relacionadas à propriedade, com foco especial nas safras e criações.
            </Typography>
          </Box>

          <MessengerList />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
