import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper'
import { AppBar, Container, Divider, Grid, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { CardButtonListActivities } from './CardButtonListActivities'
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

// fillDialog normal que chama uma lista de botões de atividade
// cada botão chama o componete de atividades ... MultiStepFormInput
export default function AddActivities({ setIsLoadingActivities }) {
  // const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    // console.log('handleClickOpen')
    // console.log(open)
    setOpen(true)
  }

  const handleClose = () => {
    setIsLoadingActivities(true)
    setOpen(false)
  }

  return (
    <>
      {/* {isLoading ? <Loading /> : ( */}
      <div>
        <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen} title='Nova Atividade de Campo'>
          Nova&nbsp;Atividade&nbsp;de&nbsp;Campo
        </Button>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> */}
          <AppBar sx={{ position: 'relative' }} color="inherit">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    Atividade de Campo
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider />
          <Box sx={{ width: '100%' }} id="focus_top_modal">
            <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
                <CardButtonListActivities handleClose={handleClose} />
              </Paper>
            </Container>
          </Box>
        </Dialog>
      </div>
      {/* )} */}
    </>
  )
}
