import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';
// import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { decode as base64_decode} from 'base-64';
import { getPolygonCenter } from '../components/Calc/getPolygonCenter';
// import LZString from 'lz-string';
import dayjs from 'dayjs';

function formatNumber(num) {
  var parse_num = num;
  if (/\..*,/.test(num)) {
    parse_num = num.replace(/\./, '');
  }
  parse_num = parse_num.replace(/,/, '.');
  return parseFloat(parse_num);
}

// create the store
const useAreasMapReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listAreasMap: [],
        areaMapRepos: { label: '', id: 0, id_property: 0 },
        fetch: async (item) => {
          var farm_id = localStorage.getItem('Property') || 1;

          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          //const response = await api.get("areamap/all", header);
          const response = await api.post('areamap/list', item, header);
          const data = await response.data;
          // console.log('areamap/list');
          // console.log(response.data);
          await set({
            listAreasMap: data.map((area) => ({
              id: area.id,
              id_property: area.id_property,
              name: area.name,
              label: area.name,
              coordinates: area.coordinates,
              //coordinatesDecode: JSON.parse(base64_decode(area.coordinates)),
              centerInit: area.coordinates ? getPolygonCenter(JSON.parse(base64_decode(area.coordinates))) : '',
              zoom: area.zoom,
              area: formatNumber(area.area || '0'),
              measure: area.measure || 'ha',
              address: area.address,
              description: area.description,
              url: area.url,
              image: area.url,
              imageDecode: area.url,
              //image: area?.image,
              //imageDecode: area.image ? LZString.decompressFromBase64(area.image) : '',
              created_at: dayjs(area.created_at).format('YYYY-MM-DD HH:mm:ss'),
              updated_at: dayjs(area.updated_at).format('YYYY-MM-DD HH:mm:ss'),
              //areas_used: JSON.parse(area.areas_used),
              areas_used: area.areas_used,
              total_animais: area.total_animais,
              isDeleted: area.isDeleted,
            })),
          });
          set({
            areaMapRepos: !data || data.length == 0 ? { label: '', id: 0, id_property: 0 } : { label: data[0].name, id: data[0].id, id_property: data[0].id_property },
          });
          return data;
        },
        changeAreaMapRepos: (areaMapRepos) => set((state) => ({ areaMapRepos })),
        // pega a(s) area(s) da listAreasMap de acordo com o key e val informados
        getAreaMapStore: (key, val) => {
          var listAreas = get().listAreasMap.filter((area) => {
            return area[key] == val;
          });
          return JSON.parse(JSON.stringify(listAreas));
        },
        // keys é uma matriz, onde 0 é a chave para comparação e 1 é o valor de comparação
        //Ex: [['id', 1], ['tipo', 'harvest']] -> safra com id = 1
        getAreaUsedMapStore: (keys) => {
          var areas = get().listAreasMap.filter((area) => {
            for (var j = 0, tamj = area.areas_used.length; j < tamj; j++) {
              let count = 0;
              for (var i = 0, tam = keys.length; i < tam; i++) {
                if (area.areas_used[j][keys[i][0]] == keys[i][1]) {
                  count++;
                }
              }
              if (count == keys.length) {
                j = tamj;
                return area;
              }
            }
          });
          return JSON.parse(JSON.stringify(areas));
        },
        // Adiciona uma nova aream à listAreasMap
        addAreaMapStore: (area) => {
          set((state) => ({
            listAreasMap: [...state.listAreasMap, area],
          }));
        },
        // Gera uma nova listAreasMap sem a area informada
        delAreaMapStore: (id) => {
          set({
            listAreasMap: get().listAreasMap.filter((area) => {
              if (area.id != id) {
                return area;
              }
            }),
          });
        },
        // atualiza listAreasMap de acordo com id, key e val informados
        updateAreaMapStore: (id, key, val) => {
          set({
            listAreasMap: get().listAreasMap.map((area) => {
              if (area.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  area = val;
                } else {
                  area[key] = val;
                }
              }
              return area;
            }),
          });
        },
      }),
      { name: 'reposAreasMap' }
    )
  )
);

export default useAreasMapReposStore;
