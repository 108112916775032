import * as React from 'react';
import { Box, Grid, Typography, Stack, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Payment } from '@mui/icons-material';
import GenericValueField from '../../../../components/Fields/GenericValueField';
import { formatValue } from '../../../../utils/functions';

// PREVISÃO DE PAGAMENTO
export default function FormPaymentForecast({ data, handleChange, obj_fn }) {
  // console.log('FormPaymentForecast')
  function ccyFormat(num) {
    var aux_num = num;
    if (typeof aux_num === 'string') {
      aux_num = parseFloat(num);
    }
    return formatValue(aux_num);
  }

  // calcula o valor de porcentagem sobre um valor
  function calcValue(percent, value) {
    var aux_value = value;
    if (typeof value === 'string') {
      aux_value = parseFloat(value);
    }
    var aux_val = aux_value * (parseFloat(percent || '0') / 100);

    return formatValue(aux_val, true);
  }

  let title_txt = /receber/.test(data.operationType) ? 'Valor de recebimento' : 'Valor de pagamento'
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Payment sx={{ mr: 1 }} />
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>{title_txt}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TableContainer>
            <Table sx={{ minWidth: obj_fn.ismobile ? 350 : 450 }} aria-label="Tabela previsão de agamento" size={'medium'}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <span style={{ float: "left" }}>Valor final</span>
                    <span style={{ float: "right" }}>R$</span>
                  </TableCell>
                  <TableCell align="right">{ccyFormat(data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Juros</TableCell>
                  <TableCell align="right">
                    <GenericValueField
                      handleChange={handleChange}
                      options={{
                        id: "fee",
                        name: "fee",
                        label: "",
                        value: data.fee,
                        variant: 'outlined',
                        max: 100,
                        height: '36px',
                        width: obj_fn.ismobile ? '85%' : '60%',
                        endAdornment: true,
                        prefix: " %",
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{calcValue(data.fee, data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Multa</TableCell>
                  <TableCell align="right">
                    <GenericValueField
                      handleChange={handleChange}
                      options={{
                        id:"penalty",
                        name:"penalty",
                        label: "",
                        value: data.penalty,
                        variant: 'outlined',
                        max: 100,
                        height: '36px',
                        width: obj_fn.ismobile ? '85%' : '60%',
                        endAdornment: true,
                        prefix: " %",
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{calcValue(data.penalty, data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Desconto</TableCell>
                  <TableCell align="right">
                    <GenericValueField
                      handleChange={handleChange}
                      options={{
                        id:"discount",
                        name:"discount",
                        label: "",
                        value: data.discount,
                        variant: 'outlined',
                        max: 100,
                        height: '36px',
                        width: obj_fn.ismobile ? '85%' : '60%',
                        endAdornment: true,
                        prefix: " %",
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{calcValue(data.discount, data.value)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontSize: '20px' }}>
                    <span style={{ float: "left" }}>Valor final</span>
                    <span style={{ float: "right" }}><strong>R$</strong></span>
                  </TableCell>
                  <TableCell style={{ fontSize: '20px' }} align="right">
                    <strong>{ccyFormat(data.value_end)}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
