import React, { useEffect, useRef } from 'react';
import { Button, Box, Grid, Typography, Stack, IconButton, Card, Snackbar, Alert } from '@mui/material';
import { Category, HighlightOff, Queue } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import CurrencyInputReal from '../../../components/Fields/CurrencyInputReal';
import GenericValueField from '../../../components/Fields/GenericValueField';
import AutocompleteGeneric from '../../../components/Fields/AutocompletGeneric';
import Typewriter from '../../../components/Typewriter/Typewriter';
import SelectPackagingUnit from '../../../components/Fields/SelectPackagingUnit';
import { formatValue, smoothScrollToBottom } from '../../../utils/functions';
import ProductRegistrar from '../../products/components/ProductRegistrar/ProductRegistrar';
import ExpensesCostCalculator from './ExpensesCostCalculator';
import { useState } from 'react';

export default function ResourcesStepFormTabInputs({ data, obj_fn }) {
  console.log('ResourcesStepFormTabInputs');
  useEffect(() => {
    // Mapeando os dados do location para location_selected
    // Mapeando os dados de location dentro de product para location_selected
    /*  data.resources.products.forEach((product) => {
      // Verifica se o product contém a propriedade location e se ela é um array
      if (product.product.location && Array.isArray(product.product.location)) {
        // Pega o primeiro item do array location (ajuste conforme necessário)
        const locationData = product.product.location.find((loc) => loc.id === product.id_location);

        if (locationData) {
          // Atualiza o location_selected com os dados encontrados
          product.location_selected = { ...locationData };
        }
      }
    });
    console.log('data');
    console.log(data); */
  }, []);

  data.resources.products.forEach((product) => {
    // Verifica se o product contém a propriedade location e se ela é um array
    if (product.product.location && Array.isArray(product.product.location)) {
      // Localiza os dados de location com base no id_location
      const locationData = product.product.location.find((loc) => loc.id === product.id_location);

      if (locationData) {
        // Adiciona as propriedades de locationData ao objeto existente em location_selected
        product.location_selected = {
          ...product.location_selected, // Mantém os dados existentes
          ...locationData, // Adiciona novos dados
        };
      }
    }
  });

  console.log(data);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Função para fechar o Snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Cria uma referência para o formulário
  const formRef = useRef(null);
  // Rola a tela até o fim do formulário
  useEffect(() => {
    smoothScrollToBottom(formRef, 1100);
  }, [formRef?.current?.scrollHeight]);

  // Ensure data.resources is defined, initialize if not present
  const products = data.resources?.products || [];
  console.log('products');
  console.log(products);

  const optionsProducts = JSON.parse(JSON.stringify(obj_fn.usestore.reposProducts.listProducts));
  console.log(optionsProducts);

  const opt_products = {
    multiple: false,
    name: 'product',
    field: 'product',
    label: 'Produto *',
    values: optionsProducts,
    variant: 'outlined',
    helperText: '',
    listHeight: 225,
  };

  const opt_products_local = {
    multiple: false,
    name: 'product_location_',
    field: 'product_location',
    label: 'Local de estoque',
    values: [],
    variant: 'outlined',
    helperText: '',
    listHeight: 225,
    disabled: !data.product?.id,
    disableClearable: true,
  };

  const handleAdd = () => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      resources: {
        ...prevState.resources,
        products: [
          ...(prevState.resources?.products || []),
          {
            id: prevState.resources?.products?.length + 1 || 1,
            product: { id: null, location: [], stock_cost: '', unit_sigla: '' },
            location_selected: { id: null, quanty: '', quant_utilizada: '', value: '' },
          },
        ],
      },
    }));
    // Rola a tela até o formulário de despesas
    // setTimeout(() => {
    //   formRef.current?.scrollIntoView({ behavior: 'smooth' });
    // }, 100); // Pequeno atraso para garantir que o novo elemento seja renderizado
  };

  const handleChangeSelect = (index, newValue) => {
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.resources?.products || [])];
      if (newValue) {
        let aux_prod = JSON.parse(JSON.stringify(newValue));
        updatedProducts[index].product = aux_prod;
        updatedProducts[index].location_selected = aux_prod.location.length > 0 ? aux_prod.location[0] : { id: null, quanty: '', quant_utilizada: '', value: '' };
      } else {
        updatedProducts[index].product = { id: null, location: [], stock_cost: '', unit_sigla: '' };
        updatedProducts[index].location_selected = { id: null, quanty: '', quant_utilizada: '', value: '' };
      }
      updatedProducts[index].product.packaging_unit = newValue?.packaging_unit || 'unidade';
      updatedProducts[index].packaging_unit = newValue?.packaging_unit || 'unidade';
      updatedProducts[index].volume_pkg = newValue?.volume_pkg;

      return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
    });
  };

  useEffect(() => {
    console.log(data);
    console.log(products);
    //handleChangeSelect = (index, newValue)
  }, []);

  const handleChangeLocal = (index, newValue) => {
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.resources?.products || [])];
      updatedProducts[index].location_selected = JSON.parse(JSON.stringify(newValue));

      return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
    });
  };

  const handleChangeQtd = (index, event) => {
    const newValue = parseFloat(event.target.value || '0');
    obj_fn.setData((prevState) => {
      const updatedProducts = [...(prevState.resources?.products || [])];
      const selectedProduct = updatedProducts[index];
      const aux_local = {
        ...selectedProduct.location_selected,
        quant_utilizada: newValue,
        value: (selectedProduct.packaging_unit !== 'unidade' ? newValue / selectedProduct.volume_pkg : newValue) * (selectedProduct.product.stock_cost || 0),
      };
      selectedProduct.location_selected = aux_local;

      return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
    });
  };
  const getCost = (product, location, index) => {
    var aux_qtd = location.quant_utilizada || 0;
    if (product?.packaging_unit !== 'unidade') {
      aux_qtd = aux_qtd / (product?.volume_pkg || 1);
    }
    var value = aux_qtd * (product?.product?.stock_cost || 0);

    const updatedProducts = data.resources?.products || [];
    if (updatedProducts?.length > 0 && updatedProducts[index]) {
      const selectedProduct = updatedProducts[index];
      if (location.value !== value) {
        obj_fn.setData((prevState) => {
          const aux_local = {
            ...selectedProduct.location_selected,
            value: value,
          };
          selectedProduct.location_selected = aux_local;

          return { ...prevState, resources: { ...prevState.resources, products: updatedProducts } };
        });
      }
    }
    return value;
  };

  const handleRmv = (index) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      resources: {
        ...prevState.resources,
        products: prevState.resources.products.filter((_, i) => i !== index),
      },
    }));
  };

  const validQtd = (product, type) => {
    let valid = true;
    let qtd = product?.location_selected?.quant_utilizada || 0;
    var qtd_prod = product?.location_selected?.quanty || 0;
    var qtd_edit = product?.location_selected?.quanty_edit || 0;

    if (product?.packaging_unit !== 'unidade') {
      qtd_prod = qtd_prod * (product?.volume_pkg || 1) + qtd_edit * (product?.volume_pkg || 1);
    } else {
      qtd_prod = qtd_prod * (product?.volume_pkg || 1) + qtd_edit;
    }

    if (qtd > qtd_prod) {
      valid = false;
    }

    if (type === 'color') {
      return valid ? 'success' : 'error';
    } else {
      return (
        <>
          Qtd. a ser utilizada
          {valid ? (
            <></>
          ) : (
            <>
              <br />
              <span style={{ color: 'red' }}>Não pode ser maior do que a Quantidade em estoque</span>
            </>
          )}{' '}
        </>
      );
    }
  };

  const getHelperText = (product, tipo) => {
    var texto = '';
    if (tipo === 'local') {
      texto = (
        <>
          Local onde o produto se encontra
          <br />
          <span style={{ color: 'green' }}>Qtd. em estoque: </span>
          {!product?.id ? (
            <>-</>
          ) : (
            (product?.product?.packaging_name === 'Unidade'
              ? ''
              : formatValue(product?.location_selected?.quanty || 0, '', { prefix: '(' + (product?.product?.packaging_sigla || '') + ')', end: true }) + ' ou ') +
            formatValue((product?.location_selected?.quanty || 0) * (product?.product?.volume_pkg || 1), '', { prefix: '(' + (product?.product?.unit_sigla || '') + ')', end: true })
          )}
          {!product?.location_selected?.quanty_edit ? (
            <></>
          ) : (
            <>
              <br />
              Qtd. em edição:{' '}
              {formatValue(product?.location_selected?.quanty_edit, '', {
                prefix: '(' + (product?.product?.packaging_unit === 'unidade' ? product.product?.packaging_sigla : product.product?.unit_sigla) + ')',
                end: true,
              })}
            </>
          )}
        </>
      );
    } else {
      texto = (
        <>
          Selecione o produto desejado
          <br />
          Valor unitário: {formatValue(product?.stock_cost || 0, true)}
        </>
      );
    }
    return texto;
  };

  const isErrorAddButton = () => {
    const products = data.resources?.products;
    if (products?.length > 0) {
      console.log('products');
      // Verificar se algum item no array está inválido
      return products.some((product) => !product?.product.id || !product?.location_selected?.quant_utilizada);
    } else {
      return false;
    }
    //return false;
  };

  // const isError = () => {
  //   let isError = false;
  //   if (!data.resources.products || !data.resources.products?.length) {
  //     isError = true;
  //   }
  //   data.products?.forEach((prod) => {
  //     if (!prod.product?.id || !prod?.location_selected?.id || !prod?.location_selected?.quant_utilizada || !prod?.location_selected?.value) {
  //       isError = true;
  //     }
  //   });
  //   return isError;
  // };
  // const returnError = () => {
  //   return false;
  // };

  console.log('products-fim');
  console.log(products);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ ml: 2, display: 'flex' }}>
            <Category />
            <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
              <Typography fontWeight={700}>Lista</Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <ProductRegistrar />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} alignItems="center" sx={{ mb: 1 }}>
        <Typography sx={{ textAlign: 'start' }} variant="subtitle1" component="div">
          Olá produtor!
        </Typography>
        {optionsProducts.length > 0 ? (
          <Typewriter
            text="Caso tenha o insumo cadastrado no sistema, clique em ADICIONAR USO DE INSUMO. Caso não encontre o produto, clique em CADASTRO DE PRODUTOS."
            variant={'subtitle1'}
            color={green[800]}
          />
        ) : (
          <Typewriter
            tam={60}
            text="Para adicionar o uso de insumo, é necessário cadastrar o insumo no estoque da sua propriedade. Clique no botão CADASTRAR PRODUTO para começar."
            variant={'subtitle1'}
            color={green[800]}
          />
        )}
      </Grid>
      {optionsProducts.length > 0 && (
        <div ref={formRef}>
          {products.map((product, index) => (
            <div key={product.id}>
              <Card sx={{ p: 1, mt: 2 }} key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                      <Box sx={{ ml: 3, display: 'flex' }}>
                        <Category />
                        <Stack spacing={0.5} sx={{ mt: 0, ml: 1 }}>
                          <Typography fontWeight={700}>Insumo {index + 1}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton variant="contained" color="error" onClick={() => handleRmv(index)} title="Remover">
                        <HighlightOff />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} alignItems="center" sx={{ mb: 0 }}>
                    <Typography sx={{ textAlign: 'start' }} variant="subtitle1">
                      Selecione o produto desejado e o local de estoque.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <AutocompleteGeneric
                      value={product.product.id ? product.product : null}
                      options={{
                        ...opt_products,
                        helperText: getHelperText(product.product),
                      }}
                      handleChange={(field, newValue) => handleChangeSelect(index, newValue)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} sx={{ mt: 0 }}>
                    <AutocompleteGeneric
                      value={product.location_selected.id ? product.location_selected : null}
                      options={{
                        ...opt_products_local,
                        values: product.product.location,
                        helperText: getHelperText(product, 'local'),
                      }}
                      handleChange={(field, newValue) => handleChangeLocal(index, newValue)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 0, mb: 0 }}>
                  <Grid item xs={12} md={12} alignItems="center" sx={{ mb: 0 }}>
                    <Typography sx={{ textAlign: 'start' }} variant="subtitle1">
                      Escolha a medida para retirada e informe a quantidade usada.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={5}>
                    {/* <CurrencyInputReal
                    name={`quant_stq_${product.product.id}_${product.location_selected.id}`}
                    label="Qtd. em estoque"
                    variant="outlined"
                    size="small"
                    value={product.location_selected.quanty || '-'}
                    adornment="quantidade"
                    endAdornment={product.product.unit_sigla ? ` (${product.product.unit_sigla})` : ''}
                    helperText="Disponível no estoque"
                    disabled={true}
                  /> */}
                    <SelectPackagingUnit data={data} obj_fn={obj_fn} product={product.product} index={index} tipo={'recursos'} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <GenericValueField
                      handleChange={(event) => handleChangeQtd(index, event)}
                      options={{
                        required: true,
                        id: `quant_uti_${product.product.id}_${product.location_selected.id}`,
                        name: `quant_uti_${product.product.id}_${product.location_selected.id}`,
                        label: 'Quantidade utilizada',
                        value: product.location_selected.quant_utilizada,
                        disabled: !product.location_selected?.id,
                        variant: 'outlined',
                        helperText: validQtd(product, 'helperText'),
                        color: validQtd(product, 'color'),
                        endAdornment: true,
                        prefix:
                          product.product?.packaging_unit === 'unidade' && product?.product?.packaging_name !== 'Unidade'
                            ? product.product?.packaging_sigla
                              ? ' (' + product.product?.packaging_sigla + ') '
                              : ''
                            : product.product?.unit_sigla
                            ? ' (' + product.product?.unit_sigla + ') '
                            : '',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <CurrencyInputReal
                      name={`prod_value_${product.product.id}_${product.location_selected.id}`}
                      label="Custo"
                      variant="outlined"
                      size="small"
                      value={getCost(product, product.location_selected, index)}
                      helperText="Custo do produto"
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Card>
            </div>
          ))}
        </div>
      )}
      {optionsProducts.length > 0 && (
        <>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={12}>
              <Button variant="contained" color="success" onClick={handleAdd} disabled={isErrorAddButton()}>
                <Queue sx={{ mr: 1 }} /> {products.length > 0 ? 'Adicionar uso de outro insumo (produto)' : 'Adicionar uso de insumo (produto)'}
              </Button>
            </Grid>
            {/* <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                Despesa adicionada! <br /> Cliquem em concluir ou inclua outra despesa.
              </Alert>
            </Snackbar> */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ExpensesCostCalculator data={data} obj_fn={obj_fn} />
            </Grid>
          </Grid>
        </>
      )}
      {/* <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} /> */}
    </>
  );
}
