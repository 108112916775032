import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

const setLivestockRepos = (list) => {
  let livestock_repos = { label: '', id: 0, id_property: 0, tipo_repos: 'Criações', tipe: 0, activity: '' };

  if (list && list.length > 0) {
    let creationHarvestRepos_aux = localStorage.getItem('creationHarvestRepos');
    let creationHarvestRepos = creationHarvestRepos_aux ? JSON.parse(creationHarvestRepos_aux) : { label: '', id: 0, tipo_repos: '' };

    if (creationHarvestRepos.id && creationHarvestRepos.tipo_repos == 'Criações') {
      livestock_repos = creationHarvestRepos;
    } else {
      let userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
      let stgs = userCurrent.settings.livestock;

      let element = list[0];
      if (stgs && stgs.first != 0) {
        element = list.filter((item) => item.id == stgs.first)[0];
      }
      if (!element && stgs.second != 0) {
        element = list.filter((item) => item.id == stgs.second)[0];
      }
      if (!element && stgs.third != 0) {
        element = list.filter((item) => item.id == stgs.third)[0];
      }
      if (!element) {
        element = list[0];
      }

      livestock_repos = {
        label: element.name,
        id: element.id,
        id_property: element.id_property,
        tipe: element.id_tipe,
        activity: element.tipo,
        tipo_repos: 'Criações',
      };
    }
  }

  return livestock_repos;
};

function getNumAnimals(livestock) {
  var list_animais = livestock.animais;
  var list_lotes = livestock.lotes;
  var aux_qtd = 0;

  list_animais.map((item) => {
    if (!item.lote) {
      aux_qtd += 1;
    }
    return item;
  });
  list_lotes.map((item) => {
    aux_qtd += Number(item.num_femeas || '0') + Number(item.num_machos || '0') + Number(item.num_animals || '0');
    return item;
  });
  return aux_qtd;
}

// create the store
const useLivestockReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listLivestock: [],
        listAnimals: [],
        listLotes: [],
        //listLivestockAreasMap: [],
        livestockRepos: { label: '', id: 0, id_property: 0, tipo: 'Criações', tipe: 0, activity: '' },
        fetch: async (item) => {
          var farm_id = localStorage.getItem('Property') || 1;

          if (!item) {
            item = { id_farm: farm_id };
          } else if (!item.id_farm) {
            item.id_farm = farm_id;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('livestock/list', item, header);
          const data = await response.data;
          // console.log('livestock');
          // console.log(data);
          let list_animals = [];
          let list_lotes = [];
          await set({
            listLivestock: data._value.map((livestock) => {
              list_animals = list_animals.concat(livestock.animais || []);
              list_lotes = list_lotes.concat(livestock.lotes || []);
              return {
                id: livestock.id,
                id_property: livestock.id_property,
                id_tipe: livestock.id_tipe,
                tipo: livestock.tipo,
                tipo_prod: livestock.tipo_prod,
                activity: livestock.tipo,
                name: livestock.name,
                label: livestock.name,
                id_account: livestock.id_account,
                date_start: dayjs(livestock.date_start).format('YYYY-MM-DD HH:mm:ss'),
                date_prev_end: dayjs(livestock.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
                date_end: livestock.date_end ? dayjs(livestock.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
                data_inicial: dayjs(livestock.date_start).format('YYYY-MM-DD HH:mm:ss'),
                data_fim: dayjs(livestock.date_end || livestock.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
                situation: livestock.situation,
                observation: livestock.observation,
                isDeleted: livestock.isDeleted,
                id_group: livestock.id_group,
                group_name: livestock.group_name,
                group_name_nf: livestock.group_name_nf,
                group_icon: livestock.group_icon,
                tipo_icon: livestock.tipo_icon,
                animais: livestock.animais || [],
                lotes: livestock.lotes || [],
                total_animais: getNumAnimals(livestock),
                areas: JSON.parse(livestock.areas),
                images: [],
                tipo_repos: 'Criações',
              };
            }),
          });
          list_lotes.map((lote) => {
            lote.list_animals = list_animals.filter((animal) => {
              return animal.id_lote == lote.id;
            });
            return lote;
          });

          set({
            listAnimals: list_animals,
            listLotes: list_lotes,
          });

          const livestock_repos = setLivestockRepos(data._value);
          get().changeLivestockRepos(livestock_repos, true);

          return data;
        },
        changeLivestockRepos: (livestockRepos, init) => {
          let userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
          const stgs_lv = JSON.parse(JSON.stringify(userCurrent.settings.livestock));
          // {"first":0,"second":0,"third":0}
          if (userCurrent.settings.livestock.first != livestockRepos.id) {
            userCurrent.settings.livestock.third = stgs_lv.second;
            userCurrent.settings.livestock.second = stgs_lv.first;
            userCurrent.settings.livestock.first = livestockRepos.id;

            if (!init) {
              userCurrent.settings.has_update = true;
              userCurrent.settings.geral.creationHarvest = 'Criações';
            }

            localStorage.setItem('userCurrent', JSON.stringify(userCurrent));
          }

          set((state) => ({ livestockRepos }));
        },
        // pega a(s) livestock(s) da listLivestock de acordo com o [key e val] informados
        getLivestockStore: (key, val) => {
          const list = get().listLivestock.filter((livestock) => {
            return livestock[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // Adiciona uma nova livestock à listLivestock
        addLivestockStore: (livestock) => {
          set((state) => ({
            listLivestock: [...state.listLivestock, livestock],
          }));
        },
        // Gera uma nova listLivestock sem a livestock informada
        delLivestockStore: (id) => {
          set({
            listLivestock: get().listLivestock.filter((livestock) => {
              return livestock.id != id;
            }),
          });
        },
        // atualiza listLivestock de acordo com id, key e val informados
        updateLivestockStore: (id, key, val) => {
          set({
            listLivestock: get().listLivestock.map((livestock) => {
              if (livestock.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  livestock = val;
                } else {
                  livestock[key] = val;
                }
              }
              return livestock;
            }),
          });
          return JSON.parse(JSON.stringify(get().listLivestock));
        },
        getAnimalsStore: (key, val) => {
          const list =  get().listAnimals.filter((animal) => {
            return animal[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        getLotesStore: (key, val) => {
          const list = get().listLotes.filter((lote) => {
            return lote[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // Adiciona um novo lote à listLotes
        addLoteStore: (lote) => {
          set((state) => ({
            listLotes: [...state.listLotes, lote],
          }));
        },
        // Gera uma nova listLotes sem o lote informado
        delLoteStore: (key, val) => {
          set({
            listLotes: get().listLotes.filter((lote) => {
              return lote[key] != val;
            }),
          });
        },
      }),
      { name: 'reposLivestock' }
    )
  )
);

export default useLivestockReposStore;
