import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Container, Paper } from '@mui/material';
// import MultiStepFormEntry from '../financial/components/FormMain/MultiStepFormEntry'
import MultiStepFormBuySales from '../financial/components/FormMain/FormBuySales/MultiStepFormBuySales';
import GenericButton from '../../components/Button/GenericButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterBuyDialog({ variant, type, setButtonType, openDialog, handleCloseDialog, buy, color1 }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleCloseDialog(false);
    setOpen(false);
  };

  let text_title = type === 'sales' ? 'Pedido de Venda' : type === 'buy' && buy ? 'Compra' : 'Pedido de Compra';

  return (
    <div>
      {/* <Button
        sx={{ fontSize: '0.75rem' }}
        variant="outlined"
        color={type === 'income' ? 'success' : type === 'expense' ? 'error' : 'inherit'}
        style={{
          color: type === 'income' ? 'success' : type === 'expense' ? 'error' : '#fff',
          backgroundColor: type != 'income' && type != 'expense' && '#000',
        }}
        startIcon={<AddBoxIcon sx={{ color: '#fff' }} />}
        onClick={handleClickOpen}
      >
        {type === 'sales' && 'Pedido de Venda'}
        {type === 'buy' && 'Pedido de Compra'}
      </Button> */}
      <GenericButton variant={variant} type={type} onClick={handleClickOpen} startIcon={<AddBoxIcon />} text={text_title} />
      {/* <Button sx={{ fontSize: '0.75rem' }} color={color1 ? 'success' : 'inherit'} variant="text" startIcon={<AddBoxIcon sx={{ color: color1 ? color1 : '#000' }} />} onClick={handleClickOpen}>
        {type === 'sales' ? 'Pedido de Venda' : type === 'buy' && buy ? 'Compra' : 'Pedido de Compra'}
      </Button> */}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Box sx={{ width: '100%' }} id="focus_top_modal">
          <AppBar elevation={1} sx={{ position: 'relative' }} style={{ background: '#fff' }}>
            <Toolbar>
              <Typography sx={{ color: '#000', flexGrow: 1, textAlign: 'center' }} variant="h6" component="div">
                {type === 'sales' ? 'Pedido de Venda' : type === 'buy' && buy ? 'Compra' : 'Pedido de Compra'}
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: 'start' }} variant="h6" component="div"></Typography>
              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: '#000' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="lg" sx={{}}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              {/* <MultiStepFormEntry type={type} handleClose={handleClose} property={""} setProperty={""} formError={true} setFormError={true} /> */}
              <MultiStepFormBuySales type={type} handleClose={handleClose} property={''} setProperty={''} formError={true} setFormError={true} />
            </Paper>
          </Container>
          {/* <FormGoalTarget handleClose={handleClose} /> */}
        </Box>
      </Dialog>
    </div>
  );
}
