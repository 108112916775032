import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { Context } from '../../../../../components/Context/AuthContext';
import SuccessDialog from '../../../../../components/Dialog/SuccessDialog';
import useStore from '../../../../../store/useStore';
import api from '../../../../../services/api';
import FormEntryStep1 from './FormEntryStep1';
import FormEntryStep2 from './FormEntryStep2';
import FormEntryStep3 from './FormEntryStep3';
import FormMainConfirm from '../FormMainConfirm';
import MultiStepFormBuySales from '../FormBuySales/MultiStepFormBuySales';
import FormEntryStep2Account from './FormEntryStep2Account';

const operationType = {
  expense: 'Registrar Despesa',
  income: 'Registrar Receita',
  sales: 'Venda',
  buy: 'Compra',
  '': '',
};

const operationName = {
  expense: 'Despesa',
  income: 'Receita',
  sales: 'Venda',
  buy: 'Compra',
  '': '',
};

//https://ajuda.contaazul.com/hc/pt-br/articles/8932395646605
//https://ajuda.contaazul.com/hc/pt-br/sections/115002247788-Gest%C3%A3o-de-compras
//https://www.youtube.com/watch?v=W9Mv6KgBL5U&t=18s

export default function MultiStepFormEntry({ type, handleClose }) {
  // console.log("MultiStepFormEntry");
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const usestore = useStore();
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;
  const bankRepos = usestore.reposBank.bankRepos;

  var aux_category = '',
    step_type = 0;
  if (type) {
    if (type === 'expense') {
      aux_category = usestore.reposAccount.getAccountStore('name', 'Despesas Operacionais')[0];
    } else {
      // income
      aux_category = usestore.reposAccount.getAccountStore('name', 'Receitas Financeiras')[0];
    }
    step_type = 1;
  }

  //console.log(aux_category);

  const [activeStep, setActiveStep] = useState(step_type);

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,

    //step1
    operationType: operationType[type],
    operationName: operationName[type],

    //step2
    //  Informações do lançamento
    date_release: dayjs(),
    date_release_f: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_release_fc: dayjs().format('DD/MM/YYYY HH:mm'),
    supplier: null,
    client: null,
    person: null,
    value: 0,
    descriptionEntry: '',
    //  Categoria da conta
    category: aux_category?.id || '',
    subcategory: '',
    //  Vincular com e descrição
    projects: [],
    projects_apport: [],
    description: '',

    //step3
    //  Condições pagamento
    dueDate: dayjs(),
    dueDate_f: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    dueDate_fc: dayjs().format('DD/MM/YYYY HH:mm'),
    installment: 'a_vista',
    installmentInterval: 30,
    formPayment: 'dinheiro',
    bankAccount: bankRepos.id,
    payed: false,
    //  Previsão de pagamento
    fee: 0,
    penalty: 0,
    discount: 0,
    value_end: 0,
    //  Parcelas do pagamento
    arr_installment: [],

    error_date: false,
  });

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const [track, setTrack] = useState(new Date().getTime());
  const handleNext = (submit) => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      if (submit !== 'submit' && activeStep === steps.length - 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleScroll();
      }
    }
  };

  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormEntryStep1', 'FormEntryStep2', 'FormEntryStep3', 'FormEntryStep4', 'FormMainConfirm'];

  const validate = () => {
    let valid = true;
    if (!data.value || !data.category || !data.formPayment || !data.bankAccount || data.error_date) {
      valid = false;
    }
    return valid;
  };
  const onSubmit = async () => {
    let formOk = validate() || false;

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api
        .post('/finances/payment', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success(data.operationType + ' registrada com sucesso!');
            usestore.reposBill.fetch();
            usestore.reposFinances.fetchT();
            usestore.reposCashFlow.fetch();
            usestore.reposCostProduction.fetch();
            usestore.reposFinances.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar ' + data.operationType + ', tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      ismobile: isMobile.any(),
    };

    switch (step) {
      case 0:
        // Seleção tipo de lançamento: Conta a receber + Conta a pagar + Compra + Venda
        return <FormEntryStep1 data={data} obj_fn={obj_fn} />;
      case 1:
        // sai da seleção de despesa/receita e vai para compra/venda
        if (/.*Compra.*/.test(data.operationType) || /.*Venda.*/.test(data.operationType)) {
          let aux_type = /.*Compra.*/.test(data.operationType) ? 'buy' : 'sales';
          return <MultiStepFormBuySales type={aux_type} handleClose={handleClose} retorno={true} />;
        } else {
          // Informações do lançamento: data + valor + rateio + descricao
          return <FormEntryStep2 data={data} obj_fn={obj_fn} />;
        }
      case 2:
        //CONDIÇÕES PAGAMENTO + PREVISÃO DE PAGAMENTO + PARCELAS
        return <FormEntryStep2Account data={data} obj_fn={obj_fn} />;
      case 3:
        //CONDIÇÕES PAGAMENTO + PREVISÃO DE PAGAMENTO + PARCELAS
        return <FormEntryStep3 data={data} obj_fn={obj_fn} />;
      case 4:
        //Confirmação dos dados do lançamento
        return <FormMainConfirm data={data} obj_fn={obj_fn} />;
      case 5:
        const data_success = {
          distination: 'financial',
          name: data.operationType,
          text_success: 'Acesse o Controle Financeiro para verificar seus lançamentos financeiros!\nContas a pagar, a receber e o Fluxo de Caixa.',
          distination_title: 'Controle Financeiro',
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>{getStepContent(activeStep)}</Box>
    </>
  );
}
