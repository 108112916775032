import { useContext, useEffect, useState } from 'react';
import { Box, Container, Paper } from '@mui/material';
import SuccessInput from './SuccessInput';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import useStore from '../../../../../store/useStore';
import useActivityInputReposStore from '../../../../../store/Activities/useActivityInputReposStore';
import { Context } from '../../../../../components/Context/AuthContext';
import useAreasMapReposStore from '../../../../../store/useAreasMapReposStore';
import useLivestockReposStore from '../../../../../store/useLivestockReposStore';
import FormTransferStep1Select from './FormTransferStep1Select';
import FormTransferStep2MoveBatch from './FormTransferStep2MoveBatch';
import dayjs from 'dayjs';
import useActivityPastureManagementReposStore from '../../../../../store/Activities/useActivityPastureManagementReposStore';
import ConfirmStepTransfer from './ConfirmStepTransfer';
import { useParams } from 'react-router-dom';
import FormStepPeriod from '../components/PeriodProgress/FormStepPeriod';
import FormTransferStep3Period from './FormTransferStep3Period';

export default function MultiStepFormTransferBatch({ handleClose }) {
  console.log('MultiStepFormTransfer');
  const usestore = useStore();
  const reposActivityTransferBatch = usestore.reposActivityTransferBatch;
  const { idFarm } = useParams();
  // pegar o livestock current
  const livestockRepos = usestore.reposLivestock.getLivestockStore('id', usestore.reposLivestock.livestockRepos.id)[0];
  //console.log("livestockRepos");
  //console.log(livestockRepos);
  const reposActivitiesInputs = usestore.reposActivityInput;
  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const list_areas = [];
  // selecionar a lista de áreas ativas do lote
  for (const area of livestockRepos.areas) {
    // pego o id da area
    //console.log(area.id)
    const areaActive = usestore.reposAreas.getAreaMapStore('id', area.id);
    list_areas.push(areaActive[0]);
  }
  // lista de áreas
  //console.log(list_areas);

  /// lista o manejo de pastagem para identificar id_areamap (onde está a criação)
  const reposPastureManagement = useActivityPastureManagementReposStore((state) => state);
  //console.log(reposPastureManagement)
  const reposlistPastureManagement = reposPastureManagement.listPastureManagement;
  // console.log(reposlistPastureManagement)

  // tipo de livestock para recuperar o icon do marker
  //console.log(livestockRepos.tipo)

  const getIcon = () => {
    // tipo do icone
    let iconUrl = '/dist/image';
    // console.log(livestockRepos.tipo)
    if (livestockRepos.tipo === 'Bovinocultura de Corte') {
      iconUrl += '/entrada_boi';
    } else if (livestockRepos.tipo === 'Bovinocultura de Leite') {
      iconUrl += '/entrada_vaca';
    } else if (livestockRepos.tipo === 'Avicultura de Corte') {
      iconUrl += '/entrada_frango';
    } else if (livestockRepos.tipo === 'Suinocultura') {
      iconUrl += '/entrada_suino';
    } else if (livestockRepos.tipo === 'Piscicultura') {
      iconUrl += '/entrada_peixe';
    } else {
      iconUrl += '/entrada_outros';
    }
    let iconBatch = iconUrl + '_afazer.png';
    //console.log(iconBatch)
    //setIconMarkerBatch(iconBatch)
    return iconBatch;
  };

  // atualizar o localstorage
  const fetchTodos = useActivityInputReposStore((state) => state.fetch);
  const fetchAreas = useAreasMapReposStore((state) => state);
  const fetchLivestock = useLivestockReposStore((state) => state);

  const getDateBirth = (lote) => {
    var date_birth = lote?.date_entry;
    var aux_fase = lote.fase_name.toLowerCase();
    // BOVINOS
    if (/cria/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(120, 'day').format('YYYY-MM-DD HH:mm:ss');
    } else if (/recria/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(450, 'day').format('YYYY-MM-DD HH:mm:ss');
    } else if (/engorda/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(600, 'day').format('YYYY-MM-DD HH:mm:ss');
    } else if (/producao/.test(aux_fase)) {
      date_birth = dayjs(date_birth).subtract(720, 'day').format('YYYY-MM-DD HH:mm:ss');
    }

    return date_birth;
  };
  const getWeight = (lote) => {
    var weight = 0;
    var aux_fase = lote.fase_name.toLowerCase();
    // BOVINOS
    if (/cria/.test(aux_fase)) {
      weight = 105;
    } else if (/recria/.test(aux_fase)) {
      weight = 260;
    } else if (/engorda/.test(aux_fase)) {
      weight = 400;
    } else if (/producao/.test(aux_fase)) {
      weight = 450;
    }
    return weight;
  };

  //console.log(livestockRepos.lotes)

  // const filteredData = reposlistPastureManagement.filter((item) => item.id_lote === data.lote.id);
  // console.log(filteredData);
  // let id_areamap = filteredData[0].id_areamap
  // console.log(id_areamap)
  //console.log('livestockRepos.lotes');
  //console.log(livestockRepos.lotes);
  //console.log(reposlistPastureManagement);

  // vincular a lista de lotes com as informações das áreas
  const list_lotes = livestockRepos.lotes.map((lote) => ({
    id: lote.id,
    label: lote.name,
    //icon: <AutoAwesomeMotionIcon />,
    num_animals: lote.num_animals,
    total_animals: lote.num_animals + lote.num_femeas + lote.num_machos,
    fase_id: lote.fase_id,
    fase_name: lote.fase_name,
    date_entry: dayjs(lote.date_entry).format('YYYY-MM-DD HH:mm:ss'),
    icon: getIcon(livestockRepos.tipo),
    // erro Cannot read properties of undefined (reading 'id_areamap')
    id_areamap: reposlistPastureManagement.length > 0 ? reposlistPastureManagement.filter((item) => item.id_lote === lote.id)[0].id_areamap : [],
    activity: reposlistPastureManagement.length > 0 ? reposlistPastureManagement.filter((item) => item.id_lote === lote.id)[0] : [],
  }));

  //console.log(list_lotes);

  // tenho todos os lostes com as informações das áreas
  //console.log(list_lotes)

  // adicionar as áreas no lote
  for (const lote of list_lotes) {
    //console.log(lote);
    lote.area = list_areas.filter((item) => item.id === lote.id_areamap)[0];
  }

  // // adicionar as atividades no lote
  // for (const lote of list_lotes) {
  //   //console.log(lote)
  //   lote.area = list_areas.filter((item) => item.id === lote.id_areamap)[0];
  // }

  //console.log(list_lotes)

  const [listBatch, setListBatch] = useState(list_lotes);

  // const opt_lotes = list_lotes.filter((lote) => {
  //   if (lote.num_animals > 0) {
  //     return lote;
  //   }
  // })
  console.log(list_lotes);
  const opt_lotes = list_lotes.filter((lote) => lote.area !== undefined);
  // const opt_lotes = list_lotes.filter((lote) => {
  //   console.log(lote);
  //   return lote;
  // });

  console.log(opt_lotes);

  var date_birth_aux = opt_lotes.length > 0 ? getDateBirth(opt_lotes[0]) : dayjs().format('YYYY-MM-DD HH:mm:ss');
  var weight_aux = opt_lotes.length > 0 ? getWeight(opt_lotes[0]) : 0;

  //console.log(list_lotes)

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_livestock: livestockRepos.id,
    id_group: livestockRepos.id_group,
    type_activity: livestockRepos.activity,
    id_tipe: livestockRepos.tipe,
    id_areamap: '',
    nameArea: '',
    nameBatch: '',
    //id_lote: "",
    //number_animals: "",
    name_activity: 'TRANSFERÊNCIA DE LOTES',
    //areamap_use: "",
    //date_exit: "",
    opt_lotes: opt_lotes,
    lote: opt_lotes[0],
    //stage: "",
    areaTranferId: { id_area: '', name: '', image: '' },
    stage: '',
    stageData: '',
    areas: list_areas,
    isDeleted: false,
    planning: 'realized',
    progress: 'CONCLUÍDA',
    progress_radio: 1,
    date_start: '',
    data_prev_end: '',
    date_end: '',
    data_prev_exit_mp: '',
  });

  //console.log("1-init-MultiStepForm");

  const [activeStep, setActiveStep] = useState(0);
  const [date, setDate] = useState('');
  const [text, setText] = useState('');
  const [selection, setSelection] = useState('');

  const [track, setTrack] = useState(new Date().getTime());
  const handleNext = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
  };

  const { aux_add, setAuxAdd } = useContext(Context);

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6'];

  const handleReposUpdate = (event) => {
    // atualizar property
    // usestore.reposProperty.fetch();
    // atualizar areaMap para atualizar total de animais
    var aused = fetchAreas.fetch({ id_farm: property_id });
    // atualizar o livestock para atualizar o lote
    usestore.reposLivestock.fetch({ id_farm: idFarm });
    //atualizar o manejo d pastagem
    usestore.reposActivityPastureManagement.fetch({ id_farm: idFarm });
    // atualizar a atividade
    // reposActivitiesInputs.fetch({ id_farm: property_id }).then((res) => {
    //   console.log("reposActivitiesInputs - fetch - res");
    //   //console.log(res);
    //   //console.log("reposActivitiesInputs.listActivityInput");
    //   //console.log(reposActivitiesInputs.listActivityInput);
    //   setAuxAdd(aux_add + 1);
    //   setTimeout(() => {
    //     handleNext();
    //   }, 200)
    // });
    reposActivityTransferBatch.fetch({ id_farm: property_id }).then((res) => {
      console.log('reposActivityTransferBatch - fetch - res');
      //console.log(res);
      //console.log("reposActivitiesInputs.listActivityInput");
      //console.log(reposActivitiesInputs.listActivityInput);
      setAuxAdd(aux_add + 1);
      // setTimeout(() => {
      setTimeout(() => {
        handleClose();
      }, 500);
    });
  };

  const handleUpdateProgress = async (id, item) => {
    console.log('handleUpdateProgress');
    // Aqui você pode adicionar a lógica para atualizar o progresso da tarefa
    const header = { header: { 'Content-Type': 'application/json' } };
    let url = '';
    const dataAtual = new Date();
    const dataProgress = {
      id_user: item.id_user,
      id_activity: id,
      name_activity: item.name_activity,
      progress: item.progress,
      oldProgress: item.progress,
      nameBatch: item.nameBatch,
      // planning: "realized",
      updated_at: dataAtual.toISOString().slice(0, 19).replace('T', ' '),
    };
    url = `activityTransfer/updateprogress`;
    //reposActivity = usestore.reposActivityInput;
    console.log(dataProgress);
    if (true) {
      console.log('dataProgress');
      console.log(dataProgress);
      const res = await api
        .put(url, dataProgress, header)
        .then((response) => {
          console.log('handleUpdateProgress atualizado');
          console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Progresso da atividade atualizado com sucesso!');
            handleReposUpdate();
            setTimeout(() => {
              handleClose();
            }, 500);
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro em atualizar o progresso da atividade. Tente novamente mais tarde.');
          setTimeout(() => {
            handleClose();
          }, 200);
        });
    } else {
      //return false;
    }
    //handleClose();
  };

  const onSubmit = async () => {
    console.log('entrou no submit - MultiStepFormTransferBatch');

    if (true) {
      const header = {
        header: {
          'Content-Type': 'application/json',
        },
      };

      //const propertyId = JSON.parse(localStorage.getItem("FarmIdcurrent"));
      // message.id_property = propertyId;
      console.log(data.dto);
      //data.areas[0].coordinates = "";
      //data.areas[0].image = "";
      const response = await api
        .post('/activityTransfer', data.dto, header)
        .then((res) => {
          //const res = true
          console.log('res');
          console.log(res.data);
          console.log(res.data.isSuccess);
          if (res.data.isSuccess) {
            //console.log("true");
            toast.success('Manejo salvo com sucesso!');
            //fetchTodos();
            // preciso atualizar o progresso da atividade
            if (data.progress == 'CONCLUÍDA') {
              console.log('CONCLUÍDA');
              console.log(res.data._value.insertId);
              handleUpdateProgress(res.data._value.insertId, data.dto);
            } else {
              handleReposUpdate();
            }
            // atualizar aremap
            //var aused = fetchAreas.fetch({ 'id_farm': property_id });
            // atualizar o livestock pelo fetch
            //var liv = fetchLivestock.fetch({ 'id_farm': property_id });

            // reposActivityTransferBatch.fetch({ id_farm: property_id }).then((res) => {
            //   console.log("reposActivityTransferBatch - fetch - res");
            //   //console.log(res);
            //   //console.log("reposActivitiesInputs.listActivityInput");
            //   //console.log(reposActivitiesInputs.listActivityInput);
            //   setAuxAdd(aux_add + 1);
            //   // setTimeout(() => {
            //   //   handleClose();
            //   //   //handleNext();
            //   // }, 4000)
            // });
          } else {
            toast.error(`Opss.. Ocorreu algum erro ao registrar a entrada de animais,por favor, tente novamente.`);
            console.log('erro');
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar a entrada de animais, tente novamente mais tarde!');
        });
    }
  };

  const getStepContent = (step) => {
    // console.log(step);
    switch (step) {
      case 0:
        // return <FourthStepFormInputOthers data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
        return (
          <FormTransferStep1Select
            listBatch={listBatch}
            data={data}
            setData={setData}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            handleClose={handleClose}
          />
        );
      case 1:
        return <FormTransferStep2MoveBatch data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 2:
        return <FormTransferStep3Period data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      case 3:
        return (
          <ConfirmStepTransfer data={data} onSubmit={onSubmit} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />
        );
      //   return <ThirdStepFormInputPeriod data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      // case 4:
      //   return <FourthStepFormInputOthers data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      // case 5:
      //   return <ConfirmStepInput data={data} onSubmit={onSubmit} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} handleClose={handleClose} />;
      default:
        return 'Não existente';
    }
  };

  //console.log("2-RENDER");
  //console.log("xxxDATA");
  //console.log(data);
  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* // se o activeStep que inci-ase com zero === [0,1,2,3] array de step*/}
        {activeStep === steps.length ? (
          // Chamada do componente final do step (activeStep) para animação
          <Container component="main" maxWidth="md" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessInput handleClose={handleClose} data={data} />
            </Paper>
          </Container>
        ) : (
          <>
            {/* // Chamada dos componentes setp 1, setp 2....  */}
            {getStepContent(activeStep)}
          </>
        )}
      </Box>
    </>
  );
}
