export default function messengerProgress(messenger) {
  if (!messenger || messenger.length === 0) {
    return [];
  }

  return messenger.map((message) => {
    // Ajustando o status com base no valor de message.status
    let statusText = '';
    switch (message.status) {
      case '2':
        statusText = 'Parado';
        break;
      case '1':
        statusText = 'Resolvido';
        break;
      case '0':
        statusText = 'Resolver';
        break;
      default:
        statusText = 'Incompleto'; // Caso o status seja diferente de 0, 1 ou 2
        break;
    }

    // Definindo o status completo ou incompleto
    const status = {
      title: message.title || '?',
      name: message.name || '?',
      status: statusText,
    };

    // Calculando o progresso baseado nos campos de status
    const progress = Object.values(status).filter((value) => value === 'Completo' || value === 'Ok').length * 20; // Progresso baseado no número de campos completos

    return { progress, status, id: message.id };
  });
}
