// Função para formatar de números para valor monetario
export const formatValue = (value, str, format) => {
  var aux_value = value;
  var text;

  if (typeof value == 'string') {
    aux_value = parseFloat(value);
  } else if (!value) {
    aux_value = 0;
  }
  // var aux_val = aux_value.toFixed(2);
  // var aux_val_f1 = aux_val.replace('.', ',');

  // Formatar para o padrão monetário
  var aux_val_f1 = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(aux_value).replace('R$', '').trim();

  if (!format) {
    text = (str ? 'R$ ' : '') + aux_val_f1;
  } else {
    text = format.prefix;

    if (format.start) {
      text += ' ' + aux_val_f1;
    } else if (format.end) {
      text = aux_val_f1 + ' ' + text;
    }
  }

  return text;
}

// Função para rolar gradativamente até o fim do conteúdo
export const smoothScrollToBottom = (modalContentRef, time) => {
  if (!modalContentRef?.current) {
    return false;
  }
  const element = modalContentRef.current;
  const startPosition = element.scrollTop;
  const targetPosition = element.scrollHeight - element.clientHeight;
  const distance = targetPosition - startPosition;
  const duration = (time || 1000); // Duração da animação em ms
  let startTime = null;

  const step = (currentTime) => {
    if (!startTime) startTime = currentTime;
    const progress = currentTime - startTime;
    const percentProgress = Math.min(progress / duration, 1);
    element.scrollTop = startPosition + distance * percentProgress;

    if (progress < duration) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
};

/*
  Remove elementos duplicados de um array de objetos com base em uma propriedade específica
  exemplo array = [{ id: 1 }, { id: 2 }, { id: 1 }, { id: 3 }, { id: 3 }]
  key = id
  resultado = [{ id: 1 }, { id: 2 }, { id: 3 }]
*/
export const removeDuplicatesFromArray = (array, key) => {
  const newArray = new Set();

  return array.filter((item) => {
    const value = item[key];
    if (newArray.has(value)) {
      return false;
    }
    newArray.add(value);

    return true;
  });
}