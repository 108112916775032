import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { green } from '@mui/material/colors';
import dayjs from 'dayjs';
import FormMainConfirm from '../FormMain/FormMainConfirm';

const operationType = {
  "Compra": "Compra",
  "Despesa": "Conta a pagar",
  "Receita": "Conta a receber",
  "Venda": "Venda",
  "": ""
}

function getAport(array) {
  let apport_id = [];

  array.map((project) => {
    apport_id.push(project.id);
    return project;
  });
  return apport_id;
}

export default function ModalVisualizePayment({ transaction, obj_fn, open, handleClose }) {
  const [data, setData] = useState({
    id_user: '',
    id_property: '',

    //step1
    operationType: '',
    operationName: '',

    //step2
    //  Informações do lançamento
    date_release: '',
    date_release_f: '',
    date_release_fc: '',
    supplier: '',
    client: '',
    person: '',
    value: '',
    descriptionEntry: '',
    //  Categoria da conta
    category: '',
    subcategory: '',
    //  Vincular com e descrição
    projects: '',
    projects_apport: '',
    description: '',

    //step3
    //  Condições pagamento
    dueDate: '',
    dueDate_f: '',
    dueDate_fc: '',
    installment: '',
    installmentInterval: '',
    formPayment: '',
    bankAccount: '',
    payed: '',
    //  Previsão de pagamento
    fee: '',
    penalty: '',
    discount: '',

    value_end: '',
    //  Parcelas do pagamento
    arr_installment: '',

    error_date: false,
    loaded: false,
  });
  
  useEffect(() => {
    if (open && transaction.id === obj_fn.isOpen) {
    // if (open) {
      const bankaccount = obj_fn.usestore.reposBank.getBankStore('id', transaction.info.id_bank)[0];
      const person = obj_fn.usestore.reposPerson.getPersonStore('id', transaction.info.id_person)[0];

      const account = obj_fn.usestore.reposAccount.getAccountStore('id', transaction.info.id_account)[0];

      setData({
        id_user: transaction.id_user,
        id_property: transaction.id_property,

        //step1
        operationType: operationType[transaction.type],
        operationName: transaction.type,

        //step2
        //  Informações do lançamento
        date_release: dayjs(transaction.date_release),
        date_release_f: dayjs(transaction.date_release).format("YYYY-MM-DD HH:mm:ss"),
        date_release_fc: dayjs(transaction.date_release).format("DD/MM/YYYY HH:mm"),
        supplier: transaction.type === 'Despesa' ? transaction.info.id_person : null,
        client: transaction.type === 'Receita' ? transaction.info.id_person : null,
        person: person,
        value: (parseFloat(transaction.value) + (parseFloat(transaction.info.fee || '0')) + (parseFloat(transaction.info.penalty || '0')) - (parseFloat(transaction.info.discount || '0'))),
        descriptionEntry: transaction.info.descriptionEntry || "",
        //  Categoria da conta
        category: account ? (/subcategory/.test(account.info) ? account.mother : account.id) : '',
        subcategory: account ? (/subcategory/.test(account.info) ? account.id : null) : '',
        //  Vincular com e descrição
        projects: transaction.record.aport ? getAport(transaction.info.aport) : [],
        projects_apport: transaction.record.aport ? transaction.info.aport : [],
        description: transaction.info.description || '',

        //step3
        //  Condições pagamento
        dueDate: dayjs(transaction.date_release),
        dueDate_f: dayjs(transaction.date_release).format("YYYY-MM-DD HH:mm:ss"),
        dueDate_fc: dayjs(transaction.date_release).format("DD/MM/YYYY HH:mm"),
        installment: 'a_vista',
        installmentInterval: 30,
        formPayment: transaction.info.formPayment,
        bankAccount: bankaccount.id,
        payed: transaction.record.payed,
        //  Previsão de pagamento
        fee: parseFloat(transaction.info.fee || '0'),
        penalty: parseFloat(transaction.info.penalty || '0'),
        discount: parseFloat(transaction.info.discount || '0'),

        value_end: transaction.value,
        //  Parcelas do pagamento
        arr_installment: [],

        error_date: false,
        loaded: true,
      });
    }
  }, [open]);

  return (<>
    {!data.loaded ? <></> :
      <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={handleClose} >
        <DialogTitle sx={{ color: green[700] }}>
          Visualização do Lançamento
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: '100%',
            }}
          > 
            <FormMainConfirm data={data} obj_fn={obj_fn} visualize={true} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mt: '-25px', mr: '10px' }}>
          <Button onClick={handleClose} color="success" variant="outlined" sx={{mr: '35px'}}>Fechar</Button>
        </DialogActions>
      </Dialog>
    }
  </>);
}