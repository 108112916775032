import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Dialog, Container, DialogContent, IconButton, Slide, Card, CardMedia } from '@mui/material';
import { AppBar, Box, Button, Divider, Grid, Paper, Toolbar, Typography } from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';
import { IconHarvesting } from '../../../../components/Icons/harvest/unit/IconHarvesting';
import HeaderModal from '../../../../components/Header/HeaderModal';
import CardProduct from '../FormActivities/Harvesting/CardProducts';
import DateFieldEditActivities from '../../../../components/Fields/DateFieldEditActivities';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const theme = createTheme(
//   { palette: { primary: { main: "#1976d2" }, }, },
//   deDE // use 'de' locale for UI texts (start, next month, ...)
// );
// const weekdays = { "2ª": "seg", "3ª": "ter", "4ª": "qua", "5ª": "qui", "6ª": "sex", Sa: "sáb", Do: "dom" };

// fillDialog normal que chama uma lista de botões de atividade
export default function EditActivitiesHarvesting({ row, usestore }) {
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);

  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState(0);

  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;

  const handleActivity = () => {
    //seta infos para data
    let this_activity = usestore.reposActivityHarvesting.getActivityHarvestingStore('id', row.id)[0];
    this_activity.areas.map((area) => {
      const optionsAreas = usestore.reposAreas.getAreaMapStore('id', area.id_area)[0];
      area.id = optionsAreas.id;
      area.imageDecode = optionsAreas.imageDecode;
      area.url = optionsAreas.url;
      area.area = optionsAreas.area;
      area.measure = optionsAreas.measure;

      let aux_prod;
      area.products = area.products.map((product, index) => {
        aux_prod = JSON.parse(JSON.stringify(product));
        product = usestore.reposProducts.getProductsStore('id', aux_prod.id_product)[0];
        product.location_selected = product.location.filter((local) => {
          return local.id_patrimony === aux_prod.id_patrimony;
        })[0];
        product.quant_colhida = aux_prod.quant * (aux_prod.packaging_unit === 'medida' ? aux_prod.volume_pkg : 1);
        product.quanty_edit = product.quant_colhida;
        product.packaging_unit = aux_prod.packaging_unit;
        product.volume_pkg = aux_prod.volume_pkg;
        return product;
      });

      return area;
    });
    this_activity.edit_prod = false;
    this_activity.error_date = false;

    return this_activity;
  };

  let this_activity = handleActivity();
  const [data, setData] = useState(this_activity);

  const handleClickOpen = () => {
    setUpdated(0);
    setOpen(true);
  };

  const handleClose = () => {
    //reseta informações
    let this_activity = handleActivity();
    setData(this_activity);
    setUpdated(0);
    setOpen(false);
  };

  // const para validar o button continuar
  const isError = () => {
    return !updated || data.error_date;
    // return !updated || !value.$D || !valueEnd.$D;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let formOk = !isError();

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      data.id_user_current = userCurrent.id;
      data.change_data = true;

      await api
        .put('/activityHarvesting/update', data, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success('Atividade editada com sucesso!');
            // atualiza o repos
            usestore.reposHarvest.fetch({ id_farm: property_id });
            usestore.reposProducts.fetch({ id_farm: property_id });
            usestore.reposPatrimony.fetch({ id_farm: property_id });
            usestore.reposFinances.fetch();
            usestore.reposFinances.fetchT();
            usestore.reposCashFlow.fetch();
            usestore.reposCostProduction.fetch();

            usestore.reposActivityHarvesting.fetch().then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleClose();
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao editar atividade. Tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios');
    }
  };

  const options_header = {
    title: 'Editando o plantio da Colheita',
    txt_msn: 'Altere os dados desejados, confira e clique em Salvar',
    icon: <IconHarvesting widthStyles={'55'} />,
  };

  const obj_fn = {
    usestore: usestore,
    setData: setData,
    setUpdated: setUpdated,
    ismobile: isMobile.any(),
  };

  // validations first step start -----
  // const [value, setValue] = useState(dayjs(data.date_start));
  // const [valueEnd, setValueEnd] = useState(dayjs(data?.date_end || data.date_prev_end));
  let obj_date = {
    setData: setData,
    setUpdated: setUpdated,
    // min: null,
    // max: null,
  };

  return (
    <>
      <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative' }} color="inherit">
            <Toolbar>
              <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    {' '}
                    Edição da atividade
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Fechar">
                {' '}
                <Close />{' '}
              </IconButton>
            </Toolbar>
          </AppBar>
          <Divider />
          <DialogContent sx={{ mb: 8, mt: -4 }}>
            <Container component="main" maxWidth="md" sx={{ mt: 0 }}>
              <Paper variant="" sx={{ my: { xs: 1, md: 4 }, p: { xs: 2, md: 1 } }}>
                <Box>
                  <HeaderModal options={options_header} />

                  <DateFieldEditActivities data={data} obj_date={obj_date} />
                  {/* <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sx={{ mt: 2, height: 110 }}>
                    <LocalizationProvider theme={theme} dateAdapter={AdapterDayjs} adapterLocale="pt">
                      <div className="d-lg-none">
                        <Stack spacing={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <MobileDatePicker
                                label="Data de Início*"
                                value={dayjs(data.date_start)}
                                onChange={(newValue) => {
                                  setValue(newValue);
                                  const newValueEnd = dayjs(newValue).add(3, "day");
                                  setValueEnd(newValueEnd);

                                  setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                renderInput={(params) => <TextField {...params} color="success" />}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <MobileDatePicker
                                label="Data de Término*"
                                value={dayjs(data.date_prev_end)}
                                onChange={(newValue) => {
                                  setValueEnd(newValue);
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                minDate={value}
                                renderInput={(params) => <TextField {...params} color="success" />}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                      <div className="d-none d-lg-block">
                        <Stack spacing={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <DesktopDatePicker
                                label="Data de Início*"
                                value={dayjs(data.date_start)}
                                onChange={(newValue) => {
                                  setValue(newValue);
                                  const newValueEnd = dayjs(newValue).add(3, "day");
                                  setValueEnd(newValueEnd);

                                  setData((prevState) => ({ ...prevState, date_start: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValueEnd).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!value.$D}
                                    helperText={!value.$D && (<p id="date_start_helper" className="form-text text-danger is-invalid"> Por favor, insira uma data válida. </p>)}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DesktopDatePicker
                                label="Data de Término*"
                                value={dayjs(data.date_prev_end)}
                                onChange={(newValue) => {
                                  setValueEnd(newValue);
                                  setData((prevState) => ({ ...prevState, date_prev_end: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") }));
                                  setUpdated(updated+1);
                                }}
                                color="success"
                                adapterLocale="pt"
                                inputFormat="DD/MM/YYYY"
                                dayOfWeekFormatter={(day) => `${weekdays[day]}`}
                                minDate={value}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="success"
                                    error={!valueEnd.$D}
                                    helperText={!valueEnd.$D && (<p id="date_prev_end_helper" className="form-text text-danger is-invalid"> Por favor, insira uma data válida. </p>)}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                      <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, ml: 1, color: "#808080" }}>
                        Data prevista para início da atividade.
                      </Typography>
                    </LocalizationProvider>
                  </Grid>
                </Grid> */}
                  <Divider />

                  <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
                    {data.areas?.map((area, index) => (
                      <>
                        <Grid item key={'G1_' + area.id + '_' + index} xs={12} sm={12} sx={{ mt: -2 }}>
                          <Card key={'C1_' + area.id + '_' + index} sx={{ display: 'flex', padding: 1 }}>
                            <Grid item xs={4} sm={4} sx={{ ml: 1.5, alignItems: 'center' }}>
                              <CardMedia
                                key={'CM_' + area.id + '_' + index}
                                component="img"
                                image={area.imageDecode}
                                alt={area.name}
                                title={area.name}
                                sx={{ ml: '20%', width: 150, height: 50, transition: 'transform 0.15s ease-in-out', '&:hover': { transform: 'scale3d(1.5, 1.5, 1)' } }}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} sx={{ ml: 1.5 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                Área: {area.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} sx={{ ml: 1.5 }}>
                              <Typography variant="subtitle2" gutterBottom>
                                <i className="fa fa-map text-success">
                                  {' '}
                                  {area.area} ({area.measure})
                                </i>
                              </Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} sx={{ ml: 1.5 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                Área plantada: {area.usedArea} ha
                              </Typography>
                            </Grid>
                          </Card>
                          <Card key={'C2_' + area.id + '_' + index} sx={{ display: 'flex', padding: 1 }}>
                            <Grid item xs={12} sm={12}>
                              <CardProduct data={data} obj_fn={obj_fn} area={area} />
                            </Grid>
                          </Card>
                        </Grid>
                      </>
                    ))}
                  </Grid>

                  <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
                    <Toolbar>
                      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                          Cancelar
                        </Button>
                      </Box>
                      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <Button color="success" variant="contained" type="submit" onClick={!updated ? () => null : onSubmit} disabled={isError()}>
                          Salvar
                        </Button>
                      </Box>
                    </Toolbar>
                  </AppBar>
                </Box>
              </Paper>
            </Container>
          </DialogContent>
        </Dialog>
        <IconButton aria-label="Editar" onClick={() => handleClickOpen()} title="Editar">
          <Edit sx={{ color: green[500] }} />
        </IconButton>
      </div>
    </>
  );
}
