import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, TextField, ButtonBase, IconButton, Grid, Typography } from "@mui/material";
import { AppBar, Container, Dialog, Paper, Slide, Stack, Toolbar } from "@mui/material";
import MultiStepForm from "./form/MultiStepForm";
import useStore from "../../../../store/useStore";
import { makeStyles } from "@mui/styles";
import GrassIcon from '@mui/icons-material/Grass';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    height: '100%',
  }
}));

export default function ModalSelectCultivation({ handleCloseTools }) {
  //console.log("ModalSelectCultivation")
  const classes = useStyles();
  const usestore = useStore();

  const [open, setOpen] = useState(false);
  const [data_culture, setData] = useState({ 'cultivation_name': '', 'id_cultivation': '', 'cultivation_icon': '', 'cultivation_name': '' });
  const [filter, setValue] = useState('');

  const option_cultures = usestore.reposHarvest.listcultivations;
  const [list_cultures, setListCultures] = useState({
    'Flores': usestore.reposHarvest.getCultivationStore('group_name_nf', 'flores'),
    'Florestas': usestore.reposHarvest.getCultivationStore('group_name_nf', 'florestas'),
    'Frutas': usestore.reposHarvest.getCultivationStore('group_name_nf', 'frutas'),
    'Grãos, Fibrosas e Oleaginosas': usestore.reposHarvest.getCultivationStore('group_name_nf', 'graos_Fibrosas_oleaginosas'),
    'Hortaliças': usestore.reposHarvest.getCultivationStore('group_name_nf', 'hortalicas'),
    'Pastagens e Forrageiras': usestore.reposHarvest.getCultivationStore('group_name_nf', 'pastagens_forrageiras'),
    'Outras': usestore.reposHarvest.getCultivationStore('group_name_nf', 'outras')
  });

  const handleClickOpen = (culture) => {
    setData(culture);
    setOpen(true);
  };

  const handleClose = () => {
    handleCloseTools();
    setOpen(false);
  };

  const getFiltered = (culture) => {
    var regex = new RegExp(".*" + filter + ".*");
    if (!filter || regex.test(culture) || /outra/.test(culture)) {
      return 'flex';
    }
    return 'none';
  };

  const handleRows = () => {
    setListCultures(list_cultures);
  };
  const getImage = (icon, width, height) => {
    return (/outra/.test(icon) ? <GrassIcon /> : <i> <img src={icon} width={width ? width : "50"} height={height ? height : "35"}></img> </i>);
  };

  useEffect(() => {
    handleRows();
  }, [filter]);

  //console.log(filter)

  return (
    <Box style={{ height: '600px' }}>
      <div className="d-flex align-items-center flex-column text-light" style={{ width: '265px' }}>
        <div className="input-group ">
          <div style={{ width: '265px' }}>
            <Autocomplete
              disableClearable
              freeSolo
              size="small"
              filterSelectedOptions
              value={filter}
              isOptionEqualToValue={(option, value) => {
                var regex = new RegExp(".*" + (value || filter) + ".*");
                (regex.test(option.cultivation_name_nf) || /outra/.test(option.cultivation_name_nf))
              }}
              options={option_cultures}
              onInputChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChange={(event, newValue) => {
                setValue(newValue.cultivation_name_nf);
                handleClickOpen(newValue);
              }}
              sx={{ maxWidth: 265 }}
              id="selectCulturas"
              name="selectCulturas"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Culturas"
                  color="success"
                  placeholder="Culturas"
                  focused
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <strong> </strong>,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box component="span" >
                    {getImage(option.cultivation_icon, 30, 30)}
                  </Box>
                  <Box sx={{ ml: 1.5, flexGrow: 1, '& span': { color: '#aed3aa' }, }} >
                    {option.cultivation_name}
                  </Box>
                </li>
              )}
              noOptionsText="Sem resultados"
            />
          </div>
        </div>
      </div>
      <div>
        <Box sx={{ marginBottom: 3 }}>
        {!filter && Object.keys(list_cultures).map((category) => (
            <div key={category}>
              <Typography variant="subtitle2" sx={{ mb: 1, mt: 2 }} color="success">{category}</Typography>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }} align="center">
                {list_cultures[category].map((button) => (
                  <Grid item xs={6} sm={3} md={2} key={button.cultivation_name_nf} style={{ display: getFiltered(button.cultivation_name_nf) }}>
                    <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} className={classes.button} onClick={() => handleClickOpen(button)}>
                      <Stack direction="column" alignItems="center">
                        {getImage(button.cultivation_icon)}
                        <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                          {button.cultivation_name}
                        </Typography>
                      </Stack>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))
        }
        {filter && Object.keys(list_cultures).map((category) => {
          //const filteredGroup = list_cultures[category].filter(button => button.cultivation_name_nf === filter || button.cultivation_name_nf === "outras_anual" || button.cultivation_name_nf === "outras_perene");
          const filteredGroup = list_cultures[category].filter(button => getFiltered(button.cultivation_name_nf) != 'none');
          if (filteredGroup.length > 0) {
            return (
              <div key={category}>
                <Typography variant="subtitle2" sx={{ mb: 1, mt: 2 }} color="success">{category}</Typography>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }} align="center">
                  {filteredGroup.map((button) => (
                    <Grid item xs={6} sm={3} md={2} key={button.cultivation_name_nf} style={{ display: getFiltered(button.cultivation_name_nf) }}>
                      <ButtonBase component={Paper} elevation={2} sx={{ p: 2, borderRadius: 1 }} className={classes.button} onClick={() => handleClickOpen(button)}>
                        <Stack direction="column" alignItems="center">
                          {getImage(button.cultivation_icon)}
                          <Typography align="center" sx={{ mt: 1 }} fontWeight={400} variant="body1">
                            {button.cultivation_name}
                          </Typography>
                        </Stack>
                      </ButtonBase>
                    </Grid>
                  ))}
                </Grid>
              </div>
            );
          }
          return null;
        })}
        </Box>

        <Grid container spacing={2} sx={{ mt: 3, mb: 3 }}>
          <Grid item xs={12} md={12}>
            <Typography gutterBottom style={{ color: '#2e7d32' }}>
              Caso não encontre a cultura desejada, selecione "Outras" para cadastrar uma específica.
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Grid item container direction="row" alignItems="center" justifyContent="center" style={{ maxWidth: 900 }}>
              <Grid item>
                <Typography variant="h6" component="div">
                  Projeto de {data_culture.cultivation_name}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="sm" sx={{ mt: -5 }}>
          <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <MultiStepForm data_culture={data_culture} handleClose={handleClose} getImage={getImage} />
          </Paper>
        </Container>
      </Dialog>
    </Box>
  );
}
