import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, Marker, StandaloneSearchBox, InfoWindow, Polygon, OverlayView } from '@react-google-maps/api';
import PropertyAreaButton from './SearchBox/PropertyAreaButton';
import ChangeMapyType from './changeMapyType/ChangeMapyType';
import LoadScriptOnlyIfNeeded from '../../../LoadScript/LoadScriptOnlyIfNeeded';
import './Map.css';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import CardMsnMapInfoWindow from './Messenger/CardMsnMapInfoWindow';
import './marker.css';
import { Avatar, Box, Card, Collapse, Fab, Grid, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import ChangeMapyTypeMobile from './changeMapyType/ChangeMapyTypeMobile';
import { GpsFixed } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import MapIcon from '@mui/icons-material/Map';
import useStore from '../../../store/useStore';
import { Context } from '../../../components/Context/AuthContext';
import GoalBadge from './Goal/GoalBadge';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';

const libraries = ['places', 'drawing'];
const apiKey = 'AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU';

const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// extrair do place e criar um objeto address
const extractAddress = (place) => {
  //console.log(place);
  const address = {
    city: '',
    state: '',
    zip: '',
    country: '',
    route: '',
    street_number: '',
    plain() {
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      const route = this.route ? this.route + ', ' : '';
      const street_number = this.street_number ? this.street_number + ', ' : '';
      return city + route + street_number + zip + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;
    // console.log("component");
    //console.log(component);

    if (types.includes('administrative_area_level_2')) {
      address.city = value;
    }

    if (types.includes('administrative_area_level_1')) {
      address.state = component.short_name;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }

    if (types.includes('route')) {
      address.route = value;
    }
    if (types.includes('street_number')) {
      address.street_number = value;
    }
  });

  return address;
};

export const Map = ({ centerInit, setIsLoadingMap, filters, property_adress }) => {
  const navigate = useNavigate();
  const property_id = localStorage.getItem('Property') || 1;

  const [isLoadingTimer, setLoadingTimer] = useState(true);
  // Guarda localização do centro do mapa
  let origin = centerInit;

  if (centerInit.lat == NaN) {
    centerInit = {
      lat: -20.9115994,
      lng: -42.7944169,
      address: '',
      zoom: 5.5,
      url: '',
    };
  }
  // Salvar as referências do map e do searchBox
  // map: instancia do mapa
  // map: google.maps.Map
  const [map, setMap] = useState();
  // searchBoxA: endereço da barra de pesquisa
  //google.maps.places.SearchBox
  const [searchBoxA, setSearchBoxA] = useState();
  //google.maps.places.latLngLiteral
  const [locations, setLocations] = useState([]);
  //google.maps.places.latLngLiteral
  const [selectLocation, setSelectLocation] = useState();
  const [selected, setselected] = useState(null);
  // google.maps.places.PlaceResult =  um array
  const [placesData, setPlacesData] = useState([]);
  const [selectPlacesData, setSelectPlacesData] = useState([]);
  const [window_google, setWindGoogle] = useState();

  //inicializar o mapa
  const [center, setCenter] = useState(centerInit);
  const [zoom, setZoom] = useState(centerInit?.zoom || 5.5);
  const [address, setAddress] = useState(property_adress);

  //Referência do mapa:onload do google para obter a referência do mapa
  // prop onLoad: Essa função onLoad recebe um parâmetro padrão que é a instância atual do mapa .
  // map: google.maps.Map
  const onLoad = (map) => {
    mapRef.current = map;
    setMap(map);
    setWindGoogle(window.google);
  };

  const mapRef = useRef(null);

  // referencia atual do centro do mapa
  const handleCenterChanged = () => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    // console.log("newPos");
    //console.log(mapRef.current);
    const newlocationcenter = {
      lat: newPos.lat,
      lng: newPos.lng,
      address: center.address || centerInit?.address,
      zoom: zoom,
      url: '',
    };
    setCenter(newlocationcenter);
    reverseGeocode({ latitude: newPos.lat, longitude: newPos.lng });
  };

  // referencia atual do zoom do mapa
  const onZoomChanged = (value) => {
    // console.log(zoom);
    //console.log(mapRef.current);
    if (!mapRef.current) return;
    setZoom(mapRef.current?.zoom);
    // console.log(zoom);
    if (value) setZoom(value);
  };

  // função para pesquisa do mapa no componente StandaloneSearchBox
  // onload do StandaloneSearchBox para obter a referência do SearchBox
  // ref:  google.maps.places.SearchBox
  const onLoadA = (ref) => {
    setSearchBoxA(ref);
  };

  // função para mudança de localização
  // quando seleciona item do autocomplete, chama a função onPlacesChangedA e informa que o local mudou
  const onPlacesChangedA = () => {
    //pegar o place selecionados no searchbox e retorará um array
    let places = [];
    if (searchBoxA !== null) {
      places = searchBoxA.getPlaces();
    }
    if (places.length === 0) {
      // Nenhum lugar válido selecionado
      return;
    }
    const place = places[0];
    setSelectLocation(null);
    // pego a localização e crio um novo objeto de localização
    const location = {
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    const newCenter = {
      lat: location.lat,
      lng: location.lng,
      address: place?.formatted_address,
      url: place?.url,
    };

    setCenter(newCenter);
    // copia das localizações atuais
    const newLocations = [...locations, location];
    setLocations(newLocations);

    // com o novo objeto de localização o mapa aponta para o novo local, colocando o centro na posição
    map?.panTo(location);
    onZoomChanged(15);

    const _address = extractAddress(place);
    setAddress(_address);
  };

  const handleKeyDown = (event) => {
    // Impede a pesquisa quando a tecla Enter for pressionada
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  //botão para visulização dos tipos de mapa
  const [changeMyTypeID, setToogleChangeMyTypeID] = useState(1);

  const MapType = {
    hybrid: 'hybrid',
    satellite: 'satellite',
    terrain: 'terrain',
    rodmap: 'roadmap',
  };

  const handleMapToggle = () => {
    if (changeMyTypeID === 0) {
      setToogleChangeMyTypeID(1);
    } else if (changeMyTypeID === 1) {
      setToogleChangeMyTypeID(2);
    } else if (changeMyTypeID === 2) {
      setToogleChangeMyTypeID(3);
    } else if (changeMyTypeID === 3) {
      setToogleChangeMyTypeID(4);
    } else if (changeMyTypeID === 4) {
      setToogleChangeMyTypeID(1);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      if (changeMyTypeID === 1) {
        mapRef.current.setMapTypeId(MapType.hybrid);
      } else if (changeMyTypeID === 2) {
        mapRef.current.setMapTypeId(MapType.terrain);
      } else if (changeMyTypeID === 3) {
        mapRef.current.setMapTypeId(MapType.satellite);
      } else if (changeMyTypeID === 4) {
        mapRef.current.setMapTypeId(MapType.rodmap);
      }
    }
  }, [changeMyTypeID]);

  // botão para localizar a geolocalização
  // pega-se a referência do input
  const searchInput = useRef(null);

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    console.log('reverseGeocode');
    let center = { lat: lat, lng: lng };
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    //searchInput.current.value = "Getting your location...";
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        setAddress(_address);
        searchInput.current.value = _address.plain();
      });
    map?.panTo(center);

    // onZoomChanged(16);
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        //console.log("findMyLocation", position);
        reverseGeocode(position.coords);
      });
    }
  };
  const { aux_add, menuContext, isMobile } = useContext(Context);
  console.log('start repos');
  const usestore = useStore();
  const ismobile = isMobile.any();

  // Polygon e Marker -> áreas e marcações das criações/safras
  const [isLoadingPoligon, setIsLoadingPoligon] = useState(false);
  const [plgn_areas, setPolygons] = useState([]);

  if (isLoadingPoligon && (!window.google || !window.google.maps) && window_google) {
    window.google = window_google;
  }
  // lista de criações
  const list_livestocks = usestore.reposLivestock.listLivestock;
  //console.log("reposLivestock");
  //console.log(list_livestocks);

  // lista de safras
  const list_harvest = usestore.reposHarvest.harvestRepos;
  //console.log("reposHarvest");
  //console.log(list_harvest);

  // lista de áreas
  var list_areas = usestore.reposAreas.listAreasMap;
  //console.log("reposAreas");
  //console.log(list_areas);
  // Listas as aéras desenhadas e dentro de cada área tem-se a usada ou []

  // lista de atividades
  const reposActivityInput = usestore.reposActivityInput;
  //console.log("reposActivityInput");
  //console.log(activitys);

  // repos de solicitações
  const reposMessenger = usestore.reposMessenger;
  //console.log("reposMessenger");
  //console.log(reposMessenger.listMessenger);

  // manejo de pastagem = quando for inserido a atividade
  const polygonBasePosition = [];

  const { id } = useParams();
  const tipoMenu = localStorage.getItem('navMenuActive') || 'selectProperty';

  // carrega as áreas que contem as aŕeas e filtra as usadas(areas_used)
  async function getId() {
    console.log('get poligons areaUsed');
    var paths_coord = [];
    // carrega as áreas que contem o array de areas usadas(areas_used[])
    var aux_activity = [];
    var aux_activity_lv = [];
    let zIndex = 3;
    var filter_map = {};
    var aux_filter = [{ areas: [] }];

    if (id && tipoMenu == 'selectCreationHarvest') {
      filter_map = JSON.parse(localStorage.getItem('creationHarvestRepos'));
      if (filter_map.tipo_repos == 'Safras') {
        aux_filter = usestore.reposHarvest.getHarvestStore('id', filter_map.id);
      } else {
        aux_filter = usestore.reposLivestock.getLivestockStore('id', filter_map.id);
      }
    }

    var aux_livestock_icons = {};

    // [areas_used] -> (livestock / harvest) que estão utilizando a área
    for (const areaUsed of list_areas) {
      //console.log(areaUsed);
      var mark_lvst = [];

      // filtra se a área está presente na livestock/harvest filtrada
      if (filter_map.id) {
        var filtered = aux_filter[0].areas.filter((area) => {
          // área não utilizada pelo filtro ou desocupada
          if (area.id == areaUsed.id || (areaUsed.areas_used.length <= 0 && filters['empty'])) {
            return area;
          }
        });
        if (filtered.length <= 0) {
          continue;
        }
      }
      let decoded_coordinates = JSON.parse(base64_decode(areaUsed.coordinates));
      const min_max_coords = getPolygonExtremits(decoded_coordinates);
      const centerPoligon = getPolygonCentroid(decoded_coordinates);
      const basePolygon = getPolygonBaseCenter(decoded_coordinates, centerPoligon);
      //const basePolygon = { lat: min_max_coords.min_lat, lng: min_max_coords.min_lng };

      aux_activity = reposActivityInput.getActivityStore('id_areamap', areaUsed.id);
      // total da área
      console.log('total da área');
      //console.log(areaUsed.areas_used)
      const areatotal = areaUsed.area;
      let areaUse = 0;
      let numberAnimal = areaUsed.total_animais;
      let iconUrl, position;
      let id_item = 0;
      let num_animais = 0;
      let cont_position = 0;
      let planejamentos = [];
      let tipo_repos = '';

      //for (const creation of areaUsed.areas_used) {
      for (var i = 0, tam = areaUsed.areas_used.length; i < tam; i++) {
        const creation = areaUsed.areas_used[i];
        id_item = creation.id;
        tipo_repos = creation.tipo == 'harvest' ? 'Safras' : 'Criações';
        if (filter_map.id) {
          if (filter_map.id != id_item || filter_map.tipo_repos != tipo_repos) {
            continue;
          }
        }
        //position = randomPointInPoly(decoded_coordinates);
        //position = circlePointInPoly(min_max_coords, centerPoligon, (cont_position * 3));
        position = circlePointInPoly(min_max_coords, centerPoligon, cont_position * 3);
        areaUse += parseFloat(creation.areaUsed);

        if (!filters[creation.tipo]) {
          continue;
        }
        // harvest
        if (creation.tipo == 'harvest') {
          planejamentos = usestore.reposHarvestTargetCost.getPlanningStore('id_harvest', id_item);

          mark_lvst.push({
            title: creation.name + '\n' + creation.tipo_harvest,
            iconUrl: '/dist/image/entrada_preto.png',
            id_item: id_item,
            tipo_item: creation.tipo,
            position: position, //{ lat: position.lat + 0.0015, lng: position.lng - 0.0015},
            zIndex: zIndex,
            cultura: creation.tipo_harvest,
            label_safra: true,
            has_plans: planejamentos?.length > 0,
          });
          zIndex += 3;

          mark_lvst.push({
            title: creation.name + '\n' + creation.tipo_harvest,
            iconUrl: '/dist/image/icon_cultures/' + creation.tipo_icon,
            id_item: id_item,
            tipo_item: creation.tipo,
            position: position, //{ lat: position.lat + 0.0018, lng: position.lng },
            zIndex: zIndex,
            cultura: creation.tipo_harvest,
            safra: true,
            has_plans: planejamentos?.length > 0,
          });
        } else {
          planejamentos = usestore.reposTargetLivestock.getPlanningStore('id_livestock', id_item);

          num_animais = creation.num_animais || 0;

          aux_activity_lv = aux_activity.filter((activity) => {
            if (activity.id_livestock == id_item && activity.progress != 'CONCLUÍDA') {
              return activity;
            }
          });
          var num_incoming_anim = 0;
          if (aux_activity_lv.length > 0 && aux_activity_lv[0]) {
            aux_activity_lv.map((actv) => {
              num_incoming_anim += actv.numberAnimals || 0;
            });
          }

          //tipo do icone
          iconUrl = '/dist/image';
          if (creation.tipo_livestock === 'Bovinocultura de Corte') {
            iconUrl += '/entrada_boi';
          } else if (creation.tipo_livestock === 'Bovinocultura de Leite') {
            iconUrl += '/entrada_vaca';
          } else if (creation.tipo_livestock === 'Avicultura de Corte') {
            iconUrl += '/entrada_frango';
          } else if (creation.tipo_livestock === 'Suinocultura') {
            iconUrl += '/entrada_suino';
          } else if (creation.tipo_livestock === 'Piscicultura') {
            iconUrl += '/entrada_peixe';
          } else {
            //if (/Outro/.test(creation.tipo_livestock)) {
            iconUrl += '/entrada_outros';
          }
          let qtd_animais = num_animais + num_incoming_anim;
          if (!aux_livestock_icons[id_item]) {
            aux_livestock_icons[id_item] = qtd_animais;
          } else {
            aux_livestock_icons[id_item] += qtd_animais;
          }

          if (num_animais == 0 && num_incoming_anim > 0) {
            mark_lvst.push({
              title: creation.name + '\n' + creation.tipo_livestock,
              iconUrl: iconUrl + '_afazer.png',
              id_item: id_item,
              tipo_item: creation.tipo,
              position: position,
              number_animals: num_animais,
              num_incoming_anim: num_incoming_anim,
              zIndex: zIndex,
              has_plans: planejamentos?.length > 0,
            });
          } else {
            iconUrl += (num_animais > 0 ? '_menor' : '_atividade') + '.png';

            mark_lvst.push({
              title: creation.name + '\n' + creation.tipo_livestock,
              iconUrl: iconUrl,
              id_item: id_item,
              tipo_item: creation.tipo,
              position: position,
              number_animals: num_animais,
              num_incoming_anim: num_incoming_anim,
              zIndex: zIndex,
              has_plans: planejamentos?.length > 0,
            });
          }
        }
        cont_position++;
        zIndex += 3;
      }

      if (mark_lvst.length > 0 || filters['empty']) {
        paths_coord.push({
          coordenadas: decoded_coordinates,
          marks: filters['markers'] ? mark_lvst : [],
          texts_position: basePolygon,
          name: areaUsed.name,
          number_animals: numberAnimal || 0,
          areatotal: areatotal,
          areaUse: areaUse,
        });
      }
    }
    let p_coord = {};
    let lv_mark = {};
    for (var i = 0; i < paths_coord.length; i++) {
      p_coord = paths_coord[i];
      for (var j = 0, tamj = p_coord.marks.length; j < tamj; j++) {
        lv_mark = p_coord.marks[j];
        if (lv_mark.tipo_item == 'harvest') {
          continue;
        } else {
          var aux_qtd_anim = lv_mark.number_animals + lv_mark.num_incoming_anim;
          if (aux_qtd_anim == 0 && aux_livestock_icons[lv_mark.id_item] > 0) {
            // remove o icone
            p_coord.marks.splice(j, 1);
            tamj = p_coord.marks.length;
            j--;
            // muda o icone
            //p_coord.marks[j].iconUrl = p_coord.marks[j].iconUrl.replace("_atividade" , "_afazer");
            //p_coord.marks[j].num_incoming_anim = '-';
            //p_coord.marks[j].number_animals = '-';
          }
        }
      }
    }

    //console.log(paths_coord)
    setPolygons(paths_coord);

    setTimeout(() => {
      onZoomChanged((zoom || 15) + Number(Math.random().toFixed(2)) / 10);
    }, 350);
  }

  const optionsEquipe = usestore.reposUser.listUser;
  // Marker das solicitações
  const [mark_solicitacoes, setMarkSolicitacoes] = useState([]);
  const [msn, setMsn] = useState([]);

  // carregar todas os markers
  const loadPosts = async () => {
    console.log('get solicitações da propriedade');
    const itemData = [];
    setMarkSolicitacoes([]);
    setselected(null);

    if (!filters['posts']) {
      setMsn(itemData);
      return;
    }

    var filter_map = {};
    if (id && tipoMenu == 'selectCreationHarvest') {
      filter_map = JSON.parse(localStorage.getItem('creationHarvestRepos'));
    }
    for (const info of reposMessenger.listMessenger) {
      if (filter_map.id) {
        if ((filter_map.tipo_repos == 'Safras' && info.id_harvest != filter_map.id) || (filter_map.tipo_repos == 'Criações' && info.id_livestock != filter_map.id)) {
          continue;
        }
      }
      // seleciona solicitacao com status resolver
      if (info.status === '0' && info.isDeleted === 0 && info.id_property == property_id) {
        itemData.push(info);
        // let decoded = await JSON.parse(base64_decode(info.coordinates));
        let decoded = info.coordinates_decod;
        info.coordinates = decoded;
        if (decoded == null) return;

        for (const iterator of optionsEquipe) {
          if (iterator.id === info.id_requester) {
            info.name = iterator.name;
            const name = info.name;
            // console.log(name);
            const charName = name[0].toUpperCase();
            //console.log(charName);
            info.charName = charName;
          }
        }
        itemData.push(info);
        setMarkSolicitacoes((current) => [...current, { info }]);
      }
    }
    setMsn(itemData);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingPoligon(true);
      setLoadingTimer(false);
      setTimeout(() => {
        getId();
        loadPosts();
      }, 1000);
    }, 800);
  }, [aux_add, filters, menuContext]);

  //cálculo do centroide
  function getPolygonCentroid(polygonCoords) {
    let centroidX = 0,
      centroidY = 0;
    let signedArea = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      let x0 = polygonCoords[i].lat;
      let y0 = polygonCoords[i].lng;
      let x1 = polygonCoords[(i + 1) % polygonCoords.length].lat;
      let y1 = polygonCoords[(i + 1) % polygonCoords.length].lng;

      let a = x0 * y1 - x1 * y0;
      signedArea += a;
      centroidX += (x0 + x1) * a;
      centroidY += (y0 + y1) * a;
    }

    signedArea /= 2;
    centroidX /= 6 * signedArea;
    centroidY /= 6 * signedArea;

    return { lat: centroidX, lng: centroidY };
  }

  // baricentro
  function getPolygonCenterbarycenter(polygonCoords) {
    let centroidX = 0,
      centroidY = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      centroidX += polygonCoords[i].lat;
      centroidY += polygonCoords[i].lng;
    }

    centroidX = centroidX / polygonCoords.length;
    centroidY = centroidY / polygonCoords.length;

    return { lat: centroidX, lng: centroidY };
  }

  // inserrindo  texto na base do poligon
  function getPolygonBaseleft(polygonCoords) {
    let centroidX = 0,
      centroidY = 0,
      minLng = Infinity,
      minLngIndex = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      centroidX += polygonCoords[i].lat;
      centroidY += polygonCoords[i].lng;

      if (polygonCoords[i].lng < minLng) {
        minLng = polygonCoords[i].lng;
        minLngIndex = i;
      }
    }

    centroidX = centroidX / polygonCoords.length;
    centroidY = polygonCoords[minLngIndex].lng;

    return { lat: centroidX, lng: centroidY };
  }

  function getPolygonBaseCenter(polygonCoords, center_poly) {
    /*let centroidX = 0,
      centroidY = 0,
      minLat = Infinity,
      minLatIndex = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      centroidX += polygonCoords[i].lat;
      centroidY += polygonCoords[i].lng;

      if (polygonCoords[i].lat < minLat) {
        minLat = polygonCoords[i].lat;
        minLatIndex = i;
      }
    }

    //centroidX = minLat + 0.001; // adiciona 20px na coordenada lat
    centroidX = minLat + 0.002;
    centroidY = centroidY / polygonCoords.length - 0.002;

    return { lat: centroidX, lng: centroidY };
    */

    let minLat1 = Infinity,
      minLat2 = Infinity;
    for (let i = 0; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat < minLat1) {
        minLat1 = polygonCoords[i].lat;
      }
    }
    for (let i = 0; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat == minLat1) {
        continue;
      }
      if (polygonCoords[i].lat < minLat2 && minLat2 > minLat1) {
        minLat2 = polygonCoords[i].lat;
      }
    }

    let center_base = (minLat1 + minLat2) / 2;
    return { lat: center_base, lng: center_poly.lng };
  }

  function getPolygonExtremits(polygonCoords) {
    let minLat = Infinity,
      minLng = Infinity;
    let maxLat = -Infinity,
      maxLng = -Infinity;
    for (let i = 0; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat < minLat) {
        minLat = polygonCoords[i].lat;
      }
      if (polygonCoords[i].lng < minLng) {
        minLng = polygonCoords[i].lng;
      }
      if (polygonCoords[i].lat > maxLat) {
        maxLat = polygonCoords[i].lat;
      }
      if (polygonCoords[i].lng > maxLng) {
        maxLng = polygonCoords[i].lng;
      }
    }
    return { max_lat: maxLat, max_lng: maxLng, min_lat: minLat, min_lng: minLng };
  }

  function circlePointInPoly(extremes, center, i) {
    var vari_lat = (extremes.max_lat - extremes.min_lat) / 5;
    var vari_lng = (extremes.max_lng - extremes.min_lng) / 5;
    let latitude = center.lat;
    let longitude = center.lng;

    //rosa dos ventos
    switch (i) {
      case 3:
        longitude = center.lng + vari_lng;
        break;
      case 6:
        latitude = center.lat - vari_lat;
        break;
      case 9:
        longitude = center.lng - vari_lng;
        break;
      case 12:
        latitude = center.lat + vari_lat;
        break;
      // 1 volta
      case 15:
        latitude = center.lat + vari_lat / 1.5;
        longitude = center.lng + vari_lng / 1.5;
        break;
      case 18:
        latitude = center.lat - vari_lat / 1.5;
        longitude = center.lng - vari_lng / 1.5;
        break;
      case 21:
        latitude = center.lat - vari_lat / 1.5;
        longitude = center.lng - vari_lng / 1.5;
        break;
      case 24:
        latitude = center.lat + vari_lat / 1.5;
        longitude = center.lng + vari_lng / 1.5;
        break;
      default:
        latitude = center.lat;
        longitude = center.lng;
    }

    return { lat: latitude, lng: longitude };
  }

  const renderOverlay = (props) => {
    return (
      <div style={{ backgroundColor: 'white', padding: '8px' }}>
        <p>Texto na base do polígono</p>
      </div>
    );
  };

  const [expanded, setExpanded] = useState(false);

  // função para clicar no icon e retornar no centro do mapa
  function centerMaps() {
    map?.panTo(center || origin);
    onZoomChanged(zoom || 15);
    handleIconClick();
  }

  const handleIconClick = () => {
    setExpanded(!expanded);
  };

  // função para clicar no icon e retornar no centro do mapa
  function linkLivestockHarvest(item, plan) {
    console.log('linkLivestockHarvest');
    //console.log(item);
    var item_repos;
    if (item.tipo_item == 'livestock') {
      item_repos = usestore.reposLivestock.getLivestockStore('id', item.id_item);
      item_repos = { label: item_repos[0].name, id: item_repos[0].id, id_property: item_repos[0].id_property, tipo_repos: 'Criações', tipe: item_repos[0].id_tipe, activity: item_repos[0].tipo };
      usestore.reposLivestock.changeLivestockRepos(item_repos);
      localStorage.setItem('creationHarvestRepos', JSON.stringify(item_repos));

      if (plan) {
        navigate(`/planning/${property_id}/${item.id_item}`);
      } else {
        // } else if (item.number_animals === 0) {
        navigate(`/activities/${property_id}/${item.id_item}`);
        // } else {
        //   navigate(`/controlpanelcreations/${property_id}/${item.id_item}`);
      }
    } else {
      item_repos = usestore.reposHarvest.getHarvestStore('id', item.id_item);
      item_repos = {
        label: item_repos[0].name,
        id: item_repos[0].id,
        id_property: item_repos[0].id_property,
        id_cultivation: item_repos[0].id_cultivation,
        cultivation_icon: item_repos[0].cultivation_icon,
        tipo_repos: 'Safras',
      };
      usestore.reposHarvest.changeHarvestRepos(item_repos);
      localStorage.setItem('creationHarvestRepos', JSON.stringify(item_repos));

      if (plan) {
        navigate(`/planningharvest/${property_id}/${item.id_item}`);
      } else {
        navigate(`/activitiesHarvest/${property_id}/${item.id_item}`);
      }
    }
  }

  // adiciona uma quebra de linha a cada N letras
  function breakeWordN(text) {
    let arr_txt = [];
    let aux_txt = '';
    for (var i = 0, tam = text.length; i < tam; i++) {
      aux_txt += text[i];
      if (aux_txt.length == 6) {
        arr_txt.push(aux_txt);
        aux_txt = '';
      } else if (i + 1 == tam) {
        arr_txt.push(aux_txt);
      }
    }
    aux_txt = arr_txt.join('\n');
    return aux_txt;
  }
  // interrompe a palavra no número de letras desejado e adiciona "..."
  function breakeWord(text, num) {
    if (!text) {
      return '';
    } else if (text.length <= num) {
      return text;
    } else {
      let aux_txt = text.slice(0, num);
      return aux_txt + '...';
    }
  }
  // função para retornar o icone de acordo com o tipo (livestock/safra)
  function returnIcon(mark_lvst) {
    if (mark_lvst.safra) {
      return {
        url: mark_lvst.iconUrl,
        //size: {width: 10, height: 10},
        //scaledSize: new window.google.maps.Size(50, 35),
        //anchor: new window.google.maps.Point(25, 83),

        //scaledSize: { width: 50, height: 35 },
        //anchor: { x: 25, y: 83 }

        scaledSize: { width: 35, height: 25 },
        anchor: { x: 18, y: 63 },
      };
    } else {
      return {
        url: mark_lvst.iconUrl,
        scaledSize: { width: 60, height: 70 },
      };
    }
  }
  // função para retornar o texto do marker de acordo com o tipo (livestock/safra)
  function returnLabel(mark_lvst, tooltip) {
    if (mark_lvst.label_safra) {
      if (tooltip) {
        return mark_lvst.cultura;
      }
      return {
        text: !tooltip ? breakeWord(mark_lvst.cultura, 6) : mark_lvst.cultura,
        className: 'maptext-marker-number',
        fontSize: ismobile ? '8px' : '10px',
      };
    } else {
      var text =
        mark_lvst.number_animals === 0 && mark_lvst.num_incoming_anim === 0
          ? ` `
          : mark_lvst.num_incoming_anim > 0
          ? `${mark_lvst.number_animals}/${mark_lvst.num_incoming_anim}`
          : `${mark_lvst.number_animals}`;
      if (tooltip) {
        return text;
      }
      return {
        text: text,
        className: 'maptext-marker-number',
        //fontSize: mark_lvst.number_animals === 0 || mark_lvst.num_incoming_anim === 0 ? "13px" : "10px",
        fontSize: ismobile ? '8px' : '10px',
      };
    }
  }
  // função para retornar a posição do OverlayView
  function returnLabelOP(position) {
    var posit = {};
    if (zoom == 12) {
      posit = { lat: position.lat + 0.005, lng: position.lng - 0.0116 };
    } else if (zoom == 13) {
      posit = { lat: position.lat + 0.002, lng: position.lng - 0.0058 };
    } else if (zoom == 14) {
      posit = { lat: position.lat + 0.0008, lng: position.lng - 0.00275 };
    } else if (zoom == 15) {
      // default
      posit = { lat: position.lat + 0.0005, lng: position.lng - 0.00145 };
    } else if (zoom == 16) {
      posit = { lat: position.lat + 0.0002, lng: position.lng - 0.00075 };
    } else if (zoom == 17) {
      posit = { lat: position.lat + 0.00009, lng: position.lng - 0.00035 };
    } else if (zoom == 18) {
      posit = { lat: position.lat + 0.00006, lng: position.lng - 0.00018 };
    } else {
      posit = { lat: position.lat, lng: position.lng };
    }
    return posit;
  }

  /** Elementos essenciais do mapa:
    apiKey, libraries, center, zoom, handleCenterChanged, onLoad, onZoomChanged, mapRef

    Elementos de marcação no mapa:
      mark_solicitacoes - selected
      isLoadingPoligon -> plgn_areas - expanded
  */
  //console.log(center)
  //console.log(zoom)

  function getTam(tipo) {
    let val_zoom = Number(localStorage.getItem('setting_zoom') || '1');
    let width_tam = 100;

    if (val_zoom == 1.4) {
      width_tam = 72;
    } else if (val_zoom == 1.3) {
      width_tam = 77;
    } else if (val_zoom == 1.2) {
      width_tam = 84;
    } else if (val_zoom == 1.1) {
      width_tam = 92;
    } else if (val_zoom == 1) {
      width_tam = 100;
    } else if (val_zoom == 0.9) {
      width_tam = 112;
    } else if (val_zoom == 0.8) {
      width_tam = 125;
    } else if (val_zoom == 0.7) {
      width_tam = 143;
    }

    let ttt = window ? window.jQuery(window.jQuery('#ajustHeigth').find('.MuiDrawer-paperAnchorBottom')[0]).css({ transform: 'none' }) : false;
    return width_tam + 'vw';
  }

  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    // Temporizador para mostrar o InfoWindow após 2000 ms
    const showTimer = setTimeout(() => {
      setShowInfoWindow(true);
    }, 2000);

    // Temporizador para fechar o InfoWindow após 2000 ms adicionais
    const hideTimer = setTimeout(() => {
      setShowInfoWindow(false);
    }, 5000); // 2000 ms para abrir + 2000 ms para exibir

    // Limpar temporizadores quando o componente for desmontado
    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };

    // setInterval(() => {
    //   setShowInfoWindow(!showInfoWindow);
    // }, 5000);
  }, []);

  return (
    <>
      {isLoadingTimer == true ? (
        <>
          {/*<FirstAcessMap />*/}
          <div className=" m-0 p-0">
            <div className=""></div>
            <div className="">
              <div className="" style={{ height: '100%', position: 'relative', width: '100%', left: 0, right: 0, bottom: 0, top: 7 }}>
                {/* <LinearIndeterminate /> */}
              </div>
              <div className="" style={{ height: '100%', position: 'fixed', width: '100%', left: 0, right: 0, bottom: 0, top: 80, overflow: 'hidden' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width={'100%'} height={800} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width={'100%'} height={80} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadScriptOnlyIfNeeded id="scrit-loader" googleMapsApiKey={apiKey} libraries={libraries}>
          {/* inicia o mapa */}
          <GoogleMap
            mapContainerStyle={{
              //height: getTam('height'),
              width: getTam('width'),
              height: `calc(100% - 142px)`,
              //width: "100vw",
            }}
            center={center}
            zoom={Number(zoom)}
            onDragEnd={handleCenterChanged}
            onLoad={onLoad}
            onZoomChanged={onZoomChanged}
            ref={mapRef}
            options={{
              mapTypeControl: false,
              mapTypeId: 'hybrid',
              streetViewControl: false,
              fullscreenControl: false,
              zoomControl: false,
            }}
          >
            {/* marcadores das solicitações */}
            {mark_solicitacoes &&
              window_google &&
              mark_solicitacoes.map((item, index) => (
                <Marker
                  key={index}
                  position={{ lat: item.info.coordinates.lat, lng: item.info.coordinates.lng }}
                  icon={{
                    url: '/dist/image/iconLocationbluec.png',
                  }}
                  label={{
                    text: item.info.charName,
                    className: 'maptext-marker',
                    fontSize: '24px',
                  }}
                  onClick={() => setselected(item.info)}
                ></Marker>
              ))}
            {selected && mark_solicitacoes ? (
              <div>
                <InfoWindow key={selected.index} onCloseClick={() => setselected(null)} position={{ lat: selected.coordinates.lat + 0.002, lng: selected.coordinates.lng }}>
                  <div>
                    <CardMsnMapInfoWindow selected={selected} setselected={setselected} setIsLoadingMap={setIsLoadingMap} sx={{ p: 2 }} />
                  </div>
                </InfoWindow>
              </div>
            ) : null}
            {/* Barra de endereço */}
            <div className="search-box-container">
              <div className="">
                <StandaloneSearchBox onLoad={onLoadA} onPlacesChanged={onPlacesChangedA}>
                  <div>
                    <Paper
                      component="form"
                      sx={{
                        position: 'fixed',
                        top: 90,
                        right: '3%',
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width: 350,
                      }}
                    >
                      {ismobile ? (
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                          <MenuIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={findMyLocation} sx={{ p: '10px' }} aria-label="my location">
                          <GpsFixed color="success" />
                        </IconButton>
                      )}
                      <input
                        style={{
                          animation: `${expandAndFade} 2s linear infinite`,
                          border: 'none', // Remove border for a cleaner look
                          outline: 'none', // Remove default outline
                          padding: '10px', // Add some padding
                          flex: 1, // Make input take remaining space
                        }}
                        ref={searchInput}
                        onKeyDown={handleKeyDown}
                        type="text"
                        className={`search-box-google input-animation`}
                        placeholder="Digite o endereço desejado"
                      />
                      <IconButton disabled sx={{ p: '10px' }} aria-label="search">
                        {/* <SearchIcon sx={{ animation: `${expandAndFade} 2s linear infinite` }} /> */}
                        <SearchIcon />
                      </IconButton>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <span sx={{ p: '10px' }} aria-label="directions">
                        <DirectionsIcon style={{ color: '#2e7d32' }} />
                      </span>
                    </Paper>
                  </div>
                </StandaloneSearchBox>
                <PropertyAreaButton map={map} center={center} setCenter={setCenter} zoom={zoom} address={address} setIsLoadingMap={setIsLoadingMap} />
              </div>
            </div>
            <div className="d-none d-lg-block">
              <ChangeMapyType handleMapToggle={handleMapToggle} />
            </div>
            <div className="d-lg-none">
              <ChangeMapyTypeMobile handleMapToggle={handleMapToggle} />
            </div>
            {/*áreas e marcadores das áreas */}
            {isLoadingPoligon && window_google && (
              <>
                {plgn_areas.map((item) => (
                  <>
                    <Polygon
                      options={{
                        fillColor: item.areaUse > 0 ? 'green' : 'gray',
                        fillOpacity: 0.7,
                        strokeColor: item.areaUse > 0 ? '#53f444' : '#e0e0e0',
                        strokeWeight: 2,
                      }}
                      path={item.coordenadas}
                    />
                    {item.marks.length > 0 && (
                      <>
                        {item.marks.map((mark_lvst, index) => (
                          <>
                            <Marker
                              key={mark_lvst.tipo_item + '_' + mark_lvst.id_item + '_' + index}
                              position={mark_lvst.position}
                              onClick={() => linkLivestockHarvest(mark_lvst)}
                              icon={returnIcon(mark_lvst)}
                              label={mark_lvst.safra ? ' ' : returnLabel(mark_lvst)}
                              zIndex={mark_lvst.zIndex}
                              title={mark_lvst.title}
                            />
                            {showInfoWindow && (
                              <InfoWindow position={mark_lvst.position} options={{ pixelOffset: new window.google.maps.Size(0, -65) }}>
                                <div
                                  style={{
                                    overflow: 'hidden',
                                    backgroundColor: 'white',
                                    position: 'relative',
                                  }}
                                >
                                  <p>Clique no ícone da {mark_lvst.safra ? 'Cultura' : 'Criação'}</p>
                                </div>
                              </InfoWindow>
                            )}
                            {/* <OverlayView position={mark_lvst.position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                              <div style={{ background: 'white', padding: '5px', borderRadius: '3px', border: '1px solid black' }}>
                                <p>Clique aqui no marker</p>
                              </div>
                            </OverlayView> */}

                            {!filters['planning'] ? (
                              <></>
                            ) : (
                              <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={returnLabelOP(mark_lvst.position)}>
                                <IconButton onClick={() => linkLivestockHarvest(mark_lvst, true)} sx={{ p: '10px', zIndex: mark_lvst.zIndex }} aria-label="my location" className={'GoalBadge'}>
                                  <GoalBadge />
                                </IconButton>
                              </OverlayView>
                            )}
                          </>
                        ))}
                      </>
                    )}
                    {!filters['names_areas'] || ismobile ? (
                      <></>
                    ) : (
                      <OverlayView
                        mapPaneName={'overlayMouseTarget'}
                        position={item.texts_position}
                        getPixelPositionOffset={(width, height) => ({
                          x: -(width / 2),
                          y: -(height / 2),
                        })}
                      >
                        <IconButton>
                          <Card onClick={() => centerMaps()} sx={{ backgroundColor: 'black', color: 'white', borderRadius: '8px' }}>
                            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                              <Avatar variant="rounded" src="/dist/image/icon_verde_location.png" sx={{ width: 21, height: 28, position: 'absolute', left: '0px' }} />
                              <Stack spacing={0.5}>
                                <Typography sx={{ fontSize: 10, color: 'white', ml: '8px' }} fontWeight={500}>
                                  {item.name}
                                </Typography>
                                <Collapse in={expanded}>
                                  <Divider />
                                  <Typography sx={{ fontSize: 10, color: 'white' }} color="text.secondary">
                                    <MapIcon sx={{ color: grey[800], fontSize: 12, color: 'white' }} /> {item.areaUse}/{item.areatotal} ha
                                  </Typography>
                                </Collapse>
                              </Stack>
                            </Box>
                          </Card>
                        </IconButton>
                      </OverlayView>
                    )}{' '}
                  </>
                ))}{' '}
              </>
            )}
          </GoogleMap>
        </LoadScriptOnlyIfNeeded>
      )}
      {/* {center.address == '' ? '' : <FirstSecondAcessMap propertyName={'teste'} />} */}
    </>
  );
};
