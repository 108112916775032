import { SvgIcon } from '@mui/material';

// icon ave - frango
export const IconChicken = ({ widthStyles }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} style={{ width: widthStyles, height: '25' }} viewBox="0 0 393.000000 375.000000" preserveAspectRatio="xMidYMid meet">
        <path
          transform="translate(0.000000,375.000000) scale(0.100000,-0.100000) "
          d="M3327 3709 c-29 -17 -49 -66 -41 -104 6 -33 5 -34 -33 -40 -75 -11
          -141 -36 -199 -73 -77 -51 -260 -242 -409 -428 -66 -82 -145 -174 -175 -204
          -98 -97 -221 -149 -412 -174 -64 -8 -152 -24 -195 -35 -43 -11 -104 -24 -135
          -30 -32 -6 -93 -25 -135 -42 l-78 -33 -258 -4 c-254 -4 -259 -4 -364 23 -90
          23 -121 27 -195 23 l-89 -6 -58 43 c-98 72 -107 75 -233 74 l-113 -1 53 -15
          c28 -9 52 -19 52 -23 0 -5 -15 -12 -33 -16 -27 -6 -29 -8 -13 -14 27 -9 14
          -15 -79 -35 -88 -20 -155 -43 -155 -55 0 -4 30 -11 67 -15 37 -4 76 -10 87
          -15 19 -7 18 -8 -9 -13 -16 -3 -50 -9 -75 -12 -66 -9 -100 -25 -100 -46 0 -17
          3 -17 44 -3 60 21 160 15 164 -8 4 -20 -53 -50 -125 -68 -46 -11 -51 -23 -28
          -54 8 -11 15 -27 15 -37 0 -18 27 -29 78 -29 37 0 39 -6 12 -40 -29 -37 -25
          -46 24 -59 49 -12 64 -27 47 -44 -6 -6 -11 -17 -11 -24 0 -21 61 -53 100 -53
          58 0 66 -13 25 -44 -29 -22 -33 -30 -25 -46 5 -10 10 -29 10 -42 0 -13 9 -29
          20 -36 11 -7 20 -18 20 -26 0 -7 20 -21 44 -30 33 -13 47 -25 56 -49 24 -64
          35 -79 72 -98 21 -11 38 -24 38 -29 0 -5 12 -12 28 -16 110 -25 136 -40 148
          -89 5 -18 -1 -34 -19 -59 l-26 -34 20 -18 c11 -10 41 -48 66 -84 52 -73 188
          -230 212 -244 9 -5 23 -17 31 -27 14 -16 85 -35 214 -58 33 -6 64 -15 69 -20
          17 -17 58 -204 64 -293 l6 -87 -42 -70 c-75 -127 -35 -158 46 -35 12 18 13 18
          29 -3 13 -19 26 -23 69 -23 29 0 68 -6 86 -14 44 -18 219 -32 219 -18 0 6 -14
          22 -32 36 -37 28 -36 29 61 45 73 12 93 5 116 -39 23 -45 19 -55 -40 -105 -93
          -80 -62 -134 35 -64 l44 32 28 -33 c24 -29 37 -35 100 -46 40 -6 113 -19 162
          -28 49 -9 106 -16 127 -16 21 0 49 -4 63 -10 33 -12 33 0 0 39 -20 24 -36 32
          -75 37 -27 4 -51 10 -54 13 -2 4 44 15 103 25 59 11 115 26 124 34 10 8 28 12
          42 10 32 -6 24 7 -16 28 -28 14 -47 15 -181 3 -132 -11 -151 -11 -161 2 -8 11
          -23 14 -52 11 -40 -4 -41 -3 -101 75 -61 79 -124 177 -163 253 l-22 43 48 48
          c26 27 69 63 94 80 49 34 113 118 223 294 56 90 69 104 129 140 36 22 114 85
          173 139 109 101 151 136 216 180 26 18 41 39 53 72 9 26 45 97 80 158 35 61
          89 163 120 227 55 113 58 125 111 390 53 266 127 527 158 555 6 6 38 16 69 23
          68 15 132 59 156 107 16 34 18 36 57 30 22 -3 50 -9 63 -12 40 -11 27 51 -23
          106 -13 14 -25 44 -28 69 -4 31 -14 51 -35 71 -21 19 -37 50 -53 104 l-23 78
          -23 -29 -23 -28 0 53 c0 28 -4 52 -10 52 -5 0 -10 -6 -10 -12 0 -7 -5 -5 -11
          5 -10 18 -11 18 -24 1 -11 -16 -14 -15 -29 14 -9 18 -18 32 -19 32 -1 0 -10
          -5 -20 -11z m-1732 -2784 c58 -18 110 -64 136 -120 10 -22 45 -78 78 -124 34
          -46 61 -88 61 -92 0 -15 -46 -22 -165 -23 -148 -1 -167 8 -189 87 -19 63 -46
          219 -46 258 0 27 3 29 38 29 20 0 59 -7 87 -15z"
        />
      </SvgIcon>
    </>
  );
};
