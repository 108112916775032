import React, { useEffect, useState } from 'react';
import { Box, Card, CardHeader, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// import InventoryHist from './components/InventoryHist';
import InventoryMov from './components/InventoryMov';
import InventorySltockListContent from './InventorySltockListContent';
import useStore from '../../store/useStore';
import { Context } from '../../components/Context/AuthContext';
import StorageLocations from './components/RegisterNewStorage/StorageLocations';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
// import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import InvestoryGeneralCardEmpty from './InvestoryGeneralCardEmpty';
import Typewriter from '../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../components/Loader/ThreeDotsSpinner';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tabs_controle_estoque-tab-${index}`,
    'aria-controls': `Controle de Estoque-${index}`,
  };
}

export default function InventoryContent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const usestore = useStore();
  const { aux_add } = React.useContext(Context);
  const [productList, setProductList] = useState(usestore.reposProducts.listProducts);
  const reposProduct = usestore.reposProducts.listProducts;

  useEffect(() => {
    setProductList(reposProduct);
  }, [aux_add]);

  // console.log(productList);

  return (
    <>
      {productList.length == 0 ? (
        <InvestoryGeneralCardEmpty />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}
            <Card sx={{ mt: 1 }}>
              <CardHeader
                title={
                  <>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid item xs={12} md={12}>
                        <Box sx={{ display: 'flex' }}>
                          {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                          <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                            <Typography fontWeight="500" variant="subtitle1" style={{ color: '#2e7d32' }}>
                              <>
                                <strong>Cresça seu negócio controlando tudo que entra e sai do estoque</strong>
                              </>
                            </Typography>
                            {/* <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                              <>
                                <strong>Controle do estoque: </strong>
                                <span>você pode controlar o estoque da propriedade e dos fornecedores através de entradas, saídas e movimentações dos produtos</span>
                              </>
                            </Typography> */}
                            <Grid item xs={12} md={12} alignItems="center">
                              {true ? (
                                <Typewriter
                                  text={`Clique em "REGISTROS" na parte superior direita da tela, e cadastre os produtos que serão utlizados na pecuária e agricultura.`}
                                  variant={'subtitle1'}
                                  color={'#2e7d32'}
                                />
                              ) : (
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                  {' '}
                                  <ThreeDotsSpinner />
                                </Box>
                              )}
                            </Grid>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                }
              ></CardHeader>
              <Divider />
            </Card>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <WidgetsIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Estoque
                  </Grid>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <SwapHorizIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Movimentações
                  </Grid>
                }
                {...a11yProps(1)}
              />
              {/* <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <ManageSearchIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Histórico do produto
                  </Grid>
                }
                {...a11yProps(2)}
              /> */}
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <WarehouseIcon sx={{ mr: 0.5, mb: 0.2 }} />
                    Locais de estoque
                  </Grid>
                }
                {...a11yProps(2)}
              />

              {/* <Tab label="Histórico do produto" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <InventorySltockListContent productList={productList} setProductList={setProductList} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InventoryMov />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <InventoryHist />
          </TabPanel> */}
          <TabPanel value={value} index={2}>
            <StorageLocations />
          </TabPanel>
        </Box>
      )}
    </>
  );
}
