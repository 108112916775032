import { Box, Card, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../components/Loader/ThreeDotsSpinner';

export default function CardLivestockInfoTimeLine() {
  const [showText, setShowText] = useState(false);
  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowText(true);
  }, 1500);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* {listPlanning.length == 0 && <TableProductionCardEmpty title={"Criação"} />} */}
        <Card sx={{ mt: 1 }}>
          <CardHeader
            title={
              <>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex' }}>
                      {/* <BarChartIcon style={{ fontSize: 35 }} /> */}
                      <Stack spacing={0.5} sx={{ ml: 1, mt: 0.5 }}>
                        <Typography fontWeight="500" variant="subtitle1" style={{ color: '#808080' }}>
                          <>
                            <strong>Controle e Monitore o Cronograma das Atividades de Campo</strong>
                          </>
                        </Typography>
                        <Typography fontWeight="400" variant="subtitle2" style={{ color: '#808080' }}>
                          <>
                            {/* <strong>Atividades: </strong> */}
                            {showText ? (
                              <>
                                {/* <Typewriter variant={'subtitle2'} tam={40} Livestok text={`Até o momento, temos X atividades.`} color={'#2e7d32'} /> */}
                                <Typography fontWeight="500" variant="subtitle2" style={{ color: '#808080' }}>
                                  {`Gerencie as data de início e de término de cada atividade `}
                                </Typography>
                              </>
                            ) : (
                              <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                {' '}
                                <ThreeDotsSpinner />
                              </Box>
                            )}
                          </>
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 2 }} variant="subtitle2">
                      <strong>Movimentações: </strong> Analise o histórico por período de todas as movimentações de entradas e saídas
                    </Typography>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Estoque: </strong>com os produtos cadastrados, tenha visibilidade dos produtos e disponíveis no seu estoque
                    </Typography>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Histórico do produto: </strong> Analise o histórico de entrada e saída de cada categoria de produto.
                    </Typography>
                    <Typography sx={{ mt: 0 }} variant="subtitle2">
                      <strong>Locais de estoque: </strong>Locais de estoque: Tenha apenas um estoque ou crie e visualize os seus produtos em estoques
                      diferentes.
                    </Typography>
                  </Grid> */}
                </Grid>
              </>
            }
          ></CardHeader>
          <Divider />
        </Card>
      </Box>
    </>
  );
}
