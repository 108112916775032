import { SvgIcon } from '@mui/material';

// icon peixe
export const IconFish = ({ widthStyles }, props) => {
  //se você quiser que a altura do componente seja de 100px, você pode definir height="200" no elemento <svg> e scale(0.05) na tag <g>.
  return (
    <>
      <SvgIcon {...props} style={{ width: widthStyles, height: '15' }} viewBox="0 0 980.000000 744.000000" preserveAspectRatio="xMidYMid meet">
        <path
          transform="translate(0.000000,744.000000) scale(0.100000,-0.100000) "
          d="M2930 7430 c-288 -22 -448 -140 -411 -304 17 -75 60 -142 208 -324
195 -237 219 -271 207 -290 -10 -16 -79 -59 -394 -245 -563 -332 -1205 -838
-1540 -1213 -554 -620 -854 -1185 -959 -1809 -98 -581 -18 -1118 245 -1645
233 -465 602 -890 1099 -1264 272 -206 482 -328 562 -330 39 -1 49 4 78 36 40
43 49 75 63 228 12 133 47 264 98 368 71 146 169 246 479 487 175 136 398 358
487 485 75 107 159 260 188 346 12 33 20 84 20 120 0 52 -4 67 -26 93 -56 66
-120 59 -394 -42 -428 -160 -752 -210 -1201 -186 -324 16 -514 62 -754 182
-244 121 -380 259 -448 456 -26 74 -30 100 -30 191 0 123 17 197 73 317 111
237 340 464 660 653 139 83 259 140 292 140 29 0 78 -32 94 -62 16 -28 18
-102 4 -128 -5 -10 -84 -61 -175 -114 -231 -135 -323 -202 -450 -330 -158
-159 -218 -271 -238 -442 -6 -52 -8 -97 -4 -100 3 -4 32 16 64 43 80 68 133
103 239 156 326 164 876 247 1494 226 271 -10 417 -32 934 -144 273 -59 450
-93 631 -120 61 -10 111 -18 113 -20 1 -1 -22 -54 -52 -118 -150 -319 -162
-425 -59 -518 59 -53 100 -64 233 -63 88 0 135 6 200 24 248 68 701 287 1095
530 364 224 315 203 635 265 990 192 1871 587 2525 1133 142 119 440 424 559
572 137 172 276 380 361 543 l67 127 -118 138 c-762 890 -1833 1409 -3219
1559 -133 14 -268 17 -810 18 l-650 1 -120 52 c-208 88 -383 145 -636 207
-306 74 -486 99 -829 115 -236 11 -346 11 -490 0z m2178 -1114 l37 -34 7 -489
c8 -672 41 -995 138 -1384 75 -299 160 -472 296 -603 59 -57 95 -83 254 -176
106 -61 126 -129 61 -199 -24 -26 -37 -31 -74 -31 -149 0 -425 205 -573 426
-120 179 -248 653 -309 1144 -24 193 -37 560 -33 940 3 383 3 379 71 420 47
29 81 25 125 -14z m2398 -489 c99 -51 159 -155 159 -277 0 -61 -5 -84 -27
-126 -30 -58 -88 -113 -150 -145 -52 -26 -155 -36 -215 -20 -64 17 -133 78
-173 152 -33 60 -35 70 -35 154 0 81 3 95 30 144 58 105 149 153 280 148 62
-3 91 -9 131 -30z"
        />
      </SvgIcon>
    </>
  );
};
