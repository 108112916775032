import * as React from 'react';
import Draggable from 'react-draggable';
import { AppBar, Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, ListItem, ListItemText } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { Close } from '@mui/icons-material';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function PersonDetailsModal({ data, open, handleClose }) {
  console.log(data.observations);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose(data.id)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        sx={{ '& .MuiDialog-container': { '& .MuiPaper-root': { width: '100%', maxWidth: '650px' } } }}
      >
        <DialogTitle>
          <div className="d-lg-none">
            <Toolbar sx={{ height: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={'Informações'} variant={'title'} color={'#2e7d32'} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={() => handleClose(data.id)} aria-label="close">
                {' '}
                <Close />{' '}
              </IconButton>
            </Toolbar>
          </div>
          <div className="d-none d-lg-block">
            <Toolbar>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} alignItems="center">
                  <Typewriter text={'Observações Gerais'} variant={'h6'} color={'#2e7d32'} />
                </Grid>
              </Grid>
              <Typography sx={{ flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton edge="start" color="inherit" onClick={() => handleClose(data.id)} aria-label="close">
                {' '}
                <Close />{' '}
              </IconButton>
            </Toolbar>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ mb: 8, textAlign: 'center' }}>
          <Container component="main" maxWidth="md" sx={{ my: { xs: 3, md: 3 } }} style={{ marginTop: '0px' }}>
            <Grid item container alignItems="center" sx={{ my: 1 }}>
              <Grid container spacing={3} style={{ padding: '5px' }}>
                <Card style={{ border: '1px solid #2e7d32' }}>
                  <CardHeader />
                  <CardContent sx={{ mt: '-30px' }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12}>
                        {data.observations ? (
                          <ListItem>
                            <ListItemText primary="Observações:" secondary={data.observations} />
                          </ListItem>
                        ) : (
                          <ListItem>
                            <ListItemText primary="Observações:" secondary={'Não foi adicionado nenhuma observação.'} />
                          </ListItem>
                        )}
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={4}>
                        <Grid item xs={4} sm={4}>
                          <ListItem>
                            <ListItemText primary="Cidade:" secondary={addres.city} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <ListItem>
                            <ListItemText primary="Bairro:" secondary={addres.neighborhood} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <ListItem>
                            <ListItemText primary="Estado:" secondary={addres.state} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <ListItem>
                            <ListItemText primary="País:" secondary={addres.country} />
                          </ListItem>
                        </Grid>
                      </Grid> */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button color="success" variant="outlined" type="submit" onClick={() => handleClose(data.id)}>
                <span>fechar</span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </>
  );
}

//   return (
//     <Modal open={open} onClose={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
//       <Box>
//         <Typography id="modal-modal-title" variant="h6" component="h2">
//           Detalhes da Pessoa
//         </Typography>
//         {person ? (
//           <Box>
//             <Typography variant="body1">Nome: {person.name}</Typography>
//             <Typography variant="body1">Tipo: {person.tipo}</Typography>
//             <Typography variant="body1">Documento: {person.documento}</Typography>
//             <Typography variant="body1">Número do Documento: {person.num_doc}</Typography>
//           </Box>
//         ) : (
//           <Typography variant="body1">Nenhuma informação disponível.</Typography>
//         )}
//       </Box>
//     </Modal>
//   );
// }
