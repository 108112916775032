import React from 'react';
import ReactDOM from 'react-dom/client';
//import { HashRouter } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
//import { ThemeProvider } from "./components/context-api/ThemeContext";
//import { QueryClient, QueryClientProvider } from "react-query";
//import { ReactQueryDevtools } from "react-query/devtools";

//HashRouter
//BrowserRouter

const root = ReactDOM.createRoot(document.getElementById('root'));

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//     },
//   },
// });

//<React.StrictMode></React.StrictMode>

// // Remover o botão se existir
// const removeStorageButton = () => {
//   const button = document.getElementById('btnReload');
//   if (button) {
//     button.remove();
//   }
// };

setTimeout(() => {
  //removeStorageButton();
  console.log('index.js');
  root.render(
    // <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      {/* <ThemeProvider> */}
      <App />
      {/* </ThemeProvider> */}
    </BrowserRouter>

    /* <ReactQueryDevtools initialIsOpen={false} /> */
    // </QueryClientProvider>
  );
}, 1000);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
