import React, { useEffect, useState, useContext } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { Context } from '../Context/AuthContext';
import ToolsMenu from './components/ToolsMenu';
import AccountMenu from './components/AccountMenu';
import Check from './components/Check';
import SelectProperty from './components/SelectProperty';
import SelectAreas from './components/SelectAreas';
import SelectCreationHarvest from './components/SelectCreationHarvest';
import useStore from '../../store/useStore';
import NotificationsAlert from './components/NotificationsAlert';
import DisplayModeSettingsContent from '../../pages/settingsUser/DisplayModeSettingsContent';

export default function Nav({ setIsLoadingPage }) {
  const navigate = useNavigate();
  const { handleLogout, authenticated, handleRepos, menuContext, setMenuContext, aux_add } = useContext(Context);
  //const [isLoading, setIsLoading] = useState(0);

  // obter a url:
  const urlAtual = window.location.pathname;
  // console.log('urlAtual');
  // console.log(urlAtual);
  // Removendo a barra inicial, se necessário
  const pathcomplete = urlAtual.startsWith('/') ? urlAtual.slice(1) : urlAtual;
  // Dividindo o caminho em partes usando '/'
  const pathcPartsomplete = pathcomplete.split('/');

  const path = pathcPartsomplete[0];

  // console.log(path);
  // instancia os repos
  const usestore = useStore();

  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  //console.log("userCurrent");
  //console.log(userCurrent);
  const date = new Date();
  const dateToExpire = new Date(localStorage.getItem('expiresTime'));
  if (!userCurrent || !userCurrent?.id || (authenticated && dateToExpire.getTime() - date.getTime() <= 0)) {
    toast.error('Sessão expirada, favor logar novamente!');
    handleLogout();
    navigate('/');
  }

  const options = usestore.reposProperty.listProperty;
  //console.log("lista de fazendas");
  //console.log(options);
  const initFarm = usestore.reposProperty.propertyRepos;
  const [value_property, setValue] = useState(!initFarm ? options[0] : initFarm);

  // seta as opções de criações
  // console.log("lista de criações");
  const optionsLivestocks = usestore.reposLivestock.listLivestock;
  // seta a criação atual
  const initCreation = usestore.reposLivestock.livestockRepos;
  // const [valueCreation, setValueCreation] = useState(initCreation);

  // seta as opções de safras
  //console.log("lista de safras");
  const optionsHarvests = usestore.reposHarvest.listHarvest;
  // seta a safra atual
  const initHarvests = usestore.reposHarvest.harvestRepos;
  // const [valueHarvest, setValueHarvests] = useState(initHarvests);

  // seta options e valores conjuntos (criação + safras)
  const optionsCreationHarvest = [...optionsLivestocks, ...optionsHarvests];

  var aux_creationHarvestRepos = localStorage.getItem('creationHarvestRepos');
  const creationHarvestRepos = aux_creationHarvestRepos ? JSON.parse(aux_creationHarvestRepos) : { label: '', id: 0, id_property: 0, tipo_repos: '' };

  let creationHarvest = userCurrent?.settings?.geral?.creationHarvest || 'Criações';
  let initCreationHarvest = creationHarvestRepos;
  if (!creationHarvestRepos.id) {
    if (creationHarvest === 'Criações') {
      initCreationHarvest = initCreation;
    } else {
      initCreationHarvest = initHarvests;
    }
  }
  //console.log("lista conjunta");
  const [value_misto, setValueCreationHarvests] = useState(initCreationHarvest);

  // valida creationHarvestRepos igual à seleção atual
  if (!aux_creationHarvestRepos || creationHarvestRepos.id !== initCreationHarvest.id) {
    localStorage.setItem('creationHarvestRepos', JSON.stringify(initCreationHarvest));
    setMenuContext(new Date().getTime());
  }
  if (value_misto.id !== initCreationHarvest.id || value_misto.tipo_repos !== initCreationHarvest.tipo_repos) {
    setValueCreationHarvests(initCreationHarvest);
  }

  // seta as opções de áreas
  const optionAreasMap = usestore.reposAreas.listAreasMap;
  //console.log("lista de áreas");
  //console.log(optionAreasMap);
  const initAreaMap = usestore.reposAreas.areaMapRepos;
  // seta a área atual
  const [value_area, setValueArea] = useState(initAreaMap);

  const navMenuActive = localStorage.getItem('navMenuActive');
  if (!navMenuActive) {
    localStorage.setItem('navMenuActive', 'selectProperty');
  }
  // const ButtonActive = localStorage.getItem('ButtonActive');
  const [value_menu, setValueMenu] = useState(navMenuActive || 'selectProperty');

  useEffect(() => {
    //console.log("change menu");
    //const ButtonActive = localStorage.getItem("ButtonActive");
    const navMenuActive_aux = localStorage.getItem('navMenuActive');
    if (navMenuActive_aux !== value_menu) {
      setValueMenu(navMenuActive_aux);
    }
  }, [value_menu, menuContext, aux_add]);

  //useEffect(() => {
  //  const newCreation = usestore.reposLivestock.livestockRepos
  //  setValueCreation(newCreation)
  //}, [])

  useEffect(() => {
    var property_id = localStorage.getItem('Property') || 1;
    if (!value_property || !value_property.id || value_property.id === property_id) {
      return;
    }
    //atualiza a mudança do seletor nos repos de acordo com a propriedade
    usestore.reposProperty.changePropertyRepos(value_property);
    //atualiza o contexto para alterar os repos
    handleRepos(value_property.id);

    if (setIsLoadingPage) {
      setIsLoadingPage(true);
    }
  }, [value_property]);

  /*useEffect(() => {
    if (!value_misto || !value_misto.tipo_repos) {
      return;
    }
    //atualiza a mudança do seletor em farmCreationsRepos ou farmHarvestRepos em toda mudança no value_misto
    if (value_misto.tipo_repos === "Safras") {
      usestore.reposHarvest.changeHarvestRepos(value_misto);
    } else {
      usestore.reposLivestock.changeLivestockRepos(value_misto);
    }
    localStorage.setItem("creationHarvestRepos", JSON.stringify(value_misto));
  }, [value_misto]);*/

  useEffect(() => {
    if (!value_area || !value_area.id) {
      return;
    }
    //atualiza a mudança do seletor em reposAreasMap->areaMapRepos em toda mudança no value_area
    usestore.reposAreas.changeAreaMapRepos({ label: value_area.name || value_area.label, id: value_area.id, id_property: value_area.id_property });
  }, [value_area]);

  const [destino, setNavigate] = useState(false);
  const [changeTxt, setchangeText] = useState(1);
  const handleChangeSelect = (newValue, menuActive) => {
    setValueMenu(menuActive);
    localStorage.setItem('navMenuActive', menuActive);
    setMenuContext(new Date().getTime());
    setchangeText(new Date().getTime());

    var destination = false;

    if (menuActive === 'selectProperty') {
      setValue(newValue);
      localStorage.setItem('ButtonActive', 'Central');
      destination = '/painel';
    } else if (menuActive === 'selectCreationHarvest') {
      localStorage.setItem('ButtonActive', 'Painel');
      setValueCreationHarvests(newValue);
      if (newValue.tipo_repos === 'Safras') {
        usestore.reposHarvest.changeHarvestRepos(newValue);
        destination = '/controlpanelharvest/' + value_property.id + '/' + newValue.id;
      } else {
        usestore.reposLivestock.changeLivestockRepos(newValue);
        destination = '/controlpanelcreations/' + value_property.id + '/' + newValue.id;
      }
    } else if (menuActive === 'selectAreaMap') {
      setValueArea(newValue);
      destination = '/ControlpanelspecificArea/' + value_property.id + '/' + newValue.id;
    }
    if (destination) {
      setTimeout(() => {
        setNavigate(destination);
      }, 100);
    }
  };

  useEffect(() => {
    if (!destino) {
      return;
    }
    navigate(destino);
  }, [destino]);

  // const changeZoom = (tipo) => {
  //   var valor = Number(window.jQuery(window.jQuery.find('body')).css('zoom'));
  //   // console.log(valor);
  //   if (tipo === 'mais') {
  //     valor += 0.1;
  //   } else {
  //     valor -= 0.1;
  //   }
  //   valor = Number(valor.toFixed(1));
  //   if (valor > 1.4) {
  //     valor = 1.4;
  //   } else if (valor < 0.7) {
  //     valor = 0.7;
  //   }
  //   let val_zoom = Number(localStorage.getItem('setting_zoom') || '1');
  //   if (val_zoom !== valor) {
  //     window.jQuery(window.jQuery.find('body')).css({ zoom: valor });
  //     setMenuContext(new Date().getTime());
  //     localStorage.setItem('setting_zoom', valor);
  //   }
  // };

  /*const preventCopyPaste = (e) => {
    alert('Copying and pasting is not allowed!');
    e.preventDefault();
  };

  const changeBGStyleActive = (id_node) => {
    var menuActive = localStorage.getItem('navMenuActive') || value_menu;
    var color = 'success';
    if (menuActive === id_node) {
      if (menuActive === 'selectProperty') {
        color = green[100];
      } else if (menuActive === 'selectCreationHarvest') {
        color = blue[100];
      } else if (menuActive === 'selectAreaMap') {
        color = grey[300];
      }
      return { backgroundColor: color, padding: '8px' };
    } else {
      return { backgroundColor: '#fff', padding: '8px' };
    }
  };*/

  const changeStyleActive = (id_node) => {
    if (id_node === 'selectAreaMap') {
      return { display: 'none' };
    }
    // var menuActive = localStorage.getItem('navMenuActive') || value_menu;
    var styles = {
      borderBottom: '4px solid #FFF',
      transform: 'translate(0px, 2px)',
      marginRight: '0px',
      marginLeft: '0px',
      padding: '12px 0px 10px 0px',
    };

    return styles;
  };

  const mountSelectNav = (tipo_select, tools) => {
    if (tools && tipo_select === navMenuActive) {
      return <></>;
    }
    return (
      <div className="d-flex align-items-center flex-column text-light" style={{ width: '100%' }}>
        <div className="input-group ">
          <div style={{ width: '100%' }}>
            {tipo_select === 'selectProperty' ? (
              <SelectProperty value={value_property} options={options} setValue={handleChangeSelect} widthx={tools ? '100%' : 180} />
            ) : tipo_select === 'selectCreationHarvest' ? (
              !tools && optionsCreationHarvest.length <= 0 ? (
                <></>
              ) : (
                <SelectCreationHarvest value={value_misto} options={optionsCreationHarvest} setValue={handleChangeSelect} widthx={tools ? '100%' : 180} />
              )
            ) : !tools && optionAreasMap.length <= 0 ? (
              <></>
            ) : (
              <SelectAreas value={value_area} options={optionAreasMap} setValue={handleChangeSelect} widthx={tools ? '100%' : 180} />
            )}
          </div>
        </div>
      </div>
    );
  };

  //if (isLoading > 0) {
  //  return <Loading />;
  //}

  const [showBar, setShowBar] = useState(false);
  const [showBarCreation, setShowBarCreation] = useState(false);
  const [showBarHarvest, setShowBarHarvest] = useState(false);

  //console.log("navMenuActive")
  //console.log(navMenuActive)

  useEffect(() => {
    // setShowBar(true);
    // setShowBarCreation(true);
    // setShowBarHarvest(true);
    if (navMenuActive === 'selectProperty') {
      setShowBar(true);
      setShowBarCreation(false);
      setShowBarHarvest(false);
    } else if (navMenuActive === 'selectCreationHarvest' && creationHarvestRepos.tipo_repos === 'Criações') {
      setShowBarCreation(true);
      setShowBar(false);
      setShowBarHarvest(false);
    } else if (navMenuActive === 'selectCreationHarvest' && creationHarvestRepos.tipo_repos === 'Safras') {
      setShowBarHarvest(true);
      setShowBar(false);
    }
  }, [navMenuActive]);

  //áreas para aparercer os textos:

  const location = useLocation();
  // const showNav = location.pathname === '/';

  const [showText, setShowText] = useState(true);

  // const [showUrl, setShowUrl] = useState(location.pathname);

  const [showTextCentral, setShowTextCentral] = useState('');

  let text = '';

  if (location.pathname === '/' || location.pathname === '/painel') {
    if (optionsLivestocks.length === 0 && optionsHarvests.length === 0) {
      text = 'Seja bem-vindo ao Fazenda Premium.';
    } else {
      text = `Sua propriedade apresenta ${optionsLivestocks.length === 1 ? `${optionsLivestocks.length} Criação` : `${optionsLivestocks.length} Criações`} e ${
        optionsHarvests.length === 1 ? `${optionsHarvests.length} Safra` : `${optionsHarvests.length} Safras`
      }`;
    }
  } else if (location.pathname === '/mapa') {
    if (optionsLivestocks.length === 0 && optionsHarvests.length === 0) {
      text = 'Seja bem-vindo ao Mapa da propriedade. ';
    } else {
      text = 'Visualize as safras e os animais nas áreas de produção.';
    }
  } else if (location.pathname === '/areasmaps') {
    if (optionAreasMap.length === 0 && optionAreasMap.length === 0) {
      text = 'Seja bem-vindo ao Mapa da propriedade.';
    } else {
      text = `Sua propriedade apresenta ${optionAreasMap.length === 0 ? ` nenhuma área` : `${optionAreasMap.length} áreas de produção`}`;
    }
  } else if (location.pathname === '/livestock') {
    if (optionsLivestocks.length === 0) {
      text = 'Seja bem-vindo ao seu primeiro projeto';
    } else {
      text = `Sua propriedade apresenta ${optionAreasMap.length === 0 ? ` nenhuma área` : `${optionAreasMap.length} área(s) de produção`}`;
    }
  } else if (location.pathname === '/messenger') {
    text = 'Gerencie as informações com o recurso solicitações.';
  } else {
    text = 'Gerencie com o Fazenda Premium.';
  }

  useEffect(() => {
    //console.log("atualizado")
    setShowTextCentral(text);
  }, [changeTxt]);

  //useEffect(() => {
  //  setShowTextCentral(text)
  //}, [])

  // execute em  3 segundos e showText é false
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowText(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  // execute em  6 segundos e showText é true para retornar ao texto anterior.
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowText(true);
    }, 9000);

    return () => clearTimeout(timeout);
  }, []);

  const handleClick = () => {
    // console.log("clique")
    setShowText(!showText);
  };

  //console.log(showText)

  return (
    <>
      {!setIsLoadingPage ? (
        <></>
      ) : (
        <div title="Fazenda Premium" style={{ marginTop: '-18px', marginLeft: '16px' }}>
          <NavLink to="/" className="brand-link mt-3">
            <i>
              <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="icone-fazenda-premium" width="50" />
            </i>
          </NavLink>
        </div>
      )}

      <nav className=" main-header navbar navbar-expand navbar-white navbar-light fixed-top " style={{ padding: 0, overflowY: 'hidden' }}>
        <ul className="navbar-nav  ">
          <li className="nav-item mt-2" style={{ width: '35px' }}>
            {setIsLoadingPage ? (
              <Grid className="nav-link text-secondary">
                <i className="fas fa-bars" style={{ color: '#fff' }} />
              </Grid>
            ) : (
              <NavLink to="#" className="nav-link text-secondary" data-widget="pushmenu" role="button">
                <i className="fas fa-bars" />
              </NavLink>
            )}
          </li>
        </ul>
        <ul className="navbar-nav ml-left " id="selectProperty" style={changeStyleActive('selectProperty')}>
          <li className="nav-item " title="Menu por propriedade" style={{ width: '40px' }}>
            <ToolsMenu tipoMenu={'selectProperty'} select_tools={mountSelectNav('selectProperty', true)} />
          </li>
          {navMenuActive !== 'selectProperty' ? <></> : <li className="nav-item ">{mountSelectNav('selectProperty')}</li>}
        </ul>
        <ul className="navbar-nav ml-left " id="selectCreationHarvest" style={changeStyleActive('selectCreationHarvest')}>
          {navMenuActive !== 'selectCreationHarvest' ? <></> : <li className="nav-item ">{mountSelectNav('selectCreationHarvest')}</li>}
          <li className="nav-item " title="Menu por Criação ou safra" style={{ width: '40px' }}>
            <ToolsMenu tipoMenu={'selectCreationHarvest'} select_tools={mountSelectNav('selectCreationHarvest', true)} />
          </li>
        </ul>
        {/*<ul className="navbar-nav ml-left " id="selectAreaMap" style={changeStyleActive("selectAreaMap")}>
          <li className="nav-item " title="Menu por área" style={{ marginRight: "-7px" }}>
            <ToolsMenu tipoMenu={"selectAreaMap"} select_tools={mountSelectNav("selectAreaMap", true)} />
          </li>
          {navMenuActive !== "selectAreaMap" ? <></> : <li className="nav-item ">{mountSelectNav("selectAreaMap")}</li>}
            </ul>*/}

        <ul className="navbar-nav ml-auto ">
          <li className="nav-item  d-none d-lg-block mt-1">
            <NavLink to="#" className="nav-link" sx={{ mt: -1 }} title='Notificações'>
              <NotificationsAlert />
            </NavLink>
          </li>
          <li className="nav-item d-none d-lg-block mt-2 mr-4 " title="Configurações">
            <DisplayModeSettingsContent />
          </li>
          <li className="nav-item d-none d-lg-block mt-2 mr-4"> </li>
          {!userCurrent?.settings?.geral?.check_on ? (
            <></>
          ) : (
            <li className="nav-item d-none d-lg-block mt-2 mr-4">
              <p>
                {' '}
                <Check />{' '}
              </p>
            </li>
          )}

          {/* <li className="nav-item d-none d-lg-block mt-1">
            <ButtonGroup disableElevation color="success" size="small" variant="outlined" aria-label="outlined button group">
              <Button title="Aumentar zoom" id="option_a1" autoComplete="off" defaultChecked onClick={(e) => changeZoom("mais")}>
                A+
              </Button>
              <Button title="Diminuir zoom" id="option_a2" autoComplete="off" onClick={(e) => changeZoom("menos")}>
                A-
              </Button>
            </ButtonGroup>
          </li> */}
          <li className="nav-item  d-none d-lg-block mt-1" title="Tela cheia">
            <NavLink to="#" className="nav-link" data-widget="fullscreen" role="button">
              <i className="fas fa-expand-arrows-alt  text-secondary" />
            </NavLink>
          </li>
          <li className="nav-item d-none d-lg-block mt-1 " title='Sair do app'>
            <Link to="/" onClick={handleLogout} className="nav-link" role="button">
              <p className="text-secondary">SAIR</p>
            </Link>
          </li>
          <li className="nav-item dropdown mr-2">
            <AccountMenu />
          </li>
          <li></li>
        </ul>
      </nav>
      {/*  */}

      {navMenuActive === 'selectProperty' && (
        <>
          {path === 'livestock' ? (
            <>
              <div style={{ backgroundColor: '', height: '69px' }}></div>
              {showText ? (
                <div
                  role="button"
                  onClick={handleClick}
                  style={{
                    backgroundColor: '#027002',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '14px',
                    transform: showBar ? 'translateY(0)' : 'translateY(-20px)',
                    transition: 'height 0.5s ease-in, transform 0.5s ease-in',
                    overflow: 'hidden',
                  }}
                >
                  Projeto Pecuário
                </div>
              ) : (
                <div
                  role="button"
                  onClick={handleClick}
                  style={{
                    backgroundColor: 'green',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  {showTextCentral}
                </div>
              )}
            </>
          ) : (
            <>
              <div style={{ backgroundColor: '', height: '69px' }}></div>
              {showText ? (
                <div
                  role="button"
                  onClick={handleClick}
                  style={{
                    backgroundColor: 'black',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '14px',
                    transform: showBar ? 'translateY(0)' : 'translateY(-20px)',
                    transition: 'height 0.5s ease-in, transform 0.5s ease-in',
                    overflow: 'hidden',
                  }}
                >
                  Propriedade: {value_property.label}
                </div>
              ) : (
                <div
                  role="button"
                  onClick={handleClick}
                  style={{
                    backgroundColor: 'black',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  {showTextCentral}
                </div>
              )}
            </>
          )}
        </>
      )}

      {navMenuActive === 'selectCreationHarvest' && creationHarvestRepos.tipo_repos === 'Criações' && (
        <>
          {path === 'activities' ? (
            <>
              <div style={{ backgroundColor: '', height: '69px' }}></div>
              <div
                style={{
                  backgroundColor: 'white',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  color: 'green',
                  fontSize: '14px',
                  transform: showBarCreation ? 'translateY(0)' : 'translateY(-20px)',
                  transition: 'height 0.5s ease-in, transform 0.5s ease-in',
                  overflow: 'hidden',
                }}
              >
                FASE: PLANEJAMENTO E EXECUÇÃO
              </div>
            </>
          ) : (
            <>
              <div style={{ backgroundColor: '', height: '69px' }}></div>
              <div
                style={{
                  backgroundColor: 'green',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '14px',
                  transform: showBarCreation ? 'translateY(0)' : 'translateY(-20px)',
                  transition: 'height 0.5s ease-in, transform 0.5s ease-in',
                  overflow: 'hidden',
                }}
              >
                Criação: {creationHarvestRepos.label}
              </div>
            </>
          )}
        </>
      )}
      {navMenuActive === 'selectCreationHarvest' && creationHarvestRepos.tipo_repos === 'Safras' && (
        <>
          <div style={{ backgroundColor: '', height: '69px' }}></div>
          <div
            style={{
              backgroundColor: '#392620',
              height: '20px',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              color: 'white',
              fontSize: '14px',
              transform: showBarHarvest ? 'translateY(0)' : 'translateY(-20px)',
              transition: 'height 0.5s ease-in, transform 0.5s ease-in',
              overflow: 'hidden',
            }}
          >
            Safra: {creationHarvestRepos.label}
          </div>
        </>
      )}

      {/* {navMenuActive === "selectProperty" && ButtonActive !== "Mapa" && ButtonActive !== "Áreas" && (
        <>
          <div style={{ backgroundColor: "", height: "69px" }}></div>
          
          <Box sx={{ mb: 2, position: "relative" }}>
            <Divider style={{ border: "1px solid black" }} />
            <Box
              style={{
                backgroundColor: "black",
                height: "30px",
                minWidth: "180px",
                position: "absolute",
                right: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "14px",
                borderRadius: "5px",
                padding: "8px",
                paddingTop: "15px",
              }}
              sx={{ mt: -1, mr: 2 }}
            >
              Propriedade: {value_property.label}
            </Box>
          </Box>
        </>
      )} */}
      {/* {navMenuActive === "selectProperty" && (ButtonActive === "Mapa" || ButtonActive === "Áreas") && (
        <>
          <div style={{ backgroundColor: "", height: "69px" }}></div>
          <div style={{ backgroundColor: "black", height: "20px", display: "flex", alignItems: "flex-end", justifyContent: "center", color: "white", fontSize: "14px" }}>
            Propriedade: {value_property.label}
          </div>
        </>
      )} */}
      {/* {navMenuActive === "selectCreationHarvest" && creationHarvestRepos.tipo_repos === "Criações" && (
        <>
          <div style={{ backgroundColor: "", height: "69px" }}></div>
           <div style={{ backgroundColor: "green", height: "20px", display: "flex", alignItems: "flex-end", justifyContent: "center", color: "white", fontSize: "14px" }}>
            Criação: {valueCreation.label}
          </div> 
          <Box sx={{ mb: 2, position: "relative" }}>
            <Divider style={{ border: "1px solid green" }} />
            <Box
              style={{
                backgroundColor: "green",
                height: "30px",
                minWidth: "180px",
                position: "absolute",
                right: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "14px",
                borderRadius: "5px",
                padding: "8px",
                paddingTop: "15px",
              }}
              sx={{ mt: -1, mr: 2 }}
            >
              Criação: {valueCreation.label}
            </Box>
          </Box>
        </>
      )} */}
      {/* {navMenuActive === "selectCreationHarvest" && creationHarvestRepos.tipo_repos === "Safras" && (
        <>
          <div style={{ backgroundColor: "", height: "69px" }}></div>
          <Box sx={{ mb: 2, position: "relative" }}>
            <Divider style={{ border: "1px solid #392620" }} />
            <Box
              style={{
                backgroundColor: "#392620",
                height: "30px",
                minWidth: "180px",
                position: "absolute",
                right: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "14px",
                borderRadius: "5px",
                padding: "8px",
                paddingTop: "15px",
              }}
              sx={{ mt: -1, mr: 2 }}
            >
              Safra: {valueHarvest.label}
            </Box>
          </Box>
        </>
      )} */}
      {/* {navMenuActive === 'selectCreationHarvest' && ButtonActive !== "Painel" &&
        <div style={{ backgroundColor: '#808080', height: '94px', display: 'flex', alignItems: 'end', justifyContent: 'center', color: 'white' }}>
          Configurações
        </div>
      } */}
      <div className="d-none d-lg-block"></div>
      <div className="d-lg-none "></div>
    </>
  );
}
