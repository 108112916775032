import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Avatar, Box, ButtonBase, ListItemAvatar, Paper, Typography, Stack, Grid } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

export default function MessengerList() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/areasmaps');
  };

  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper', mt: -2 }}>
        <Box>
          <ListItem
            key={null}
            disableGutters
            secondaryAction={
              <span aria-label="comment">
                <CheckBoxOutlineBlankIcon style={{ color: '#2e7d32' }} />
              </span>
            }
          >
            <ListItemText primary={<strong>Passo 1:</strong>} secondary={`Clique no botão verde "+", no canto inferior direito da tela.`} />
          </ListItem>
        </Box>
        <Box>
          <ListItem
            key={null}
            disableGutters
            secondaryAction={
              <span aria-label="comment">
                <CropOriginalIcon style={{ color: '#2e7d32' }} />
              </span>
            }
          >
            <ListItemText primary={<strong>Passo 2:</strong>} secondary="Clique em solicitações" />
          </ListItem>
          {/* <ListItem disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt="Imagem" src="/dist/image/escolhacriações.png" style={{ maxWidth: '100%' }} />
                </ListItem> */}
        </Box>
        <Box>
          <ListItem
            key={null}
            disableGutters
            secondaryAction={
              <span aria-label="comment">
                <CropOriginalIcon style={{ color: '#2e7d32' }} />
              </span>
            }
          >
            <ListItemText
              primary={<strong>Passo 3:</strong>}
              secondary="Defina o título da solicitação, a descrição, uma data da ocorrência, se deseja insira uma imagem e a localização no mapa da solcitação."
            />
          </ListItem>
        </Box>
      </List>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={12}>
          <Typography variant="subtitle1" gutterBottom sx={{ ml: 2 }}>
            As anotações podem ser exibidas como lembretes diretamente no mapa da propriedade e também na página de solicitações.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
