import { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../components/Context/AuthContext';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { alpha, Chip, Skeleton, Box, Toolbar, Typography, Paper, IconButton, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import EditPerson from '../editPerson/EditPerson';
import DeletePerson from '../deletePerson/DeletePerson';
import useStore from '../../../../store/useStore';
import ModalAddres from './ModalAddres';
import ModalContacts from './ModalContacts';
import PersonDetailsModal from './PersonDetailsModal';
import GradingIcon from '@mui/icons-material/Grading';
//função descendingComparator() para ajudar na classificação descendente das linhas da tabela
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//função getComparator() retorna uma função de comparação com base na direção da classificação.
function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// cabeçalhos
const headCells = [
  {
    id: 'name',
    align: 'left',
    label: 'Nome',
    minWidth: 150,
  },
  {
    id: 'tipo',
    align: 'left',
    label: 'tipo',
  },
  {
    id: 'documento',
    align: 'left',
    label: 'Documento',
  },
  {
    id: 'num_doc',
    align: 'left',
    label: 'Número doc.',
  },
  {
    id: 'Contatos',
    align: 'center',
    label: 'Contatos',
  },
  {
    id: 'Endereços',
    align: 'center',
    label: 'Endereços',
  },
  {
    id: 'view',
    align: 'right',
    label: 'Observações',
  },
  {
    id: 'edit',
    align: 'right',
    label: 'Editar',
  },
];

//exibir opções de filtro e botões de ação na tabela.
function EnhancedTableHead(props) {
  // const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, key } = props;
  const { order, orderBy, onRequestSort, propKey } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={propKey}>
        <TableCell padding="checkbox"> </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={'TH_' + headCell.id} align={headCell.align} style={{ minWidth: headCell.minWidth, fontWeight: 'bold' }} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  propKey: PropTypes.any,
};

//A função EnhancedTableToolbar() recebe como argumento o número de linhas selecionadas e retorna o componente Toolbar com as opções de filtro e botões de ação.
function EnhancedTableToolbar(props) {
  const { numSelected, selecteded, repos, tab_active } = props;
  //console.log(props)
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography key={'ty_' + tab_active[0] + '_' + tab_active[1]} sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selecionado
        </Typography>
      ) : (
        <Typography key={'ty_' + tab_active[0] + '_' + tab_active[1]} sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {tab_active[0]}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip key={'tod_' + tab_active[0] + '_' + tab_active[1]} title="Delete">
          <DeletePerson selected={selecteded} usestore={repos} tab_active={tab_active} local={'tooltip'} />
        </Tooltip>
      ) : (
        <Tooltip key={'tof_' + tab_active[0] + '_' + tab_active[1]} title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selecteded: PropTypes.any,
  repos: PropTypes.any,
  tab_active: PropTypes.any,
};

export default function TablesPerson({ tab_active }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [selecteded, setSelecteded] = useState({});
  const [page, setPage] = useState(0);
  // const [dense, setDense] = useState(true);
  const dense = true;
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  const [rows, setRowsPerson] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openModal, setOpenModal] = useState(false);
  // const [openAddres, setOpenAddres] = useState({0: false});
  // const openAddres = { 0: false };
  // const handleOpenAddres = (id) => {
  //   openAddres[id] = true;
  //   //setOpenAddres(openAddres);
  //   setOpenModal(true);
  // };
  // const handleCloseAddres = (id) => {
  //   openAddres[id] = false;
  //   //setOpenAddres(openAddres);
  //   setOpenModal(false);
  // };
  const [openAddres, setOpenAddres] = useState({});

  const handleOpenAddres = (id) => {
    setOpenAddres((prev) => ({ ...prev, [id]: true }));
  };

  const handleCloseAddres = (id) => {
    setOpenAddres((prev) => ({ ...prev, [id]: false }));
  };

  // // const [openContact, setOpenContact] = useState({0: false});
  // const openContact = { 0: false };
  // const handleOpenContact = (id) => {
  //   openContact[id] = true;
  //   //setOpenContact(openContact);
  //   setOpenModal(true);
  // };
  // const handleCloseContact = (id) => {
  //   openContact[id] = false;
  //   //setOpenContact(openContact);
  //   setOpenModal(false);
  // };
  const [openContact, setOpenContact] = useState({});

  // Função para abrir o modal
  const handleOpenContact = (id) => {
    setOpenContact((prev) => ({ ...prev, [id]: true }));
  };

  // Função para fechar o modal
  const handleCloseContact = (id) => {
    setOpenContact((prev) => ({ ...prev, [id]: false }));
  };

  const [openDesc, setOpenDesc] = useState({});

  // Função para abrir o modal
  const handleOpenDesc = (id) => {
    setOpenDesc((prev) => ({ ...prev, [id]: true }));
  };

  // Função para fechar o modal
  const handleCloseDesc = (id) => {
    setOpenDesc((prev) => ({ ...prev, [id]: false }));
  };

  useEffect(() => {
    // console.log('openModal');
  }, [openModal]);

  // instancia os repos
  const usestore = useStore();
  const reposPersons = usestore.reposPerson;
  // const property_id = (localStorage.getItem("Property") || 1);

  // variavel para atualizar a tabela após edição dos dados da harvest
  const { aux_add } = useContext(Context);
  useEffect(() => {
    // console.log('refresh TablesPerson');
    setSelected([]);
    setSelecteded({});
    setTimeout(() => {
      handleRows();
    }, 200);
  }, [aux_add, tab_active]);

  const handleRows = () => {
    let list_person = [];
    if (tab_active[0] === 'Cliente') {
      list_person = reposPersons.listCliente;
    } else if (tab_active[0] === 'Fornecedor') {
      list_person = reposPersons.listFornecedor;
    } else if (tab_active[0] === 'Transportadora') {
      list_person = reposPersons.listTransportadora;
    }

    var list_person_active = [];
    if (tab_active[1] === 'Ativo') {
      list_person_active = list_person.filter((item) => item.isDeleted === 0).map((item) => item);
    } else {
      list_person_active = list_person.filter((item) => item.isDeleted > 0).map((item) => item);
    }

    setRowsPerson(list_person_active);
    setIsLoadingTable(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, id) => {
    var target_parent_name = window.jQuery(event.target).parentsUntil('table').parent().eq(0).attr('name') || '';
    if (target_parent_name !== 'tableListPersons') {
      return;
    }
    target_parent_name = window.jQuery(event.target).parentsUntil('td').parent().eq(0).attr('name') || '';
    if (target_parent_name === 'EditPerson') {
      return;
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);

    if (selectedIndex === -1) {
      selecteded[id] = { id: id, name: name };
    } else {
      delete selecteded[id];
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const handleChange = (e) => {
  //   var harvest = JSON.parse(window.jQuery(e.currentTarget).attr('value'));
  //   localStorage.setItem("creationPersonRepos", JSON.stringify(harvest));
  //   reposPersons.changePersonRepos(harvest);
  // };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} selecteded={selecteded} repos={usestore} tab_active={tab_active} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} name="tableListPersons">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                propKey={'ativos'}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    var selecteded = {};
                    selecteded[row.id] = { id: row.id, name: row.name };
                    return (
                      <>
                        {isLoadingTable === true ? (
                          <>
                            <TableRow key={'void_' + tab_active[0] + '_' + tab_active[1]}>
                              <TableCell align="right" height={67}>
                                <Skeleton variant="rectangular" height={34} />
                              </TableCell>
                              <TableCell align="right" height={67}>
                                <Skeleton variant="rectangular" height={34} />
                              </TableCell>
                              <TableCell align="right" height={67}>
                                <Skeleton variant="rectangular" height={34} />
                              </TableCell>
                              <TableCell align="right" height={67}>
                                <Skeleton variant="rectangular" height={34} />
                              </TableCell>
                              <TableCell align="right" height={67}>
                                <Skeleton variant="rectangular" height={34} />
                              </TableCell>
                              <TableCell align="right" height={67}>
                                <Skeleton variant="rectangular" height={34} />
                              </TableCell>
                              <TableCell align="right" height={67}>
                                <Skeleton variant="rectangular" height={34} />
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name + '_' + tab_active[0] + '_' + tab_active[1]}
                            selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.typePerson}</TableCell>
                            <TableCell align="left">{row.document_type}</TableCell>
                            <TableCell align="left">{row.document}</TableCell>
                            <TableCell align="center">
                              <Chip label={row.contacts.length} color="success" variant="outlined" size="small" title="Contatos" onClick={() => handleOpenContact(row.id)} />
                              {/* <ModalContacts data={row} open={openContact[row.id]} handleClose={handleCloseContact} /> */}
                              <ModalContacts
                                data={row}
                                open={!!openContact[row.id]} // Verifica se o modal está aberto para o ID correspondente
                                handleClose={() => handleCloseContact(row.id)} // Passa o ID específico para fechar
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Chip label={row.addresses.length} color="success" variant="outlined" size="small" title="Endereços" onClick={() => handleOpenAddres(row.id)} />
                              {/* <ModalAddres data={row} open={openAddres[row.id]} handleClose={handleCloseAddres} /> */}
                              <ModalAddres
                                data={row}
                                open={!!openAddres[row.id]} // Certifica-se de que é booleano
                                handleClose={() => handleCloseAddres(row.id)}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Abrir Modal">
                                <IconButton onClick={() => handleOpenDesc(row.id)}>
                                  <GradingIcon />
                                </IconButton>
                              </Tooltip>
                              <PersonDetailsModal
                                data={row}
                                open={!!openDesc[row.id]} // Certifica-se de que é booleano
                                handleClose={() => handleCloseDesc(row.id)}
                              />
                            </TableCell>
                            <TableCell align="right" name="EditPerson" width={'112px'}>
                              {tab_active[1] === 'Ativo' ? (
                                <EditPerson id={row.id} usestore={usestore} tab_active={tab_active} />
                              ) : (
                                <DeletePerson selected={row} usestore={usestore} tab_active={tab_active} local={'table'} />
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow key={'emptyRows_' + tab_active[0] + '_' + tab_active[1]} style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={'Linhas por página'}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {/*<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Visualização da tabela" />*/}
      </Box>
    </>
  );
}
