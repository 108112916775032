import { useState, useContext, useEffect } from 'react';
import { Context } from '../../../../../components/Context/AuthContext';
import { Box } from '@mui/material';
// import Success from "./Success";
import Confirm from './Confirm';
// import FirstStep from "./FirstStep";
import SecondStep from './SecondStep';
import ImageTypeLivestock from './ImageTypeLivestock';
import ThirdStep from './ThirdStep';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import useStore from '../../../../../store/useStore';
import SuccesGreen from './SuccesGreen';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import FirstStepDate from './FirstStepDate';

export default function MultiStepForm({ type, tipo, isLoading, setIsLoading, handleClose }) {
  // console.log("MultiStepForm - livestock")
  const usestore = useStore();
  const { aux_add, setAuxAdd } = useContext(Context);
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controle de submissão

  const [activeStep, setActiveStep] = useState(0);
  // const [date, setDate] = useState("");
  // const [text, setText] = useState("");
  // const [selection, setSelection] = useState("");
  const property_id = localStorage.getItem('Property') || 1;

  const [track, setTrack] = useState(new Date().getTime());

  const handleNext = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    // Evita clique duplo: só permite continuar se já passou mais de 0.5 segundos
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    // Evita clique duplo: só permite retornar se já passou mais de 0.5 segundos
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const handleDateChange = (event) => {
  //   setDate(event.target.value);
  // };

  // const handleTextChange = (event) => {
  //   setText(event.target.value);
  // };

  // const handleSelectionChange = (event) => {
  //   setSelection(event.target.value);
  // };

  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
  //const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

  // errors.chave define true (property.chave não validada) ou false
  // const [errors, setErrors] = useState({});
  // gaurdar o array de erros de cada chave
  const [formError, setFormError] = useState({});

  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));

  const numberLivestock = usestore.reposLivestock.listLivestock.length + 1;
  const date_start = dayjs();
  const date_prev_end = dayjs().add(1, 'year');
  const anoInitvalue = new Date().getFullYear();
  const nameLivestock = type + ' ' + anoInitvalue + '/' + (anoInitvalue + 1) + ' ' + numberLivestock;

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    nameLivestock: nameLivestock,
    typenameLivestock: type,
    id_tipe: tipo,
    dateEnd: '',
    date_start: dayjs(date_start).format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs(date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
    areas: [],
    usedArea: [],
    measure: 'hectares',
    //id_stock: ""
    //typeCost: "",
    status: '0',
    isDeleted: '0',
    situation: 'ativa',
    observation: '',
  });

  // console.log(data)

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('onSubmit');
    // console.log(isSubmitting);

    setIsSubmitting(true); // Desabilita o botão ao iniciar a submissão

    setFormError({});
    let errors = {};
    let formOk = true;
    // console.log(data);

    if (data.nameLivestock.length < 5) {
      errors.nameLivestock = true;
      formOk = false;
      // } else if (property.address.length < 3) {
    } else if (data.areas.length === 0) {
      errors.area = true;
      formOk = false;
    }

    if (formOk) {
      data.name = data.nameLivestock;
      const areas_bkp = window.jQuery.extend(true, [], data.areas);

      data.areas = data.areas.map((repos) => ({ id: repos.id, area: repos.area, usedArea: repos.usedArea || repos.area }));
      const header = { header: { 'Content-Type': 'application/json' } };
      try {
        const response = await api.post('/livestock/save', data, header);
        if (response.data.isSuccess) {
          toast.success('Sucesso');
          handleNext();
          setTimeout(() => {
            usestore.reposProperty.fetch(userCurrent.id);
            usestore.reposAreas.fetch({ id_farm: property_id });
            usestore.reposLivestock.fetch({ id_farm: property_id }).then(() => {
              setAuxAdd(aux_add + 1);
            });
          }, 8000);
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error(error.response.data);
      } finally {
        //setIsSubmitting(false); // Reabilita o botão após o envio
        data.areas = areas_bkp;
      }
    } else {
      toast.error('Por favor, preencha os campos obrigatórios');
      setFormError(errors);
      setIsSubmitting(false); // Reabilita o botão em caso de erro
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      //case 0: // seleção da data
      //  return <FirstStep data={data} setData={setData} formError={formError} isLoading={isLoading} setIsLoading={setIsLoading} handleClose={handleClose} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} />;
      case 0: // setar o nome da livestock
        return <FirstStepDate data={data} setData={setData} handleNext={handleNext} handleClose={handleClose} activeStep={activeStep} steps={steps} />;

      case 1: // setar o nome da livestock
        return (
          <SecondStep
            data={data}
            setData={setData}
            formError={formError}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleClose={handleClose}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
          />
        );
      case 2: // seleção da área
        return (
          <ThirdStep
            data={data}
            setData={setData}
            formError={formError}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleClose={handleClose}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
          />
        );
      case 3:
        return <Confirm data={data} setData={setData} onSubmit={onSubmit} isSubmitting={isSubmitting} handleNext={handleNext} handleBack={handleBack} handleClose={handleClose} />;
      case 4:
        return <SuccesGreen handleClose={handleClose} />;
      default:
        return 'Não existente';
    }
  };

  return (
    <>
      <Box sx={{ my: 1 }}>{activeStep !== 4 && <ImageTypeLivestock data={data} setData={setData} formError={formError} isLoading={isLoading} setIsLoading={setIsLoading} />}</Box>
      {/* <Stepper activeStep={activeStep} sx={{ py: 3 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
      {getStepContent(activeStep)}
      {/* <Box sx={{ width: '100%', maxWidth: 900 }}>
        {activeStep === steps.length ? (
          // <Success handleClose={handleClose} />
          <SuccesGreen handleClose={handleClose} />
        ) : (
          <>{getStepContent(activeStep)}</>
        )}
      </Box> */}
    </>
  );
}
