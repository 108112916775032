import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import FooterModal from '../../../../../components/Footer/FooterModal';
import FormAccountSelection from '../../Forms/FormAccountSelection';
import FormApportChoice from '../../Forms/FormApportChoice';

const text_title = {
  'Registrar Despesa': 'Por favor, defina uma categoria para organizarmos a despesa e como será apropriada.',
  'Registrar Receita': 'Por favor, defina uma categoria para organizarmos a despesa e como será apropriada.',
  'Conta a receber': 'Por favor, defina o dinheiro previsto para entrar no caixa (venda de produtos ou serviços)',
  'Conta a pagar': 'Por favor, defina as saídas previstas (acertos com fornecedores ou quitação de contas de consumo)',
  Gasto: 'Por favor, defina uma categoria de conta ',

  Compra: '',
  Venda: '',
};

export default function FormEntryStep2Account({ data, obj_fn }) {
  console.log('FormEntryStep3');
  console.log(data);
  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  // const para validar o button continuar
  // não sei proque está vindo financeiro
  const isError = () => {
    let iserror = false;

    if (!data.category || data.category == 'Financeiro') {
      return true;
    }
    if (!data.projects_apport.length) {
      iserror = true;
    }
    return iserror;
  };
  const returnError = () => {
    return false;
  };
  console.log(data);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box>
            <Typography style={{ color: green[800] }}>
              <div className="d-lg-none">
                <Typewriter tam={110} minHeight={0} text={text_title[data.operationType]} />
              </div>
              <div className="d-none d-lg-block">
                <Typewriter tam={60} minHeight={0} text={text_title[data.operationType]} />
              </div>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        {/* Seleção da conta */}
        <FormAccountSelection data={data} obj_fn={obj_fn} handleChange={handleChange} />
      </Box>
      {data.category != 'Financeiro' && (
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography style={{ color: green[800] }}>
                <div className="d-lg-none">
                  <Typewriter tam={60} minHeight={0} text={'Vamos agora definir onde iremos apropriar o valor'} />
                </div>
                <div className="d-none d-lg-block">
                  <Typewriter tam={20} minHeight={0} text={'Vamos agora definir onde iremos apropriar o valor'} />
                </div>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: 0, mb: 3 }}>
            <FormApportChoice data={data} obj_fn={obj_fn} />
          </Grid>
        </Grid>
      )}

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
