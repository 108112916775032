import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, CardMedia, Divider, Grid, Stack, Typography } from '@mui/material';
import { AddBox, ExpandMore, Map } from '@mui/icons-material';
import { green, orange } from '@mui/material/colors';
import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import GenericValueField from '../../../../../components/Fields/GenericValueField';
import { formatValue } from '../../../../../utils/functions';
import DialogRegisterArea from '../../../../areasMaps/components/DialogRegisterArea';
import ProductRegistrar from '../../../../products/components/ProductRegistrar/ProductRegistrar';
import CardProduct from './CardProducts';

export default function FormPlantingStep1({ data, obj_fn }) {
  // const para validar o button continuar
  const isError = () => {
    var valid = false;
    if (!data.areas.length) {
      valid = true;
    } else {
      data.areas?.map((area) => {
        var qtd_prod = area.product?.location_selected?.quanty || 0;
        if (area.product?.packaging_unit !== 'unidade') {
          qtd_prod = (qtd_prod || 0) * (area.product?.volume_pkg || 1);
        }

        if (!area.product?.location_selected?.quant_utilizada) {
          valid = true;
        } else if (qtd_prod < area.product?.location_selected?.quant_utilizada) {
          valid = true;
        }
        return area;
      });
    }
    return valid;
  };
  const returnError = () => {
    return false;
  };

  const getOptAreas = (tipo) => {
    // Monta o array e obj para o campo de projeto
    const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
      ['id', data.id_harvest],
      ['tipo', 'harvest'],
    ]);

    const opt_areas = {
      multiple: true,
      name: 'areas',
      label: 'Áreas da Safra',
      values: optionsAreas,
      field: 'areas',
      variant: 'outlined',
      helperText: 'Selecione a(s) área(s) para o plantio',
      listHeight: 225,
    };

    if (tipo) {
      const allAreas_aux = obj_fn.usestore.reposAreas.getAreaMapStore('id_property', data.id_property);
      const listAreasProp = allAreas_aux.filter((area) => {
        var is_in = false;
        for (const areaH of optionsAreas) {
          if (areaH.id === area.id) {
            is_in = true;
            break;
          }
        }
        area.assign = true;
        return !is_in;
      });
      const opt_areas_prop = {
        multiple: true,
        name: 'areas_p',
        label: 'Áreas da Propriedade',
        values: listAreasProp,
        field: 'areas',
        variant: 'outlined',
        helperText: (
          <Typography style={{ color: orange[500] }} color="success" variant="subtitle1">
            Ao utilizar uma destas áreas, ela será automaticamente vinculada à safra atual!
          </Typography>
        ),
        listHeight: 225,
      };

      return opt_areas_prop;
    }

    return opt_areas;
  };

  const handleChangeArea = (field, newValue) => {
    // console.log('handleChangeArea');
    if (newValue && newValue.length > 0) {
      newValue.map((area) => {
        if (!area.product) {
          area.product = null;
        }
        return area;
      });
    }
    obj_fn.setData((prevState) => ({ ...prevState, [field]: newValue }));
  };

  const handleChangeInput = (e, area) => {
    // console.log('handleChangeInput');
    const usedArea = e.target.value ? parseFloat(e.target.value) : '';

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          return { ...area_prev, usedArea };
        }
        return area_prev;
      }),
    }));
  };

  useEffect(() => {
    getOptAreas();
  }, [obj_fn.aux_add]);

  const handleAssign = (area) => {
    setTimeout(() => {
      const newArea = obj_fn.usestore.reposAreas.getAreaMapStore('id', area.id)[0];

      let aux_areas = data.areas.concat(newArea);
      handleChangeArea('areas', aux_areas);
    }, 300);
  };

  const filters_prods = [
    ['group_category', 'Sementes'],
    ['group_category', 'Mudas'],
  ];
  const ismobile = obj_fn.isMobile;

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Accordion sx={{ ml: -1, mb: 1, width: '100%' }}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Cadastros auxiliares">
            <Typography fontWeight="500" variant="subtitle2" style={{ color: green[800] }}>
              <AddBox sx={{ mr: 1 }} />
              <strong>Cadastros auxiliares para realização do plantio.</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} sx={{ mt: -2, mb: 1 }} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={7}>
                <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
                  É possivel desenhar novas áreas clicando no botão {ismobile ? 'a baixo' : 'ao lado'}
                </Typography>
                <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
                  A nova área será automaticamente vinculada à safra atual!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DialogRegisterArea setData={handleAssign} assign={data.project} />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1, width: '90%', borderColor: green[100] }} />

            <Grid container spacing={2} sx={{ mt: -1 }} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={7}>
                <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
                  É possivel cadastrar novos produtos clicando no botão {ismobile ? 'a baixo' : 'ao lado'}
                </Typography>
                <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
                  Lembre-se de adicionar o estoque para poder utilizá-lo
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ProductRegistrar filters={filters_prods} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) para plantio</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Grid item xs={12} sm={6}>
            <AutocompleteGeneric value={data.areas.filter((area) => !area.assign)} options={getOptAreas()} handleChange={handleChangeArea} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutocompleteGeneric value={data.areas.filter((area) => area.assign)} options={getOptAreas('propriedade')} handleChange={handleChangeArea} />
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 2, mt: 0 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sm={12} sx={{ mt: -1, mb: 1, display: ismobile ? 'block' : 'flex' }}>
                <Grid item key={'G2_' + area.id} xs={ismobile ? 12 : 3} sm={ismobile ? 12 : 3} sx={{ mb: 2, display: ismobile ? 'flex' : 'block' }}>
                  <Grid item key={'G3_' + area.id} xs={ismobile ? 8 : 12} sm={ismobile ? 8 : 12} sx={{ mb: ismobile ? 0 : 1 }}>
                    <Grid item key={'G4_' + area.id} xs={12} sm={12} sx={{ mt: -1, mb: 2 }}>
                      <Typography style={{ fontSize: 'larger', fontWeight: 700, color: 'green' }}>{area.name}</Typography>
                      <Typography variant="subtitle2" align="left">
                        <i className="fa fa-map text-success">
                          {' '}
                          {formatValue(area.area)} ({area.measure})
                        </i>
                      </Typography>
                    </Grid>
                    <Grid item key={'G5_' + area.id} xs={12} sm={12}>
                      <GenericValueField
                        handleChange={(e) => handleChangeInput(e, area)}
                        options={{
                          id: area.id + '',
                          name: area.name,
                          label: 'Área utilizada',
                          value: area.usedArea,
                          helperText: (
                            <>
                              <span style={{ color: 'green' }}>Área utilizada no plantio</span>
                            </>
                          ),
                          color: 'success',
                          max: area.area,
                          width: '90%',
                          endAdornment: true,
                          prefix: ' (' + (area.measure || 'ha') + ')',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item key={'G6_' + area.id} xs={ismobile ? 4 : 11} sm={ismobile ? 4 : 11}>
                    <CardMedia
                      key={'CM_' + area.id}
                      component="img"
                      image={area.url}
                      alt={'Área: ' + area.name}
                      title={'Área: ' + area.name}
                      sx={{
                        // height: ismobile ? '80px' : '100%',
                        transition: 'transform 0.15s ease-in-out',
                        objectFit: 'contain', // Mostra a imagem completa
                        '&:hover': {
                          transform: ismobile ? 'scale(1.5) translateX(-15%) translateY(10%)' : 'scale(1.5) translateX(15%) translateY(-10%)',
                          position: 'relative',
                          zIndex: 3,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item key={'G7_' + area.id} xs={ismobile ? 12 : 9} sm={ismobile ? 12 : 9} sx={{ ml: 1 }}>
                  <CardProduct data={data} obj_fn={obj_fn} area={area} />
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrição */}
        {/* <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: green[800] }}>
                {' '}
                Descrição geral do plantio
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para o plantio"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid> */}

        <Grid item xs={12} sm={12}>
          <Grid sx={{ mt: 1, ml: 1, display: 'flex', justifyContent: 'center' }}>
            <Stack spacing={0.5} align="center">
              <Typography fontWeight={700} style={{ color: orange[500] }}>
                {!data.areas.length ? 'Adicione pelo menos uma área da safra para o plantio!' : 'Adicione um produto para realizar o plantio na(s) área(s) selecionada(s).'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
