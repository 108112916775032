import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import api from '../../services/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';

function processData(activityApplications) {
  return {
    id: activityApplications.id,
    id_property: activityApplications.id_property,
    id_user: activityApplications.id_user,
    name_user: activityApplications.name_user,
    id_harvest: activityApplications.id_harvest,
    id_monitoring: activityApplications.id_monitoring,
    areas: JSON.parse(activityApplications.areas),
    /* produto utilizado por área
      areas.map((area) => (
        area.id_area
        area.name
        area.id_product
        area.id_patrimony
        area.name_product
        area.unit
        area.quant
        area.value
        area.usedArea
    */
    name_product: JSON.parse(activityApplications.areas)[0].name_product,
    date_start: dayjs(activityApplications.date_start).format('YYYY-MM-DD HH:mm:ss'),
    date_start_f: dayjs(activityApplications.date_start).format('DD/MM/YYYY'),
    date_prev_end: dayjs(activityApplications.date_prev_end).format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end_f: dayjs(activityApplications.date_prev_end).format('DD/MM/YYYY'),
    date_end: activityApplications.date_end ? dayjs(activityApplications.date_end).format('YYYY-MM-DD HH:mm:ss') : '',
    date_end_f: activityApplications.date_end ? dayjs(activityApplications.date_end).format('DD/MM/YYYY') : '',
    description: activityApplications.description,
    isDeleted: activityApplications.isDeleted,
    info_areas: JSON.parse(activityApplications.info),
    id_transaction: activityApplications.id_transaction,
    info_transaction: activityApplications.info_transaction ? JSON.parse(activityApplications.info_transaction) : '',
    record: activityApplications.record ? JSON.parse(activityApplications.record) : '',

    type: 'activity',
    activity: activityApplications.name,
    name_activity: activityApplications.name,
    progress: activityApplications.progress || 'CONCLUÍDA',
    planning: activityApplications.planning || 'realized',
    tipo_repos: 'APLICAÇÃO',
  };
}

// create the store
const useActivityApplicationsReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listActivityApplications: [],
        fetch: async (item) => {
          var id_property = localStorage.getItem('Property') || 1;
          if (!item) {
            item = { id_property: id_property };
          } else if (!item.id_property) {
            item.id_property = id_property;
          }
          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('activityApplications/list', item, header);
          // console.log('activityApplications')
          // console.log(data)
          const data = await response.data;
          const data_v = data._value || [];

          await set({
            listActivityApplications: data_v.map((activityApplications) => {
              return processData(activityApplications);
            }),
          })

          const lista = JSON.parse(localStorage.getItem('listActvsH'));
          const lista_i = get().listActivityApplications;

          let array_aux = lista.filter((activity) => {
            return !/APLICAÇÃO.*/.test(activity.activity);
          })
          const res = array_aux.concat(lista_i);
          localStorage.setItem('listActvsH', JSON.stringify(res));

          return data;
        },
        // pega o(s) plantio(s) da listActivityApplications de acordo com o [key e val] informados
        getActivityApplicationsStore: (key, val) => {
          const list = get().listActivityApplications.filter((activityApplications) => {
            return activityApplications[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // // Adiciona um nov plantio à listActivityApplications
        addActivityApplicationsStore: (plantio) => {
          set((state) => ({
            listActivityApplications: [...state.listActivityApplications, plantio],
          }))
        },
        // Gera uma nova listActivityApplications sem a activity informada
        delPlantingStore: (id) => {
          set({
            listActivityApplications: get().listActivityApplications.filter((activity) => {
              return activity.id != id;
            }),
          })
        },
        // atualiza listActivityApplications de acordo com id, key e val informados
        updateActivityApplicationsStore: (id, key, val) => {
          set({
            listActivityApplications: get().listActivityApplications.map((activity) => {
              if (activity.id === id) {
                if (key == 'all') {
                  // atualiza todos os dados
                  activity = val;
                } else {
                  activity[key] = val;
                }
              }
              return activity;
            }),
          })
          return JSON.parse(JSON.stringify(get().listActivityApplications));
        },
        // consulta os lançamentos do ano informado
        fetchFiltered: async (filters) => {
          var id_property = localStorage.getItem('Property') || 1;
          if (!filters) {
            filters = {};
          }
          if (!filters.filter) {
            filters.filter = true;
          }
          if (!filters.id_property) {
            filters.id_property = id_property;
          }
          // carrega dados do ano
          if (!filters.between) {
            filters.date = 'ap.date_start';
            filters.dateI = dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss');
            filters.dateF = dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss');
          }

          const header = { header: { 'Content-Type': 'application/json' } };
          const response = await api.post('activityApplications/list', filters, header);
          // console.log('activityApplications')
          // console.log(data_v)
          const data = await response.data;
          const data_v = data._value || [];

          var listActivityApplications = data_v.map((activityApplications) => {
            return processData(activityApplications);
          })

          return listActivityApplications;
        },
      }),
      { name: 'reposActivityApplications' }
    )
  )
)

export default useActivityApplicationsReposStore
