import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import WidgetsIcon from '@mui/icons-material/Widgets'
import ButtonListStockAccordion from './ButtonListStockAccordion'

export default function ButtonListStock({ openModal, setOpenModal }) {
  // ativar para abrir quando iniciar
  //const [open, setOpen] = React.useState(openModal);
  const [open, setOpen] = React.useState(openModal || false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    if (setOpenModal) {
      setOpenModal(false)
    }
  }

  return (
    <div>
      <Button variant="text" color="success" onClick={(e) => handleClickOpen(e)} title='Movimentações no estoque'>
        <WidgetsIcon />
      </Button>
      <Dialog maxWidth={'lg'} open={open || openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <>
            <WidgetsIcon /> Movimentações no estoque
          </>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Com o registro de seus produtos (insumos) e de seus fornecedores, fica fácil ter um controle de estoque eficiente, a qual envolve o monitoramento de entradas, saídas e movimentações de
            produtos, assegurando que haja sempre um equilíbrio entre a oferta e a demanda.
          </DialogContentText> */}
          {/* <Box sx={{ mt: 2, mb: 2 }}>
            <Typography paragraph variant="subtitile1" fontWeight={500}>
              Veja a seguir o seu estoque:
            </Typography>
          </Box> */}
          <ButtonListStockAccordion />
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
