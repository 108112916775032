import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardHeader, Container, Divider, Grid, Paper, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { Span } from '../../../../components/Typography';
import MenuPlan from '../../../signature/components/MenuPlan';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import AddActivities from '../../../activitiesHarvest/components/AddButtonActivities/AddActivities';
import { useState } from 'react';

export default function TablesActivitiesHarvestTrashEmpty({ isLoadingActivities, setIsLoadingActivities }) {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return (
    <>
      {isLoading === true ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </>
      ) : (
        <>
          <Card sx={{ m: 2 }}>
            <CardHeader
              title={
                <Box sx={{ display: 'flex' }}>
                  <Typography style={{ color: '#2e7d32' }}>
                    <Typewriter tam={30} minHeight={0} text="Sua Lixeira encontra-se vazia." />
                  </Typography>
                </Box>
              }
            ></CardHeader>
            <Divider />
          </Card>
          <Card sx={{ m: 0 }}>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
              <Paper variant="" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 1 } }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Grid container xs={12} md={12} item alignItems="center" justifyContent="center">
                    <img src="/dist/image/trash.png" name="" style={{ width: '200px', height: '200px' }} />
                  </Grid>
                  <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
                    Lixeira vazia
                  </Typography>
                  {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    No Fazenda Premium você pode realizar a gestão da sua propriedade registrando as atividade do dia a dia ou planejando atividades futuras.
                  </Typography>
                  <Grid container xs={12} md={12} sx={{ mt: 2 }} item alignItems="center" justifyContent="center">
                    <AddActivities isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
                  </Grid> */}
                </Box>
              </Paper>
            </Container>
          </Card>
        </>
      )}
    </>
  );
}

//https://aegro.com.br/termos-de-uso/
