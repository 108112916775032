import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Grid, LinearProgress, MenuItem, Skeleton, TextField } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import html2canvas from 'html2canvas';
import LZString from 'lz-string';
// import SuccessDraw from '../successDraw/SuccessDraw';
import api from '../../../../services/api';
import { Context } from '../../../../components/Context/AuthContext';
import useStore from '../../../../store/useStore';
import SuccessDrawGreen from '../successDraw/SuccessDrawGreen';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import { base64ToBlob } from '../../../../components/Image/base64ToBlob';

const currencies = [
  {
    value: 'ha',
    label: 'hectare (ha)',
  },
  {
    value: 'm2',
    label: 'metros (m²)',
  },
];

const formatNumber = (number) => {
  let number_f = number;
  if (/\,/.test(number)) {
    number_f = number.replace(/\./g, '');
    number_f = number_f.replace(',', '.');
  }
  return parseFloat(number_f || '0');
};

export default function FormSaveMapButton({ handleClose, path, area, removeAllPolygon, container }) {
  const usestore = useStore();
  const { aux_add, setAuxAdd } = useContext(Context);
  // console.log(area);

  const [isLoading, setIsLoading] = useState(false);
  const [LoadingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    setLoadingButton(false);
  }, []);

  let encoded = base64_encode(JSON.stringify(path));
  //console.log(encoded);
  //https://developers.google.com/maps/documentation/utilities/polylinealgorithm?hl=pt-br
  // var encodeda = base64.encode(JSON.stringify(path));
  // let decodeda = base64.decode(encodeda);

  const [image, setImage] = useState({ imagem: '' });
  // const [file, setFile] = useState({
  //   name: 'filename.jpg',
  // });

  const [imageMap, setImageMap] = useState('');
  const [areaCircle, setAreaCircle] = useState(parseFloat(area).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

  const property_id = localStorage.getItem('Property') || 1;
  const userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const nameArea = `Área ${usestore.reposAreas.listAreasMap.length + 1}`;

  const [areaMap, setAreaMap] = useState({
    id_property: property_id,
    id_user: userCurrent.id,
    name: nameArea,
    area: areaCircle,
    measure: 'ha',
    coordinates: encoded,
    zoom: 15,
    address: '',
    description: '',
    image: '',
  });

  useEffect(() => {
    // console.log('useEffect');
    if (path != '') {
      handlePolygonChange(path);
    }
  }, [areaMap.measure]);

  const calculatePolygonArea = (coordinates, measure) => {
    const areaGoogle = window.google.maps.geometry.spherical.computeArea(coordinates);

    if (measure === 'm2') {
      const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedArea;
    } else if (measure === 'ha') {
      const areaHectares = areaGoogle * 0.0001; // 1 hectare = 10.000 metros quadrados
      const formattedAreaEmHectares = areaHectares.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmHectares;
    } else if (measure === 'acres') {
      const areaAcres = areaGoogle * 0.000247105; // 1 acre = 0.000247105 acres
      const formattedAreaEmAcres = areaAcres.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmAcres;
    }
    //console.log(areaGoogle)
    return areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const calculateCircule = (areaCicle, measure) => {
    // console.log('calculateCircule');
    // console.log(areaCicle);
    const numeroSemPontos = areaCicle.replace(/\./g, '');
    const numeroTransformado = numeroSemPontos.replace(',', '.');
    const areaGoogle = numeroTransformado;
    // console.log('areaGoogle');
    // console.log(areaGoogle);

    if (measure === 'm2') {
      const formattedArea = areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedArea;
    } else if (measure === 'ha') {
      const areaHectares = areaGoogle * 0.0001; // 1 hectare = 10.000 metros quadrados
      const formattedAreaEmHectares = areaHectares.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmHectares;
    } else if (measure === 'acres') {
      const areaAcres = areaGoogle * 0.000247105; // 1 acre = 0.000247105 acres
      const formattedAreaEmAcres = areaAcres.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedAreaEmAcres;
    }
    //console.log(areaGoogle)
    return areaGoogle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handlePolygonChange = (path) => {
    // console.log('handlePolygonChange');
    const { area, measure } = areaMap;
    const calculatedArea = calculatePolygonArea(path, measure);
    //console.log('entrou')
    //console.log(area)

    setAreaMap((prevState) => ({
      ...prevState,
      area: calculatedArea,
    }));
  };

  const handleChangeMeasure = (event) => {
    // console.log('handleChangeMeasure');
    const newMeasure = event.target.value;
    //console.log(newMeasure)
    //console.log(path, areaMap.area)
    let calculatedArea = '';
    if (path.length > 0) {
      calculatedArea = calculatePolygonArea(path, newMeasure);
    } else {
      //console.log(areaCircle)
      calculatedArea = calculateCircule(areaCircle, newMeasure);
    }

    // Atualiza a medida e a área calculada
    setAreaMap((prevState) => ({
      ...prevState,
      measure: newMeasure,
      area: calculatedArea,
    }));
  };

  const handleChange = (event, tipo) => {
    event.persist();
    var value = tipo ? event.target.value.trim() : event.target.value;

    if (event.target.name == 'name') {
      validateName(value);
    } else if (event.target.name == 'area') {
      value = parseFloat(event.target.value || '0');
      setFormError({ ...formError, [event.target.name]: value });
    }

    setAreaMap({ ...areaMap, [event.target.name]: value });
  };

  // para validar o input name
  const validateName = (value) => {
    var name_area = value.trim();
    if (!name_area || name_area.length < 5) {
      setFormError({ ...formError, name: 'Por favor, insira um nome válido para sua área!' });
    } else {
      var list_areas = usestore.reposAreas.getAreaMapStore('name', name_area);
      if (list_areas.length > 0) {
        var compare_area = list_areas.filter((area_repos) => {
          if (area_repos.id != areaMap.id) {
            return area_repos;
          }
        });
        if (compare_area.length > 0) {
          setFormError({ ...formError, name: 'Já existe outra área com esse nome!' });
        }
      } else {
        setFormError({ ...formError, name: '' });
      }
    }
  };

  // ipload de imagesn https://www.youtube.com/watch?v=kBSckls-Ih4
  //https://makitweb.com/take-screenshot-of-webpage-with-html2canvas/
  //Crie uma imagem a partir de um elemento dom
  //https://medium.com/@minhquocece/use-html2canvas-to-capture-google-map-instead-of-google-static-maps-api-with-angular-7-2ed415a46ba3
  const captureImage = () => {
    //Para renderizar um elementcom html2canvas, basta chamar: html2canvas(element[, options]);
    //Método de chamada  html2canvas()que recebe o seletor como parâmetro e com resposta de then manipulação .canvas
    // função retorna uma promessa contendo o <canvas>elemento.
    //com backgroundColor: null definirá o plano de fundo da tela como transparente;
    //useCORS: verdadeiro. Para aceitar imagens do servidor do Google para desenhar a tela.
    // html2canvas(document.body).then(function (canvas) {
    var element = document.getElementById('map');
    html2canvas(element, {
      backgroundColor: null,
      useCORS: true,
    }).then(function (canvas) {
      //var a = document.createElement('a')
      //console.log(a)
      //canvas.toDataURL() método retorna o valor base64 que você pode usar para criar uma fonte de imagem ou um arquivo de imagem.
      var scale = 0.5; // Define a escala desejada (por exemplo, 0.5 para reduzir pela metade)
      var width = canvas.width * scale; // Calcula a nova largura proporcionalmente
      var height = canvas.height * scale; // Calcula a nova altura proporcionalmente
      var scaledCanvas = document.createElement('canvas'); // Cria um novo canvas escalado
      scaledCanvas.width = width;
      scaledCanvas.height = height;

      var scaledContext = scaledCanvas.getContext('2d');
      scaledContext.drawImage(canvas, 0, 0, width, height); // Desenha a imagem original no novo canvas escalado

      // blob
      var base64image = scaledCanvas.toDataURL('image/jpeg', 0.8);

      const blobAreaTranferImage = base64ToBlob(base64image, 'image/jpeg');
      // console.log(blobAreaTranferImage); // O objeto Blob resultante

      setAreaMap((prevState) => ({
        ...prevState,
        imageBlob: blobAreaTranferImage,
      }));

      // Salva a URL da imagem em uma variável
      //limit: 102400
      setImageMap(base64image);
      const compressedImagebase64 = LZString.compressToBase64(base64image);
      setAreaMap((prevState) => ({
        ...prevState,
        image: compressedImagebase64,
      }));
      //console.log("areaMap.image")
      //console.log(areaMap.image)
      setImage({ imagem: areaMap.image });

      //base64image.replace('image/jpeg', 'image/octet-stream');
      //a.href = base64image
      //a.download = file.name
      //console.log(a)
      //a.click()
      //save();

      // Obtém base64URL
      //const formData = { imagem: base64image }
      // enviar para back-end
      // await api.post("/upload-image", formData)

      //document.body.appendChild(canvas) para anexar uma nova tela ao corpo
      // document.body.appendChild(canvas);
    });

    // html2canvas(element).then(function (canvas) {
    //   var image = canvas.toDataURL("image/png");
    //   var pHtml = "<img src=" + image + " />";
    //   return pHtml
    // });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(true);
  //   }, 2000);
  //   console.log("carregou");
  // }, []);

  useEffect(() => {
    removeAllPolygon();
    setTimeout(() => {
      captureImage();
    }, 100);
  }, []);

  const onSubmit = async () => {
    // console.log('entrou no save onSubmit');
    save();
  };

  //const [formError, setFormError] = useState({});
  const [formError, setFormError] = useState({ name: '', area: areaMap.area });

  // console.log(areaMap);

  async function save() {
    let formOk = true;

    if (!areaMap.name) {
      formOk = false;
    } else if (!areaMap.area) {
      formOk = false;
    }

    let aux_assign = localStorage.getItem('assignProject');
    if (aux_assign) {
      aux_assign = typeof aux_assign === 'string' ? JSON.parse(aux_assign) : aux_assign;
    }
    let assign = aux_assign || { id: false };

    if (assign.id) {
      areaMap.assignProject = assign;
    }

    // Salvar os dados da área na tabela areamap e obter o ID da área.
    // Usar o ID da área para salvar a imagen na aws e na tabela imagem.
    // Dentro do front upload, salvar a URL na tabela areamap.

    if (formOk) {
      setLoadingButton(true);
      const header = { header: { 'Content-Type': 'application/json' } };
      await api
        .post('/areamap/saveMaparea', areaMap, header)
        .then((response) => {
          console.log(response.data);
          if (response.data.isSuccess) {
            // console.log('response.dataok');
            console.log(response.data);
            // console.log(response.data._value.insertId);
            const id = response.data._value.insertId;
            uploadImage(id);
            // console.log('testesetTimeout1');
            //   usestore.reposProperty.fetchAreasProperty().then((res1) => {
            //     usestore.reposAreas.fetch({ id_farm: property_id }).then((res) => {
            //       setTimeout(() => {
            //         setAuxAdd(aux_add + 1);
            //         handleClose();
            //         if (container) {
            //           container();
            //         } else {
            //           setIsLoading(true);
            //         }
            //       }, 200);
            //     });
            //   });
          }
          //else {
          //   toast.error('Falha ao salvar Área tente novamente mais tarde! Caso o erro persista, contate o suporte.');
          // }
        })
        .catch((error) => {
          console.error('Erro ao salvar área:', error);
          // toast.error('Falha ao salvar Área, tente novamente mais tarde! Caso o erro persista, contate o suporte.');
          if (error.response && error.response.data.message) {
            const errorMessage = error.response.data.message.message;
            if (errorMessage.includes('Ops. O nome') && errorMessage.includes('já está associado a uma área existente')) {
              toast.error('O nome fornecido já está associado a uma área existente. Por favor, escolha outro nome.');
            } else {
              toast.error('Falha ao salvar a área. Tente novamente mais tarde.');
            }
          } else {
            // Tratamento genérico para erros inesperados
            toast.error('Erro inesperado. Tente novamente mais tarde.');
          }
          setLoadingButton(false);
        });
    } else {
      // escreve mensagem de erro para cliente
      toast.error('Por favor, preencha os campos obrigatórios');
    }
  }

  //  const [item, setItem] = useState({
  //   id: 1,
  //   tipo_item: 'album',
  //   id_farm: 1,
  // });

  async function uploadImage(id) {
    // console.log('uploadImage');
    // toast.error(`test ${id}`);
    var id_farm = localStorage.getItem('Property') || 1;
    const fd = new FormData();
    fd.append('image', areaMap.imageBlob, `${nameArea}.png`);
    //const tipo_item = 'area';
    fd.append('item_type', 'area' || '');
    fd.append('item_id', id || 0);
    fd.append('id_farm', id_farm || 1);

    try {
      const response = await api.post('upload', fd);
      if (response.data.isSuccess) {
        toast.success('Imagem enviada para Amazon');
        var farm_id = localStorage.getItem('Property');
        usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
          usestore.reposAreas.fetch({ id_farm: property_id }).then((res) => {
            setAuxAdd(aux_add + 1);
            setTimeout(() => {
              // console.log('testesetTimeout2');
              setLoadingButton(false);
              if (container) {
                container(id);
                handleClose();
              } else {
                setIsLoading(true);
              }
            }, 300);
          });
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar na Amazon');
      setLoadingButton(false);
    }
  }

  // async function updateBloB(id) {
  //   console.log('uploadImage');
  //   toast.error(`test ${id}`);
  //   var id_farm = localStorage.getItem('Property') || 1;
  //   const fd = new FormData();
  //   fd.append('image', areaMap.imageBlob, `${nameArea}.png`);

  //   try {
  //     const header = { header: { 'Content-Type': 'application/json' } };
  //     const response = await api.post('/areamap/updateImageBlob', areaMap, header).then((response) => {
  //       if (response.data.isSuccess) {
  //         var farm_id = localStorage.getItem('Property');
  //         usestore.reposImages.fetch({ id_farm: farm_id }).then((res) => {
  //           setAuxAdd(aux_add + 1);
  //         });
  //       }
  //     });
  //   } catch (error) {
  //     toast.error('Erro ao salvar na Amazon');
  //   }
  // }

  //isLoading
  // true
  // false
  return (
    <>
      {isLoading && <SuccessDrawGreen />}
      <Box onSubmit={(e) => onSubmit(e)} sx={{ p: 2, mt: 2 }}>
        <Card>
          {!imageMap ? (
            <>
              <Box height={140}>
                <LinearProgress color="success" />
                <Skeleton variant="rectangular" width={'100%'} height={136} />
              </Box>
            </>
          ) : (
            <CardMedia component="img" height="140" image={imageMap} />
          )}

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  required
                  id="name"
                  name="name"
                  label="Nome da área"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  color="success"
                  value={areaMap.name}
                  onChange={handleChange}
                  onBlur={(e) => handleChange(e, 'name')}
                  error={formError.name != ''}
                  helperText={formError.name != '' ? formError.name + ' Mínimo 5 letras!' : '*Insira o nome da área'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="area"
                  name="area"
                  label="Dimensão"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  color="success"
                  value={formatNumber(areaMap.area)}
                  onChange={handleChange}
                  type="number"
                  inputProps={{
                    inputMode: 'decimal',
                    pattern: '[0-9]*',
                  }}
                  error={!formError.area}
                  helperText={!formError.area ? 'Por favor, preencha o campo obrigatório de dimensão!' : 'Tamanho da área'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ style: { color: '#2e7d32' } }}
                  id="measure"
                  name="measure"
                  label="Medida"
                  select
                  variant="standard"
                  color="success"
                  value={areaMap.measure}
                  onChange={handleChangeMeasure}
                  helperText="Unidade de medida"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              sx={{ width: 120, ml: 1, ml: 'auto', animation: `${expandAndFade} 2s linear infinite` }}
              color="success"
              variant="contained"
              type="submit"
              onClick={onSubmit}
              //disabled={!imageMap}
              //disabled={LoadingButton}
              disabled={!imageMap || LoadingButton}
            >
              {LoadingButton ? (
                <CircularProgress sx={{ mb: 0 }} size="1.5rem" style={{ color: '#fff' }} />
              ) : (
                <>
                  <SaveAltIcon sx={{ mr: 1 }} />
                  Salvar
                </>
              )}
            </Button>
            {/* <Button sx={{ ml: 1, ml: "auto" }} color="success" variant="contained" onClick={onSubmit} disabled={!imageMap}>
              <SaveAltIcon sx={{ mr: 1 }} />
              Salvar
            </Button> */}
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
