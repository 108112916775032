export function createNotificationMessenger(messengerProgress) {
  //console.log('Entrando em createNotificationMessenger');
  //console.log('Dados de messengerProgress:', messengerProgress);

  // Filtra as mensagens para mostrar apenas aquelas com status 0 (Resolver) ou 2 (Parado)
  const notifications = messengerProgress
    .filter(({ status }) => status.status === 'Parado' || status.status === 'Resolver')
    .map(({ status, id }) => {
      //console.log(status);

      // Verifica campos incompletos
      const incompleteFields = Object.entries(status)
        .filter(([_, value]) => value === 'Incompleto' || value === 'Faltando' || value === 'Parado' || value === 'Resolver') // Ajustado para incluir "Parado" ou "Resolver"
        .map(([key]) => key);

      // Verifica campos completos
      const completeFields = Object.entries(status)
        .filter(([_, value]) => value === 'Completo' || value === 'Ok')
        .map(([key]) => key);

      const titleText = `Solicitação: ${id}`;
      //console.log(titleText);

      // Customizar mensagens baseadas no progresso
      const progressMessage = incompleteFields.length > 0 ? 'Resolvido.' : 'Aguardando';
      const actionText = 'Clique aqui para resolver.';

      // Substitua 'status' pelo valor correto da propriedade do objeto de status (como 'Parado' ou 'Resolver')
      const statusText = status.status === 'Parado' ? 'Parado' : status.status === 'Resolver' ? 'Resolver' : 'Desconhecido';

      // Adicionando status.name e status.title ao texto
      const text = `${titleText}\nNome: ${status.name}\nTítulo: ${status.title}\nStatus: ${statusText}\n${actionText}`;

      // console.log(text);

      return {
        group: 'Solicitações',
        type: incompleteFields.length > 0 ? 'warning' : 'success', // Determinar tipo baseado nos campos pendentes
        title: `Notificação para a solicitação ID: ${id}`,
        text,
        link: `/messenger`,
      };
    });

  const hasIncompleteMessenger = notifications.some((n) => n.type === 'warning');

  //console.log('Notificações geradas:', notifications);
  //console.log('Existem mensagens incompletas?', hasIncompleteMessenger);

  return { notifications, hasIncompleteMessenger };
}
