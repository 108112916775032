import { Avatar, Divider, Grid } from '@mui/material';
import React from 'react';
import AvatarFp from '../../components/Avatar/AvatarFp';
import SimpleTable from '../../components/Tables/SimpleTable';
import { CardContent, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { green, red } from '@mui/material/colors';
import { Box, Stack } from '@mui/system';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import IEProgressBars from './components/IEProgressBars';
import LogoNameWhite from '../../assets/png/logo-name-white.png';
import { useState } from 'react';
import CardIR from './components/CardIR';
import useStore from '../../store/useStore';
import { useEffect } from 'react';
import propertySettingsProgress from './components/propertySettingsProgress';
import propertyProgress from './components/propertyProgress';
import livestockProgress from './components/livestockProgress';
import harvestProgress from './components/harvestProgress';
import CardIRHarvest from './components/CardIRHarvest';
import CardIRProperty from './components/CardIRProperty';
import CardIRLivestock from './components/CardIRLivestock';
import areaProgress from './components/areaProgress';
import CircularStaticIE from './components/CircularStaticIE';

export default function IndexExperienceContent() {
  const usestore = useStore();
  const [bar, setBar] = useState(50);

  const property = usestore.reposProperty.listProperty;
  const livestock = usestore.reposLivestock.listLivestock;
  const harvest = usestore.reposHarvest.listHarvest;
  var id_property = localStorage.getItem('Property') || 1;
  console.log(id_property);
  const area = usestore.reposAreas.getAreaMapStore('id_property', id_property);
  const products_percentage = usestore.reposProducts.listProducts.length;
  const patrimony_percentage = usestore.reposPatrimony.getPatrimonyStore('id', usestore.reposPatrimony.patrimonyRepos.id).length;
  const finances_percentage = usestore.reposFinances.getTransactionStore('id_property', Number(id_property)).length;
  var listActvs = JSON.parse(localStorage.getItem('listActvs'));
  // Verifica se o item existe
  var activitiesLivestock_percentage = 0;
  if (listActvs) {
    // Calcula o comprimento da string armazenada
    activitiesLivestock_percentage = listActvs.length;
  } else {
    // Caso o item não exista, trata a situação
    console.log("O item 'listActvs' não existe no localStorage.");
  }
  const activitiesCostLivestock_percentage = usestore.reposCostProduction.getList('livestock').length;
  console.log(usestore.reposCostProduction.listCostProduction);
  const activitiesCostHarvest_percentage = usestore.reposCostProduction.getList('harvest').length;
  var listActvsH = JSON.parse(localStorage.getItem('listActvsH'));
  // Verifica se o item existe
  var activitiesHarvest_percentage = 0;
  if (listActvsH) {
    // Calcula o comprimento da string armazenada
    activitiesHarvest_percentage = listActvsH.length;
  } else {
    // Caso o item não exista, trata a situação
    console.log("O item 'listActvs' não existe no localStorage.");
  }
  const buy_percentage = usestore.reposBuys.getBuyStore().length;
  console.log(buy_percentage);
  const sale_percentage = usestore.reposSales.getSaleStore().length;
  console.log(sale_percentage);

  const calcularPorcentagem = (num, num_max, num_min) => {
    if (num_max <= num_min) {
      throw new Error('O valor máximo deve ser maior que o valor mínimo.');
    }
    const porcentagem = (num / num_max) * 100;
    const porcentagemMin = (num_min / num_max) * 100;

    return {
      num,
      porcentagem,
      porcentagemMin,
    };
  };

  //console.log(areaProgress(area))

  const initialData = [
    { module: 'settings', num: propertySettingsProgress(property).progress, numMin: 0, numMax: 100 },
    { module: 'property', num: propertyProgress(property).progress, numMin: 0, numMax: 100 },
    { module: 'livestock', num: livestockProgress(livestock).progress, numMin: 0, numMax: 100 },
    { module: 'harvest', num: harvestProgress(harvest).progress, numMin: 0, numMax: 100 },
    { module: 'area', num: areaProgress(area).progress, numMin: 0, numMax: 100 },
    { module: 'product', num: products_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'patrimony', num: patrimony_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'financial', num: finances_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'activitiesLivestock', num: activitiesLivestock_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'activitiesCostLivestock', num: activitiesCostLivestock_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'activitiesHarvest', num: activitiesHarvest_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'activitiesCostHarvest', num: activitiesCostHarvest_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'buy', num: buy_percentage * 10, numMin: 0, numMax: 100 },
    { module: 'sale', num: sale_percentage * 10, numMin: 0, numMax: 100 },
  ];

  const data = initialData.map((item) => {
    const percentageData = calcularPorcentagem(item.num, item.numMax, item.numMin);
    return {
      module: item.module,
      num: percentageData.num,
      porcentagem: Math.round(percentageData.porcentagem),
      porcentagemMin: Math.round(percentageData.porcentagemMin),
    };
  });

  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(data);
  }, []);

  console.log('newData1');
  console.log(newData);

  const module = [
    { stock: newData[0]?.porcentagem || 0 },
    { livestock: newData[2]?.porcentagem },
    { harvest: newData[3]?.porcentagem },
    { product: newData[5]?.porcentagem || 0 },
    { patrimony: newData[6]?.porcentagem || 0 },
    { financial: newData[7]?.porcentagem || 0 },
    { activitiesLivestock: newData[8]?.porcentagem || 0 },
    { activitiesCostLivestock: newData[9]?.porcentagem || 0 },
    { activitiesHarvest: newData[10]?.porcentagem || 0 },
    { activitiesCostHarvest: newData[11]?.porcentagem || 0 },
    { buy: newData[12]?.porcentagem || 0 },
    { sale: newData[13]?.porcentagem || 0 },
  ];

  console.log(module);

  function value_percentage(modules) {
    let totalPercentage = 0;
    const numModules = modules.length;

    for (const item of modules) {
      for (const key in item) {
        const percentage = parseFloat(item[key]);
        totalPercentage += percentage;
      }
    }

    const averagePercentage = totalPercentage / numModules;
    return averagePercentage;
  }

  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(value_percentage(module));
  }, [newData]);

  return (
    <div className="content-wrapper bg-white">
      <div className="content-header" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom variant="h5" component="div">
                Índice de Experiência
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <Card>
        <Box sx={{ p: 0, display: 'flex' }}>
          {/* <Stack spacing={0.5}>
            <Typography fontWeight={700}>Índice de experiência</Typography>
          </Stack> */}
        </Box>
        <CardHeader
          sx={{}}
          avatar={
            <Avatar variant="rounded" sx={{ bgcolor: green[500], ml: 4 }} aria-label="recipe">
              E
            </Avatar>
          }
          action={
            <Grid sx={{ mb: -4, mr: 2 }}>
              <CircularStaticIE total={total} />
            </Grid>
          }
          //title={user.name}
          title="Equipe"
          // /subheader="Operacional"
        />
      </Card>
      <Divider />
      <div className="container-fluid">
        <CardIR bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
      <div className="container-fluid">
        <CardIRHarvest bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
      <div className="container-fluid">
        <CardIRLivestock bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
      <div className="container-fluid">
        <CardIRProperty bar={bar} newData={newData} property={property} livestock={livestock} harvest={harvest} />
      </div>
    </div>
  );
}
