import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Badge, Tooltip } from '@mui/material';
import MenuCard from '../../../Messenger/components/MenuCard';
import { useState } from 'react';
import Loading from '../../../../components/Loader/Loading';
import ModalImg from './ModalImg';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { Link } from 'react-router-dom';

const location = [{ label: 'Fazenda CPT', id: 1 }];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardMsnMap({ post, setIsLoading, isLoading, setIsLoadingMap }) {
  console.log('CardMsnMap');

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    // setUploadImage("https://cpt-soft.s3.us-east-1.amazonaws.com/b5b1497cd640237049b7-react.png")
    setExpanded(!expanded);
  };

  const status = { 0: 'Resolver', 1: 'Resolvido', 2: 'Parado' };
  const statuscolor = { 0: 'primary', 1: 'success', 2: 'default' };

  const imageDefault = '/dist/image/imagemcarddefault.jpg';
  const uploadImage = post.key_image;
  const imageDelete = '/dist/image/imagedelete.png';

  const handleImgError = (e) => {
    // inserir uma imagem com texto excluido
    e.target.src = imageDelete;
  };

  return (
    <>
      {isLoading == true ? (
        <Loading />
      ) : (
        post && (
          <Card sx={{ width: 300 }}>
            <CardHeader
              sx={{ m: -1, ml: -1 }}
              avatar={
                <Avatar sx={{ bgcolor: blue[700] }} aria-label="recipe">
                  {post.charName}
                </Avatar>
              }
              action={
                //<MenuCardMap id={post.id} setIsLoading={setIsLoading} setIsLoadingMap={setIsLoadingMap} />
                <MenuCard id={post.id} setIsLoading={setIsLoading} setIsLoadingMap={setIsLoadingMap} />
              }
              title={post.name}
              subheader={post.date_f}
            />
            <div style={{ position: 'relative' }}>
              <CardMedia component="img" height="200" image={uploadImage || imageDefault} onError={handleImgError} alt="Imagem" />
              {/*<Box sx={{ position: "absolute", bottom: 0, left: 0, width: "100%", bgcolor: "rgba(0, 0, 0, 0.3)", color: "white", padding: "100px", }} ></Box>*/}
              <CardContent>
                <div style={{ position: 'absolute', color: 'white', top: 10, right: '2%' }}>
                  <ModalImg uploadImage={uploadImage} imageDefault={imageDefault} />
                </div>
              </CardContent>
              <CardActions sx={{ mt: 1 }}>
                <div style={{ position: 'absolute', color: uploadImage ? 'white' : 'black', bottom: 10, left: '5%', display: 'flex', alignItems: 'center' }}>
                  <Link to="/messenger" style={{ textDecoration: 'none', color: 'inherit', marginLeft: 8 }}>
                    <ConnectWithoutContactIcon />
                    <Tooltip
                      title={`Clique aqui e será encaminhado para a página de solicitações "${post.title}"`}
                      placement="top" // Define a posição do Tooltip
                      arrow // Adiciona a seta no tooltip
                    >
                      <Typography variant="subtitle1" style={{ cursor: 'pointer', color: 'green' }}>
                        {post.title}
                      </Typography>
                    </Tooltip>
                  </Link>
                </div>

                <div style={{ position: 'absolute', color: uploadImage ? 'white' : 'black', bottom: 10, right: '2%' }}>
                  {post.status && statuscolor && <Badge sx={{ ml: 'auto', mr: 5 }} badgeContent={status[post.status]} color={statuscolor[post.status]} />}
                </div>
              </CardActions>
            </div>
          </Card>
        )
      )}
    </>
  );
}
