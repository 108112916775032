import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import Typewriter from '../../../../components/Typewriter/Typewriter';
import SelectHarvest from '../addHarvest/SelectHarvest';

export default function StartProjectHarvest() {
  const [open, setOpen] = React.useState(true);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="Fazenda Premium Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5} sx={{ mt: 0 }}>
                <Typography variant="h6" style={{ color: '#808080' }} fontWeight={500}>
                  Fazenda Premium
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                Com o projeto agrícola, você gerenciará de forma organizada as atividades, as produção e as finanças.
              </Typography>
              <Typography gutterBottom sx={{ mt: 0, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
                <Typewriter tam={60} text={`A seguir, veja como é fácil criar o projeto agrícola e iniciar o gerenciamento das atividades de campo!`} />
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Grid item sm={12} xs={12} style={{ textAlign: 'center' }}>
                <img
                  src="/dist/image/gestaoagricola.png"
                  alt="Gestão Pecuária"
                  style={{
                    width: '100%', // A largura ocupa 100% do container, ajustando para mobile
                    maxWidth: '600px', // Define a largura máxima para telas maiores (desktop)
                    height: 'auto', // Mantém a proporção da imagem
                    objectFit: 'contain', // Ajusta a imagem para caber no espaço sem distorcer
                  }}
                />
              </Grid>
            </Grid>

            {/* <Grid item sm={12} xs={12}>
              <Box sx={{ mt: 4 }}>
                <Typography paragraph variant="subtitile2" fontWeight={300}>
                  <strong>Gestão fácil e organizada!</strong>
                </Typography>
                <Typography paragraph variant="subtitile2" fontWeight={300}>
                  Descubra como é fácil criar os projetos pecuários e iniciar a Gestão Agrícola.
                </Typography> 
              </Box>
            </Grid> */}
          </Grid>

          <Grid item sm={12} xs={12}>
            <Box sx={{ mt: 0 }}>
              <Typography paragraph variant="subtitile2" fontWeight={300}>
                Clique a seguir no <strong style={{ color: '#2e7d32' }}>botão verde (+ CRIAR PROJETO)</strong> para inciarmos um projeto pecuário...
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SelectHarvest />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="success" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
