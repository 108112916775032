import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Container, Paper } from '@mui/material';
import SuccessDialog from '../../../../../components/Dialog/SuccessDialog';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import { Context } from '../../../../../components/Context/AuthContext';
import useStore from '../../../../../store/useStore';
import api from '../../../../../services/api';
import dayjs from 'dayjs';
import HeaderModal from '../../../../../components/Header/HeaderModal';
import FormProgressSelect from '../../../../activitiesHarvest/components/FormActivities/FormProgress/FormProgressSelect';
import FormNutritionStep1 from './FormNutritionStep1';
import FormNutritionStep2 from './FormNutritionStep2';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

export default function MultiStepFormNutrition({ handleClose, type }) {
  // console.log('MultiStepFormNutrition')
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const usestore = useStore();
  var userCurrent = JSON.parse(localStorage.getItem('userCurrent'));
  const property_id = localStorage.getItem('Property') || 1;
  const livestockRepos = usestore.reposLivestock.livestockRepos;
  const this_activity = JSON.parse(JSON.stringify(usestore.reposLivestock.getLivestockStore('id', livestockRepos.id)[0]));

  const [data, setData] = useState({
    id_user: userCurrent.id,
    id_property: property_id,
    id_livestock: this_activity.id,
    operationType: 'Nutrição',
    // this_activity: this_activity,

    lotes: [],
    /* produto utilizado - lote / animais
      lotes.map((lote) => {
        lote.product
        lote.product.location_selected

        lote.list_animals.map((animal, index) => {
          animal.nutrition
          return animal
        })
        return lote
      })
    */
    description: '',

    isDeleted: 0,
    progress_radio: 1,
    planning: 'realized',
    progress: 'CONCLUÍDA',
    date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    date_prev_end: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    date_end: null,
  });

  const [activeStep, setActiveStep] = useState(0);

  const [track, setTrack] = useState(new Date().getTime());
  const handleNext = (submit) => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      if (submit !== 'submit' && activeStep === steps.length - 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleScroll();
      }
    }
  };
  const handleBack = () => {
    const currentTime = new Date().getTime();
    const aux_time = currentTime - track;
    if (aux_time > 500) {
      setTrack(currentTime);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      handleScroll();
    }
  };

  const handleScroll = () => {
    setTimeout(() => {
      window.jQuery(window.jQuery.find('#focus_top_modal')[0])?.parent()[0]?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const validate = () => {
    let valid = true;

    if (!data.lotes.length) {
      valid = false;
    } else {
      data.lotes?.map((lote) => {
        let aux_product = lote.product?.nutrition || 0;
        lote.table_confirm.map((animal) => {
          aux_product += animal?.nutrition || 0;
          return animal;
        });
        var qtd_prod = lote.product?.location_selected?.quanty;
        if (lote.product?.packaging_unit !== 'unidade') {
          qtd_prod = (qtd_prod || 0) * (lote.product?.volume_pkg || 1);
        } else {
          qtd_prod = qtd_prod || 0;
        }
        if (!aux_product) {
          valid = true;
        } else if (aux_product > qtd_prod) {
          valid = true;
        }
        return lote;
      });
    }
    return valid;
  };

  //controla a qunatidade de passos e o botão confirmar e salvar
  const steps = ['FormStepPeriod', 'FormNutritionStep1', 'FormNutritionStep2'];
  const steps_text = [
    'Informe como se encontra o progresso do manejo nutricional',
    'Defina o lote que deseja realizar o manejo nutricional',
    'Pronto! Agora basta conferir as informações do manejo Nutricional e clicar em confirmar!',
  ];

  const onSubmit = async () => {
    let formOk = validate() || false;

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      await api
        .post('/activityNutrition/save', data, header)
        .then((response) => {
          //console.log(response.data);
          if (response.data.isSuccess) {
            toast.success('Nutrição dos animais realizada com sucesso!');

            usestore.reposLivestock.fetch({ id_farm: property_id });
            usestore.reposProducts.fetch({ id_farm: property_id });
            usestore.reposPatrimony.fetch({ id_farm: property_id });
            usestore.reposFinances.fetch({ id_property: property_id });
            usestore.reposFinances.fetchT({ id_property: property_id });
            usestore.reposCashFlow.fetch();
            usestore.reposCostProduction.fetch();
            usestore.reposActivityNutrition.fetch({ id_property: property_id }).then((res) => {
              setAuxAdd(aux_add + 1);
              setTimeout(() => {
                handleNext('submit');
              }, 200);
            });
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.error('Erro ao registrar a Nutição, tente novamente mais tarde!');
        });
    } else {
      toast.error('Por favor, preencha os campos obrigatórios!');
    }
  };

  const setOptLotes = () => {
    // gera novo array sem alterar o array do repos
    let aux_lotes = JSON.parse(JSON.stringify(this_activity.lotes));
    const opt_lotes = aux_lotes.filter((lote) => {
      if (lote.total_animals > 0) {
        lote.label = lote.name;
        lote.product = null;
        lote.num_selecteds = 0;

        if (lote.num_animals > 0) {
          lote.list_animals.unshift({
            id: 0,
            id_lote: lote.id,
            identifier: 'Não identificados (' + lote.num_animals + ')',
            sexo: '-',
            weight: lote.weight,
            nutrition: 0,
          });
        }
        lote.table_selected = {};
        lote.table_confirm = [];
        return lote;
      }
    });

    data.opt_lotes = opt_lotes;
  };

  const getStepContent = (step) => {
    const obj_fn = {
      usestore: usestore,
      setData: setData,
      handleNext: handleNext,
      handleBack: handleBack,
      activeStep: activeStep,
      steps: steps,
      handleClose: handleClose,
      onSubmit: onSubmit,
      isMobile: isMobile.any(),
    };

    switch (step) {
      case 0:
        // progresso do manejo
        return <FormProgressSelect data={data} obj_fn={obj_fn} />;
      case 1:
        if (!data.opt_lotes) {
          setOptLotes();
        }
        // identificadores do manejo
        return <FormNutritionStep1 data={data} obj_fn={obj_fn} />;
      case 2:
        // confirmação dos dados
        return <FormNutritionStep2 data={data} obj_fn={obj_fn} />;
      case 3:
        const data_success = {
          distination: 'activities/' + property_id + '/' + livestockRepos.id,
          name: 'Manejo Nutricional:',
          text_success: 'Acesse a lista de Atividades de campo e planeje seu próximo manejo.',
          distination_title: 'Atividades de campo',
          // image: '/dist/image/nutricaoAnimal.png',
          icon: <PendingActionsIcon color="success" style={{ fontSize: 100 }} sx={{ mr: 1, width: '50px', height: '50px' }} />,
        };
        return (
          <Container component="main" maxWidth="lg" sx={{ mt: -3 }}>
            <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 1 } }}>
              <SuccessDialog handleClose={handleClose} data={data_success} />
            </Paper>
          </Container>
        );
      default:
        return 'Não existente';
    }
  };

  const options = {
    title: 'Manejo Nutricional:',
    txt_msn: steps_text[activeStep],
    icon: <FoodBankIcon color="success" style={{ fontSize: 100 }} sx={{ mr: 1, width: '50px', height: '50px' }} />,
    img: <img style={{ maxHeight: 120 }} src={'/dist/image/nutricaoAnimal.png'} alt="Manejo Nutricional" className="img-fluid" />,
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {activeStep !== 3 && <HeaderModal options={options} />}
        {getStepContent(activeStep)}
      </Box>
    </>
  );
}
