import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import { Skeleton, Stack } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import ColorChips from './ColorChips';
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import useActivityInputReposStore from '../../../../store/Activities/useActivityInputReposStore';
import TablesActivitiesCreationsCardEmpty from './TablesActivitiesCreationsCardEmpty';
import IconsLivestock from '../../../../components/Icons/IconsLivestock';
import EditActivitiesCreations from '../EditActivities/EditActivitiesCreations';
import EditActivityIdentify from '../EditActivities/Identification/EditActivityIdentify';
import EditActivityWeight from '../EditActivities/Weighting/EditActivityWeight';
import { Context } from '../../../../components/Context/AuthContext';
import EditModalFormActivityDelete from '../DeleteActivities/EditModalFormActivityDelete';
import RestoreActivitiesCreations from '../DeleteActivities/RestoreActivitiesCreations';
import EditModalFormActivityTrash from '../EditActivities/EditModalFormActivityTrash';
import TablesActivitiesCreationsTrashEmpty from './TablesActivitiesCreationsTrashEmpty';
import RestoreActivitiesCreationsRealized from '../DeleteActivities/RestoreActivitiesCreationsRealized';
import useStore from '../../../../store/useStore';
import LoadActivityListLivestock from '../../../../components/LoadActivityList/LoadActivityListLivestock';
import CardLivestockInfo from '../tab/CardLivestockInfo';
import EditActivityMedicines from '../EditActivities/Medicines/EditActivityMedicines';
import EditActivitiesProduction from '../EditActivities/production/EditActivityProduction';
import EditActivityNutrition from '../EditActivities/Nutrition/EditActivityNutrition';
import LetterAvatarsFade from '../../../../components/Avatar/LetterAvatarsFade';

function descendingComparator(a, b, orderBy) {
  let field1 = b[orderBy];
  let field2 = a[orderBy];
  if (/.*Date.*/.test(orderBy)) {
    field1 = new Date(b[orderBy + '_nf']);
    field2 = new Date(a[orderBy + '_nf']);
  }
  if (field1 < field2) {
    return -1;
  }
  if (field1 > field2) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'team',
    align: 'center',
    disablePadding: false,
    label: 'Equipe',
  },
  {
    id: 'activity',
    align: 'center',
    disablePadding: false,
    label: 'Tipo',
  },
  // {
  //   id: 'type_activity',
  //   align: 'center',
  //   disablePadding: false,
  //   label: 'Criação',
  // },
  // {
  //   id: 'numberAnimals',
  //   align: 'center',
  //   disablePadding: true,
  //   label: 'Quantidade',
  // },
  {
    id: 'progress',
    align: 'center',
    disablePadding: false,
    label: 'Progresso',
  },
  {
    id: 'startDate',
    align: 'center',
    disablePadding: false,
    label: 'Data de início',
  },
  {
    id: 'endDate',
    align: 'center',
    disablePadding: false,
    label: 'Data de término',
  },
  {
    id: 'nameBatch',
    align: 'center',
    disablePadding: false,
    label: 'Lote',
  },
  // {
  //   id: 'areaName',
  //   align: 'center',
  //   disablePadding: false,
  //   label: 'Área',
  // },
  // {
  //   id: 'ha',
  //   align: 'center',
  //   disablePadding: false,
  //   label: 'ha',
  // },
  {
    id: 'edit',
    align: 'left',
    disablePadding: false,
    label: 'Ações',
  },
];

//exibir opções de filtro e botões de ação na tabela.
function EnhancedTableHead(props) {
  // const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tab_active } = props
  const { order, orderBy, onRequestSort, tab_active } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={tab_active}>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }} 
          />*/}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tab_active: PropTypes.any,
};

//A função EnhancedTableToolbar() recebe como argumento o número de linhas selecionadas e retorna o componente Toolbar com as opções de filtro e botões de ação.
function EnhancedTableToolbar(props) {
  // const { numSelected, selecteded, repos, tab_active } = props;
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selecionado
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Gerenciar as atividades
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete"><DeleteLivestock selected={selecteded} reposLivestocks={repos} tab_active={tab_active} /></Tooltip>
      ) : (
        <Tooltip title="Itens selecionados">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selecteded: PropTypes.any,
  repos: PropTypes.any,
  tab_active: PropTypes.any,
};

export default function TablesActivitiesCreations({ isLoadingActivities, setIsLoadingActivities, tab_active, table_active, status }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('endDate');
  const [selected, setSelected] = useState([]);
  const selecteded = {};
  // const [selecteded, setSelecteded] = useState({})
  const [page, setPage] = useState(0);
  // const [dense, setDense] = useState(false)
  const dense = false;
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // start repos
  const usestore = useStore();
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  //criado o state para o rows
  const [rows, setRowsActivitiesInputs] = useState([]);
  // acessar o repos das atividades
  const loadActivityListLivestock = LoadActivityListLivestock();
  const reposActivitiesInputs = useActivityInputReposStore((state) => state);

  const { id } = useParams();
  const reposLivestocks = usestore.reposLivestock;
  const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];

  const handleRows = () => {
    // console.log('handleRows TablesActivitiesCreations')
    let filters = {
      table_active: table_active,
      status: status,
    };
    //console.log(filters)
    const rows_lv = loadActivityListLivestock.loadList(currentLivestok, filters);
    // console.log(rows_lv)

    setRowsActivitiesInputs(rows_lv);

    setTimeout(() => {
      setIsLoadingTable(false);
    }, 500);
  };

  const { aux_add } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      // LoadActivityListLivestock()
      handleRows();
    }, 500);
  }, [isLoadingActivities, aux_add]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n?.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name, id, icon) => {
  //   //console.log("handleClick");
  //   if (tab_active === 'excluida') {
  //     return
  //   }
  //   var target_parent_name = window.jQuery(event.target).parentsUntil('table').parent().eq(0).attr('name') || ''
  //   if (target_parent_name !== 'tableListLivestocks') {
  //     return
  //   }
  //   target_parent_name = window.jQuery(event.target).parentsUntil('td').parent().eq(0).attr('name') || ''
  //   if (target_parent_name === 'EditActivity') {
  //     return
  //   }
  //   //console.log(event, name);
  //   const selectedIndex = selected.indexOf(name)
  //   //console.log(selectedIndex);
  //   let newSelected = []

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name)
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1))
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1))
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
  //   }

  //   setSelected(newSelected)

  //   if (selectedIndex === -1) {
  //     selecteded[id] = { id: id, name: name, icon: icon }
  //   } else {
  //     delete selecteded[id]
  //   }
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked)
  // }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (!rows.length) {
    setTimeout(() => {
      setIsLoadingTable(false);
    }, 1000);
  }

  // const [isLoadingEdit, setIsLoadingEdit] = useState(true)

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoadingEdit(false)
  //   }, 2000) // Tempo de espera em milissegundos (aqui definido como 2 segundos)

  //   return () => clearTimeout(timeout)
  // }, [])

  console.log(rows);

  return (
    <>
      {!rows.length ? (
        status === 'trash' ? (
          <TablesActivitiesCreationsTrashEmpty />
        ) : (
          <TablesActivitiesCreationsCardEmpty isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
        )
      ) : (
        <>
          <CardLivestockInfo rows={rows} livestok={currentLivestok} />
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar numSelected={selected.length} selecteded={selecteded} repos={reposLivestocks} tab_active={tab_active} />
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} name="tableListLivestocks">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    tab_active={tab_active}
                  />
                  {rows && (
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.activity);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          var selecteded = {};
                          selecteded[row.id] = { id: row.id, name: row?.activity, icon: <IconsLivestock type={row.type} widthStyles={'80%'} /> };
                          return (
                            <>
                              {isLoadingTable === true ? (
                                <>
                                  <TableRow key={'void'}>
                                    <TableCell align="center" component="th" height={67}>
                                      <Skeleton variant="rectangular" height={34} width={27} />
                                    </TableCell>
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                    {/* <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell> */}
                                    {/* <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell> */}
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                    {/* <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell> */}
                                    <TableCell align="center" height={67}>
                                      <Skeleton variant="rectangular" height={34} />
                                    </TableCell>
                                  </TableRow>
                                </>
                              ) : (
                                <TableRow
                                  hover
                                  // onClick={(event) => handleClick(event, row.activity, row.id, <IconsLivestock type={row.type} widthStyles={'80%'} />)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.activity + '_' + index}
                                  selected={isItemSelected}
                                  // sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell align="center">
                                    <IconsLivestockActivities activity={row.activity} type={row.type} widthStyles={'32px'} type_activity={row.type_activity} />
                                  </TableCell>
                                  <TableCell align="center">
                                    <LetterAvatarsFade
                                      name={row.team}
                                      textoTeam={`${row.team} assumiu a responsabilidade de conduzir a atividade relacionada a ${row.activity} no projeto ${row.type_activity}, ${
                                        row.activity === 'MEDICAMENTOS'
                                          ? `visando aplicá-lo em ${row.numberAnimals} animais.`
                                          : row.activity === 'ENTRADA'
                                          ? `visando a entrada de ${row.numberAnimals} animais.`
                                          : /PESAGEM.*/.test(row.activity)
                                          ? `visando coletar o peso de ${row.numberAnimals} animais.`
                                          : ''
                                      } A atividade tem o status de ${row.progress} ${row.progress === 'CONCLUÍDA' ? 'que teve início' : 'que se inicia'}  em ${row.startDate} e término em ${
                                        row.endDate
                                      } no ${row.nameBatch}, localizado na área: ${row.areaName}(${row.ha})`}
                                    />
                                  </TableCell>
                                  <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                                    <Typography variant="button" display="block" gutterBottom sx={{ fontWeight: 'bold' }}>
                                      {row.activity}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell align="center" sx={{ fontWeight: '300', fontSize: 12 }}>
                                    {row.type_activity}
                                  </TableCell> */}
                                  {/* <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 18, color: green[500] }}>
                                    {row.numberAnimals}
                                  </TableCell> */}
                                  <TableCell align="center">
                                    <ColorChips row_data={row} usestore={usestore} />
                                  </TableCell>
                                  <TableCell align="center">{row.startDate}</TableCell>
                                  <TableCell align="center">{row.endDate}</TableCell>
                                  <TableCell align="center" sx={{ fontWeight: '300', fontSize: 12 }}>
                                    {row.nameBatch}
                                  </TableCell>
                                  {/* <TableCell align="center" sx={{ fontWeight: '300', fontSize: 12 }}>
                                    {row.areaName}
                                  </TableCell>
                                  <TableCell align="center">{row.ha}</TableCell> */}
                                  <TableCell align="right" name="EditActivity">
                                    <Box sx={{ p: 0, display: 'flex' }}>
                                      {status === 'edit' && (
                                        <>
                                          <Stack sx={{ mt: -1 }} spacing={0.5}>
                                            {row.activity === 'ENTRADA' ? (
                                              <EditActivitiesCreations id={row.id} reposActivitiesInputs={reposActivitiesInputs} reposLivestocks={reposLivestocks} tab_active={tab_active} />
                                            ) : (
                                              <></>
                                            )}
                                            {row.activity === 'IDENTIFICAÇÃO' ? <EditActivityIdentify row_data={row} usestore={usestore} /> : <></>}
                                            {/PESAGEM.*/.test(row.activity) ? <EditActivityWeight row_data={row} usestore={usestore} /> : <></>}
                                            {row && row.activity === 'MEDICAMENTOS' ? <EditActivityMedicines row_data={row} usestore={usestore} /> : <></>}
                                            {row && row.activity === 'NUTRICAO' ? <EditActivityNutrition row_data={row} usestore={usestore} /> : <></>}
                                            {row && row.activity === 'PRODUÇÃO' ? <EditActivitiesProduction row_data={row} usestore={usestore} /> : <></>}
                                          </Stack>
                                          <Stack sx={{ mt: -1 }} spacing={0.5}>
                                            {/.*NOVO LOTE.*/.test(row.activity) ? <>-</> : <EditModalFormActivityTrash row={row} usestore={usestore} />}
                                          </Stack>
                                        </>
                                      )}
                                      {status === 'trash' && (
                                        <>
                                          {table_active !== 'planning' && <RestoreActivitiesCreations row={row} usestore={usestore} />}
                                          {table_active === 'planning' && (
                                            <EditActivitiesCreations id={row.id} reposActivitiesInputs={reposActivitiesInputs} reposLivestocks={reposLivestocks} tab_active={tab_active} />
                                          )}
                                          {table_active === 'planning' && <RestoreActivitiesCreationsRealized data={row} usestore={usestore} />}
                                          {/.*NOVO LOTE.*/.test(row.activity) ? <>-</> : <EditModalFormActivityDelete data={row} usestore={usestore} />}
                                        </>
                                      )}
                                    </Box>
                                    {/* {tab_active !== "excluida" ? <></> : <DeleteLivestock selected={selecteded} tab_active={tab_active} />} */}
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow key={'emptyRows'} style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage={'Linhas por página'}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Visualização da tabela" /> */}
          </Box>
        </>
      )}
    </>
  );
}
