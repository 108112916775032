import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Skeleton, Stack, Toolbar, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import useStore from '../../../../store/useStore';
import { Context } from '../../../../components/Context/AuthContext';
import LoadActivityListHarvest from '../../../../components/LoadActivityList/LoadActivityListHarvest';
import TablesActivitiesHarvestCardEmpty from './TablesActivitiesHarvestCardEmpty';
import TablesActivitiesHarvestTrashEmpty from './TablesActivitiesHarvestTrashEmpty';
import TrashActivitiesHarvests from '../DeleteActivities/TrashActivitiesHarvests';
import RestoreActivitiesHarvests from '../DeleteActivities/RestoreActivitiesHarvests';
import DeleteActivitiesHarvests from '../DeleteActivities/DeleteActivitiesHarvests';
import EditActivitiesPlanting from '../EditActivities/EditActivitiesPlanting';
import EditActivitiesHarvesting from '../EditActivities/EditActivitiesHarvesting';
import ColorChips from './ColorChips';
import IconsHarvestActivities from '../../../../components/Icons/harvest/IconsHarvestActivities';
import ViewActivitiesMonitoring from '../ViewActivities/ViewActivitiesMonitoring';
import EditActivitiesApplications from '../EditActivities/EditActivitiesApplications';
import EditActivitiesSoilPreparation from '../EditActivities/EditActivitiesSoilPreparation';
import LetterAvatarsFade from '../../../../components/Avatar/LetterAvatarsFade';
import Typewriter from '../../../../components/Typewriter/Typewriter';

function descendingComparator(a, b, orderBy) {
  let field1 = b[orderBy];
  let field2 = a[orderBy];
  if (/.*Date.*/.test(orderBy)) {
    field1 = new Date(b[orderBy + '_nf']);
    field2 = new Date(a[orderBy + '_nf']);
  }
  if (field1 < field2) {
    return -1;
  }
  if (field1 > field2) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'team',
    align: 'center',
    disablePadding: false,
    label: 'Equipe',
  },
  {
    id: 'activity',
    align: 'center',
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'progress',
    align: 'center',
    disablePadding: false,
    label: 'Progresso',
  },

  {
    id: 'startDate',
    align: 'center',
    disablePadding: false,
    label: 'Data de início',
  },
  {
    id: 'endDate',
    align: 'center',
    disablePadding: false,
    label: 'Data de término',
  },
  {
    id: 'areaName',
    align: 'center',
    disablePadding: false,
    label: 'Área (ha)',
  },
  {
    id: 'ha',
    align: 'center',
    disablePadding: false,
    label: 'Total (ha)',
  },
  {
    id: 'edit',
    align: 'left',
    disablePadding: false,
    label: 'Ações',
  },
];

//exibir opções de filtro e botões de ação na tabela.
function EnhancedTableHead(props) {
  // const { order, orderBy, rowCount, onRequestSort, tab_active } = props;
  const { order, orderBy, onRequestSort, tab_active } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={tab_active}>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tab_active: PropTypes.any,
};

//A função EnhancedTableToolbar() retorna o componente Toolbar com a opção de filtro.
function EnhancedTableToolbar(props) {
  // const { handleFilter, tab_active } = props
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography sx={{ flex: '1 1 100%', mt: 1 }} variant="h6" id="tableActivHarvest" component="div">
        Gerenciar as atividades:
        <Typography gutterBottom sx={{ mt: 0, mb: 0 }} fontWeight={500} style={{ color: '#2e7d32' }}>
          <Typewriter tam={60} text={`Monitore o progresso, prazos e o custo de produção.`} />
        </Typography>
      </Typography>

      {/* <Tooltip title="Filtros">
        <IconButton>
          <FilterList />
        </IconButton>
      </Tooltip> */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  handleFilter: PropTypes.any,
  tab_active: PropTypes.any,
};

export default function TablesActivitiesHarvest({ isLoadingActivities, setIsLoadingActivities, tab_active, table_active, status }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('endDate');
  const [page, setPage] = useState(0);
  // const [dense, setDense] = useState(false)
  const dense = false;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [filters, setFilters] = useState({
    table_active: table_active,
    status: status,
  });

  // start repos
  const usestore = useStore();
  const { aux_add } = useContext(Context);
  const loadActivityListHarvest = LoadActivityListHarvest();
  // console.log('loadActivityListHarvest')
  //console.log(loadActivityListHarvest)

  //criado o state para o rows
  const [rows, setRowsActivities] = useState([]);
  const { id } = useParams();

  const currentHarvest = usestore.reposHarvest.getHarvestStore('id', id)[0];
  // console.log(currentHarvest)
  //criado o handle para listar as atividades de input
  const handleRows = () => {
    const rows_h = loadActivityListHarvest.loadList(currentHarvest, filters);
    // console.log(rows_h);

    setRowsActivities(rows_h);

    setTimeout(() => {
      setIsLoadingTable(false);
    }, 500);
  };

  useEffect(() => {
    // console.log('refresh TablesActivitiesHarvests')
    setTimeout(() => {
      handleRows();
    }, 200);
  }, [isLoadingActivities, aux_add]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked)
  // }

  const handleFilter = (field, newValue) => {
    // console.log('change filters')
    setFilters((prevState) => ({ ...prevState, [field]: newValue }));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getTableSqueleton = () => {
    return (
      <TableRow key={'void'}>
        <TableCell align="center" component="th" height={67}>
          <Skeleton variant="rectangular" height={34} width={27} />
        </TableCell>
        {headCells.map((row, index) => {
          return (
            <>
              <TableCell key={'TC_s_' + index} align="center" component="th" height={67}>
                <Skeleton key={'SK_' + index} variant="rectangular" height={34} width={27} />
              </TableCell>
            </>
          );
        })}
      </TableRow>
    );
  };

  // console.log('rowsx')
  console.log(rows);

  return (
    <>
      {!rows.length ? (
        status === 'trash' ? (
          <TablesActivitiesHarvestTrashEmpty />
        ) : (
          <TablesActivitiesHarvestCardEmpty isLoadingActivities={isLoadingActivities} setIsLoadingActivities={setIsLoadingActivities} />
        )
      ) : (
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar handleFilter={handleFilter} tab_active={tab_active} />

            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} name="tableActivitiesHarvest">
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} tab_active={tab_active} />
                {rows && (
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <>
                            {isLoadingTable === true ? (
                              <>{getTableSqueleton()}</>
                            ) : (
                              <TableRow
                                key={row.activity + '_' + index}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                // sx={{ cursor: 'pointer' }}
                              >
                                <TableCell align="center">
                                  <IconsHarvestActivities activity={row.activity} />
                                </TableCell>
                                <TableCell align="center">
                                  <LetterAvatarsFade
                                    name={row.team}
                                    textoTeam={`${row.team} assumiu a responsabilidade de conduzir a atividade relacionada a ${row.activity} no projeto ${currentHarvest.name}, ${
                                      row.activity === 'APLICAÇÃO'
                                        ? `visando aplicar o ${row?.operation}.`
                                        : row.activity === 'MONITORING'
                                        ? `visando monitorar ${row?.operation}.`
                                        : row.activity === 'PREPARAÇÃO DO SOLO'
                                        ? `visando preparar o solo com o método de ${row?.operation}.`
                                        : ''
                                    } A atividade tem o status de ${row.progress} ${row.progress === 'CONCLUÍDA' ? 'que teve início' : 'que se inicia'}  em ${row.startDate} e término em ${
                                      row.endDate
                                    }.`}
                                  />
                                </TableCell>
                                <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                                  {/* <Typography variant="button" display="block" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    </Typography> */}
                                  <Stack direction="column" alignItems="center" spacing={1} useFlexGap>
                                    <div>
                                      <Typography color="text.primary" fontWeight="Bold">
                                        {/* PRECISO CONSERTA O NOME NO BANCO DO MONITORING */}
                                        {row.activity == 'MONITORING' ? 'MONITORAMENTO' : row.activity}
                                      </Typography>
                                      {row?.operation && (
                                        <Typography variant="caption" color="text.secondary" fontWeight="medium" textAlign="center" sx={{ width: '100%' }}>
                                          {row?.operation}
                                        </Typography>
                                      )}
                                    </div>
                                  </Stack>
                                </TableCell>
                                <TableCell align="center">
                                  <ColorChips row_data={row} usestore={usestore} />
                                </TableCell>

                                <TableCell align="center">{row.startDate}</TableCell>
                                <TableCell align="center">{row.endDate}</TableCell>
                                <TableCell align="center">{row.areaName}</TableCell>
                                <TableCell align="center">{row.ha}</TableCell>
                                <TableCell align="right" name="EditActivity">
                                  <Box sx={{ p: 0, display: 'flex' }}>
                                    {status === 'edit' && (
                                      <>
                                        {row.activity === 'PLANTIO' ? <EditActivitiesPlanting row={row} usestore={usestore} /> : <></>}
                                        {row.activity === 'COLHEITA' ? <EditActivitiesHarvesting row={row} usestore={usestore} /> : <></>}
                                        {row.activity === 'MONITORING' ? <ViewActivitiesMonitoring row={row} usestore={usestore} /> : <></>}
                                        {row.activity === 'APLICAÇÃO' ? <EditActivitiesApplications row={row} usestore={usestore} /> : <></>}
                                        {row.activity === 'PREPARAÇÃO DO SOLO' ? <EditActivitiesSoilPreparation row={row} usestore={usestore} /> : <></>}
                                        {/.*VINCULA.*/.test(row.activity) ? <div style={{ width: '40px' }}>&nbsp;</div> : <></>}
                                        <TrashActivitiesHarvests row={row} usestore={usestore} />
                                      </>
                                    )}
                                    {status === 'trash' && (
                                      <>
                                        <RestoreActivitiesHarvests row={row} usestore={usestore} />
                                        <DeleteActivitiesHarvests row={row} usestore={usestore} />
                                      </>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow key={'emptyRows'} style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage={'Linhas por página'}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Visualização da tabela" /> */}
        </Box>
      )}
    </>
  );
}
