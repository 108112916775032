import { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, Stack, TextField } from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import { green } from '@mui/material/colors';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import FormInputFooter from './FormInputFooter';
import FormInputStep1NumAnimalRadioButton from './FormInputStep1NumAnimalRadioButton';
// import GenericValueField from '../../../../../components/Fields/GenericValueField';

// Converte o valor numérico para uma string formatada
const formatValue = (num) => {
  // Verifica se num é um número; caso contrário, usa 0
  const number = isNaN(num) || num === '' ? 0 : Number(num)
  let str = number.toFixed(0)
  str = str.replace('.', ',') // Substitui ponto por vírgula
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, '.') // Adiciona separadores de milhar
}

export default function FormInputStep1NumAnimal({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  //1-texto para acionar o botao radio
  const [showTextFase, setShowTextFase] = useState(false);
  const [showTextNumber, setShowTextNumber] = useState(false);

  //1.1texto é acionado quando o botão radio é ativado
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  //2.texto é acionado quando o botão radio é ativado
  useEffect(() => {
    setTimeout(() => {
      setShowTextNumber(true);
    }, 1000);
    //return () => clearTimeout(timer);
  }, [showTextNumber]);

  // função para receber os valores digitados do formulário
  const handleChangeInput = (event) => {
    // console.log("handleChangeInput")
    //recebe o estado anterior como um parâmetro e retorna o novo estado
    var value = event.target.value
    if (event.target.name === 'numberAnimals') {
      value = event.target.value.replace(/\D/g, '');
      value = parseFloat(value || '0');
      if (value > 999999999) {
        value = 999999999;
      }
    }
    setData(prevState => ({ ...prevState, [event.target.name]: value }));
  };

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    if (!data.numberAnimals) {
      return true;
    }
    return false;
  };

  const returnError = () => {
    return true;
  };

  const inputRef = useRef(null);
  const handleFocus = (event) => {
    // Adiciona um pequeno atraso para garantir que a seleção ocorra após o campo receber foco
    setTimeout(() => {
      if (inputRef.current) {
        const length = event.target.value.length;
        event.target.setSelectionRange(length, length); // Posiciona o cursor no final do texto
      }
    }, 10);
  }

  const color_text = green[800];

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <InputIcon />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: -0.5 }}>
              <Typography variant="h6" fontWeight={500}>Entradas dos animais</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center" >
          <Grid item sx={{ mt: 1, height: 150 }} >
            <img
              style={{ maxHeight: 150 }}
              src="/dist/image/entradadeanimais_numero.png"
              class="img-fluid"
              alt="Entrada de animais"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: -1 }}>
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Informações Essenciais</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid container spacing={1} sx={{}}>
            <div className="d-lg-none">
              <Grid container >
                <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} >
                  {showTextFase ?
                    <>
                      { data.stage === "" ?
                        <Typography style={{ color: color_text }}>
                          <Typewriter tam={0} minHeight={0} text="Por favor, especifique a fase da sua criação." />
                        </Typography>
                        :
                        <Box display="flex" alignItems="center">
                          <Typography style={{ color: color_text }}>
                            Por favor, especifique a fase da sua criação.
                          </Typography>
                        </Box>
                      }
                    </>
                    :
                    <Box
                      display="flex" alignItems="center"
                      style={{ height: 30 }}
                    >
                      <ThreeDotsSpinner />
                    </Box>
                  }
                </Grid>
                <Grid item xs={12} sm={12} sx={{}} >
                  <FormInputStep1NumAnimalRadioButton data={data} setData={setData} />
                </Grid>
              </Grid>
            </div>
            <div className="d-none d-lg-block"  >
              <Grid container >
                <Grid item xs={12} md={12} alignItems="center" style={{ height: 40 }} >
                  {showTextFase ?
                    <>
                      { data.stage === "" ?
                        <Typography style={{ color: color_text }}>
                          <Typewriter tam={0} minHeight={0} text="Por favor, especifique a fase da sua criação." />
                        </Typography>
                        :
                        <Box display="flex" alignItems="center">
                          <Typography style={{ color: color_text }}>
                            Por favor, especifique a fase da sua criação.
                          </Typography>
                        </Box>
                      }
                    </>
                    :
                    <Box
                      display="flex" alignItems="center"
                      style={{ height: 30 }}
                    >
                      <ThreeDotsSpinner />
                    </Box>
                  }
                </Grid>
                <Grid item xs={12} sm={12} sx={{}} >
                  <FormInputStep1NumAnimalRadioButton data={data} setData={setData} />
                </Grid>
              </Grid>
            </div >
          </Grid>
        </Grid >
        {/* data.stage Acionando quando o botao radio é selecionado */}
        <Grid Grid container spacing={1} style={{ overflow: 'auto' }}>
          { data.stage !== "" &&
            <>
              <Grid container spacing={0} sx={{ mt: 2 }}>
                <Grid item xs={12} md={12} alignItems="center" style={{ height: 20 }} >
                  {showTextNumber ?
                    <>
                      {!data.numberAnimals ?
                        <Typography style={{ color: color_text }}>
                          <Typewriter tam={0} minHeight={0} text="Defina o número de animais." />
                        </Typography>
                        :
                        <Box display="flex" alignItems="center">
                          <Typography style={{ color: color_text }}>
                            Defina o número de animais.
                          </Typography>
                        </Box>
                      }
                    </>
                    :
                    <Box
                      display="flex" alignItems="center"
                      style={{ height: 30 }}
                    >
                      <ThreeDotsSpinner />
                    </Box>
                  }
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                  <TextField
                    inputRef={inputRef}
                    id="numberAnimals"
                    label="Número de Animais"
                    name="numberAnimals"
                    // type="text"
                    inputMode="numeric"
                    color="success"
                    value={formatValue(data?.numberAnimals)}
                    // onChange={handleChangeInput}
                    onFocus={handleFocus}
                    fullWidth
                    sx={{ width: "100%",
                      "& .MuiInputBase-input": {
                        textAlign: 'right',
                      },
                    }}
                    // style={{ width: 160 }}
                    variant="standard"
                    //error={numberAnimalsError}
                    helperText={"Quantidade de animais entrando na propriedade\nCampo obrigatório"}
                    InputProps={{
                      inputProps: {
                        mask: Number,
                        maptoradix: ['.'],
                        thousandsseparator: '.',
                        scale: 1,
                        padfractionalzeros: 'true',
                        normalizezeros: 'true',
                        signed: "false",
                        min: 0,
                        max: 999999999,
                        onChange: handleChangeInput,
                      },
                    }}
                  />
                  {/* <GenericValueField
                    handleChange={handleChangeInput}
                    options={{
                      required: true,
                      id: "numberAnimals",
                      name: "numberAnimals",
                      label: 'Número de Animais',
                      value: data?.numberAnimals,
                      variant:"standard",
                      helperText: (<>Quantidade de animais entrando na propriedade<br/>Campo obrigatório</>),
                      height: '30px',
                      endAdornment: true,
                      prefix: '',
                    }}
                  /> */}
                </Grid>
              </Grid>
            </>
          }
        </Grid >
        < FormInputFooter data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} steps={steps} isError={isError} handleClose={handleClose} returnError={returnError} />
      </Grid >
    </>
  );
}