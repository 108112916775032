import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardMedia, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Map } from '@mui/icons-material';
import FooterModal from '../../../../../components/Footer/FooterModal';
import CardProduct from './CardProducts';
import ProductRegistrar from '../../../../products/components/ProductRegistrar/ProductRegistrar';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import ViewSpecificActivitiesMonitoring from './ViewSpecificActivitiesMonitoring';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import AutocompletGenericUnic from '../../../../../components/Fields/AutocompletGenericUnic';
import { formatValue } from '../../../../../utils/functions';
import GenericValueField from '../../../../../components/Fields/GenericValueField';

export default function FormApplicationsStep1({ data, obj_fn }) {
  const [showText, setShowText] = useState(false);
  setTimeout(() => {
    setShowText(true);
  }, 2000);

  useEffect(() => {
    setShowText(false);
  }, []);

  // const para validar o button continuar
  const isError = () => {
    var valid = false;
    if (!data.areas.length) {
      valid = true;
    } else {
      data.areas?.map((area) => {
        var qtd_prod = area.product?.location_selected?.quanty || 0;
        if (area.product?.packaging_unit !== 'unidade') {
          qtd_prod = ((qtd_prod || 0) * (area.product?.volume_pkg || 1));
        }

        if (!area.product?.location_selected?.quant_utilizada) {
          valid = true;
        } else if (qtd_prod < area.product?.location_selected?.quant_utilizada) {
          valid = true;
        }
        return area;
      });
    }
    return valid;
  };
  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de projeto
  const optionsAreas = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
    ['id', data.id_harvest],
    ['tipo', 'harvest'],
  ]);

  const opt_areas = {
    multiple: false,
    name: 'areas',
    label: 'Áreas da Safra',
    values: optionsAreas,
    field: 'areas',
    variant: 'standard',
    helperText: 'Selecione a(s) área(s) para a aplicação',
    listHeight: 225,
  };
  const handleChangeArea = (field, newValue) => {
    // console.log('handleChangeArea');
    if (newValue && newValue.length > 0) {
      newValue.map((area) => {
        if (!area.product) {
          area.product = null;
        }
        return area;
      });
    }
    obj_fn.setData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangeInput = (e, area) => {
    // console.log('handleChangeInput');
    //console.log(e.target.value, area)
    const usedArea = e.target.value ? parseFloat(e.target.value) : '';

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => {
        if (area_prev.id === area.id) {
          return { ...area_prev, usedArea };
        }
        return area_prev;
      }),
    }));
  };

  // const handleChange = (event) => {
  //   obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  // };

  const filters_prods = [['group_category', 'Defensivos']];
  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) da Aplicação do defensivo</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
          <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
            <AutocompletGenericUnic value={data.areas} options={opt_areas} handleChange={handleChangeArea} />
          </Grid>
        </Grid>

        {data.areas?.map((area) => (
          <>
            <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems="center">
              {showText ? (
                <Box display="flex" alignItems="center">
                  <Typewriter tam={60} text={'Clique em "Selecionar monitoramento" e selecione a área a ser monitorada.'} variant={'subtitle1'} color={'#2e7d32'} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <ThreeDotsSpinner />
                </Box>
              )}
            </Grid>
            <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
              <Grid item xs={12} md={4} sx={{ mt: 1 }}>
                <Grid item key={'G4_' + area.id} xs={12} sm={12} sx={{mt: -1, mb: 2}}>
                  <Typography style={{ fontSize: 'larger', fontWeight: 700, color: 'green' }}>
                    {area.name}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    <i className="fa fa-map text-success">
                      {' '} {formatValue(area.area)} ({area.measure})
                    </i>
                  </Typography>
                </Grid>
                <Grid item key={'G5_' + area.id} xs={12} sm={12}>
                  <GenericValueField
                    handleChange={(e) => handleChangeInput(e, area)}
                    options={{
                      id: (area.id + ''),
                      name: area.name,
                      label: 'Área utilizada',
                      value: area.usedArea,
                      helperText: (<>
                        <span style={{ color: 'green' }}>
                          Área utilizada para aplicação
                        </span>
                      </>),
                      color: 'success',
                      max: area.area,
                      width: '90%',
                      endAdornment: true,
                      prefix: (' (' + (area.measure || 'ha') + ')')
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={data?.monitoringData ? 4 : 6} sx={{ mt: 1 }}>
                <CardMedia key={'CM_' + area.id} height="194" component="img" image={area.url} alt={area.name} />
                <ViewSpecificActivitiesMonitoring data={data} obj_fn={obj_fn} area={area} />
              </Grid>
              {data?.monitoringData && (
                <Grid item xs={12} md={4} sx={{ mt: 2, p: 1 }}>
                  <>
                    <Typography variant="subtitle1" gutterBottom>
                      {data?.monitoringData[data.selectedId].monitoringName}
                    </Typography>
                    {data.monitoringData[data.selectedId].image ? (
                      <>
                        <CardMedia key={'CM_' + area.id} height="150" component="img" image={data.monitoringData[data.selectedId].image} alt={area.name} />
                        <Typography variant="subtitle1" gutterBottom>
                          intensidade:{data?.monitoringData && data?.monitoringData[data.selectedId].heatmapWeight} raio:{data?.monitoringData && data?.monitoringData[data.selectedId].heatmapRadius}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <LocationSearchingIcon sx={{ height: 100, width: 'auto', mt: 2, mb: 5 }} />
                        </Box>
                        Monitorando: {data?.monitoringData[data.selectedId].monitoringName}
                      </>
                    )}
                  </>
                </Grid>
              )}
            </Grid>

            {/* <Box sx={{ mt: 1 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Clique em "Selecionar monitoramento" e selecione a área a ser monitorada.
                          </Typography>
                        </Box> */}

            {/* <Grid item xs={12} md={data?.monitoringData ? 4 : 8} sx={{ mt: 2, p: 1 }}>
                <CardMedia key={'CM_' + area.id} height="194" component="img" image={area.imageDecode} alt={area.name} />
                <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                  <ViewSpecificActivitiesMonitoring data={data} obj_fn={obj_fn} area={area} />
                </Grid>
              </Grid> */}

            {data?.monitoringData && (
              <>
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={12} md={8} sx={{ mt: 2 }} alignItems="center">
                      {showText ? (
                        <Box display="flex" alignItems="center">
                          <Typewriter tam={60} text={'Com a área definida, é hora de realizar a aplicação.'} variant={'subtitle1'} color={'#2e7d32'} />
                        </Box>
                      ) : (
                        <Box display="flex" alignItems="center">
                          <ThreeDotsSpinner />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Como aplicar o defensivo">
                      Como aplicar o defensivo?
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem>
                          <Grid container direction="column" style={{marginTop: -2}}>
                            <Grid item>
                              <ListItemText primary="1- Selecione o defensivo (produto) do estoque" secondary="Caso não encontre o produto, clique em cadastro de produtos." />
                            </Grid>
                            <Grid item>
                              <ProductRegistrar filters={filters_prods} />
                            </Grid>
                          </Grid>
                        </ListItem>

                        <ListItem>
                          <ListItemText primary="2- Defina o local do estoque que será dado a baixa do produto" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="3- Defina a quantidade que será aplicado." />
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            )}
            {/* <Grid item xs={12} sm={12} sx={{ p: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ProductRegistrar filters={filters_prods} />
              </Box>
            </Grid> */}

            <Grid item key={'G1_' + area.id} xs={12} sm={12} sx={{ mt: 2 }}>
              <>
                {data?.monitoringData && (
                  <>
                    <Card key={'C1_' + area.id} sx={{ display: 'flex', padding: 1 }} id="img_plantio">
                      <Grid item key={'G3_' + area.id} xs={12} sm={12}>
                        <CardProduct data={data} obj_fn={obj_fn} area={area} />
                      </Grid>
                    </Card>
                  </>
                )}
              </>
            </Grid>
          </>
        ))}

        {/* Descrição */}
        {/* <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: '#2e7d32' }}>
                {' '}
                Descrição geral do plantio
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para o plantio"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid> */}

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
