import dayjs from 'dayjs';
import IconsHarvestActivities from '../Icons/harvest/IconsHarvestActivities';

function createData(id, activity, operation, progress, type, team, startDate, endDate, areaName, ha, isDeleted, planning, startDate_nf, endDate_nf, tipo_repos, icon) {
  return {
    id,
    activity,
    operation,
    progress,
    type,
    team,
    startDate,
    endDate,
    areaName,
    ha,
    isDeleted,
    planning,
    startDate_nf,
    endDate_nf,
    tipo_repos,
    icon,
  };
}

// formatar a data dentro da função handleRows
// function formatData(Inputdata) {
//   return !Inputdata ? '' : dayjs(Inputdata).format('DD/MM/YYYY')
// }

// Custom date comparison function considering both date and time
function compareDates(dateStr1, dateStr2) {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  return date1 - date2;
}

// Retorna o nome e o total de área utilizado
function calcAreas(tipo, data) {
  // console.log('calcAreas')
  let ha = 0;
  let areas = (
    <>
      {data.areas.map((area, index) => {
        ha += area.usedArea || 0;
        return (
          <p key={'area_' + index} style={{ fontSize: '0.875rem', marginBottom: '2px' }}>
            {area.name}: {area.usedArea || 0}
          </p>
        );
      })}{' '}
    </>
  );

  if (tipo === 'ha') {
    return ha;
  } else {
    return areas;
  }
}

function calcAreasSimple(tipo, data) {
  // console.log('calcAreasSimple')
  let ha = 0;
  let areas = (
    <>
      {data.info_areas.map((area, index) => {
        ha += area.usedArea || 0;
        return (
          <p key={'area_' + index} style={{ fontSize: '0.875rem', marginBottom: '2px' }}>
            {area.name}: {area.usedArea || 0}
          </p>
        );
      })}{' '}
    </>
  );

  if (tipo === 'ha') {
    return ha;
  } else {
    return areas;
  }
}

function calcRadius(raioEmMetros) {
  // Calcular o valor do metro quadrado
  const areaEmMetrosQuadrados = Math.PI * raioEmMetros * raioEmMetros;

  // Calcular o valor em hectares
  const areaEmHectares = areaEmMetrosQuadrados / 10000; // 1 hectare = 10.000 metros quadrados

  return {
    metroQuadrado: areaEmMetrosQuadrados,
    hectare: areaEmHectares,
  };
}

function Loadlist(currentHarvest, filters) {
  const id = currentHarvest.id;
  let rows_h = [];
  let data;

  // carrega toda lista de atividades
  const listActvs = JSON.parse(localStorage.getItem('listActvsH'));
  let listActivitys = [];

  if (filters) {
    if (filters.table_active && filters.table_active === 'realized') {
      listActivitys = listActvs.filter((item) => item.id_harvest === id && item.planning === filters.table_active && (filters.status === 'edit' ? item.isDeleted === 0 : item.isDeleted === 1));
    } else if (filters.table_active && filters.table_active === 'planning') {
      listActivitys = listActvs.filter((item) => item.id_harvest === id && item.planning === filters.table_active && (filters.status === 'trash' ? item.isDeleted === 0 : item.isDeleted === 1));
    }

    if (filters.date_i && filters.date_f) {
      listActivitys = listActivitys.filter(
        (item) => dayjs(item.startDate_nf).isBetween(filters.date_i, filters.date_f, 'day') || dayjs(item.endDate_nf).isBetween(filters.date_i, filters.date_f, 'day')
      );
    }

    if (filters.user && filters.user?.length > 0) {
      listActivitys = listActivitys.filter((item) => {
        let is_in = filters.user.filter((user_f) => {
          return Number(user_f.id) === Number(item.id_user);
        });
        if (is_in.length > 0) {
          return item;
        }
      });
    }
  } else {
    listActivitys = listActvs;
  }

  for (const activityList of listActivitys) {
    // console.log('listActivitys')
    // console.log(listActivitys)
    if (activityList.activity === 'PLANTIO') {
      //console.log("PLANTIO")
      data = createData(
        activityList.id, // id,
        activityList.name_activity, // activity,
        activityList?.operation, // operation,
        activityList?.progress, // progress,
        activityList.activity, // type,
        activityList.name_user, // team,
        activityList.date_start_f, // startDate,
        activityList.date_end_f, // endDate,
        calcAreas('areaName', activityList),
        calcAreas('ha', activityList),
        activityList.isDeleted, // isDeleted,
        activityList.planning, // planning,
        activityList.date_start, // startDate_nf,
        activityList.date_end // endDate_nf,
      );
    }
    if (activityList.activity === 'APLICAÇÃO') {
      //console.log("APLICAÇÃO")
      //const product = usestore.reposProducts.getProductsStore('id', activityList?.info_areas[0].id_product), // operation,
      data = createData(
        activityList.id, // id,
        activityList.name_activity, // activity,
        activityList?.name_product, // operation,
        activityList?.progress, // progress,
        activityList.activity, // type,
        activityList.name_user, // team,
        activityList.date_start_f, // startDate,
        activityList.date_end_f, // endDate,
        calcAreas('areaName', activityList),
        calcAreas('ha', activityList),
        activityList.isDeleted, // isDeleted,
        activityList.planning, // planning,
        activityList.date_start, // startDate_nf,
        activityList.date_end // endDate_nf,
      );
    }
    if (activityList.activity === 'PREPARAÇÃO DO SOLO') {
      // console.log('PREPARAÇÃO DO SOLO')
      // console.log('calcAreasSimple')
      calcAreasSimple('areaName', activityList);
      data = createData(
        activityList.id, // id,
        activityList.name_activity, // activity,
        activityList?.operation, // operation,
        activityList?.progress, // progress,
        activityList.activity, // type,
        activityList.name_user, // team,
        activityList.date_start_f, // startDate,
        activityList.date_end_f, // endDate,
        calcAreasSimple('areaName', activityList),
        calcAreasSimple('ha', activityList),
        activityList.isDeleted, // isDeleted,
        activityList.planning, // planning,
        activityList.date_start, // startDate_nf,
        activityList.date_end // endDate_nf,
      );
      // console.log(data)
      // console.log(data)
    }
    if (activityList.activity === 'MONITORING') {
      data = createData(
        activityList.id, // id,
        activityList?.name_activity, // activity,
        activityList?.monitoringName, // operation,
        activityList?.progress, // progress,
        activityList.activity, // type,
        activityList.name_user, // team,
        activityList.date_start_f, // startDate,
        activityList.date_end_f, // endDate,
        `${calcRadius(activityList?.heatmapRadius).hectare.toFixed(2)}ha (${calcRadius(activityList?.heatmapRadius).metroQuadrado.toFixed(2)}m)`,
        activityList?.area_area,
        // calcAreas('areaName', activityList),
        // calcAreas('ha', activityList),
        activityList.isDeleted, // isDeleted,
        activityList.planning, // planning,
        activityList.date_start, // startDate_nf,
        activityList.date_end // endDate_nf,
      );
    }
    if (activityList.activity === 'COLHEITA') {
      //console.log("COLHEITA")
      data = createData(
        activityList.id, // id,
        activityList.name_activity, // activity,
        activityList?.operation, // operation,
        activityList?.progress, // progress,
        activityList.activity, // type,
        activityList.name_user, // team,
        activityList.date_start_f, // startDate,
        activityList.date_end_f, // endDate,
        calcAreas('areaName', activityList),
        calcAreas('ha', activityList),
        activityList.isDeleted, // isDeleted,
        activityList.planning, // planning,
        activityList.date_start, // startDate_nf,
        activityList.date_end // endDate_nf,
      );
    }
    if (/.*VINCULA.*/.test(activityList.activity)) {
      //console.log("VINCULA")
      data = createData(
        activityList.id, // id,
        activityList.name_activity, // activity,
        activityList?.operation, // operation,
        activityList?.progress, // progress,
        activityList.activity, // type,
        activityList.name_user, // team,
        activityList.date_start_f, // startDate,
        activityList.date_end_f, // endDate,
        calcAreas('areaName', activityList),
        calcAreas('ha', activityList),
        activityList.isDeleted, // isDeleted,
        activityList.planning, // planning,
        activityList.date_start, // startDate_nf,
        activityList.date_end // endDate_nf,
      );
      //rows_lv.push(data);
    }

    data.icon = <IconsHarvestActivities activity={data.activity} widthStyles={'42'} />;
    data.tipo_repos = 'harvest';
    rows_h.push(data);
  }

  //ordenação
  rows_h.sort((a, b) => compareDates(b.startDate_nf, a.startDate_nf));

  return rows_h;
}

function LoadActivityListHarvest() {
  return {
    loadList: Loadlist,
  };
}

export default LoadActivityListHarvest;
