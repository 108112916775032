import { Box, Grid, Typography, Stack, Button, AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import StartThirdStepForm from './StartThirdStepForm';
import { useContext } from 'react';
import usePropertyReposStore from '../../../store/usePropertyReposStore';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { Context } from '../../../components/Context/AuthContext';
import { expandAndFade } from '../../../components/Animations/animationKeyFrames';
import { useState } from 'react';
import useStore from '../../../store/useStore';

const useStyles = makeStyles({
  // selected: {
  //     border: "2px solid green",

  // },
  img: {
    height: 'auto',
  },
});

export default function StartThirdStep({ data, setData, handleNext, handleBack, activeStep, steps, handleClose }) {
  console.log(data);

  const { aux_add, setAuxAdd } = useContext(Context);

  const reposProperty = usePropertyReposStore((state) => state);

  const [imageSrc, setImageSrc] = useState('/dist/image/startmap.jpeg');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const para validar o button continuar, se existem as 2 validações
  const isError = () => {
    console.log(property_address.address);
    if (property_address.address == '') {
      return true;
    }
    // if (!data.city || data.city.trim() === "") {
    //     return true;
    // }
    return false;
  };

  const returnError = () => {
    return false;
  };

  const color = '#2e7d32';

  const classes = useStyles;

  const usestore = useStore();
  const propertyRepos = usestore.reposProperty.propertyRepos;
  //const property = usestore.reposProperty.getPropertyStore('id', propertyRepos.id)[0];
  //const display_demo = JSON.parse(localStorage.getItem('display'));

  const [property_address, setProperty_address] = useState({
    id: data.id_property,
    //name: data.nameProperty,
    name: '',
    id_property: data.id_property,
    lat: '',
    lng: '',
    zoom: 15,
    address: '',
    description: '',
    area: data.area || '',
    measure: data.measure || '',
    city: '' || '',
    state: '' || '',
  });

  const onSubmit = async () => {
    console.log(onSubmit);
    console.log(property_address);
    // console.log(data.nameProperty);
    // console.log(reposProperty.propertyRepos.label);
    console.log(property_address.address == '');
    // if (data.address === "") {
    //   // console.log('ok');
    //   // toast.success("Lembre depois de cadastrar o nome da propriedade.");
    //   handleNext();
    //   return true;
    // }

    //setFormError({});
    //let errors = {};
    let formOk = true;

    if (property_address.address == '') {
      formOk = false;
    }

    console.log(property_address);

    if (formOk) {
      const header = { header: { 'Content-Type': 'application/json' } };
      const res = await api
        .put('/property/local', property_address, header)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success(`${property_address.name} se encontra no GOOGLE MAPS`);
            handleNext();
            // usestore.reposProperty.changePropertyRepos({ label: property_address.name, id: property_address.id });
            // usestore.reposProperty.fetch().then((resp) => {
            //   // setAuxAdd(aux_add + 1);
            //   handleNext();
            //   // setTimeout(() => {
            //   //   handleNext();
            //   // }, 200);
            // });
            //usestore.reposProperty.changePropertyRepos({ label: property_address.name, id: property.id });

            //usestore.reposProperty.fetch().then((resp) => {
            //handleNext();
            // setTimeout(() => {
            //   handleNext();
            //   // setTimeout(() => {
            //   //   setAuxAdd(aux_add + 1);
            //   // }, 5000);
            // }, 200);
            //});
          }
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
          toast.success('Falha ao editar a Propriedade tente novamente mais tarde!');
        });
    } else {
      // escreve mensagem de erro para cliente
      //setLoadingButton(false);
      toast.error('Por favor, preencha os campos obrigatórios');
      //setFormError(errors);
    }
  };
  console.log('property_address');
  console.log(property_address);

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <img src="/dist/img_fp/icon/icone-fazenda-premium.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8', height: 32, maxWidth: '100%' }} />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5} sx={{ mt: 0 }}>
              <Typography variant="h6" fontWeight={500}>
                Fazenda Premium
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container className={classes.root} sx={{ mt: 3 }} style={{ height: 'auto', maxWidth: '100%' }}>
          <Grid container>
            <StartThirdStepForm
              isError={isError}
              data={data}
              setProperty_address={setProperty_address}
              property_address={property_address}
              // mapCenter={mapCenter}
              // zoom={zoom}
              // setMapCenter={setMapCenter}
              // setZoom={setZoom}
              // setAddress={setAddress}
              // setAddress_complete={setAddress_complete}
              // address_complete={address_complete}
            />
          </Grid>
        </Grid>
        <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              {/* <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                <Span>cancelar</Span>
              </Button> */}
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button onClick={handleBack} color="success" sx={{ marginRight: 2 }} disabled={returnError()}>
                Retornar
              </Button>
              {/* O botão Confirmar e Salvar, leva para tela successo */}
              {/* <Button color="success" variant="contained" type="submit" onClick={isError() ? onSubmit() : () => null} disabled={isError()}> */}
              <Button
                sx={{
                  animation: `${expandAndFade} 2s linear infinite`, // Aplica a animação expandAndFade ao botão
                }}
                color="success"
                variant="contained"
                type="submit"
                onClick={onSubmit}
                disabled={isError()}
              >
                {activeStep === steps.length - 1 ? 'Confirmar e Salvar' : 'Continuar'}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  );
}
