import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import useStore from '../../../store/useStore';
import { useNavigate, useParams } from 'react-router-dom';
import { LocalAtm, PendingActions } from '@mui/icons-material';

const InfoModalTableProductionCost = ({ info }) => {
  const [open, setOpen] = useState(false);
  const usestore = useStore();
  const navigate = useNavigate();
  const { idFarm, id } = useParams();
  const creationHarvest = JSON.parse(localStorage.getItem('creationHarvestRepos'));
  let check = creationHarvest.tipo_repos === 'Criações' ? 'livestock' : 'harvest';
  console.log(check);

  console.log(info);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // resources - expenses and products
  let listResources = usestore.reposActivityResources.listActivityResources;
  console.log(listResources);

  //verificar se a propriedade id_product (nos products) ou id_expense (nas expenses) corresponde ao id no objeto info
  const matchedResource = listResources?.find(
    (resource) => resource.products.some((product) => product.id_product === info.id) || resource.expenses.some((expense) => [expense?.id_expense, expense?.id_expense_sub].includes(info.id))
  );

  console.log(matchedResource);

  let listTrans = usestore.reposFinances.listTransactions;
  console.log(listTrans);

  const matchedExpenses = listTrans?.find((expenses) => expenses.info.id_account === info.id);

  console.log(matchedExpenses);

  return (
    <>
      {/* Ícone que abre o modal */}
      <IconButton onClick={handleOpen}>
        <MessageIcon />
      </IconButton>

      {/* Modal */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h4" component="h2">
            Informações
          </Typography>
          {/* <Typography id="modal-description" sx={{ mt: 2 }}>
            {info.group_category}: {info.nome}
          </Typography> */}

          <Box sx={{ mt: 2 }}>
            {
              matchedResource ? (
                <div>
                  <p>
                    <strong>{info?.nome}</strong>
                  </p>
                  <p>
                    <strong>Tipo:</strong> {info?.tipo}
                  </p>
                  <p>
                    <strong>{info?.group_category}</strong>
                  </p>
                  {/* <p>
                  <strong>Categoria:</strong> {info.category}
                </p>
                <p>
                  <strong>Grupo de Categoria:</strong> {info.group_category}
                </p> */}
                  {/* <p>
                    <strong>Tipo de Recurso:</strong> {matchedResource?.type}
                  </p> */}
                  {/* <p>
                  <strong> {matchedResource.description} </strong>
                </p> */}
                  <Typography id="modal-description" sx={{ mt: 2 }}>
                    Para editar as informações do(a) {info?.nome} acesse a edição:
                  </Typography>
                  <Typography id="modal-description" sx={{ mt: 2 }}></Typography>
                  <Button onClick={() => (check == 'harvest' ? navigate(`/activitiesHarvest/${idFarm}/${id}`) : navigate(`/activities/${idFarm}/${id}`))} color="success" variant="contained">
                    <PendingActions /> {matchedResource?.description.match(/^[^;]+/)[0]}
                  </Button>
                </div>
              ) : matchedExpenses ? (
                <div>
                  <Typography id="modal-description" sx={{ mt: 2 }}>
                    {info.group_category}: <strong>{matchedExpenses?.info?.descriptionEntry}</strong>
                  </Typography>
                  <p>
                    <strong>{matchedExpenses?.description}</strong>
                  </p>
                  <Typography id="modal-description" sx={{ mt: 2 }}>
                    Para visualizar mais informações acesse o extrato do financeiro da propriedade
                  </Typography>
                  <Button sx={{ mt: 2 }} onClick={() => navigate(`/financial`)} color="inherit" variant="contained">
                    <LocalAtm /> Financeiro
                  </Button>
                </div>
              ) : (
                ''
              )
              // <p>Nenhuma infomação encontrada para este recurso.</p>
            }
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default InfoModalTableProductionCost;
