import { Grid, TextField, FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import PhoneTextField from '../../../../../components/Fields/PhoneTextField';

const AccountDetails = (props) => {
  const { formik } = props;

  const format = {
    variant: 'outlined',
    size: 'small',
    color: 'success',
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mt: -5 }}>
          <Alert severity="success">
            <AlertTitle>Veja que é muito fácil criar uma conta para a equipe</AlertTitle>
          </Alert>
          <Typography sx={{ mt: 2, mb: 1 }} variant="subtitle1">
            <strong>Precisamos definir um nome, um email e uma senha segura</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="name"
            label="Nome"
            variant="outlined"
            size="small"
            color="success"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="email"
            label="Email"
            variant="outlined"
            type="email"
            size="small"
            color="success"
            error={Boolean(formik.touched.email && formik.errors.email)}
            onChange={formik.handleChange}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneTextField phone={formik.values.phone} handleChange={formik.handleChange} format={format} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Senha com no mínimo 6 caracteres e um número"
            variant="outlined"
            size="small"
            type="password"
            color="success"
            error={Boolean(formik.touched.password && formik.errors.password)}
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Typography variant="caption" display="block" gutterBottom>
            Certifique-se de ter pelo menos 6 caracteres, incluindo um número e uma letra.
          </Typography>
        </Grid>
        {/* <Grid item xs={12} >
        <TextField
          name="confirmPassword"
          label="Confirm Password"
          variant="outlined"
          size="small"
          type="password"
          fullWidth
          error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
        />
      </Grid> */}
        {formik.errors.submit && (
          <Grid item xs={12}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AccountDetails;
