import React, { useState, useContext } from 'react';
import { Box, ListItem, ListItemText, Divider, Button } from '@mui/material';
import { AppBar, Grid, Stack, Toolbar, Typography } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import SpreadsheetAnimals from '../../../../../components/Spreadsheet/SpreadsheetAnimals';
import { Context } from '../../../../../components/Context/AuthContext';
import { makeStyles } from '@mui/styles';

import FormIdentifyHeader from './FormIdentifyHeader';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  content: {
    width: '100%',
    maxWidth: 'none',
  },
}));

export default function FormIdentification4({ onSubmit, data, obj_fn }) {
  const [showTextFase, setShowTextFase] = useState(false);
  setTimeout(() => {
    setShowTextFase(true);
  }, 500);

  const classes = useStyles();
  const { isMobile } = useContext(Context);
  const ismobile = isMobile.any();

  const handleSubmit = () => {
    // console.log("onSubmit");
    onSubmit();
  };

  const getTableAnimals = () => {
    const tabelaAnimals = <SpreadsheetAnimals listaP={data.table_confirm} view={true} />;
    return (
      <>
        {!ismobile ? (
          <Grid item xs={12} md={12} className={classes.container} sx={{ mt: 1, mb: 5 }}>
            {tabelaAnimals}
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ width: '100%', overflow: 'auto' }}>
            {tabelaAnimals}
          </Grid>
        )}
      </>
    );
  };

  const text_title = 'Passo 4: Confirmação dos dados';
  const txt_msn = 'Pronto! Agora basta conferir as informações e clicar em confirmar!';

  return (
    <>
      <FormIdentifyHeader text_title={text_title} txt_msn={txt_msn} showTextFase={showTextFase} imagem={'/dist/image/entrada.png'} />

      <Grid container spacing={2} sx={{}}>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary="Data de Início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
          </ListItem>
        </Grid>
        <Grid item xs={6} md={6}>
          <ListItem>
            <ListItemText primary="Data de Término" secondary={dayjs(data.date_prev_end).format('DD/MM/YYYY')} />
          </ListItem>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} sx={{}}>
        <ListItem>
          <ListItemText primary="Nome do Lote" secondary={data.lote.label} />
        </ListItem>
      </Grid>
      <Divider />
      <Grid container spacing={1} sx={{}}>
        <ListItem>
          <ListItemText primary="Fase de manejo" secondary={data.lote.fase_name} />
        </ListItem>
      </Grid>
      <Divider />
      <Grid container spacing={3} sx={{}}>
        <Grid item xs={4} md={4}>
          <ListItem sx={{ textAlign: 'center' }}>
            <ListItemText primary="Animais" secondary={data.num_animals} />
          </ListItem>
        </Grid>
        <Grid item xs={4} md={4}>
          <ListItem sx={{ textAlign: 'center' }}>
            <ListItemText primary="Fêmeas" secondary={data.num_females} />
          </ListItem>
        </Grid>
        <Grid item xs={4} md={4}>
          <ListItem sx={{ textAlign: 'center' }}>
            <ListItemText primary="Machos" secondary={data.num_males} />
          </ListItem>
        </Grid>
      </Grid>
      <Divider />

      <Grid container spacing={1} sx={{ mt: 1, mb: 1 }} direction="column" alignItems="center" justifyContent="center">
        <Stack spacing={0.5}>
          <Typography fontWeight={700}> Tabela dos animais </Typography>
        </Stack>
        {getTableAnimals()}
      </Grid>

      <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button color="success" variant="outlined" type="submit" onClick={obj_fn.handleClose}>
              cancelar
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Button onClick={obj_fn.handleBack} color="success" sx={{ marginRight: 2 }}>
              Retornar
            </Button>
            {/* O botão Confirmar e Salvar, leva para tela successo */}
            <Button color="success" variant="contained" type="submit" onClick={handleSubmit}>
              Confirmar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
