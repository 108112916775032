import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Category, Close, CurrencyExchange, ShoppingCart } from '@mui/icons-material';
import FooterModal from '../../../components/Footer/FooterModal';
import CustomRadioGroup from '../../../components/Fields/CustomRadioGroup';
import FormApportChoice from '../../financial/components/Forms/FormApportChoice';
import ResourcesStepFormTabGeneralExpenses from './ResourcesStepFormTabGeneralExpenses';
import ExpensesCostCalculator from './ExpensesCostCalculator';
import ResourcesStepFormTabInputs from './ResourcesStepFormTabInputs';
import Typewriter from '../../../components/Typewriter/Typewriter';
import { green } from '@mui/material/colors';
import { useState } from 'react';
import { invalid } from 'moment/moment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ResourcesStepFormMainTab({ data, obj_fn }) {
  console.log('ResourcesStepFormMainTab');
  const [value, setValue] = React.useState(0);
  const [showTabs, setShowTabs] = React.useState(false); // Estado para mostrar ou ocultar os tabs
  const [showApportionment, setShowApportionment] = React.useState(false); // Novo estado para controlar exibição de FormApportionment

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    if (showTabs == false) {
      obj_fn.setData((prevData) => ({
        ...prevData,
        resources: {
          ...prevData.resources,
          expenses: prevData.resources?.expenses || [], // Inicializa expenses se undefined
          products: prevData.resources?.products || [], // Inicializa products se undefined
        },
      }));
    }
  }, []);

  useEffect(() => {
    const savedShowTabs = localStorage.getItem('showTabs');
    //console.log(savedShowTabs);
    if (savedShowTabs !== null) {
      //console.log(savedShowTabs);
      setShowTabs(savedShowTabs === 'true');
    }
  }, []);

  // Função chamada para o segundo RadioGroup (mostrar FormApportionment)
  const handleApportionmentChange = (event) => {
    const newValue = event.target.value === 'Sim';
    var project = [];

    if (data.resources?.expenses?.length > 0 || data.resources?.products?.length > 0) {
      let aux_project = [];
      // busca dados do projeto ativo
      var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
      if (creationHarvestRepos.tipo_repos === 'Safras') {
        aux_project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id)[0];
      } else {
        aux_project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id)[0];
      }

      if (aux_project?.id) {
        project.push({
          id: aux_project.id,
          name: aux_project.name,
          id_account: aux_project.id_account,
          tipo_repos: aux_project.tipo_repos,
          val: 100,
        });
      }
    }
    obj_fn.setData((prevState) => ({
      ...prevState,
      projects_apport: project, // atualiza apport do data
      resources: {
        ...prevState.resources,
        projects_apport: project,
      },
    }));
    setShowApportionment(newValue); // Atualiza o estado para exibir ou ocultar FormApportionment
  };

  const bankRepos = obj_fn.usestore.reposBank.bankRepos;

  // useEffect para monitorar mudanças em data.projects_apport
  useEffect(() => {
    var project = data?.resources?.projects_apport || [];
    var project_data = data?.projects_apport || [];

    // inicia projects_apport
    if ((data.resources?.value > 0) && !project.length) {
      let aux_project = [];
      // busca dados do projeto ativo
      var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
      if (creationHarvestRepos.tipo_repos === 'Safras') {
        aux_project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id)[0];
      } else {
        aux_project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id)[0];
      }

      if (aux_project?.id) {
        project.push({
          id: aux_project.id,
          name: aux_project.name,
          id_account: aux_project.id_account,
          tipo_repos: aux_project.tipo_repos,
          val: 100,
        });
      }
    }

    if (project !== project_data) {
      obj_fn.setData((prevState) => ({
        ...prevState, // Mantém o estado anterior
        projects_apport: project, // Atualiza projects_apport
        resources: {
          ...prevState.resources, // Preserva outros valores de resources
          projects_apport: project, // Atualiza projects_apport
          bankAccount: bankRepos,
        },
      }));
    }
  }, [data?.projects_apport, data.value]);

  // Função chamada ao mudar a seleção (Sim/Não)
  const handleSelectChange = (event) => {
    const newValue = event.target.value === 'Sim';
    // console.log(newValue);

    setShowTabs(newValue); // Atualiza o estado de 'showTabs'
    localStorage.setItem('showTabs', newValue); // Salva a escolha no localStorage

    var project = [];
    if (!newValue) {
      // Limpa os dados de expenses, products, e projects_apport quando a escolha é "Não"
      obj_fn.setData((prevState) => ({
        ...prevState,
        projects_apport: project, // atualiza apport do data
        resources: {
          expenses: [],
          products: [],
          projects_apport: project,
        },
      }));
      // } else {
      //   // Sim
      //   // busca dados do projeto ativo
      //   var creationHarvestRepos = JSON.parse(localStorage.getItem('creationHarvestRepos') || '{ "label": "", "id": 0, "id_property": 0, "tipo_repos": "" }');
      //   if (creationHarvestRepos.tipo_repos === 'Safras') {
      //     project = obj_fn.usestore.reposHarvest.getHarvestStore('id', creationHarvestRepos.id);
      //   } else {
      //     project = obj_fn.usestore.reposLivestock.getLivestockStore('id', creationHarvestRepos.id);
      //   }

      //   obj_fn.setData((prevState) => ({
      //     ...prevState,
      //     projects_apport: project, // atualiza apport do data
      //     resources: {
      //       projects_apport: project,
      //     },
      //   }));
    }
  };

  // const para validar o button continuar
  const isError = () => {
    if (!showTabs) {
      return false;
    }
    const expenses = data.resources?.expenses || [];
    const products = data.resources?.products || [];
    // console.log(expenses.length > 0);
    //console.log(expenses.products > 0);
    if (expenses?.length > 0) {
      // console.log('expenses');
      // Verificar se algum item no array está inválido
      // return expenses.some((expense) => !expense.description || !expense.value || !expense.category);
      return expenses.some((expense) => !expense.value || !expense.category);
    }
    if (products?.length > 0) {
      // console.log('products');
      // Verificar se algum item no array está inválido
      return products.some((product) => !product.product.id || !product.location_selected.quant_utilizada);
    }

    // Se não houver despesas, considerar como válido (sem erro)
    return false;
  };

  const returnError = () => {
    return false;
  };

  //const [value, setValue] = useState(0); // controla a aba selecionada
  const [openDialog2, setOpenDialog2] = useState(false); // controla a visibilidade do modal

  // Função para abrir o modal
  const handleOpen = (index) => {
    setValue(index); // Define a aba selecionada
    setOpenDialog2(true); // Abre o modal
  };

  // Fecha o modal
  const handleClose = () => {
    setOpenDialog2(false);
    var totalQuantity = data.resources?.totalQuantity || data.totalQuantity;
    var totalValue = (data.resources?.value || data.value);

    var invalid_products = false;
    // remove invalid products
    var new_prods = data.resources?.products.filter((product) => {
      let valid = true;
      if (!product?.product?.id || !product?.location_selected?.quant_utilizada) {
        invalid_products = true;
        valid = false;
      }
      return valid;
    });

    // remove invalid expenses
    var invalid_expenses = false;
    var new_expenses = data.resources?.expenses.filter((expense) => {
      let valid = true;
      if (!expense.category || !expense.value) {
        valid = false;
      }
      if (!expense.category && expense.value) {
        totalValue -= expense.value;
        valid = false;
      }
      if (!valid) {
        totalQuantity -= 1;
        invalid_expenses = true;
      }
      return valid;
    });

    if (invalid_products || invalid_expenses) {
      obj_fn.setData((prevState) => ({
        ...prevState,
        totalQuantity: totalQuantity,
        value: totalValue,
        resources: {
          ...prevState.resources, // Preserva outros valores de resources
          products: new_prods,
          expenses: new_expenses,
          totalQuantity: totalQuantity,
          value: totalValue,
        },
      }));
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Pergunta com combobox Sim/Não */}
      {/* Usando o componente CustomRadioGroup */}
      <Grid container spacing={3} sx={{ mb: 0, mt: 5 }}>
        <CurrencyExchange />
        <Box sx={{ ml: 1, display: 'flex' }}>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>Registros dos custos de produção:</Typography>
          </Stack>
        </Box>
      </Grid>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <CustomRadioGroup labelText="Você gostaria de registrar as despesas (custo operacional) para esta atividade?" value={showTabs ? 'Sim' : 'Não'} onChange={handleSelectChange} />
      </FormControl>

      {showTabs && (
        <>
          <Grid container sx={{ mt: 2 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitle1" color="textSecondary" fontWeight={500}>
                Ao optar por 'Sim', por favor, registre as despesas ou insumos utilizados a partir do estoque.
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{}} />
          <Grid container spacing={3} sx={{ mb: 0, mt: 4 }}>
            <Box sx={{ ml: 1, display: 'flex' }}>
              <Stack spacing={0.5}>
                <Typography fontWeight={700}>
                  <ShoppingCart sx={{ mr: 1 }} />
                  Despesas (Custos Operacionais)
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid container spacing={6} sx={{ ml: -2, mb: 0, mt: 0 }}>
            <Grid item xs={12} md={12} alignItems="center" style={{ height: obj_fn.ismobile ? 80 : 60 }} sx={{ ml: -3, mt: 0, mb: 0 }}>
              <Typography style={{ color: green[800] }}>
                <Typewriter tam={0} minHeight={0} text={`Adicione no carrinho os tipo de itens desejados para a ${data.operationType}`} />
              </Typography>
            </Grid>
            {/* Botões de seleção */}
            <Box sx={{ width: '100%' }}>
              <FormControl display="flex" sx={{ m: 0, mb: 3, mt: 0 }}>
                {/* <Typography variant="h6" color="success">
              Itens para: {data.operationType}
            </Typography> */}
                <Grid container spacing={6} justifyContent="center" alignItems="center" sx={{ ml: -2, mb: 0, mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant={value === 0 ? 'contained' : 'outlined'}
                      color="success"
                      onClick={() => handleOpen(0)} // Abre o modal e define a aba 0
                      sx={{ mx: 1 }}
                    >
                      <Category sx={{ mr: 0.5 }} />

                      <Badge
                        badgeContent={data.resources?.expenses?.length}
                        color="success"
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: 'white', // Fundo branco
                            color: 'black', // Texto preto
                            //transform: 'translateX(20px)', // Deslocamento para a direita
                            //mr: 0.5,
                          },
                        }}
                      >
                        Adicionar Depesas&nbsp;&nbsp;
                      </Badge>
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant={value === 1 ? 'contained' : 'outlined'}
                      color="success"
                      onClick={() => handleOpen(1)} // Abre o modal e define a aba 1
                      sx={{ mx: 1 }}
                    >
                      <Category sx={{ mr: 0.5 }} />
                      <Badge
                        badgeContent={data.resources?.products?.length}
                        color="success"
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: 'white', // Fundo branco
                            color: 'black', // Texto preto
                            //transform: 'translateX(20px)', // Deslocamento para a direita
                            //mr: 0.5,
                          },
                        }}
                      >
                        Adicionar Uso de Insumos&nbsp;&nbsp;
                      </Badge>
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>

              {/* Modal que será aberto quando o item for selecionado */}
              <Dialog open={openDialog2} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                  <>
                    {value === 0 ? <Category sx={{ mr: 0.5 }} /> : value === 1 ? <Category sx={{ mr: 0.5 }} /> : <></>} Adicionar {value === 0 ? 'Depesas' : value === 1 ? 'uso de Insumos' : ''}
                  </>
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  })}
                >
                  <Close />
                </IconButton>
                <DialogContent>
                  {/* Exibe o conteúdo do TabPanel com base no valor selecionado */}
                  <TabPanel value={value} index={0} label={'Add Despesas'}>
                    <ResourcesStepFormTabGeneralExpenses data={data} obj_fn={obj_fn} />
                  </TabPanel>
                  <TabPanel value={value} index={1} label={'Add Insumos'}>
                    <ResourcesStepFormTabInputs data={data} obj_fn={obj_fn} />
                  </TabPanel>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="success" disabled={isError()}>
                    Concluir
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
          <div style={{ border: `1px solid ${green[800]}`, padding: '16px', borderRadius: '8px' }}>
            <ExpensesCostCalculator data={data} obj_fn={obj_fn} />
          </div>
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 5 }}>
            <Tabs textColor="success" TabIndicatorProps={{ style: { background: 'green' } }} variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <PermIdentity sx={{ mr: 0.5, mb: 0.2 }} />
                    Despesas Gerais
                  </Grid>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Grid container direction="row" alignItems="center">
                    <CenterFocusStrong sx={{ mr: 0.5, mb: 0.2 }} />
                    Insumos (produtos)
                  </Grid>
                }
                {...a11yProps(1)}
              />
              
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ResourcesStepFormTabGeneralExpenses data={data} obj_fn={obj_fn} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ResourcesStepFormTabInputs data={data} obj_fn={obj_fn} />
          </TabPanel> */}
          <Divider sx={{ mt: 4 }} />
          {(data.resources?.expenses?.length > 0 || data.resources?.products?.length > 0) && (
            <FormControl fullWidth sx={{ mt: 1 }}>
              <CustomRadioGroup
                labelText={
                  <>
                    Você deseja atribuir as despesas à propriedade ou distribuí-las (rateá-las)?
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 0 }}>
                      Caso opte por 'Não', a despesa será automaticamente registrada na safra atual ({data?.projects_apport[0]?.name}).
                    </Typography>
                  </>
                }
                value={showApportionment ? 'Sim' : 'Não'}
                onChange={handleApportionmentChange}
              />
            </FormControl>
          )}
          {showApportionment && (
            <Grid item xs={12} sm={12} sx={{ mt: 0, mb: 3 }}>
              <FormApportChoice data={data} obj_fn={obj_fn} />
            </Grid>
          )}
          <Divider sx={{}} />
          <Grid container sx={{ mt: 2, mb: 2 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitile1" style={{ color: green[800] }} fontWeight={500}>
                Optando por inserir despesas é necessário inserir as informações para continuar.
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {!showTabs && (
        <>
          <Grid container sx={{ mt: 3 }}>
            <Grid item sm={12} xs={12}>
              <Typography paragraph variant="subtitle1" style={{ color: green[800] }} fontWeight={500}>
                Se você optar por não registrar as despesas, clique em 'CONTINUAR' para prosseguir...
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {!openDialog2 && <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />}
    </Box>
  );
}
