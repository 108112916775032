import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography } from '@mui/material';

const AnimalCounter = ({ data, loadAdd }) => {
  const [timerNumber, setTimerNumber] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (loadAdd) {
      const length = data;

      // Determinar o tempo do contador
      let timer = 0;
      if (length >= 0 && length <= 100) {
        timer = 2;
      } else if (length >= 101 && length <= 200) {
        timer = 5;
      } else if (length >= 201 && length <= 300) {
        timer = 10;
      } else if (length >= 301 && length <= 400) {
        timer = 25;
      } else if (length > 400) {
        timer = 35 + ((length - 400) / 100) * 10; // Adicionar 10 segundos para cada 100 acima de 400
      }

      setTimerNumber(timer);
      setCountdown(timer);
      setShowModal(true);

      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setShowModal(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval); // Cleanup ao desmontar
    } else {
      setShowModal(false);
    }
  }, [loadAdd, data]);

  // Estilo do modal
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          Carregando Tabela
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Tempo restante: {timerNumber}s.
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Por favor, aguarde.
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Essa ação consome muito recurso de hardware.
        </Typography>
      </Box>
    </Modal>
  );
};

export default AnimalCounter;
