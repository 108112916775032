import React, { useContext, useEffect, useState } from 'react'
import { IconButton, Menu, MenuItem, Paper } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import dayjs from 'dayjs'
import useStore from '../../../store/useStore'
import { Context } from '../../../components/Context/AuthContext'
import ModalVisualizeBill from './outhers/ModalVisualizeBill'
import ModalDeleteBill from './outhers/ModalDeleteBill'
import ChipStatusBill from './outhers/ChipStatusBill'
import TableBuyContent from '../../buy/TableBuyContent'
import TableBuyDelete from '../../buy/TableBuyDelete'
import TableSaleContent from '../../sales/TableSaleContent'
import TableSaleDelete from '../../sales/components/TableSaleDelete'
import ViewMaintenance from '../../patrimony/components/componentsMaintenance/ViewMaintenance'
import { formatValue } from '../../../utils/functions'
import DeleteMaintenance from '../../patrimony/components/componentsMaintenance/DeleteMaintenance'

const getColumns = (type) => {
  return [
    //{ id: 'id_bill', label: 'id_bill', minWidth: 50 },
    { 
      id: 'id',
      label: 'ID',
      minWidth: 50
    },
    { 
      id: 'date_expire',
      label: 'Vencimento',
      minWidth: 100,
      align: 'center',
    },
    // {
    //   id: 'date_payment',
    //   label: (type === 'income' ? 'Recebimento' : 'Pagamento'),
    //   minWidth: 100,
    //   align: 'center',
    // },
    {
      id: 'description',
      label: 'Descrição',
      minWidth: 170,
      //align: 'right',
      //format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'person',
      label: (type === 'income' ? 'Cliente' : 'Fornecedor'),
      minWidth: 100,
      //align: 'right',
      //format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'installment',
      label: 'Parcela',
      minWidth: 120,
      align: 'right',
      //format: (value) => value.toFixed(2),
    },
    {
      id: 'value',
      label: 'Valor (R$)',
      minWidth: 120,
      align: 'right',
      //format: (value) => value.toFixed(2),
    },
    {
      id: 'situation',
      label: 'Situação',
      minWidth: 120,
      align: 'center',
    },
    {
      id: 'actions',
      label: 'Ações', // Nomeie a nova coluna como 'Ações'
      minWidth: 100,
      align: 'center',
    },
  ]
}

// Função para criar dados com valores iniciais para "Ações" (ícone)
function createDataRow(item, id, date_expire, date_payment, description, person, installment, value, status, type) {
  return { item, id, date_expire, date_payment, description, person, installment, value, status, type };
}

export default function FinancialBillsTable({ type, rowsBills }) {
  const usestore = useStore();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { aux_add, setAuxAdd, isMobile } = useContext(Context);
  const userCurrent = JSON.parse(localStorage.getItem("userCurrent"));

  const [obj_fn, setObjFun] = useState({
    usestore: usestore,
    aux_add: aux_add,
    setAuxAdd: setAuxAdd,
    isMobile: isMobile,
    userCurrent: userCurrent,
    isOpen: 0,
    show_btn: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const [openShow, setOpenShow] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  // Gerenciamento do estado do menu
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedRowId, setSelectedRowId] = useState(null);
  const handleMenuClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  }

  const handleMenuClose = (e, type, idBill) => {
    setAnchorEl(null);
    if (type === 'show') {
      setOpenShow(true);
    } else if (type === 'delete') {
      setOpenDel(true);
    }
    setObjFun(prevState => ({ ...prevState, isOpen: idBill }));
  }
  const handleCloseShow = () => {
    setOpenShow(false);
    setObjFun(prevState => ({ ...prevState, isOpen: 0 }));
    setSelectedRowId(null);
  }
  const handleCloseDel = () => {
    setOpenDel(false);
    setObjFun(prevState => ({ ...prevState, isOpen: 0 }));
    setSelectedRowId(null);
  }

  function getVizualize(row, type) {
    var dialog = <></>;
    if (selectedRowId === row.id) {
      let aux_tipo = (row.type).toLowerCase();
      let item;
      if (/compra/.test(aux_tipo)) {
        if (type === 'show') {
          item = obj_fn.usestore.reposBuys.getBuyStore('id_trans_pay', row.item.id_transaction)[0];
          dialog = <TableBuyContent row={item} usestore={usestore} openExtern={openShow} handleCloseExtern={handleCloseShow}/>;
        } else { //if (type === 'delete') {
          dialog = <TableBuyDelete row={row} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn} />;
        }
      } else if (/venda.*/.test(aux_tipo)) {
        item = obj_fn.usestore.reposSales.getSaleStore('id_trans_pay', row.item.id_transaction)[0];
        if (type === 'show') {
          dialog = <TableSaleContent row={item} usestore={usestore} openExtern={openShow} handleCloseExtern={handleCloseShow}/>;
        } else { //if (type === 'delete') {
          dialog = <TableSaleDelete row={row} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn} />;
        }
      } else if (/manutenção.*/.test(aux_tipo)) {
        item = obj_fn.usestore.reposPatrimonyMaintenance.getPatrimonyMaintenanceStore('id_trans_pay', row.item.id_transaction)[0];
        if (type === 'show') {
          dialog = <ViewMaintenance row={item} obj_fn={obj_fn} openExtern={openShow} handleCloseExtern={handleCloseShow} />;
        } else { //if (type === 'delete') {
          dialog = <DeleteMaintenance row={item} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn} setObjFun={setObjFun} />;
        }
      } else {
        if (type === 'show') {
          dialog = <ModalVisualizeBill bill={row.item} open={openShow} handleClose={handleCloseShow} obj_fn={obj_fn} />;
        } else { //if (type === 'delete') {
          dialog = <ModalDeleteBill bill={row.item} open={openDel} setOpen={setOpenDel} handleClose={handleCloseDel} obj_fn={obj_fn} />;
        }
      }
    }
    return dialog;
  }

  // retorna o nome da pessoa
  function getNamePerson(id_person) {
    const person = usestore.reposPerson.getPersonStore('id', id_person)[0];
    return person?.name || '';
  }

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setColumns(getColumns(type));

    const aux_list = rowsBills.map((item, index) => {
      return createDataRow(
        item,
        item.id,
        dayjs(item.date_expire).format('DD/MM/YYYY HH:mm'),
        item.date_payment ? dayjs(item.date_payment).format('DD/MM/YYYY HH:mm') : '',
        item.description,
        item.info.id_person ? getNamePerson(item.info.id_person) : '',
        (item.portion_text || item.portion),
        formatValue(item.value),
        item.status,
        (item.info.type).toLowerCase()
      );
    });
    setRows(aux_list);
  }, [aux_add, rowsBills]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="Tabela de contas">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold', }} >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'actions') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <IconButton aria-controls="Ações" aria-haspopup="true" onClick={(e) => handleMenuClick(e, row.id)}>
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id={`actions-menu-${row.id}`}
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl) && selectedRowId === row.id}
                              onClose={handleMenuClose}
                            >
                              {/* <MenuItem onClick={(e) => handleMenuClose(e, 'edit', row.id)}>Editar</MenuItem> */}
                              <MenuItem onClick={(e) => handleMenuClose(e, 'show', row.id)}>Visualizar</MenuItem>
                              <MenuItem onClick={(e) => handleMenuClose(e, 'delete', row.id)}>Deletar</MenuItem>
                            </Menu>
                            {!openShow ? <></> : getVizualize(row, 'show')}
                            {!openDel ? <></> : getVizualize(row, 'del')}
                          </TableCell>
                        )
                      } else if (column.id === 'situation') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <ChipStatusBill row_data={row.item} obj_fn={obj_fn} />
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Linhas por página"}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
