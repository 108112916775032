import * as React from 'react';
import { Box, Card, CardContent, CardMedia, Grid, ListItem, ListItemIcon, ListItemText, Stack, TextareaAutosize, Typography } from '@mui/material';
import { Checklist, Input, Map, Notes } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import dayjs from 'dayjs';
import FooterModal from '../../../../../components/Footer/FooterModal';
import ChartBarHActvSoul from '../../../../../components/Charts/ChartBarHActvSoul';
import ExpensesCostCalculator from '../../../../activitiesResources/Resources/ExpensesCostCalculator';
import { formatValue } from '../../../../../utils/functions';
import { useEffect } from 'react';

export default function FormSoilPreparationStep4({ data, obj_fn }) {
  // const [showTextFase, setShowTextFase] = useState(false);
  // setTimeout(() => {
  //   setShowTextFase(true);
  // }, 500);

  // const para validar o button continuar
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  const handleChange = (event) => {
    obj_fn.setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  // calcula o valor de porcentagem sobre um valor
  function calcValue(percent, value) {
    var aux_value = value;
    if (typeof value === 'string') {
      aux_value = parseFloat(value);
    }
    var aux_val = aux_value * (parseFloat(percent || '0') / 100);

    return formatValue(aux_val, true);
  }

  const savedShowTabs = localStorage.getItem('showTabs');

  const [showTabs, setShowTabs] = React.useState(savedShowTabs);

  useEffect(() => {
    const NewSavedShowTabs = localStorage.getItem('showTabs');
    if (NewSavedShowTabs !== null) {
      setShowTabs(NewSavedShowTabs === 'true');
    }
  }, []);

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Datas e progresso do preparo de solo</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de término" secondary={dayjs(data.date_end ? data.date_end : data.date_prev_end).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Progresso" secondary={data.progress} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Área(s) de preparo de solo */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) de preparo do solo</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sm={12}>
                <>
                  <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                    <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                      <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                        <CardMedia key={'CM_' + area.id} component="img" sx={{ height: 260 }} image={area.url} alt={area.name} />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                        <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
                          <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                            <Grid item key={'G2_' + area.id} xs={12} sm={12} sx={{ ml: 1.5 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                Área: <strong>{area.name}</strong>
                              </Typography>
                              <Typography variant="subtitle2" gutterBottom>
                                <i className="fa fa-map text-success">
                                  {' '} Totalidade {formatValue(area.area)} ({area.measure})
                                </i>
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                Área de preparo do solo: <strong>{formatValue(area.usedArea || area.area)}</strong> ({area.measure})
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={12} sx={{ mt: -2 }}>
                            <ChartBarHActvSoul usedArea={area?.usedArea || area?.area} areaTotal={area?.area} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </>
          ))}
        </Grid>
        {savedShowTabs && (
          <>
            <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
              <Checklist />
              <Box sx={{ ml: 1, display: 'flex' }}>
                <Stack spacing={0.5}>
                  <Typography fontWeight={700}>Custos Operacionais</Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
              <Grid item xs={12} sm={12}>
                <Card>
                  <ExpensesCostCalculator data={data} obj_fn={obj_fn} />
                </Card>
              </Grid>
            </Grid>
            {/* Rateio e Descrição */}
            {!data?.projects_apport?.length > 0 && !data.description ? (
              <></>
            ) : (
              <>
                <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
                      <Stack spacing={0.5}>
                        <Typography fontWeight={700}>
                          <Notes sx={{ mr: 1 }} />
                          Rateios e Descrição
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  {data?.projects_apport?.length > 0 ? (
                    <>
                      <Grid item>
                        <ListItemText primary="Lançar valor para a Propriedade ou ratear com Criações ou Safras" />
                        <Grid container spacing={2} sx={{ mt: '-20px' }}>
                          {data?.projects_apport?.map((project) => (
                            <Grid key={project.id + '_' + project.tipo_repos} item container alignItems="left" justifyContent="left" xs={6} sm={6}>
                              <Card sx={{ display: 'flex', minWidth: '450px' }}>
                                <ListItemIcon>
                                  <CardContent sx={{ flex: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pl: 1, pb: 1 }}>
                                      <Typography variant="subtitle1" gutterBottom>
                                        {project.name} ({project.tipo_repos === 'Property' ? 'Propriedade' : project.tipo_repos})
                                      </Typography>
                                      <Typography variant="subtitle2" gutterBottom>
                                        Rateio de: {project.val}%. ({calcValue(project.val, data.value_end)})
                                      </Typography>
                                    </Box>
                                  </CardContent>
                                </ListItemIcon>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.description ? (
                    <>
                      <Grid item xs={12} sm={12}>
                        <ListItem>
                          <ListItemText primary="Descrição Geral do Lançamento:" secondary={data.description} />
                        </ListItem>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </>
            )}
          </>
        )}
        <Grid container spacing={3} sx={{ mb: 0, mt: 2 }}>
          <Notes />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700} style={{ color: green[800] }}>
                {' '}
                Descrição/observações da atividade de preparo do solo
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0 }}>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              minRows={3} // Customize the initial number of rows
              maxRows={10} // Customize the maximum number of rows
              id="description"
              name="description"
              placeholder="Informações auxiliares para o preparo do solo"
              value={data.observations}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              style={{ width: '100%', padding: '8px' }}
            />
          </Grid>
        </Grid>

        {/* Descrições */}
        {/* <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Descrição geral do preparo de solo
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.description} />
            </ListItem>
          </Grid>
        </Grid> */}
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
