import * as React from 'react'
import { Box, Card, CardMedia, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Input, Map } from '@mui/icons-material';
import dayjs from 'dayjs';
import FooterModal from '../../../../../components/Footer/FooterModal';
import { formatValue } from '../../../../../utils/functions';

export default function FormApplicationsStep2({ data, obj_fn }) {
  // const [showTextFase, setShowTextFase] = useState(false);
  // setTimeout(() => {
  //   setShowTextFase(true);
  // }, 500);

  // const para validar o button continuar
  const isError = () => {
    return false;
  };

  const returnError = () => {
    return false;
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        {/* dados gerais */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
          <Input />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Datas e progresso da aplicação</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 0, mt: -3 }}>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de início" secondary={dayjs(data.date_start).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Data de término" secondary={dayjs(data.date_end ? data.date_end : data.date_prev_end).format('DD/MM/YYYY')} />
            </ListItem>
          </Grid>
          <Grid item xs={4} sm={4}>
            <ListItem>
              <ListItemText primary="Progresso" secondary={data.progress} />
            </ListItem>
          </Grid>
        </Grid>

        {/* Área(s) de plantio */}
        <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Área(s) da aplicação</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: -2 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item key={'G1_' + area.id} xs={12} sm={12}>
                {obj_fn.isMobile ? (
                  <>
                    <Grid item key={'G2_' + area.id} xs={12} sm={12} sx={{ mb: 1 }}>
                      <CardMedia key={'CM_' + area.id} component="img" sx={{ height: 90, minWidth: 125 }} image={area.url} alt={area.name} />
                    </Grid>
                    <Grid container key={'G3_' + area.id} xs={12} sm={12}>
                      <Typography variant="subtitle1" align="left">
                        Área: {area.name}
                      </Typography>
                      <Typography variant="subtitle2" align="right" style={{ marginLeft: '10px' }}>
                        <i className="fa fa-map text-success">
                          {' '} {formatValue(area.area)} ({area.measure})
                        </i>
                      </Typography>
                    </Grid>
                    <Grid item key={'G4_' + area.id} xs={12} sm={12} sx={{ mb: 0, mt: -1 }}>
                      <ListItem>
                        <ListItemText primary="Área utilizada" secondary={
                          formatValue((area.usedArea || area.area), false, {end: true, prefix: area.measure})} />
                      </ListItem>
                    </Grid>
                    <Grid item key={'G5_' + area.id} xs={12} sm={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                          <ListItem>
                            <ListItemText primary="Produto" secondary={area.product?.name} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <ListItem>
                            <ListItemText primary="Local de estoque" secondary={area.product?.location_selected?.name} />
                          </ListItem>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mt: -3 }}>
                        <Grid item xs={4} sm={4}>
                          <ListItem>
                            <ListItemText primary="Qtd. utilizada" secondary={
                              formatValue(area.product?.location_selected?.quant_utilizada) + (
                              area.product?.packaging_unit === 'unidade' ? 
                              area.product?.packaging_sigla ? " (" + area.product?.packaging_sigla + ") " : '' :
                              area.product?.unit_sigla ? " (" + area.product?.unit_sigla + ") " : ''
                            )} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <ListItem>
                            <ListItemText primary="Custo" secondary={formatValue(area.product?.location_selected?.value, true)} />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Card key={'C1_' + area.id} sx={{ display: 'flex' }} id="img_c_plantio">
                      <CardMedia key={'CM_' + area.id} component="img" sx={{ width: 125, p: 1 }} image={area.imageDecode} alt={area.name} />
                      <Grid item key={'G3_' + area.id} xs={12} sm={3}>
                        <Typography variant="subtitle1" gutterBottom>
                          Área:
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {area.name}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          <i className="fa fa-map text-success">
                            {' '} {formatValue(area.area)} ({area.measure})
                          </i>
                        </Typography>
                        <ListItem>
                          <ListItemText primary="Área utilizada" secondary={
                            formatValue((area.usedArea || area.area), false, {end: true, prefix: area.measure})} />
                        </ListItem>
                      </Grid>
                      <Grid item key={'G4_' + area.id} xs={12} sm={9}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6}>
                            <ListItem>
                              <ListItemText primary="Produto" secondary={area.product?.name} />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <ListItem>
                              <ListItemText primary="Local de estoque" secondary={area.product?.location_selected?.name} />
                            </ListItem>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 1 }}>
                          <Grid item xs={4} sm={4}>
                            <ListItem>
                              <ListItemText primary="Quantidade utilizada" secondary={
                                formatValue(area.product?.location_selected?.quant_utilizada) + (
                                area.product?.packaging_unit === 'unidade' && area.product?.packaging_name !== 'Unidade' ?
                                area.product?.packaging_sigla ? " (" + area.product?.packaging_sigla + ") " : '' :
                                area.product?.unit_sigla ? " (" + area.product?.unit_sigla + ") " : ''
                              )} />
                            </ListItem>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <ListItem>
                              <ListItemText primary="Custo" secondary={formatValue(area.product?.location_selected?.value, true)} />
                            </ListItem>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </>
                )}
              </Grid>
            </>
          ))}
        </Grid>

        {/* Descrições */}
        {/* <Grid container spacing={3} sx={{ mb: 2, mt: 2 }}>
          <Box sx={{ mb: -1, ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>
                <Notes sx={{ mr: 1 }} />
                Descrição geral da aplicação
              </Typography>
            </Stack>
          </Box>
          <Grid item xs={12} sm={12}>
            <ListItem>
              <ListItemText primary="" secondary={data.description} />
            </ListItem>
          </Grid>
        </Grid> */}
      </Grid>

      <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
    </>
  );
}
