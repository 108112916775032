import React, { useEffect, useState } from 'react'
import { Box, Button, CardMedia, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material"
import { Map, Queue } from '@mui/icons-material'
import AutocompleteGeneric from '../../../../components/Fields/AutocompletGeneric'
import IconsLivestockActivities from '../../../../components/Icons/IconsLivestockActivities';
import GenericValueField from '../../../../components/Fields/GenericValueField'
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import { formatValue } from '../../../../utils/functions';
import { green } from '@mui/material/colors';

const default_lote = {
  id: null,
  id_livestock: null,
  livestock: null,
  id_area: null,
  area: null,
  new_lote: 0,
  name: '',
  new_weight: 0,
  weight_unit: 'Kg',
  quant: 0,
  value: 0,
  fase_name: 'Cria',
  image: '',
  num_animals: 0,
  num_females: 0,
  num_males: 0,
  id_group: null
}
const tipo_lote = [
  { id: 0, label: 'Lote cadastrado' },
  { id: 1, label: 'Novo Lote' },
];

const faseManejo = [
  {id: 'Cria', label: 'Cria'},
  {id: 'Recria', label: 'Recria'},
  {id: 'Engorda', label: 'Engorda'},
  {id: 'Reprodução', label: 'Reprodução'},
  {id: 'Produção', label: 'Produção'},
  // "Completo",
];

export default function FormAddLotesBuy({ data, obj_fn, handleClose }) {
  const optionsLotesList = obj_fn.usestore.reposLivestock.getLotesStore('id_property', data.id_property);
  const optionsLotes = optionsLotesList.map((item) => {
    item.weight_unit = item.weight_unit || 'kg';
    item.peso = formatValue(item.weight || 0) + " (" + item.weight_unit + ")";
    item.new_lote = 0;

    if (!item.image) {
      const listImageRepos = obj_fn.usestore.reposImages.getImageStore("lote", item.id);
      const itemData = listImageRepos.filter((img) => {
        return img.img;
      });
      item.image = itemData[0] ? itemData[0].img : null;
    }

    return item;
  })

  const [selectedLote, setSelectedLotes] = useState(default_lote);

  const handleChange = (event, blur) => {
    let field = event.target.name;
    let value = event.target.value;
    if (value && (field === 'value' || field === 'new_weight' || field === 'new_lote' )) {
      value = parseFloat(value);
    } else if (field === 'name' && blur) {
      value = value.trim();
    }
    if (field === 'new_lote') {
      // let aux_lot = default_lote;
      // aux_lot.new_lote = value;
      // setSelectedLotes(aux_lot);
      setSelectedLotes({ ...default_lote, new_lote: value });
    } else {
      setSelectedLotes(prevState => ({ ...prevState, [field]: value }));
    }
  }

  const handleChangeSelect = (field, newValue) => {
    // console.log('handleChangeSelect')
    let aux_lote = newValue ? JSON.parse(JSON.stringify(newValue)) : null;

    setSelectedLotes(aux_lote);
    obj_fn.setData(prevState => ({ ...prevState, lote_selected: aux_lote }));
  }

  const opt_lotes = {
    multiple: false,
    name: 'lote',
    field: 'lote',
    label: ('Lote para: ' + data.operationType),
    values: optionsLotes,
    helperText: (
      <>
        Selecione o lote desejado
        <br />
        <span id="span_qtd_anim_sale" style={{ color: green[800] }}>
          Qtd. animais Não Identificados: 
          <strong> {selectedLote?.num_animals || '-'}</strong>
        </span>
      </>
    ),
    listHeight: 225,
    disableClearable: true,
  };

  const optionsLivestockList = obj_fn.usestore.reposLivestock.getLivestockStore('id_property', data.id_property);
  const opt_livestock = {
    name: 'livestock',
    field: 'livestock',
    label: 'Criações',
    values: optionsLivestockList,
    variant: 'standard',
    helperText: (<>
      Selecione em qual Criação lote ficará
    </>),
    listHeight: 225,
    disableClearable: true,
  };
  const handleChangeSelectLivestock = (field, newValue) => {
    // console.log('handleChangeSelectLivestock')
    let aux_livestock = newValue ? JSON.parse(JSON.stringify(newValue)) : null;

    setSelectedLotes(prevState => ({
      ...prevState,
      livestock: aux_livestock,
      id_livestock: (newValue ? newValue.id : null)
    }));

    if (selectedLote?.new_lote) {
      setSelectedLotes(prevState => ({ ...prevState, id_group: (newValue ? newValue.id_group : null) }));
    }
  }

  const handleChangeSelectArea = (field, newValue) => {
    // console.log('handleChangeSelectArea')
    let aux_area = newValue ? JSON.parse(JSON.stringify(newValue)) : null;

    setSelectedLotes(prevState => ({
      ...prevState,
      area: aux_area,
      id_area: (newValue ? newValue.id : null)
    }));
  }

  const handleAreas = () => {
    const optionsAreasList = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
      ['id', selectedLote?.id_livestock],
      ['tipo', 'livestock']
    ]);
    const opt_areas = {
      name: 'areas',
      field: 'areas',
      label: 'Áreas da Criação',
      values: optionsAreasList,
      variant: 'standard',
      helperText: (<>
        Selecione em qual Área o lote ficará
      </>),
      listHeight: 225,
      disableClearable: true,
      disabled: !selectedLote?.id_livestock
    };
    return (<>
      <Grid item xs={12} sm={4.5} >
        <AutocompleteGeneric value={selectedLote?.area} options={opt_areas || []} handleChange={handleChangeSelectArea} />
      </Grid>
      <Grid item xs={12} sm={3} >
        {!selectedLote?.area?.url && !selectedLote?.area?.imageDecode ? 
          <Map sx={{ width: '100%', height: '50px' }} /> :
          <CardMedia
            component="img"
            image={selectedLote?.area?.url || selectedLote?.area?.imageDecode}
            alt={"Imagem da área " + (selectedLote?.area?.name || '')}
            sx={{
              maxHeight: '80px',
              width: '100%',
              transition: 'transform 0.15s ease-in-out',
              objectFit: 'contain', // Mostra a imagem completa
              '&:hover': {
                transform: obj_fn.ismobile ? 'scale(1.5)' : 'scale(1.5) translateX(-15%) translateY(10%)', 
                position: 'relative',
                zIndex: 3
              },
            }}
          />
        }
      </Grid>
    </>);
  };

  // const para validar os campos e o button add ao carrinho
  const validFields = (type) => {
    let text = ''
    var mensagem = (<></>);
    let error = '';
    let is_error = false

    if (type === 'name' || type === 'isError') {
      if (selectedLote?.new_lote) {
        const loteExists = obj_fn.usestore.reposLivestock.getLotesStore('name', selectedLote?.name)
        text = "Informe o nome do Lote"

        if (loteExists.length > 0) {
          error = 'Nome já existente, escolha outro nome!'
          is_error = true
        } else if (selectedLote?.name?.length < 5) {
          error = 'Nome do lote deve ter no mínimo 5 caracteres!'
          is_error = true
        }
      } else {
        if (!selectedLote?.id) {
          error = 'Selecione um lote de animais'
          is_error = true
        }
      }
    }
    if (type === 'fase_name' || type === 'isError') {
      // let fase = selectedLote?.fase_name
      text = 'Selecione a fase de manejo em que o lote se encontra'

      // if (!fase) {
      //   error = 'Selecione a fase de manejo do animal'
      //   is_error = true
      // }
    }
    if (type === 'quantidade' || type === 'isError') {
      let qtd = selectedLote?.quant
      text = "Quantidade de animais para: " + data?.operationType

      if (!qtd) {
        error = 'Quantidade deve ser maior que 0!'
        is_error = true
      } else if (data.operationType === "Venda" && qtd > selectedLote?.num_animals) {
        error = 'Não pode ser maior do que a Qtd de animais do lote'
        is_error = true
      }
    }
    if (type === 'valor' || type === 'isError') {
      let valor = selectedLote?.value
      text = "Valor total dos animais no lote"

      if (!valor) {
        error = 'Valor deve ser maior que 0!'
        is_error = true
      }
    }

    if (type === 'isError') {
      return is_error
    }

    if (is_error) {
      mensagem = (<>
        <br/>
        <span style={{ color: 'red' }}>
          {error}
        </span>
      </>);
    }

    return (<>
      {text}
      {mensagem}
    </>);
  };

  const handleAdd = () => {
    const aux_add = JSON.parse(JSON.stringify(data.lotes));
    selectedLote.discount = 0;
    aux_add.push(selectedLote);

    var val_total = 0;
    var aux_val = 0;
    for (var i = 0, tam = aux_add.length; i < tam; i++) {
      aux_val = parseFloat(aux_add[i].value);
      // aux_val -= aux_val * parseFloat(aux_add[i].discount) / 100;

      val_total += aux_val;
      aux_add[i].value_end = aux_val;
    }
    var aux_value = (data.value - data.lotes_value) + val_total;
    var aux_value_end = (data.value_end - data.lotes_value) + val_total;

    selectedLote.peso = formatValue(selectedLote.new_weight || 0) + " (" + selectedLote.weight_unit + ")";
    if (selectedLote.new_lote) {
      obj_fn.usestore.reposLivestock.addLoteStore(selectedLote);
    }

    obj_fn.setData(prevState => ({
      ...prevState,
      lotes_value: val_total,
      lotes: aux_add,
      value: aux_value,
      value_end: aux_value_end,
      lote_selected: null
    }));
    setSelectedLotes(default_lote);
    handleClose();
  }

  useEffect(() => {
    // Verifica as condições e define se o botão deve estar desabilitado
    const isDisabled = validFields('isError');
    obj_fn.setbtnAdd(
      <Button
        variant="contained"
        color="success"
        onClick={handleAdd}
        disabled={isDisabled}
        sx={{ my: 2, animation: `${expandAndFade} 2s linear infinite` }}
        id="btn_add_item"
      >
        <Queue sx={{ mr: 1 }} /> Adicionar ao carrinho
      </Button>
    );
  }, [selectedLote]);

  return ( <>
    <Grid container spacing={3}>
      <Box sx={{ display: "flex", justifyContent: "center", p: 1, ml: 1, mt: 1 }}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={12} sx={{ mb: -2 }}>
            <RadioGroup
              label="seleção tipo do lote"
              aria-label="seleção tipo do lote"
              name="lote_radio_group"
              value={selectedLote?.new_lote}
              onChange={handleChange}
              style={{ display: "flex", flexDirection: "row" }}
              defaultValue={0}
            >
              {tipo_lote.map((prog) => (
                <FormControlLabel
                  key={prog.id}
                  value={prog.id}
                  name="new_lote"
                  control={<Radio color="success" />}
                  label={prog.label}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            {selectedLote?.new_lote ? <>
              <TextField
                label="Nome do Lote"
                name="name"
                id="name"
                color="success"
                variant="outlined"
                fullWidth
                value={selectedLote?.name}
                onChange={handleChange}
                onBlur={(e) => handleChange(e, true)}
                helperText={validFields('name')}
              />
            </> : <>
              <AutocompleteGeneric value={(!selectedLote?.new_lote && selectedLote?.id ? selectedLote : null)} options={opt_lotes} handleChange={handleChangeSelect} />
            </>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4} sx={{ mt: -5, mb: -1 }}>
              {selectedLote?.new_lote ? <>
                <Grid item xs={12} sm={12} >
                  <FormControl sx={{ m: 1, minWidth: 160 }}>
                    <InputLabel id="faseManejo-label" color="success">
                      Fase de Manejo
                    </InputLabel>
                    <Select color="success" fullWidth variant="outlined" 
                      labelId="faseManejo-label"
                      label="Fase de Manejo" 
                      name="fase_name" 
                      value={selectedLote?.fase_name} 
                      onChange={(e) => handleChange(e)}
                      disabled={!selectedLote?.new_lote}
                      defaultValue={'Cria'}
                    >
                      {faseManejo.map((prog) => (
                        <MenuItem key={prog.id} value={prog.id} >
                          {prog.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {validFields('fase_name')}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </> : <>
                <Grid item xs={8} sm={8}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary={"Fase de manejo: " + (selectedLote?.fase_name || '-')} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={"Peso médio atual: " + (selectedLote?.peso || '-')} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} md={4}>
                  {!selectedLote?.image ? <>
                    <IconsLivestockActivities activity={'Geral'} widthStyles={'80px'} fill={'#1f2d3d'}/>
                  </> : 
                    <CardMedia
                      component="img"
                      image={selectedLote?.image}
                      alt={"Imagem do lote " + selectedLote?.name}
                      sx={{
                        borderRadius: '16px',
                        height: '80px',
                        width: '100px',
                      }}
                    />
                  }
                </Grid>
              </>}
            </Grid>
          </Grid>
          {!selectedLote?.new_lote ? <></> : <>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={4} >
                <Grid item xs={12} sm={4.5} >
                  <AutocompleteGeneric value={selectedLote?.livestock} options={opt_livestock} handleChange={handleChangeSelectLivestock} />
                </Grid>
                {/* area */}
                {handleAreas()}
              </Grid>
            </Grid>
          </>}
          <Grid item xs={12} sm={3.5} sx={{ mx: 1 }}>
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id: "quant",
                name: "quant",
                label: "Quantidade",
                value: selectedLote?.quant,
                // helperText: validFields('quantidade'),
                helperText: "Quantidade de animais para: Compra",
                helperTextError: "Quantidade deve ser maior do que 0",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3.5} sx={{ mx: 1 }}>
            <GenericValueField
              handleChange={handleChange}
              options={{
                required: true,
                id: "value",
                name: "value",
                label: "Valor",
                value: selectedLote?.value,
                helperText: "Valor total dos animais no lote",
                helperTextError: "Valor deve ser maior que 0!",
                endAdornment: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3.5} sx={{ mx: 1 }}>
            {/* <TextField
              label="Peso médio"
              name="weight"
              id="weight"
              type="number"
              color="success"
              variant="standard"
              fullWidth
              InputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 12,
                min: 0,
              }}
              value={selectedLote?.weight}
              onChange={handleChange}
              onBlur={(e) => handleChange(e, true)}
              helperText={"Peso médio individual dos animais no lote"}
            /> */}
            <GenericValueField
              handleChange={handleChange}
              options={{
                id: "new_weight",
                name: "new_weight",
                label: "Peso médio",
                value: selectedLote?.new_weight,
                helperText: "Peso médio individual dos animais no lote",
                endAdornment: true,
                prefix: `(${selectedLote?.weight_unit})`,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </>)
}