import create from "zustand"
import { persist, devtools } from "zustand/middleware"
import api from '../../services/api'

// create the store
const useCashFlowReposStore = create(
  devtools(
    // persist the created state
    persist(
      (set, get) => ({
        listCashFlow: [],
        listCashFlowCategory: {},
        cashFlowRepos: { entradas: 0, saidas: 0, saldo: 0 },
        fetch: async (item) => {
          const header = { header: { "Content-Type": "application/json" } }
          var id_property = (localStorage.getItem("Property") || 1)

          if (!item) {
            item = { 'id_property': id_property };
          }
          if (!item.id_property) {
            item.id_property = id_property;
          }
          let data_map = [[], []];
          let repos_default = {
            entradas: 0,
            saidas: 0,
            saldo: 0,
            saldo_acumulado: 0,
            data_mes: 0,
            valores: []
          };

          await api.post("reports/cashFlow", (item), header)
            .then((response) => {
              const data = response.data;
              data_map = (data.isSuccess ? data._value : [[], []]);
            })
            .catch((error) => {
              console.log("error: reports/cashFlow");
              console.log(error);
            })

          var mes_atual = (new Date()).getMonth() +1;
          await set({
            listCashFlow:
              data_map[0].map((finance) => {
                if (mes_atual == finance.data_mes) {
                  repos_default.entradas = finance.entradas;
                  repos_default.saidas = finance.saidas;
                  repos_default.saldo = finance.saldo;
                  repos_default.saldo_acumulado = finance.saldo_acumulado;
                  repos_default.data_mes = Number(finance.data_mes);
                  repos_default.valores = JSON.parse(finance.valores || '[]');
                }
                return {
                  entradas: finance.entradas,
                  saidas: finance.saidas,
                  saldo: finance.saldo,
                  saldo_acumulado: finance.saldo_acumulado,
                  data_mes: Number(finance.data_mes),
                  valores: JSON.parse(finance.valores || '[]')
                }
              })
          });
          set({
            cashFlowRepos: repos_default
          });

          let saldo_acumulado = 0;
          var aux_listCashFlowCategory = {};
          var aux_mes;
          data_map[1].map((finance) => {
            aux_mes = Number(finance.data_mes);

            if (!aux_listCashFlowCategory[aux_mes]) {
              aux_listCashFlowCategory[aux_mes] = {
                data_mes: aux_mes,
                lancamentos: [],
                saldo_mes: 0,
                saldo_acumulado: 0,
              }
            }
            saldo_acumulado += finance.saldo
            aux_listCashFlowCategory[aux_mes].lancamentos.push({
              entradas: finance.entradas,
              saidas: finance.saidas,
              saldo: finance.saldo,
              entradas_dia: JSON.parse(finance.entradas_d || '[]'),
              saidas_dia: JSON.parse(finance.saidas_d || '[]'),
              saldo_dia: JSON.parse(finance.saldos_d || '[]'),
              saldo_acumulado: saldo_acumulado,
              data_mes: aux_mes,
              conta: finance.name,
              category: finance.category,
              mother: finance.mother_name,
              tipo: finance.tipo_acc,
            });
            aux_listCashFlowCategory[aux_mes].saldo_mes += finance.saldo;
            aux_listCashFlowCategory[aux_mes].saldo_acumulado = saldo_acumulado;
            return finance;
          });
          await set({
            listCashFlowCategory: aux_listCashFlowCategory
          });

          return data_map;
        },
        changeCashFlowRepos: (cashFlowRepos) => {
          set((state) => ({ cashFlowRepos }));
        },
        // pega a(s) transação(ões) da listCashFlow de acordo com o [key e val] informados
        getCashFlowStore: (key, val) => {
          const list = get().listCashFlow.filter((finance) => {
            return finance[key] == val;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // pega a(s) transação(ões) da listCashFlowCategory de acordo com o mes informado
        getCashFlowStoreCategory: (mes) => {
          const list = get().listCashFlowCategory;
          return JSON.parse(JSON.stringify(list[mes]));
        },
        // keys é uma matriz, onde 0 é a chave para comparação e 1 é o valor de comparação
        //Ex1: [['data_diaria', 1], ['saldo', '0']] -> data_diaria = 1 e saldo = 0
        getCompostCashFlowStore: (keys) => {
          const list = get().listCashFlow.filter((trans) => {
            let count = 0
            for (var i = 0, tam = keys.length; i < tam; i++) {
              if (trans[keys[i][0]] == keys[i][1]) {
                count++
              }
            }
            return count == keys.length;
          });
          return JSON.parse(JSON.stringify(list));
        },
        // Adiciona uma nova finance à listCashFlow
        addCashFlowStore: (finance) => {
          set(state => ({
            listCashFlow: [...state.listCashFlow, finance]
          }))
        },
        // Gera uma nova listCashFlow sem a finance informada
        delCashFlowStore: (id) => {
          set({
            listCashFlow:
              get().listCashFlow.filter((finance) => {
                return finance.id != id;
              })
          })
        },
        // atualiza listCashFlow de acordo com id, key e val informados
        updateCashFlowStore: (id, key, val) => {
          set({
            listCashFlow:
              get().listCashFlow.map((finance) => {
                if (finance.id === id) {
                  if (key == 'all') { // atualiza todos os dados
                    finance = val;
                  } else {
                    finance[key] = val;
                  }
                }
                return finance;
              })
          })
        },
      }),
      { name: "reposCashFlow" }
    )
  )
)

export default useCashFlowReposStore