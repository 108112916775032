import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import Breadcrumb from '../../../../components/Breadcrumb';
import SimpleCard from '../../../../components/SimpleCard';
import { Span } from '../../../../components/Typography';
import SendIcon from '@mui/icons-material/Send';
import { AccountCircle } from '@mui/icons-material';
import TitleIcon from '@mui/icons-material/Title';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import Upload from '../../../../components/Upload/Upload';
import MapIcon from '@mui/icons-material/Map';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ImageIcon from '@mui/icons-material/Image';
import RoomIcon from '@mui/icons-material/Room';
import BasicDatePicker from './BasicDatePicker.js';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { MapFormObservation } from './MapFormObservation';
import MapFormObservationDialog from './MapFormObservationDialog';
import useStore from '../../../../store/useStore';
import { green } from '@mui/material/colors';

const location = [{ label: 'Fazenda CPT', id: 1 }];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const optionsPessoa = [
  { id: 1, user: 'Leonardo', cel: 5531987683264, charName: 'L' },
  { id: 2, user: 'Miguel', cel: 5531987683264, charName: 'M' },
];

export default function AppForm({ message, setMessage }) {
  //https://blog.logrocket.com/using-material-ui-with-react-hook-form/
  const usestore = useStore();
  const reposProperty = usestore.reposProperty;
  const listUsers = usestore.reposUser.listUser;
  //const optionsPessoa = []
  listUsers.map((user) => {
    if (user.phone != '') {
      optionsPessoa.push({ id: user.id, user: user.name, phone: user.phone, charName: user.charName });
    }
  });

  //const initialValue = optionsPessoa.find((option) => option.user === "Leonardo");
  //const initialValue = ""
  //const valuePessoa = optionsPessoa[0];

  //3.2 - inicializar o value
  const [value, setValue] = useState(reposProperty.propertyRepos);
  const [inputValue, setInputValue] = useState('');
  const [customValue, setCustomValue] = useState('');
  const options = reposProperty.listProperty;

  const [selectedValue, setSelectedValue] = useState('');
  const getOptionLabel = (option) => option?.user || '';

  const handleAutocompleteChange = (event, value) => {
    console.log(event.target.value);
    console.log(value);
    setSelectedValue(value);
    setMessage({ ...message, sendMsn: value });
  };

  const handleChangeInput = (event) => {
    console.log(event);
    event.persist();
    setMessage({ ...message, [event.target.name]: event.target.value });
  };

  console.log(message);

  return (
    <form className="container-fluid " style={{ maxWidth: 500 }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
        <TitleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          onChange={handleChangeInput}
          value={message.title}
          fullWidth
          id="title"
          color="success"
          name="title"
          size="small"
          InputLabelProps={{ style: { fontSize: 12, color: '#808080' } }}
          label="Título da Solicitação*"
          variant="standard"
        />
      </Box>
      <br />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          margin: 'auto',
        }}
      >
        <TextField
          onChange={handleChangeInput}
          value={message.description}
          sx={{ width: 300 }}
          id="description"
          name="description"
          label="Descrição"
          multiline
          color="success"
          // InputProps={{ style: { fontSize: 40 } }}
          InputLabelProps={{ style: { fontSize: 12, color: '#808080' } }}
          rows={3}
          variant="outlined"
        />
      </Box>

      <br />

      <div>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <CalendarMonthIcon sx={{ color: 'action.active', mr: 0.5 }} />
            <Typography>Data </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <BasicDatePicker dateMsn={message.date} />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <ImageIcon sx={{ color: 'action.active', mr: 0.5 }} />
            <Typography>Anexar imagem </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Upload />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <AccountCircle sx={{ color: 'action.active', mr: 0.5 }} />
            <Typography>Encaminhar para o Whatsapp</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
                <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
                <Autocomplete
                  //multiple
                  id="checkboxes-tags-demo"
                  onChange={handleAutocompleteChange}
                  value={'' || message.sendMsn}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={optionsPessoa}
                  disableCloseOnSelect
                  color="success"
                  getOptionLabel={getOptionLabel}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} color="success" checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      <Avatar sx={{ bgcolor: green[500], mr: 2 }}>{option.charName}</Avatar>
                      {option.user}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => <TextField {...params} label="Encaminhar para" placeholder="Selecionar" />}
                  noOptionsText="Sem resultados"
                />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <MapIcon sx={{ color: "action.active", mr: 0.5 }} /> <Typography>Fazenda </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Autocomplete
                  disableClearable
                  // size="small"
                  value={value}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={options}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    setCustomValue(newValue.id);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Propriedade"
                      color="success"
                      focused
                      // https://mui.com/pt/material-ui/react-text-field/
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <strong> </strong>,
                      }}
                    />
                  )}
                  noOptionsText="Sem resultados"
                />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <MapIcon sx={{ color: "action.active", mr: 0.5 }} /> <Typography>Área </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <MapIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField fullWidth id="standard-basic" size="small" InputLabelProps={{ style: { fontSize: 12, color: "#808080" } }} label="Área" variant="standard" />
              </Box>
              <br />
            </div>
          </AccordionDetails>
        </Accordion> */}
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 0, mt: 1 }}>
            Adicione um marcador no mapa da propriedade:
          </Typography>
        </Grid>
        <Accordion>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item>
              <RoomIcon sx={{ color: 'action.active', ml: 2 }} />
            </Grid>
            <Grid item>
              <Typography sx={{ ml: -1 }}>Localização</Typography>
            </Grid>
            <Grid item sx={{ ml: 'auto', mb: 1, mr: 1 }}>
              <MapFormObservationDialog />
            </Grid>
          </Grid>

          {/*<AccordionDetails>
            <div style={{ width: "100%" }}>
               <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Autocomplete disablePortal id="combo-box-demo" options={location} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Adicionar Localização" />} noOptionsText="Sem resultados"/>

              </Box> 
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >

              <MapFormObservation /> 
              </Box> 
              <br />
            </div>
          </AccordionDetails>*/}
        </Accordion>
        <br />
        <br />
        <br />
        <br />
      </div>

      {/* <FormControl>
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" />
          <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
          <Button color="primary" variant="contained" type="submit">
            <SendIcon />
            <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
          </Button>
        </FormControl> */}
      {/* </SimpleCard> */}
    </form>
  );
}
