import React, { useContext, useState } from 'react';
import { IconButton, Badge, Menu, MenuItem, Divider, Typography, Box, Switch, ListItemIcon, Button, Grid, FormGroup, FormControlLabel } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LogoutIcon from '@mui/icons-material/Logout';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { useRef } from 'react';
import { useEffect } from 'react';
import useStore from '../../store/useStore';
import { Context } from '../../components/Context/AuthContext';

const SwitchWithLabel = ({ checked, onChange, label, ariaLabel }) => (
  <MenuItem sx={{ ml: -1 }}>
    <Switch checked={checked} onChange={onChange} inputProps={{ 'aria-label': ariaLabel }} color="success" sx={{ mr: 2 }} />
    <Typography sx={{ ml: -1 }}>{label}</Typography>
  </MenuItem>
);

export default function DisplayModeSettingsContent() {
  var navMenuActive = localStorage.getItem('navMenuActive');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const usestore = useStore();
  const userCurrent = usestore.reposUser.userRepos;
  const { aux_add, setAuxAdd, isMobile, setMenuContext } = useContext(Context);
  const ismobile = isMobile.any();
  // Função para atualizar as configurações
  const updateSettings = (new_user_current) => {
    usestore.reposUser.updateSettings(new_user_current); // Supondo que usestore já esteja configurado
    setAuxAdd(aux_add + 1); // Incrementa alguma variável de controle
  };

  useEffect(() => {
    // Verifica se as configurações estão desmarcadas e realiza as ações necessárias
    const settings = userCurrent.settings.icons_gestao;

    // Condição para verificar se algum item essencial está desmarcado
    const shouldEnableEssential =
      !settings.Painel && !settings.Map && !settings.Area && !settings.Culturas && !settings.Animais && !settings.Activities && !settings.Resources && !settings.ProductionCost;

    // Se algum item estiver desmarcado, ativa as configurações essenciais
    if (shouldEnableEssential) {
      // Verifica se as configurações já estão ativas, para evitar atualização desnecessária
      const essentialNotEnabled = !settings.Culturas || !settings.Animais || !settings.Activities || !settings.ProductionCost;

      if (essentialNotEnabled) {
        // Ativa a criação das configurações essenciais se ainda não estiver ativada
        enableCreationHarvestEssential(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   const areAllDisabled = Object.values(userCurrent.settings.icons_property).every((value) => !value);

  //   if (areAllDisabled) {
  //     enableEssential();
  //   }
  // }, [userCurrent.settings.icons_property]);

  // Função para alternar o estado de todos os switches
  const toggleAll = (state) => {
    const updatedSettings = { ...userCurrent.settings };
    Object.keys(updatedSettings.icons_property).forEach((key) => {
      updatedSettings.icons_property[key] = state;
    });
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  // // Função para alternar o estado de todos os switches, respeitando essencial
  // const toggleAll = (state) => {
  //   const updatedSettings = { ...userCurrent.settings };
  //   Object.keys(updatedSettings.icons_property).forEach((key) => {
  //     if (key === 'Painel' || key === 'Livestock' || key === 'Harvest') {
  //       updatedSettings.icons_property[key] = true; // Essenciais sempre ativados
  //     } else {
  //       updatedSettings.icons_property[key] = state;
  //     }
  //   });

  // Função para ativar o modo essencial
  const enableEssential = () => {
    // Assegura que as configurações essenciais (Painel, Livestock, Harvest) sempre estarão ativadas
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;

    // Atualiza as outras configurações (se houver)
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enableMessenger = () => {
    // Assegura que as configurações essenciais (Painel, Livestock, Harvest) sempre estarão ativadas
    toggleAll(false);
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Messenger = true;

    // Atualiza as outras configurações (se houver)
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enableMap = () => {
    toggleAll(false);
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Map = true;
    updatedSettings.icons_property.Area = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enablePeople = () => {
    toggleAll(false);
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Team = true;
    updatedSettings.icons_property.Messenger = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enableFinancial = () => {
    toggleAll(false); // Desativa todos os switches
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Financial = true;
    //updatedSettings.icons_property.Reports = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enableBuyingSelling = () => {
    toggleAll(false); // Desativa todos os switches
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Buy = true;
    updatedSettings.icons_property.Sales = true;
    updatedSettings.icons_property.Financial = true;
    updatedSettings.icons_property.Product = true;
    updatedSettings.icons_property.Inventory = true;
    //updatedSettings.icons_property.Reports = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enableAdvanced = () => {
    toggleAll(false);
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Map = true;
    updatedSettings.icons_property.Area = true;
    updatedSettings.icons_property.Team = true;
    updatedSettings.icons_property.Messenger = true;
    updatedSettings.icons_property.Financial = true;
    updatedSettings.icons_property.Product = true;
    updatedSettings.icons_property.Inventory = true;
    updatedSettings.icons_property.Buy = true;
    updatedSettings.icons_property.Sales = true;
    updatedSettings.icons_property.Patrimony = true;
    updatedSettings.icons_property.Reports = true;
    //updatedSettings.icons_property.Reports = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enableStock = () => {
    toggleAll(false); // Desativa todos os switches
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Product = true;
    updatedSettings.icons_property.Inventory = true;
    //updatedSettings.icons_property.Reports = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enableStockFinancial = () => {
    toggleAll(false); // Desativa todos os switches
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Product = true;
    updatedSettings.icons_property.Inventory = true;
    updatedSettings.icons_property.Financial = true;
    //updatedSettings.icons_property.Reports = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  const enablePatrimony = () => {
    toggleAll(false);
    const updatedSettings = { ...userCurrent.settings };
    updatedSettings.icons_property.Painel = true;
    updatedSettings.icons_property.Livestock = true;
    updatedSettings.icons_property.Harvest = true;
    updatedSettings.icons_property.Patrimony = true;
    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  // criação e safra

  const toggleAllCreationHarvest = (enabled) => {
    const updatedSettings = { ...userCurrent.settings };

    // Se 'enabled' for false, desmarque todos os ícones
    updatedSettings.icons_gestao.Painel = enabled;
    updatedSettings.icons_gestao.Map = enabled;
    updatedSettings.icons_gestao.Area = enabled;
    updatedSettings.icons_gestao.Culturas = enabled;
    updatedSettings.icons_gestao.Animais = enabled;
    updatedSettings.icons_gestao.Activities = enabled;
    updatedSettings.icons_gestao.Resources = enabled;
    updatedSettings.icons_gestao.ProductionCost = enabled;

    updateSettings({ ...userCurrent, settings: updatedSettings });
    enableCreationHarvestEssential();
    setAuxAdd(aux_add + 1);
  };

  // Funções específicas de criação e safra
  const enableCreationHarvestEssential = () => {
    const updatedSettings = { ...userCurrent.settings };

    // Se 'enabled' for false, desmarque todos os ícones essenciais
    updatedSettings.icons_gestao.Painel = true;
    updatedSettings.icons_gestao.Culturas = true;
    updatedSettings.icons_gestao.Animais = true;
    updatedSettings.icons_gestao.Activities = true;
    updatedSettings.icons_gestao.ProductionCost = true;

    updateSettings({ ...userCurrent, settings: updatedSettings });
    setAuxAdd(aux_add + 1);
  };

  // Funções de ação específicas para cada cenário de "Gestão"
  const toggleSwitch = (enabled, toggleFunc, essentialFunc) => {
    console.log(enabled, toggleFunc, essentialFunc);
    return (e) => {
      if (!e.target.checked) {
        toggleFunc(false); // Desativa todos os switches
      } else {
        essentialFunc(); // Ativa a configuração essencial
      }
    };
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const mountSwitch = (config, tipo) => {
  //     const isChecked = userCurrent.settings?.icons_property?.[tipo] ?? false; // Verificação segura
  //     return (
  //       <MenuItem key={tipo}>
  //         <Switch
  //           checked={isChecked}
  //           onChange={(e) => handleChangeSetting(e, tipo)} // Atualizando apenas o tipo
  //           inputProps={{ 'aria-label': 'controlled' }}
  //           color="success"
  //           sx={{ mr: 2 }}
  //         />
  //         <Typography sx={{ ml: 1 }}>{tipo}</Typography>
  //       </MenuItem>
  //     );
  //   };

  // Lógica de mudança de estado do Switch
  //   const handleChangeSetting = (e, tipo) => {
  //     const updatedSettings = { ...userCurrent.settings };
  //     updatedSettings.icons_property[tipo] = e.target.checked;
  //     updateSettings({ ...userCurrent, settings: updatedSettings });
  //   };

  return (
    <li className="nav-item d-none d-lg-block mt-1">
      <IconButton onClick={handleClick} size="small" sx={{ mt: -1 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
        <ChromeReaderModeIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account_menu_user"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 430,
            maxHeight: 400, // Altura máxima para ativar a barra de rolagem
            overflowY: 'auto', // Ativa a rolagem vertical
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 200,
              height: 100,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
          <ChromeReaderModeIcon />
          <Typography sx={{ ml: 1, fontWeight: 'bold' }}>Modos de Exibição</Typography>
        </Box>
        {navMenuActive == 'selectProperty' && (
          <>
            <Divider />
            <Typography variant="caption" sx={{ ml: 2, mt: 2 }}>
              Nível administrativo da propriedade
            </Typography>
            {/* Renderizando os switches com foco programático */}
            {/* {mountSwitch('icons_property', 'Painel')} */}
            {/* <MenuItem>
          <Switch
            checked={userCurrent.settings.icons_property.Painel && userCurrent.settings.icons_property.Livestock && userCurrent.settings.icons_property.Harvest}
            onChange={(e) => {
              if (!e.target.checked) {
                toggleAll(false); // Desativa todos os switches quando o switch estiver desmarcado
              } else {
                enableEssential(); // Ativa o modo essencial quando o switch for marcado
              }
            }}
            inputProps={{ 'aria-label': 'toggle gestão essencial' }}
            color="success"
            sx={{ mr: 2 }}
          />
          <Typography sx={{ ml: 1 }}>Gestão Essencial</Typography>
        </MenuItem> */}
            {/* Configuração essencial */}
            <SwitchWithLabel
              checked={userCurrent.settings.icons_property.Painel && userCurrent.settings.icons_property.Livestock && userCurrent.settings.icons_property.Harvest}
              onChange={toggleSwitch(true, toggleAll, enableEssential)}
              label="Gestão Essencial"
              ariaLabel="toggle gestão essencial"
            />
            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Messenger
              }
              onChange={toggleSwitch(true, toggleAll, enableMessenger)}
              label="Gestão da Comunicação"
              ariaLabel="toggle gestão da comunicação"
            />
            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Map &&
                userCurrent.settings.icons_property.Area
              }
              onChange={toggleSwitch(true, toggleAll, enableMap)}
              label="Gestão das Áreas de Produção"
              ariaLabel="toggle áreas de produção"
            />
            {/* <MenuItem>
          <Switch
            checked={
              userCurrent.settings.icons_property.Painel &&
              userCurrent.settings.icons_property.Livestock &&
              userCurrent.settings.icons_property.Harvest &&
              userCurrent.settings.icons_property.Team &&
              userCurrent.settings.icons_property.Messenger
            }
            onChange={(e) => {
              if (!e.target.checked) {
                toggleAll(false); // Desativa todos os switches quando o switch estiver desmarcado
              } else {
                enablePeople(); // Ativa o modo financeiro quando o switch for marcado
              }
            }}
            inputProps={{ 'aria-label': 'toggle gestão financeira' }}
            color="success"
            sx={{ mr: 2 }}
          />
          <Typography sx={{ ml: 1 }}>Gestão de Equipe</Typography>
        </MenuItem> */}
            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Team &&
                userCurrent.settings.icons_property.Messenger
              }
              onChange={toggleSwitch(true, toggleAll, enablePeople)}
              label="Gestão de Equipe"
              ariaLabel="toggle gestão Equipe"
            />
            {/* <MenuItem>
          <Switch
            checked={
              userCurrent.settings.icons_property.Painel &&
              userCurrent.settings.icons_property.Livestock &&
              userCurrent.settings.icons_property.Harvest &&
              userCurrent.settings.icons_property.Financial
            }
            onChange={(e) => {
              if (!e.target.checked) {
                toggleAll(false); // Desativa todos os switches quando o switch estiver desmarcado
              } else {
                enableFinancial(); // Ativa o modo financeiro quando o switch for marcado
              }
            }}
            inputProps={{ 'aria-label': 'toggle gestão financeira' }}
            color="success"
            sx={{ mr: 2 }}
          />
          <Typography sx={{ ml: 1 }}>Gestão Financeira</Typography>
        </MenuItem> */}
            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Financial
              }
              onChange={toggleSwitch(true, toggleAll, enableFinancial)}
              label="Gestão Financeira"
              ariaLabel="toggle gestão financeira'"
            />
            {/* <MenuItem>
          <Switch
            checked={
              userCurrent.settings.icons_property.Painel &&
              userCurrent.settings.icons_property.Livestock &&
              userCurrent.settings.icons_property.Harvest &&
              userCurrent.settings.icons_property.Financial &&
              userCurrent.settings.icons_property.Buy &&
              userCurrent.settings.icons_property.Sales
            }
            onChange={(e) => {
              if (!e.target.checked) {
                toggleAll(false); // Desativa todos os switches quando o switch estiver desmarcado
              } else {
                enableBuyingSelling(); // Ativa o modo financeiro quando o switch for marcado
              }
            }}
            inputProps={{ 'aria-label': 'toggle gestão financeira' }}
            color="success"
            sx={{ mr: 2 }}
          />
          <Typography sx={{ ml: 1 }}>Gestão de Compras e Vendas</Typography>
        </MenuItem> */}
            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Financial &&
                userCurrent.settings.icons_property.Buy &&
                userCurrent.settings.icons_property.Sales &&
                userCurrent.settings.icons_property.Product &&
                userCurrent.settings.icons_property.Inventory
              }
              onChange={toggleSwitch(true, toggleAll, enableBuyingSelling)}
              label="Gestão de Compras e Vendas"
              ariaLabel="toggle modo compras e vendas''"
            />
            {/* <MenuItem>
          <Switch
            checked={
              userCurrent.settings.icons_property.Painel &&
              userCurrent.settings.icons_property.Livestock &&
              userCurrent.settings.icons_property.Harvest &&
              userCurrent.settings.icons_property.Product &&
              userCurrent.settings.icons_property.Inventory
            }
            onChange={(e) => {
              if (!e.target.checked) {
                toggleAll(false); // Desativa todos os switches quando o switch estiver desmarcado
              } else {
                enableStock(); // Ativa o modo de estoque quando o switch for marcado
              }
            }}
            inputProps={{ 'aria-label': 'toggle modo estoque' }}
            color="success"
            sx={{ mr: 2 }}
          />
          <Typography sx={{ ml: 1 }}>Gestão de Estoque</Typography>
        </MenuItem> */}
            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Product &&
                userCurrent.settings.icons_property.Inventory
              }
              onChange={toggleSwitch(true, toggleAll, enableStock)}
              label="Gestão de Estoque"
              ariaLabel="toggle modo estoque''"
            />
            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Product &&
                userCurrent.settings.icons_property.Inventory &&
                userCurrent.settings.icons_property.Financial
              }
              onChange={toggleSwitch(true, toggleAll, enableStockFinancial)}
              label="Gestão de Estoque e Financeiro"
              ariaLabel="toggle modo estoque e financeiro''"
            />
            {/* <MenuItem>
          <Switch
            checked={
              userCurrent.settings.icons_property.Painel &&
              userCurrent.settings.icons_property.Livestock &&
              userCurrent.settings.icons_property.Harvest &&
              userCurrent.settings.icons_property.Patrimony
            }
            onChange={(e) => {
              if (!e.target.checked) {
                toggleAll(false); // Desativa todos os switches quando o switch estiver desmarcado
              } else {
                enablePatrimony(); // Ativa o modo financeiro quando o switch for marcado
              }
            }}
            inputProps={{ 'aria-label': 'toggle gestão financeira' }}
            color="success"
            sx={{ mr: 2 }}
          />
          <Typography sx={{ ml: 1 }}>Gestão Patrominial</Typography>
        </MenuItem> */}

            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Patrimony
              }
              onChange={toggleSwitch(true, toggleAll, enablePatrimony)}
              label="Gestão Patrimonial"
              ariaLabel="toggle modo patrimonial''"
            />

            {/* <MenuItem>
          <Switch
            checked={
              userCurrent.settings.icons_property.Painel &&
              userCurrent.settings.icons_property.Livestock &&
              userCurrent.settings.icons_property.Harvest &&
              userCurrent.settings.icons_property.Map &&
              userCurrent.settings.icons_property.Area &&
              userCurrent.settings.icons_property.Team &&
              userCurrent.settings.icons_property.Messenger &&
              userCurrent.settings.icons_property.Financial &&
              userCurrent.settings.icons_property.Product &&
              userCurrent.settings.icons_property.Inventory &&
              userCurrent.settings.icons_property.Buy &&
              userCurrent.settings.icons_property.Sales &&
              userCurrent.settings.icons_property.Patrimony &&
              userCurrent.settings.icons_property.Reports
            }
            // checked={userCurrent.settings.icons_property.Painel && userCurrent.settings.icons_property.Livestock && userCurrent.settings.icons_property.Harvest}
            onChange={(e) => {
              if (!e.target.checked) {
                toggleAll(false); // Desativa todos os switches quando o switch estiver desmarcado
              } else {
                enableAdvanced(); // Ativa o modo essencial quando o switch for marcado
              }
            }}
            inputProps={{ 'aria-label': 'toggle gestão essencial' }}
            color="success"
            sx={{ mr: 2 }}
          />
          <Typography sx={{ ml: 1 }}>Gestão Avançada</Typography>
        </MenuItem> */}

            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_property.Painel &&
                userCurrent.settings.icons_property.Livestock &&
                userCurrent.settings.icons_property.Harvest &&
                userCurrent.settings.icons_property.Map &&
                userCurrent.settings.icons_property.Area &&
                userCurrent.settings.icons_property.Team &&
                userCurrent.settings.icons_property.Messenger &&
                userCurrent.settings.icons_property.Financial &&
                userCurrent.settings.icons_property.Product &&
                userCurrent.settings.icons_property.Inventory &&
                userCurrent.settings.icons_property.Buy &&
                userCurrent.settings.icons_property.Sales &&
                userCurrent.settings.icons_property.Patrimony &&
                userCurrent.settings.icons_property.Reports
              }
              onChange={toggleSwitch(true, toggleAll, enableAdvanced)}
              label="Gestão Avançada"
              ariaLabel="toggle modo avançada"
            />
          </>
        )}
        {navMenuActive == 'selectCreationHarvest' && (
          <>
            <Divider />

            <Typography variant="caption" sx={{ ml: 2, mt: 2 }}>
              Nível operacional das criações/safras
            </Typography>
            {/* {mountSwitch('icons_property', 'Livestock')}
        {mountSwitch('icons_property', 'Harvest')} */}
            {/* Configuração de Criação e Safra */}

            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_gestao.Painel &&
                userCurrent.settings.icons_gestao.Culturas &&
                userCurrent.settings.icons_gestao.Animais &&
                userCurrent.settings.icons_gestao.Activities &&
                userCurrent.settings.icons_gestao.ProductionCost
              }
              onChange={(e) => enableCreationHarvestEssential(e.target.checked)} // Ajuste aqui
              label="Gestão Essencial da Criação e Safra"
              ariaLabel="toggle criação e safra"
            />

            <SwitchWithLabel
              checked={
                userCurrent.settings.icons_gestao.Painel &&
                userCurrent.settings.icons_gestao.Map &&
                userCurrent.settings.icons_gestao.Area &&
                userCurrent.settings.icons_gestao.Culturas &&
                userCurrent.settings.icons_gestao.Animais &&
                userCurrent.settings.icons_gestao.Activities &&
                userCurrent.settings.icons_gestao.Resources &&
                userCurrent.settings.icons_gestao.ProductionCost
              }
              onChange={(e) => toggleAllCreationHarvest(e.target.checked)} // Ajuste aqui
              label="Gestão Avançada da Criação e Safra"
              ariaLabel="toggle criação e safra"
            />
          </>
        )}
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Fechar
        </MenuItem>
      </Menu>
    </li>
  );
}
