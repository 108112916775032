import React, { useEffect, useState } from 'react';
import { Box, CardMedia, Divider, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Map } from '@mui/icons-material';
import { green, orange } from '@mui/material/colors';
import FooterModal from '../../../../../components/Footer/FooterModal';
import AutocompleteGeneric from '../../../../../components/Fields/AutocompletGeneric';
import Typewriter from '../../../../../components/Typewriter/Typewriter';
import ThreeDotsSpinner from '../../../../../components/Loader/ThreeDotsSpinner';
import ChartBarHActvSoul from '../../../../../components/Charts/ChartBarHActvSoul';
import { formatValue, removeDuplicatesFromArray } from '../../../../../utils/functions';
import DialogRegisterArea from '../../../../areasMaps/components/DialogRegisterArea';
import GenericValueField from '../../../../../components/Fields/GenericValueField';

export default function FormSoilPreparationStep2({ data, obj_fn }) {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 2000);
    return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
  }, []); // Executa apenas uma vez, após o primeiro render

  // const para validar o button continuar
  const isError = () => {
    // Verifica se há áreas e se os valores de área usada são válidos
    return data.areas.length === 0 || data.areas.some((area) => area.usedArea > area.area);
  };

  const returnError = () => {
    return false;
  };

  // Monta o array e obj para o campo de projeto
  const listAreasHarvest = obj_fn.usestore.reposAreas.getAreaUsedMapStore([
    ['id', data.id_harvest],
    ['tipo', 'harvest'],
  ]);
  const opt_areas = {
    multiple: true,
    name: 'areas_h',
    label: 'Áreas da Safra',
    values: listAreasHarvest,
    field: 'areas',
    variant: 'outlined',
    helperText: (
      <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
        Áreas já vinculadas à safra atual
      </Typography>
    ),
    listHeight: 225,
  };

  const allAreas_aux = obj_fn.usestore.reposAreas.getAreaMapStore('id_property', data.id_property);
  const listAreasProp = allAreas_aux.filter((area) => {
    var is_in = false;
    for (const areaH of listAreasHarvest) {
      if (areaH.id === area.id) {
        is_in = true;
        break;
      }
    }
    area.assign = true;
    return !is_in;
  });
  const opt_areas_prop = {
    multiple: true,
    name: 'areas_p',
    label: 'Áreas da Propriedade',
    values: listAreasProp,
    field: 'areas',
    variant: 'outlined',
    helperText: (
      <Typography style={{ color: orange[500] }} color="success" variant="subtitle1">
        Ao utilizar uma destas áreas, ela será automaticamente vinculada à safra atual!
      </Typography>
    ),
    listHeight: 225,
  };

  const handleChangeArea = (field, newValue, reason, details) => {
    // garante que ambos os campos (Safra ou Propriedade) vão adicionar itens à areas
    let newAreas = data.areas.concat(newValue);
    if (/remove/.test(reason)) {
      newAreas = newAreas.filter((area) => {
        return area.id !== details?.id;
      });
    }
    // garante que todos os elementos sejam únicos
    let areas = removeDuplicatesFromArray(newAreas, 'id') || [];
    areas.map((area) => {
      if (!area.product) {
        area.product = null;
      }
      area.usedArea = area.usedArea || area.area;
      return area;
    });

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: areas,
    }));
  };

  const handleAssign = (area) => {
    setTimeout(() => {
      let newArea = obj_fn.usestore.reposAreas.getAreaMapStore('id', area.id)[0];

      let aux_areas = data.areas.concat(newArea);
      handleChangeArea('areas', aux_areas);
    }, 300);
  };

  const handleChangeInput = (e, area, isPercentage) => {
    const value = parseFloat(e.target.value || '0');
    if (isNaN(value) || value < 0) {
      // Evita valores inválidos
      return 0;
    }

    var usedArea = isPercentage ? Math.min((value * area.area) / 100, area.area) : Math.min(value, area.area);
    usedArea = parseFloat(usedArea.toFixed(2));

    obj_fn.setData((prevState) => ({
      ...prevState,
      areas: prevState.areas.map((area_prev) => (area_prev.id === area.id ? { ...area_prev, usedArea } : area_prev)),
    }));
  };

  console.log(opt_areas_prop);

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={1} sx={{ mt: 1, mb: 1 }} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={7}>
            <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
              É possivel desenhar novas áreas clicando no botão {obj_fn.isMobile ? 'a baixo' : 'ao lado'}
            </Typography>
            <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
              A nova área será automaticamente vinculada à safra atual!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogRegisterArea setData={handleAssign} assign={data.project} />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 1, mb: 1, width: '90%', borderColor: green[100] }} />

        <Grid container spacing={3} sx={{ mb: 0, mt: 1 }}>
          <Map />
          <Box sx={{ ml: 1, display: 'flex' }}>
            <Stack spacing={0.5}>
              <Typography fontWeight={700}>Áreas para o Preparo do solo</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 0, mt: 1 }}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ color: green[800] }} color="success">
              Selecione a(s) área(s) desejadas para o preparo do solo
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 0, mt: 1 }}>
          <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
            <AutocompleteGeneric value={data.areas.filter((area) => !area.assign)} options={opt_areas} handleChange={handleChangeArea} />
          </Grid>
          {opt_areas_prop?.values.length > 0 && (
            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
              <AutocompleteGeneric value={data.areas.filter((area) => area.assign)} options={opt_areas_prop} handleChange={handleChangeArea} />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={4} sx={{ mb: 1, mt: 0 }}>
          {data.areas?.map((area) => (
            <>
              <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
                  <Typography variant="subtitle1" display="block" gutterBottom>
                    Defina a área que será utlizada para o preparo do solo:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sx={{ mt: 0 }} alignItems="center">
                  {showText ? (
                    <Typewriter
                      text={`Por padrão, definimos a área total como a quantidade de área utilizada (${area.measure}) onde o preparo do solo será realizado.`}
                      variant={'subtitle1'}
                      color={green[800]}
                    />
                  ) : (
                    <ThreeDotsSpinner />
                  )}
                </Grid>

                <Grid container spacing={3} sx={{ mb: 0, mt: 0 }}>
                  <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <CardMedia key={'CM_' + area.id} component="img" sx={{ height: 260 }} image={area.url} alt={area.name} />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <Grid item key={'G2_' + area.id} xs={12} sm={12} sx={{ ml: 1.5 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Área: <strong>{area.name}</strong>
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        <i className="fa fa-map text-success">
                          {' '}
                          {formatValue(area.area)} ({area.measure})
                        </i>
                      </Typography>
                      <Grid container spacing={3} sx={{ mb: 0, mt: -2 }}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                          {/* <TextField
                            sx={{ marginRight: 2 }}
                            id={area.id + '-usedArea-hectares'}
                            name={area.name}
                            label={'Área utilizada (ha):'}
                            variant="standard"
                            size="small"
                            value={area.usedArea || area.area}
                            // value={area.usedArea ? area.usedArea : area.area}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{area.measure}</InputAdornment>,
                            }}
                            onChange={(e) => handleChangeInput(e, area, false)}
                            // helperText={area.usedArea > area.area ? 'O valor não pode ser maior que a área total.' : ''}
                          /> */}
                          <GenericValueField
                            handleChange={(e) => handleChangeInput(e, area)}
                            options={{
                              id: area.id + '-usedArea-hectares',
                              name: area.name,
                              label: 'Área utilizada',
                              value: area.usedArea,
                              color: 'success',
                              max: area.area,
                              width: '90%',
                              endAdornment: true,
                              prefix: ' (' + (area.measure || 'ha') + ')',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                          <TextField
                            disabled
                            sx={{ marginRight: 2 }}
                            id={area.id + '-usedArea-percentage'}
                            name={area.name}
                            label={'Área utilizada (%):'}
                            variant="standard"
                            size="small"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            value={((area.usedArea / area.area) * 100).toFixed(2) || 100}
                            onChange={(e) => handleChangeInput(e, area, true)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                          <ChartBarHActvSoul usedArea={area?.usedArea || area?.area} areaTotal={area?.area} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.areas?.length > 0 && <Divider sx={{ mt: 1, mb: 1, width: '90%', borderColor: green[100] }} />}
            </>
          ))}
        </Grid>
        <Grid container spacing={3} sx={{ mb: 2, mt: 0, display: !data.areas?.length ? 'none' : '' }}>
          <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
            <Divider sx={{ borderColor: green[100] }} />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: 0 }}>
            <Typography variant="subtitle1" display="block" gutterBottom sx={{ ml: 2, color: green[800] }}>
              Clique abaixo em continuar
            </Typography>
          </Grid>
        </Grid>

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
