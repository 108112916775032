import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalSendPassword({ handleOpen, open, handleClose }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Excelente!!!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Um link foi enviado para o seu email para criar uma nova senha.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Verifique a caixa de Spam caso não encontre o e-mail.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            O link tem a validade de 2 horas.
          </Typography>
          <Button onClick={handleClose} className="text-success">
            Fechar
          </Button>
          {/* <p className="mb-1 mt-3  ">
            <Link to="/" className=" text-success ">
              Ir para o Login
            </Link>
          </p> */}
        </Box>
      </Modal>
    </div>
  );
}
