import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { AppBar, Divider, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { H6, Span } from '../../../../components/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ListAreasMapsLivestock from './ListAreasMapsLivestock';
import DialogRegisterArea from '../../../areasMaps/components/DialogRegisterArea';
import { Context } from '../../../../components/Context/AuthContext';
import { expandAndFade } from '../../../../components/Animations/animationKeyFrames';
import { green } from '@mui/material/colors';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function AreaSelectDialog({ data, setData, isLoading, setIsLoading }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { aux_add } = useContext(Context);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // console.log('nova area adicionada');
  }, [aux_add]);

  // console.log(data);
  // console.log(data);

  return (
    <div>
      <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} size="small" variant="outlined" color="success" onClick={handleClickOpen}>
        Selecionar área(s)
      </Button>
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={true} maxWidth={'lg'}>
        <AppBar sx={{ cursor: 'move', position: 'relative' }} color="success" id="draggable-dialog-title">
          <div className="d-none d-lg-block">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>

              <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }} className="m-0">
                {' '}
              </H6>

              <Button color="inherit" onClick={handleClose} disabled={!data.areas?.length}>
                Selecionar
              </Button>
            </Toolbar>
          </div>
        </AppBar>
        <section className="content " style={{ overflowY: 'scroll', paddingBottom: 80 }}>
          <div className="container-fluid ">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Selecione a(s) área(s) de produção:
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={3} sx={{ mt: -2 }} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={5}>
                  <Typography style={{ color: green[800] }} color="success" variant="subtitle1">
                    {data.areas.length > 0 ? 'É possivel desenhar novas áreas clicando no botão: DESENHAR NOVA ÁREA a seguir.' : <></>}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DialogRegisterArea setData={setData} />
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogContent sx={{ m: -3 }}>
              <ListAreasMapsLivestock data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading} />
            </DialogContent>
            <AppBar elevation={3} position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff' }}>
              <Toolbar>
                {/* <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                  <Button color="success" variant="outlined" type="submit" onClick={handleClose}>
                    <Span>Limpar</Span>
                  </Button>
                </Box> */}
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Button sx={{ animation: `${expandAndFade} 2s linear infinite` }} color="success" variant="contained" type="submit" onClick={handleClose} disabled={!data.areas?.length}>
                    <Span sx={{ textTransform: 'capitalize' }}>Selecionar</Span>
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
          </div>
        </section>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
