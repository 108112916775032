import * as React from 'react';
import { Grid, Divider, Box, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import dayjs from 'dayjs';
import FormPaymentInstallments from '../../Forms/FormPaymentInstallments';
import FooterModal from '../../../../../components/Footer/FooterModal';
import FormPaymentCondition from '../../Forms/FormPaymentCondition';
import FormPaymentForecast from '../../Forms/FormPaymentForecast';
import Typewriter from '../../../../../components/Typewriter/Typewriter';

/*
  CONDIÇÕES PAGAMENTO
    Data de Vencimento === "Data lançamento + "intervalo" (dias)
    Conta de Pagamento: Vem marcado com o banco "standard"

  PREVISÃO DE PAGAMENTO:
    Juros, Multa e Desconto: devem ser um input editaval. Quando alterados, mudam o valor final

  PARCELAS: 
    Data de cada parcela deve ser um incremental de: "Vencimento" + "intervalo" + "num parcela"
    Ao alterar o valor da parcela 1, o valor restanto deve ser rateado para as outras parcelas
*/
export default function FormEntryStep3({ data, obj_fn }) {
  const isError = () => {
    if (!data.value || data.error_date) {
      return true;
    }
    return false;
  };
  const returnError = () => {
    return false;
  };

  const handleChange = (event, blur) => {
    if (blur === 'checkbox') {
      obj_fn.setData((prevState) => ({ ...prevState, payed: event.target.checked }));
    } else {
      var aux_val;
      var field = event.target.name;
      var value = event.target.value;
      if (field === 'fee' || field === 'penalty' || field === 'discount') {
        aux_val = parseFloat(data.value || '0');
        value = parseFloat(value || '0');
        // porcentagens do valor total
        var fee = (aux_val * parseFloat(field === 'fee' ? value : data.fee)) / 100;
        var penalty = (aux_val * parseFloat(field === 'penalty' ? value : data.penalty)) / 100;
        var discount = (aux_val * parseFloat(field === 'discount' ? value : data.discount)) / 100;

        var value_aux = aux_val + fee + penalty - discount;
        obj_fn.setData((prevState) => ({ ...prevState, value_end: value_aux }));
      } else if (/installment.*/.test(field)) {
        let aux_installment = data.installment;
        let new_date = dayjs();

        if (field === 'installmentInterval') {
          aux_val = parseFloat(value || '0');
          value = aux_val;
        } else {
          aux_val = data.installmentInterval;
          aux_installment = value;
        }
        if (aux_installment !== 'a_vista') {
          new_date = dayjs().add(aux_val, 'day');
        }

        obj_fn.setData((prevState) => ({
          ...prevState,
          dueDate: new_date,
          dueDate_f: dayjs(new_date).format('YYYY-MM-DD HH:mm:ss'),
          dueDate_fc: dayjs(new_date).format('DD/MM/YYYY')
        }));
      }

      obj_fn.setData((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  // Monta o obj e função para o campo de data de vencimento
  const handleChangeDate = (value) => {
    // if (data.installment !== 'a_vista') {
    //   data.arr_installment[0].dueDate = dayjs(value);
    //   data.arr_installment[0].dueDate_f = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    // }
    obj_fn.setData((prevState) => ({
      ...prevState,
      dueDate: dayjs(value),
      dueDate_f: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      dueDate_fc: dayjs(value).format('DD/MM/YYYY')
    }));
  };
  let obj_date = {
    label: 'Vencimento',
    field_data: 'dueDate',
    text_helper: 'Início do vencimento das parcelas',
    // max: data.installment === 'a_vista' ? dayjs() : dayjs().add((data.installment * data.installmentInterval), 'day'),
    min: data.date_release,
    handleChange: handleChangeDate,
    maxWidth: '250px',
    disabled: data.installment === 'a_vista',
  };

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography style={{ color: green[800] }}>
                <div className="d-lg-none">
                  <Typewriter tam={110} minHeight={0} text={'Agora confirme o valor de pagamento, defina as condições de pagamento.'} />
                </div>
                <div className="d-none d-lg-block">
                  <Typewriter tam={60} minHeight={0} text={'Agora confirme o valor de pagamento, defina as condições de pagamento.'} />
                </div>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Previsão de pagamento */}
        <Grid container spacing={3} sx={{ mt: 1, mb: 0 }}>
          <FormPaymentForecast data={data} handleChange={handleChange} obj_fn={obj_fn} />
        </Grid>
        <Divider sx={{ mt: 2, mb: 1, width: '90%', borderColor: green[200] }}/>

        {/* Condições pagamento */}
        <Grid container spacing={3} sx={{ mt: 2, mb: -2 }}>
          <FormPaymentCondition data={data} handleChange={handleChange} obj_date={obj_date} obj_fn={obj_fn} />
        </Grid>

        {/* Parcelas do pagamento */}
        {data.installment !== 'a_vista' && (<>
          <Divider sx={{ mt: -2, mb: 1, width: '90%', borderColor: green[200] }}/>

          <Grid container spacing={3} sx={{ ml: 1, mt: 1, mb: 1 }}>
            <FormPaymentInstallments data={data} obj_fn={obj_fn} />
          </Grid>
        </>)}

        <FooterModal data={data} obj_fn={obj_fn} isError={isError} returnError={returnError} />
      </Grid>
    </>
  );
}
