import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { blue, cyan, green } from '@mui/material/colors';
import { Context } from '../../Context/AuthContext';
import Loading from '../../Loader/Loading';
import useStore from '../../../store/useStore';
import LoadActivityListLivestock from '../../LoadActivityList/LoadActivityListLivestock';
import LoadActivityListHarvest from '../../LoadActivityList/LoadActivityListHarvest';
import CardChatKanban from '../CardChatKanban';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
const columns = [
  {
    id: 1,
    label: 'Resolver - A fazer',
    progress: 'A FAZER',
    icon: <RadioButtonUncheckedIcon sx={{ mr: 0.5, mt: -0.4 }} />,
  },
  {
    id: 2,
    label: 'Em progresso',
    progress: 'EM PROGRESSO',
    icon: <RadioButtonCheckedIcon sx={{ mr: 0.5, mt: -0.4, color: green[700] }} />,
  },
  // {
  //   id: 3,
  //   label: "Revisar",
  //   progress: "REVISAR",
  //   icon: <DoDisturbIcon sx={{ mr: 0.5, mt: -0.4 }} />,
  // },
  {
    id: 4,
    label: 'Resolvida - Concluída',
    progress: 'CONCLUÍDA',
    icon: <CheckCircleOutlineIcon sx={{ mr: 0.5, mt: -0.4, color: green[700] }} />,
  },
];
// Custom date comparison function considering both date and time
function compareDates(dateStr1, dateStr2) {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  return date1 - date2;
}

export default function BoardProgress({ type }) {
  const { aux_add } = useContext(Context);
  const { idFarm, id } = useParams();

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [rows, setRows] = useState([]);

  const loadActivityListLivestock = LoadActivityListLivestock();
  const loadActivityListHarvest = LoadActivityListHarvest();

  const usestore = useStore();

  const handleRows = () => {
    let filters = {
      table_active: 'realized',
      status: 'edit',
    };

    let rows_atv;
    if (type == 'harvest') {
      const currentHarvest = usestore.reposHarvest.getHarvestStore('id', id)[0];
      rows_atv = loadActivityListHarvest.loadList(currentHarvest, filters);
    } else {
      const currentLivestok = usestore.reposLivestock.getLivestockStore('id', id)[0];
      rows_atv = loadActivityListLivestock.loadList(currentLivestok, filters);
    }

    setRows(rows_atv);

    setTimeout(() => {
      setIsLoadingTable(false);
    }, 500);
  };

  useEffect(() => {
    console.log('refresh BoardProgress');
    handleRows();
  }, [aux_add]);

  // area do board
  return (
    <>
      <Box sx={{ ml: -3 }}>
        <div className="content-wrapper kanban p-0 m-0" style={{ backgroundColor: '#fff', font: '14px', color: '#333' }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col"></div>
              </div>
            </div>
          </section>
          {isLoadingTable ? (
            <>
              {' '}
              <Loading />{' '}
            </>
          ) : (
            <>
              <section className="content pb-3 ">
                <div className="container-fluid h-100">
                  {columns.map((types) => (
                    <div className={`card card-row `}>
                      <div className="card-header">
                        <h3 className="card-title">
                          {types.icon} {types.label}
                        </h3>
                      </div>
                      <div className="card-body">
                        {rows
                          ?.filter((filteredPost) => filteredPost.progress === types.progress)
                          .sort((a, b) => compareDates(b.startDate_nf, a.startDate_nf))
                          .map((post) => (
                            <CardChatKanban post={post} type={'progress'} isLoading={isLoadingTable} />
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </>
          )}
        </div>
      </Box>
    </>
  );
}
