import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DrawingManager, GoogleMap, InfoWindow, Marker, OverlayView, Polygon } from '@react-google-maps/api';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import LoadScriptOnlyIfNeeded from '../../../../../../LoadScript/LoadScriptOnlyIfNeeded';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Avatar, Card, Collapse, Divider, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { LocationOn } from '@mui/icons-material';
import useStore from '../../../../../../store/useStore';
import useActivityPastureManagementReposStore from '../../../../../../store/Activities/useActivityPastureManagementReposStore';
import './styles.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const libraries = ['drawing'];
const apiKey = 'AIzaSyBNOeEroFWGH9RPyAUlol-YHLcyHAx0xTU';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const MapViewBatch = ({ listBatch, data, setData }) => {
  console.log('MapView');
  console.log(listBatch);

  const navigate = useNavigate();
  const usestore = useStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  //o estado map representa a instância do mapa do Google Maps.
  //função será usada para atualizar a instância do mapa
  const [map, setMap] = React.useState();

  //id="map"
  // será usado para referenciar o mapa
  // é uma maneira de criar uma referência ao elemento do mapa na DOM para que você possa acessá-lo e manipulá-lo diretamente.
  //O useRef é usado para criar uma referência mutável que persiste durante toda a vida do componente.
  const mapRef = useRef(null);

  // carrego o id da criação
  //carego o id da propriedade
  const { idFarm, id } = useParams();

  // dados da propriedade
  // mapa: definir o centro da propriedade
  //const [center, setCenter] = useState({ lat: -20.9223027, lng: -42.7958599 });
  const [center, setCenter] = useState({ lat: '', lng: '' });
  const [zoom, setZoom] = useState(center.zoom || 15);

  // livestock, areas: as aŕaeas da livestock, lote: lote especifico selecionado, opt_lotes: lista de lotes com as áreas
  console.log(data);

  // tenho todos o nome de cada lote com o número de animias em cada lote
  if (listBatch) {
    console.log(listBatch);
  }

  // lista o manejo de pastagem que tem date_exit, id_aremap e o id_lote
  const reposPastureManagement = useActivityPastureManagementReposStore((state) => state);
  console.log(reposPastureManagement);
  const reposlistPastureManagement = reposPastureManagement.listPastureManagement;
  //activity_transfer_batch ver aqui
  //listas dos lotes selecionados
  console.log(reposlistPastureManagement);
  let listPastureManagementActive = reposlistPastureManagement.filter((item) => item.date_exit === null);
  console.log(listPastureManagementActive);

  // carrego a lista da criação current (livestock)
  // dentro da criação temos as áreas usadas
  const livestockRepos = usestore.reposLivestock.getLivestockStore('id', id)[0];
  console.log(livestockRepos);
  // tipo de livestock para recuperar o icon do marker
  //console.log(livestockRepos.tipo)
  // a criação tem aŕea 1 e 2

  // adicionar a informação da aŕea no reposlistPastureManagement
  const listAreas = usestore.reposAreas.listAreasMap;
  console.log('listAreas');
  console.log(listAreas);
  console.log(data);
  for (const pastureManagement of listPastureManagementActive) {
    console.log(pastureManagement);
    const areaInfo = listAreas.filter((item) => item.id === pastureManagement.id_areamap);
    pastureManagement.area = areaInfo[0];
    const batchInfo = listBatch.filter((item) => item.id === pastureManagement.id_lote);
    console.log(batchInfo);
    pastureManagement.batchName = batchInfo[0]?.area?.label || batchInfo[0]?.label || batchInfo[0]?.name;
  }

  console.log(listPastureManagementActive);

  let id_areamap = listPastureManagementActive[0].id_areamap;

  const list_areas = [];
  // devo criar a lista de áreas ativas do lote
  for (const area of livestockRepos.areas) {
    // pego o id da area
    //console.log(area.id)
    const areaActive = usestore.reposAreas.getAreaMapStore('id', area.id);
    list_areas.push(areaActive[0]);
  }
  //lista de áreas
  console.log(list_areas);

  // baricentro
  function getPolygonCenterbarycenter(polygonCoords) {
    let centroidX = 0,
      centroidY = 0;

    for (let i = 0; i < polygonCoords.length; i++) {
      //console.log(polygonCoords[i].lat)
      centroidX += polygonCoords[i].lat;
      centroidY += polygonCoords[i].lng;
    }

    centroidX = centroidX / polygonCoords.length;
    centroidY = centroidY / polygonCoords.length;

    return { lat: centroidX, lng: centroidY };
  }

  function calcularCentroNaExtremidadeInferior(polygonCoords) {
    let minLat = polygonCoords[0].lat; // Inicializar com a primeira latitude do polígono
    let centroidX = minLat;
    let centroidY = polygonCoords[0].lng;

    for (let i = 1; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat < minLat) {
        minLat = polygonCoords[i].lat;
        centroidX = minLat;
        centroidY = polygonCoords[i].lng;
      }
    }

    // Adicionar o deslocamento
    const deslocamentoLatitude = 0.002;
    const deslocamentoLongitude = 0.0001;

    centroidX += deslocamentoLatitude;
    centroidY += deslocamentoLongitude;

    return { lat: centroidX, lng: centroidY };
  }

  useEffect(() => {
    data.areaTranferId = { id_area: '', name: '', image: '' };
  }, []);

  // definir a posição do marker ao selecionar a área desejada para transferencia
  const [markerPosition, setMarkerPosition] = useState();

  function calcularCentroEntrePoligonosNew(polygonCoords, index) {
    let minLat = polygonCoords[0].lat; // Inicializar com a primeira latitude do polígono
    let centroidX = minLat;
    let centroidY = polygonCoords[0].lng;

    for (let i = 1; i < polygonCoords.length; i++) {
      if (polygonCoords[i].lat < minLat) {
        minLat = polygonCoords[i].lat;
        centroidX = minLat;
        centroidY = polygonCoords[i].lng;
      }
    }
    console.log(index);
    // Adicionar o deslocamento

    let deslocamentoLatitude = 0;
    let deslocamentoLongitude = 0;

    console.log(index);
    switch (index) {
      case 0:
        console.log(index);
        deslocamentoLatitude = 0.0005;
        deslocamentoLongitude = 0.0005;
        break;
      case 1:
        console.log(index);
        deslocamentoLatitude = 0.0005 + 1 / 1000 + 1 / 1000;
        // deslocamentoLongitude = 0.001 + (index / 1000) + (index / 1000);
        deslocamentoLongitude = 0.0002 + 2 / 1000 + 2 / 1000;
        break;
      default:
        deslocamentoLatitude = 0.0005 + 1 / 1000 + 1 / 1000;
        deslocamentoLongitude = 0.0001 + index / 1000 + index / 1000;
    }

    centroidX += deslocamentoLatitude;
    centroidY += deslocamentoLongitude;

    return { lat: centroidX, lng: centroidY };
  }

  const [listMarkerPosition, setListMarkerPosition] = useState();

  const listAreaBatch = [];

  for (const [index, areaBatch] of listPastureManagementActive.entries()) {
    console.log('areaBatch');
    console.log(areaBatch);
    console.log(listPastureManagementActive);
    console.log(listPastureManagementActive[0].area.coordinates);
    //console.log(listPastureManagementActive.area.coordinates)
    // // criar um array com as coordernadas de cada área da lista para plotar no mapa
    let decoded_coordinates = JSON.parse(base64_decode(areaBatch.area.coordinates));
    // console.log(decoded_coordinates)
    // //const name_coordinates = getPolygonBaseCenter(decoded_coordinates)
    // //console.log(name_coordinates)
    listAreaBatch.push({
      index: index,
      name: areaBatch.area?.label,
      //areaUse: areaActive.areaUse,
      //areatotal: areaActive.areatotal,
      batch: areaBatch.batchName,
      num_animals: areaBatch.number_animals,
      // icon: areaBatch.icon,
      coordenadas: calcularCentroEntrePoligonosNew(decoded_coordinates, index),
    });
    console.log('listAreaBatch');
    console.log(listAreaBatch);
    //console.log(listAreaBatch[index].coordenadas)
  }
  useEffect(() => {
    setListMarkerPosition(listAreaBatch);
  }, []);

  //setListMarkerPosition(listAreaBatch)

  // devo criar um array para armazenar a lista de coordenada de cada área
  const [plgn_areas, setPolygons] = useState([]);

  var paths_coord = [];
  let markerBatchCoordinates = [];
  // lista de áreas com coordenadas decodigicadas para serem plotadas no mapa
  const listAreaActive = [];
  const [markerBatch, setMarkerBatch] = useState({});
  const [iconMarkerBatch, setIconMarkerBatch] = useState({});
  const [numberAnimalsBatch, setNumberAnimalsBatch] = useState(data.lote.total_animals.toString());

  async function getId() {
    //console.log("getId: get poligons areaUsed");
    let iconUrl, position;
    for (const [index, areaActive] of list_areas.entries()) {
      // console.log("areaActive")
      // criar um array com as coordernadas de cada área da lista para plotar no mapa
      // console.log(areaActive)
      let decoded_coordinates = JSON.parse(base64_decode(areaActive.coordinates));
      // console.log(decoded_coordinates)
      //const name_coordinates = getPolygonBaseCenter(decoded_coordinates)
      //console.log(name_coordinates)
      listAreaActive.push({
        name: areaActive.name,
        //areaUse: areaActive.areaUse,
        //areatotal: areaActive.areatotal,
        coordenadas: decoded_coordinates,
      });
      // console.log("listAreaActive")
      // console.log(listAreaActive)
      // console.log(areaActive.id, id_areamap)

      // pegar as coordendas da área do lote plotará no grafico
      // informações de numeros de animias total em cada área
      if (areaActive.id === id_areamap) {
        //console.log("lote ativo")
        // console.log(areaActive)
        //console.log(areaActive.total_animais)
        //setNumberAnimalsBatch((areaActive.total_animais).toString())
        markerBatchCoordinates.push(decoded_coordinates);
        // console.log(markerBatchCoordinates[0])
        setMarkerBatch(getPolygonCenterbarycenter(markerBatchCoordinates[0]));
        setMarkerPosition(getPolygonCenterbarycenter(markerBatchCoordinates[0]));
        //console.log(markerBatch)

        // tipo do icone
        let iconUrl = '/dist/image';
        //console.log(livestockRepos.tipo)
        if (livestockRepos.tipo === 'Bovinocultura de Corte') {
          iconUrl += '/entrada_boi';
        } else if (livestockRepos.tipo === 'Bovinocultura de Leite') {
          iconUrl += '/entrada_vaca';
        } else if (livestockRepos.tipo === 'Avicultura de Corte') {
          iconUrl += '/entrada_frango';
        } else if (livestockRepos.tipo === 'Suinocultura') {
          iconUrl += '/entrada_suino';
        } else if (livestockRepos.tipo === 'Piscicultura') {
          iconUrl += '/entrada_peixe';
        } else {
          iconUrl += '/entrada_outros';
        }
        let iconBatch = iconUrl + '_afazer.png';
        // console.log(iconBatch)
        setIconMarkerBatch(iconBatch);
      }

      paths_coord.push({
        coordenadas: decoded_coordinates,
        //'marks':
        iconUrl: iconUrl + '_afazer.png',
        name: areaActive.name,
        number_animals: areaActive.total_animais || 0,
        areatotal: areaActive.area,
        id: areaActive.id,
        // 'areaUse': areaUse,
      });
    }
    // setar a lista de coordenadas
    //console.log(paths_coord)
    setPolygons(paths_coord);
  }

  useEffect(() => {
    // getId();
    setTimeout(() => {
      getId();
    }, 1000);
  }, []);

  const listcoordinates = [];
  for (const coordinates of plgn_areas) {
    listcoordinates.push(coordinates.coordenadas);
  }

  const calcularCentroEntrePoligonos = (poligonos) => {
    let centroLat = 0;
    let centroLng = 0;
    let totalVertices = 0;

    for (const poligono of poligonos) {
      for (const coordenada of poligono) {
        centroLat += coordenada.lat;
        centroLng += coordenada.lng;
        totalVertices++;
      }
    }

    centroLat /= totalVertices;
    centroLng /= totalVertices;

    return { lat: centroLat, lng: centroLng };
  };

  // cria o poligono
  const [drawingModeEnabled, setDrawingModeEnabled] = useState('polygon');

  const onLoadPoly = (polygon) => {
    //console.log(listcoordinates)
    const novoCentro = calcularCentroEntrePoligonos(listcoordinates);
    // console.log(novoCentro)
    setCenter(novoCentro);
    // setIsLoading(false)
  };

  const optionsDraw = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: drawingModeEnabled,
    },
    fillColor: 'green',
    fillOpacity: 0.7,
    strokeColor: '#53f444',
    strokeWeight: 2,
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
    circleOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  };

  //console.log("xxxxx")
  //console.log(data.areaTranferId)

  const renderOverlayContent = () => (
    <Card style={{ position: 'fixed', bottom: '4%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '10px' }}>
      <Box sx={{ p: 1, display: 'flex' }}>
        <div className="d-none d-lg-block">
          <Stack spacing={0} sx={{ ml: 1, alignItems: 'center', width: 400 }}>
            <Grid container spacing={1} sx={{ width: 400 }}>
              <Grid item>
                <Avatar variant="rounded" sx={{ bgcolor: green[500] }}>
                  <LocationOn />
                </Avatar>
              </Grid>
              <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography fontWeight={700}>Tranferência do lote</Typography>
                <Typography variant="body2" color="text.secondary">
                  Origem: <strong>{data.lote.area.name}</strong> <ArrowRightIcon sx={{ mt: -0.2, color: green[500] }} /> Destino:{' '}
                  <strong>{data.areaTranferId.name == '' ? 'Clique na área desejada' : data.areaTranferId.name}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </div>
        <div className="d-lg-none">
          <Typography variant="body2" fontWeight={700}>
            Clique na área
          </Typography>
        </div>
      </Box>
    </Card>
  );

  //console.log("plgn_areas")
  //console.log(plgn_areas)
  //console.log(markerBatch)
  //console.log(iconMarkerBatch)
  //console.log(numberAnimalsBatch)

  // OverlayView do name da área
  const [expanded, setExpanded] = useState(false);

  const handleIconClick = () => {
    setExpanded(!expanded);
  };

  const [showInfoWindow, setShowInfoWindow] = useState(true);

  const handleMarkerClick = () => {
    setShowInfoWindow(true);
  };

  const handleInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  /// Deslocamento vertical de -20 pixels (mover para cima)
  const infoWindowPixelOffset = { y: -20 };

  const infoWindowStyles = `
  background-color: black; // Cor de fundo preto
  color: white; // Cor do texto branco
  padding: 10px; // Espaçamento interno
`;

  console.log('center');
  console.log(center);
  console.log(listMarkerPosition);
  //console.log(data.lote.id)
  return (
    <>
      {isLoading && center.lat !== '' ? (
        <Skeleton variant="rectangular" width={'75vw'} height={'80vh'} />
      ) : (
        <Box style={{ width: '75vw', height: '80vh' }}>
          <LoadScriptOnlyIfNeeded id="scrit-loader" googleMapsApiKey={apiKey} libraries={libraries}>
            {/* <button type="text" placeholder="Last name" onClick={() => (refreshPage())} >Reload</button> */}
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              {/* <FloatingButtoDraw /> */}
              {/* <FloatingButtoDrawGeneral path={path} setPath={setPath} drawingModeEnabled={drawingModeEnabled} handleDrawingMode={handleDrawingMode} /> */}
              {/* <LightTooltip title="Retornar" placement="left">
          <Fab sx={{ position: "fixed", top: 1, right: 10, backgroundColor: "#fff" }} color="neutral" size="medium" onClick={() => navigate("/areasmaps")} aria-label="edit">
            <ReplyIcon />
          </Fab>
        </LightTooltip> */}
            </Box>
            {/* <SaveMapEditButton path={path} area={area} setArea={setArea} areasMap_data={areasMap_data} /> */}
            <GoogleMap
              id="map"
              mapContainerStyle={{
                height: '80vh',
                width: '75vw',
              }}
              ref={mapRef}
              center={center}
              zoom={Number(zoom)}
              options={{
                mapTypeId: 'satellite', // Define o tipo de mapa padrão como "Satélite"
                //mapTypeControl: true,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: true,
                zoomControlOptions: {
                  //style: window.google.maps.ZoomControlStyle.SMALL,
                  gestureHandling: 'greedy', // Torna o zoom mais sensível
                },
              }}
            >
              {true && (
                <>
                  {listMarkerPosition?.map((item) => (
                    <>
                      <Marker
                        // position={{ lat: item.coordenadas.lat, lng: (item.coordenadas.lng + 0.005) }}
                        position={item.coordenadas}
                        icon={iconMarkerBatch}
                        onClick={handleMarkerClick}
                        label={{
                          text: item?.num_animals.toString(),
                          fontSize: '12px',
                          fillOpacity: '0.7',
                          className: 'custom-marker-label',
                        }}
                      />

                      {showInfoWindow && (
                        <InfoWindow
                          position={{ lat: item.coordenadas.lat + 0.003, lng: item.coordenadas.lng }}
                          onCloseClick={handleInfoWindowClose}
                          //pixelOffset={infoWindowPixelOffset}
                        >
                          {item.batch === data.lote?.label ? (
                            <div style={{ backgroundColor: 'black', color: 'white', padding: '5px' }}>
                              <p>
                                <strong>{item.batch}</strong>
                              </p>
                            </div>
                          ) : (
                            <div>
                              <p>{item.name}</p>
                            </div>
                          )}
                        </InfoWindow>
                      )}
                    </>
                  ))}

                  {/* {markerPosition && (
                <Marker
                  position={markerPosition}
                  icon={iconMarkerBatch}
                  label={{
                    text: numberAnimalsBatch,
                    fontSize: "12px",
                    className: "custom-marker-label"
                  }}
                />

              )} */}
                  {plgn_areas.map((item) => (
                    <>
                      <React.Fragment key={item.id}>
                        <Polygon
                          //a propriedade paths do objeto Polygon especifica uma matriz
                          options={optionsDraw}
                          path={item.coordenadas}
                          onLoad={onLoadPoly}
                          //onClick={(e) => handlePolygonClick(e, item)}
                        />
                      </React.Fragment>

                      <OverlayView
                        mapPaneName={'overlayMouseTarget'}
                        position={calcularCentroNaExtremidadeInferior(item.coordenadas)}
                        // position={getPolygonCenterbarycenter(item.coordenadas)}
                      >
                        <IconButton>
                          <Card sx={{ backgroundColor: 'black', color: 'white', borderRadius: '8px' }}>
                            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                              <Avatar variant="rounded" src="/dist/image/icon_verde_location.png" sx={{ width: 21, height: 28, position: 'absolute', left: '0px' }} />
                              <Stack spacing={0.5}>
                                <Typography sx={{ fontSize: 10, color: 'white', ml: '8px' }} fontWeight={500}>
                                  {item.name}
                                </Typography>
                                {/* <Collapse in={expanded}>
                              <Divider />
                              <Typography sx={{ fontSize: 10, color: 'white' }} color="text.secondary">
                                <MapIcon sx={{ color: grey[800], fontSize: 12, color: 'white' }} /> {item.areaUse}/{item.areatotal} ha
                              </Typography>
                            </Collapse> */}
                              </Stack>
                            </Box>
                          </Card>
                        </IconButton>
                      </OverlayView>
                    </>
                  ))}
                </>
              )}
            </GoogleMap>
          </LoadScriptOnlyIfNeeded>
        </Box>
      )}
    </>
  );
};

export default MapViewBatch;
